import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableColecoes = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [colecoes, setColecoes] = useState([]);  
  const [colecoesData, setColecoesData] = useState([]);
  const [selectedColecoes, setSelectedColecoes] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirColecoesSelecionadas } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedColecoes = sessionStorage.getItem('selectedColecoes');
    if (storedSelectedColecoes) {    
      setSelectedColecoes(JSON.parse(storedSelectedColecoes));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedColecoes.length > 0)
   {
      sessionStorage.setItem('selectedColecoes', JSON.stringify(selectedColecoes));
   }       
  }, [selectedColecoes, colecoes]);  

  const handleColecaoSelection = (id) => {
    const updatedIndexes = selectedColecoes.includes(id)
      ? selectedColecoes.filter((index) => index !== id)
      : [...selectedColecoes, id];
    setSelectedColecoes(updatedIndexes);   

    const selectedColecoesIds = updatedIndexes.map((index) => index.id);
    definirColecoesSelecionadas(selectedColecoesIds);
  };

  const handleRemoverColecoesSelecionadasClick = (id) => {
    const updatedColecoesSelecionadas = selectedColecoes.includes(id)
      ? selectedColecoes.filter((index) => index !== id)
      : [...selectedColecoes, id];
    setSelectedColecoes(updatedColecoesSelecionadas);   

    const selectedColecoesIds = updatedColecoesSelecionadas.map((index) => index.id);
    definirColecoesSelecionadas(selectedColecoesIds);

    if (selectedColecoesIds.length === 0)
    {
      sessionStorage.removeItem('selectedColecoes');
    }
  };

  const handleRowClick = (colecaoId) => {
    setColecoes((prevColecoes) =>
      prevColecoes.filter((colecao) => colecao.id !== colecaoId)
    );
  };

  const handleRowSelectedClick = (colecao) => {

    if (!colecoes.includes(colecao.id))
    {
        const updatedColecoes = [...colecoes, colecao];
        setColecoes(updatedColecoes);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedColecoes(colecoes);
       setColecoes([]);
       
       const selectedColecoesIds = colecoes.map((index) => index.id);
       definirColecoesSelecionadas(selectedColecoesIds);       
       sessionStorage.setItem('selectedColecoes', selectedColecoes);      
  }

  const handleRemoverTodos = async() => {         
      if (!colecoesData || colecoesData.length === 0)
      {
        getColecoes(); 
      }      
      setColecoes(colecoesData);
      setSelectedColecoes([]);    
      definirColecoesSelecionadas([]);         
      sessionStorage.removeItem('selectedColecoes');      
  }

  const getColecoes = async () => {   
    console.log('pesquisando');
    try {
      let url = 'colecao';

      if (busca !== '') {
        url = `colecao/descricao/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setColecoes(response.data);   
      setColecoesData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getColecoes();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da coleção"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>                 
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {colecoes.map((colecao) => {
                  if (selectedColecoes.some((mrc) => mrc.id === colecao.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={colecao.id}
                        onClick={() => {
                          handleColecaoSelection(colecao);
                          handleRowClick(colecao.id);
                        }}
                      >
                        <td>{colecao.id}</td>
                        <td>{colecao.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedColecoes.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedColecoes.map((colecao) => (
                    <tr key={colecao.id}
                        onClick={() => {
                          handleRemoverColecoesSelecionadasClick(colecao);
                          handleRowSelectedClick(colecao)
                        }}
                    >
                      <td>{colecao.id}</td>
                      <td>{colecao.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableColecoes;

