import React, { useContext } from 'react';
import Layout from './LayoutPadrao';

import {
    HashRouter as Router,
    Route,
    Routes    
} from "react-router-dom";

import { AuthProvider , AuthContext } from "./contexts/auth";
import LoginPage from './pages/LoginPage';
import CondicionalProvider from './pages/Condicional';
import AnaliseCondicionalProvider from './pages/AnaliseCondicional';
import VendaPeriodoProvider from './pages/VendaPeriodo';
import VendaProdutoProvider from './pages/VendaProduto';
import CrediarioProvider from './pages/Crediario'
import LoadingScreen from './components/Loading/TelaLoading';
import PaginaPrincipal from './pages/Principal';
import EstoqueProvider from './pages/Estoque';
import FinanceiroProvider from './pages/Financeiro';
import ContasAPagarProvider from './pages/ContasAPagar'
import ComprasVendasProvider from './pages/ComprasVendas';
import ComprasFornecedorProvider from './pages/ComprasFornecedor';
import ContagemEstoqueProvider from './pages/ContagemEstoque';
import DREProvider from './pages/DRE';
import ComissaoVendedorProvider from './pages/ComissaoVendedor';
import ComissaoRecebimentoProvider from './pages/ComissaoRecebimento';
import ClientesProvider from './pages/AnaliseDados/ListagemClientes';

const AppRoutes = () => {
    const Private = ({children}) => {
        const {authenticated, loading } = useContext(AuthContext);
       
        if (loading) {
            return (                
               <LoadingScreen/>
            );
          }

        if(!authenticated)
        {                       
            return;
        }

        return children;
    };

    return(
        <Router basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          <Routes>   
            <Route exact path="/login/:idLoja/:dealer" element={<LoginPage />} />
          </Routes>  

          <Private>
          <Layout> 
            <Routes>            
              <Route exact path="/home" element={<PaginaPrincipal />} />
              <Route exact path="/condicional" element={<CondicionalProvider />} />
              <Route exact path="/analisecondicional" element={<AnaliseCondicionalProvider />} />
              <Route exact path="/crediario" element={<CrediarioProvider />} />
              <Route exact path="/contasapagar" element={<ContasAPagarProvider />} />
              <Route exact path="/vendaperiodo" element={<VendaPeriodoProvider />} />  
              <Route exact path="/vendaproduto" element={<VendaProdutoProvider />} />  
              <Route exact path="/estoque" element={<EstoqueProvider />} />  
              <Route exact path="/entradasaidas" element={<FinanceiroProvider />} />     
              <Route exact path="/comprasvendas" element={<ComprasVendasProvider />} />     
              <Route exact path="/comprasfornecedor" element={<ComprasFornecedorProvider />} />     
              <Route exact path="/estoque/contagem" element={<ContagemEstoqueProvider />} />  
              <Route exact path="/dre" element={<DREProvider />} />
              <Route exact path="/comissao/vendedor" element={<ComissaoVendedorProvider />} />
              <Route exact path="/comissao/recebimento" element={<ComissaoRecebimentoProvider />} />
              <Route exact path="/analisedados/clientes" element={<ClientesProvider />} />
            </Routes>
          </Layout>    
          </Private>    

        </AuthProvider>
      </Router>
    )
}

export default AppRoutes;