import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../LayoutPadrao/styles.css';
import { LicenseInfo } from '@mui/x-license-pro';
import SessionStorageUtils from '../Utils/SessionStorageUtils';
import DrawerLeft  from '../Drawer/index';

const Layout = ({ children }) => {   
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [userLogin, setUserLogin] = useState('');  
  const [nomegrupo, setNomeGrupo] = useState('');
  const [plano, setPlano] = useState('');  
 
  useEffect(() => {
    LicenseInfo.setLicenseKey('c1a3dfa4b9172f3511c9e71aa4968b66Tz03MDgyNyxFPTE3MjExMzY4NDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');         
    SetUser();
  }, []);   
  
  const SetUser = async () => {    
    const desencryptJSONObj = await sessionStorageUtils.GetUsuario();  
    const userLogin = JSON.parse(desencryptJSONObj).login;
    const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
    const plano = JSON.parse(desencryptJSONObj).plano;    
    setUserLogin(userLogin);
    setNomeGrupo(groupName);    
    setPlano(plano);    
  }  
   
  return (
    <>                      
      <DrawerLeft nomegrupo={nomegrupo} usuario={userLogin} plano={plano}/>                     
      <div >{children}</div>
    </>
  );
};

export default Layout;