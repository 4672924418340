import * as XLSX from 'xlsx';
const { format } = require('date-fns');

//IMPRESSÃO DE RELATÓRIO DE CONTAGEM DE ESTOQUE

export class DRE_XLS {
    constructor(dadosParaImpressao, totalizadoresImpressao) {
      this.dadosParaImpressao = dadosParaImpressao;
      this.totalizadoresImpressao = totalizadoresImpressao;
    }
  
    exportToXLS () {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(this.dataExportXLS());
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `D.R.E_${format(new Date(), 'dd_MM_yyyy')}.xlsx`);
    }
  
    dataExportXLS = () => {      
      const dataRows = this.dadosParaImpressao.flatMap((dt) => {        
        const detalhesLinha = dt.subRows.map((detalhe) => ({
          DESCRICAO: detalhe.descricao, 
          VALOR_R$: this.formatDecimal(detalhe.valor) ,             
        }));
            
        return [
          { DESCRICAO: dt.descricao, VALOR_R$: dt.valor !=  '0' ? this.formatDecimal(dt.valor) : '' }, 
          ...detalhesLinha,
        ];
      });          
    
      return dataRows;
    } 
              
    calcTotal = (property) => {
      return this.dadosParaImpressao.reduce((sum, dt) => sum + dt[property], 0);
    }

    formatDecimal = (value) => {      
      if (!isNaN(value)) {
        return parseFloat(value).toFixed(2);
      }
      return value; 
    };
}

