import React from 'react';
import { Button } from 'react-bootstrap';
import { PrinterOutlined } from '@ant-design/icons';

function ButtonPrint({ onClick }) {
  return (
    <Button
      type="button"
      onClick={onClick}
      style={{
        backgroundColor: 'transparent',
        border: '0px',
        color: 'rgb(27, 119, 253)',
        fontSize: '15px',
        width: '100px',
        height: '30px',
        margin: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        cursor: 'pointer',
      }}
    >
      <PrinterOutlined style={{ marginRight: '4px' }} /> IMPRIMIR
    </Button>
  );
}

export default ButtonPrint;