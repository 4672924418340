import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Button, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableFornecedores = ({contexto}) => {  
  const [fornecedores, setFornecedores] = useState([]);  
  const [fornecedoresData, setFornecedoresData] = useState([]);
  const [selectedFornecedores, setSelectedFornecedores] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirFornecedoresSelecionados } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedFornecedores = sessionStorage.getItem('selectedFornecedores');
    if (storedSelectedFornecedores) {    
      setSelectedFornecedores(JSON.parse(storedSelectedFornecedores));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedFornecedores.length > 0)
   {
      sessionStorage.setItem('selectedFornecedores', JSON.stringify(selectedFornecedores));
   }       
  }, [selectedFornecedores, fornecedores]);  

  const handleClienteSelection = (id) => {
    const updatedIndexes = selectedFornecedores.includes(id)
      ? selectedFornecedores.filter((index) => index !== id)
      : [...selectedFornecedores, id];
    setSelectedFornecedores(updatedIndexes);   

    const selectedFornecedoresIds = updatedIndexes.map((index) => index.id);
    definirFornecedoresSelecionados(selectedFornecedoresIds);
  };

  const handleRemoverClientesSelecionadosClick = (id) => {
    const updatedFornecedoresSelecionados = selectedFornecedores.includes(id)
      ? selectedFornecedores.filter((index) => index !== id)
      : [...selectedFornecedores, id];
    setSelectedFornecedores(updatedFornecedoresSelecionados);   

    const selectedFornecedoresIds = updatedFornecedoresSelecionados.map((index) => index.id);
    definirFornecedoresSelecionados(selectedFornecedoresIds);

    if (selectedFornecedoresIds.length === 0)
    {
      sessionStorage.removeItem('selectedFornecedores');
    }
  };

  const handleRowClick = (itemId) => {
    setFornecedores((prevFornecedores) =>
      prevFornecedores.filter((item) => item.id !== itemId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!fornecedores.includes(item.id))
    {
        const updatedFornecedores = [...fornecedores, item];
        setFornecedores(updatedFornecedores);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedFornecedores(fornecedores);
       setFornecedores([]);
       
       const selectedFornecedoresIds = fornecedores.map((index) => index.id);
       definirFornecedoresSelecionados(selectedFornecedoresIds);       
       sessionStorage.setItem('selectedFornecedores', selectedFornecedores);      
  }

  const handleRemoverTodos = async() => {         
      if (!fornecedoresData || fornecedoresData.length === 0)
      {
         getFornecedores(); 
      }      
      setFornecedores(fornecedoresData);
      setSelectedFornecedores([]);    
      definirFornecedoresSelecionados([]);         
      sessionStorage.removeItem('selectedFornecedores');      
  }

  const getFornecedores = async () => {   
    try {
      let url = 'fornecedor';

      if (busca !== '') {
        url = `fornecedor/nomerazaofiltro/${busca.toUpperCase()}`;
      }
            
      await SetTenant();
      const response = await api.get(url);
      setFornecedores(response.data);   
      setFornecedoresData(response.data);
      console.log('pesquisando');
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getFornecedores();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite o Fornecedor"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar}/>                
            </div>            
          </div>
        </div>
      </Card>

        <div>
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos
                     onClick={handleAdicionarTodos}
                  />
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {fornecedores.map((item) => {
                  if (selectedFornecedores.some((cli) => cli.id === item.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={item.id}
                        onClick={() => {
                          handleClienteSelection(item);
                          handleRowClick(item.id);
                        }}
                      >
                        <td>{item.id}</td>
                        <td>{item.fantasia}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedFornecedores.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos
                  onClick={handleRemoverTodos}
                />                  
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedFornecedores.map((item) => (
                    <tr key={item.id}
                        onClick={() => {
                          handleRemoverClientesSelecionadosClick(item);
                          handleRowSelectedClick(item)
                        }}
                    >
                      <td>{item.id}</td>
                      <td>{item.fantasia}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>
        </div>
      </div>    
  );
};

export default TableFornecedores;

