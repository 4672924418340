import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonFornecedores({ onClick, width, marginTop, fornecedoresFiltro }) {
  return (    
    <Button style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        border: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
        marginTop : marginTop,
        borderRadius: '15px'                                 
        }} 
        onClick={onClick}>
            + Fornecedores
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {fornecedoresFiltro.length > 0 ? fornecedoresFiltro.length : ''}
            </span>
        </Button>                                        
  );
}

export default ButtonFornecedores;


