import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableManequimSuperior = ({ contexto }) => {
  const [manequimSuperior, setManequimSuperior] = useState([]);
  const [manequimSuperiorData, setManequimSuperiorData] = useState([]);
  const [selectedManequimSuperior, setSelectedManequimSuperior] = useState([]);
  const [busca, setBusca] = useState('');
  const { definirManequimSuperiorSelecionado } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {
    const storedSelectedManequimSuperior = sessionStorage.getItem('selectedManequimSuperior');
    if (storedSelectedManequimSuperior) {
      setSelectedManequimSuperior(JSON.parse(storedSelectedManequimSuperior));
    }
    pesquisaRef.current.focus();
  }, []);

  useEffect(() => {
    if (selectedManequimSuperior.length > 0) {
      sessionStorage.setItem('selectedManequimSuperior', JSON.stringify(selectedManequimSuperior));
    }
  }, [selectedManequimSuperior, manequimSuperior]);

  const handleManequimSuperiorSelection = (id) => {
    const updatedIndexes = selectedManequimSuperior.includes(id)
      ? selectedManequimSuperior.filter((index) => index !== id)
      : [...selectedManequimSuperior, id];
    setSelectedManequimSuperior(updatedIndexes);

    const selectedManequimSuperiorIds = updatedIndexes.map((index) => index.descricao);
    definirManequimSuperiorSelecionado(selectedManequimSuperiorIds);
  };

  const handleRemoverManequimSuperiorSelecionadosClick = (id) => {
    const updatedManequimSuperiorSelecionados = selectedManequimSuperior.includes(id)
      ? selectedManequimSuperior.filter((index) => index !== id)
      : [...selectedManequimSuperior, id];
    setSelectedManequimSuperior(updatedManequimSuperiorSelecionados);

    const selectedManequimSuperiorIds = updatedManequimSuperiorSelecionados.map((index) => index.descricao);
    definirManequimSuperiorSelecionado(selectedManequimSuperiorIds);

    if (selectedManequimSuperiorIds.length === 0) {
      sessionStorage.removeItem('selectedManequimSuperior');
    }
  };

  const handleRowClick = (manequimSuperiorId) => {
    setManequimSuperior((prevManequimSuperior) =>
      prevManequimSuperior.filter((manequimSuperior) => manequimSuperior.id !== manequimSuperiorId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!manequimSuperior.includes(item.id)) {
      const updatedManequimSuperior = [...manequimSuperior, item];
      setManequimSuperior(updatedManequimSuperior);
    }
  };

  const handleAdicionarTodos = () => {
    setSelectedManequimSuperior(manequimSuperior);
    setManequimSuperior([]);

    const selectedManequimSuperiorIds = manequimSuperior.map((index) => index.descricao);
    definirManequimSuperiorSelecionado(selectedManequimSuperiorIds);
    sessionStorage.setItem('selectedManequimSuperior', selectedManequimSuperior);
  }

  const handleRemoverTodos = async () => {
    if (!manequimSuperiorData || manequimSuperiorData.length === 0) {
      getManequimSuperior();
    }
    setManequimSuperior(manequimSuperiorData);
    setSelectedManequimSuperior([]);
    definirManequimSuperiorSelecionado([]);
    sessionStorage.removeItem('selectedManequimSuperior');
  }

  const getManequimSuperior = async () => {
    try {
      let url = `tamanhoproduto/listagem/false/${busca.toUpperCase()}`;  
      await SetTenant();
      const response = await api.get(url);
      
      
      const selectedVendedoresIds = selectedManequimSuperior.map(tamanhoproduto => tamanhoproduto.id);    
      const filteredData = response.data.filter(tamanhoproduto => 
        !selectedVendedoresIds.includes(tamanhoproduto.id)
      );

      setManequimSuperior(filteredData);
      setManequimSuperiorData(filteredData);
    } catch (e) {
      console.log('erro');
    }
  };

  const handlePesquisar = () => {
    getManequimSuperior();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }
    }
  }

  return (
    <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
      <Card style={{ border: '0px' }}>
        <div className="align-left">
          <div className="row">
            <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição do Manequim Superior"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>
          </div>
        </div>
      </Card>

      <Container>
        <div className="col">
          <Card>
            <div className='col'>
              <ButtonAdicionarTodos onClick={handleAdicionarTodos} />
            </div>
          </Card>
          <Card>
            <table className="table table-striped table-hover">
              <thead className="table mt-3">
                <tr>                  
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white', textAlign: 'center' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {manequimSuperior.map((manequimsuperior) => {
                  if (selectedManequimSuperior.some((mrc) => mrc.id === manequimsuperior.id)) {
                    return null;
                  } else {
                    return (
                      <tr
                        key={manequimsuperior.id}
                        onClick={() => {
                          handleManequimSuperiorSelection(manequimsuperior);
                          handleRowClick(manequimsuperior.id);
                        }}
                      >                        
                        <td className='col text-center'>{manequimsuperior.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Card>
        </div>

        <div className="col">
          <Card>
            <Card>
              <div className='row justify-content-end'>
                <div className='col text-left'>
                  <label className='labelSelecionar'>Selecionados: {selectedManequimSuperior.length}</label>
                </div>
                <div className='col text-right'>
                  <ButtonRemoverTodos onClick={handleRemoverTodos} />
                </div>
              </div>
            </Card>
            <table className="table table-striped table-hover">
              <thead className="table-info mt-3">
                <tr>                  
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white', textAlign: 'center'  }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {selectedManequimSuperior.map((manequimsuperior) => (
                  <tr key={manequimsuperior.id}
                    onClick={() => {
                      handleRemoverManequimSuperiorSelecionadosClick(manequimsuperior);
                      handleRowSelectedClick(manequimsuperior)
                    }}
                  >                    
                    <td className='col text-center'>{manequimsuperior.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default TableManequimSuperior;

