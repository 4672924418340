import  React , { createContext, useState, useEffect, useRef } from 'react';
import {        
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
} from '@mui/x-data-grid';
import PaginationItem from '@mui/material/PaginationItem';
import "react-datepicker/dist/react-datepicker.css";
import { Container, FormControl, FormControlLabel, InputLabel, MenuItem, Pagination, Radio, Select, Switch, Typography, setRef } from '@mui/material';
import { api } from '../../services/api';
import { Card, Button } from 'react-bootstrap';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalItem from '../../components/Modal/ModalItens';
import ModalMarcas from '../../components/Modal/ModalMarcas';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonItens from '../../components/Buttons/ButtonItens';
import LoadingScreen from '../../components/Loading/TelaLoading';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import '../../pages/Estoque/styles.css';
import { isEmpty } from 'lodash';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { ImpressaoEstoquePDF } from '../../components/Impressao/ImpressaoEstoquePDF';
import ButtonMarcas from '../../components/Buttons/ButtonMarcas';
import clsx from 'clsx';
import ButtonReferencias from '../../components/Buttons/ButtonReferencias';
import ModalReferencias from '../../components/Modal/ModalReferencias';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { ContagemEstoqueXLS } from '../../components/Impressao/Excel/ContagemEstoqueXLS';
import { ImpressaoContagemEstoquePDF } from '../../components/Impressao/ImpressaoContagemEstoque';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ContagemEstoqueContext = createContext();

  export const ContagemEstoqueProvider = () => {   
    const sessionStorageUtils = new SessionStorageUtils();  
    const PAGE_SIZE = 50;
    const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0});         
    const [showItens, setShowItens] = useState(false);
    const [showMarcas, setShowMarcas] = useState(false);                    
    const [showReferencias, setShowReferencias] = useState(false);
    const [dadosContagemEstoque, setDadosContagemEstoque] = useState([]);  
    const [dadosContagemEstoqueTotalizador, setDadosContagemEstoqueTotalizador] = useState([]);  
        
    const [tipoVisaoSelect, setTipoVisaoSelect] = useState('produto');    
    const [lojasFiltro, setLojasFiltro] = useState([]); 
    const [lojaSelecionada, setLojaSelecionada] = useState({idloja: 0, nome: '' });
    
    const [itensFiltro, setItensFiltro] = useState([]);
    const [marcasFiltro, setMarcasFiltro] = useState([]);                       
    const [referenciasFiltro, setReferenciasFiltro] = useState([]);   
    const [nomeGrupo, setNomeGrupo] = useState([]);    
    const [loading, setLoading] = useState(true);  
    const [limparFiltros, SetLimparFiltros] = useState(false);    
    const [contagens, setContagens] = useState([]);
    const [contagemSelecionada, setContagemSelecionada] = useState({id: 0, descricao: '' });    
    const [somenteDiferencas, setSomenteDiferencas] = useState({ diferencas: false });
    const navigate = useNavigate();
    const permissao = new Permissoes(); 

    registerLocale('pt-BR', dateFnsPtBR);
    setDefaultLocale('pt-BR');       
 
    useEffect(() => {
        setTimeout(() => {     
            setLoading(true);   
            if (ValidarPermissoes() == false) { return; }     
            LimparFiltrosSelecionados();
            definirLojaInicial();             
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []);   

    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('b7de6e30a193716d571b7836f96b19eb');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  
        
    useEffect(() => {            
        if (lojaSelecionada.idloja > 0)
        {
            GetContagensByUsuarioId();
        }        
      }, [lojaSelecionada]);     
      
      const handleLimparFiltros = async () => {    
        LimparFiltrosSelecionados()                
        SetLimparFiltros(true);
      }
      
      function LimparFiltrosSelecionados() {        
        sessionStorage.removeItem('selectedMarcas');
        sessionStorage.removeItem('selectedItens');                         
        sessionStorage.removeItem('selectedReferencias');        
        setMarcasFiltro([]);
        setItensFiltro([]);                        
        setReferenciasFiltro([]);       
        setDadosContagemEstoque([]);
        definirLojaInicial(); 
      }      
 
    const DefinirNomeGrupo = async () => {
        const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
        const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
        setNomeGrupo(groupName);
    }

    function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
    const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
    
    return (
        <>
        <div>           
            <div className='table-align'>
                {renderTableTotalizador()}
            </div>
            <div className='table-align'>
                {renderTable()}                
            </div>                        
           

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <Typography variant="subtitle2" color="textSecondary">
                        Total de registros: {totalRowCount}
                    </Typography>
                </div>

                <div style={{marginLeft: '50px'}}>
                    <Typography variant="subtitle2" color="textSecondary" >
                        Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                    </Typography>
                </div>
            </div>
                
                <div style={{ alignSelf: 'flex-end'}}>
                    <Pagination
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        page={page + 1}
                        count={pageCount}                                                    
                        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}
                    />
                </div>
            </div>            
        </div>
        
        </>
        );        
    }  

    const definirLojaInicial = async()  => 
    {        
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();             
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null)
        {                        
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant();  
            const response = await api.get(`loja/lojascadastradas/${userId}`);                        
            await setLojasFiltro(response.data);  
            
            await setLojaSelecionada({
                idloja: response.data[0].idloja,
                nome: response.data[0].nome
            });                                                       
        }          
    } 

    async function GetContagensByUsuarioId()
    {
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                                  
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null)
        {       
            const userId = JSON.parse(desencryptJSONUser).id;   
            await SetTenant();
            const responseContagem = await api.get(`contagem/listar/usuarioacesso/${userId}/${lojaSelecionada.idloja}`);            
            setContagens(responseContagem.data); 
            var contagemFirst = responseContagem.data[0];  
            
            setContagemSelecionada({id: 0, descricao: ''});
            if (contagemFirst != null)
            {
                await setContagemSelecionada({
                    id: contagemFirst.id,
                    descricao: contagemFirst.descricao
                });                        
            }            
        }
    }
    
    const filtrosSelecionados = {
        tipoVisao : tipoVisaoSelect,        
        lojasSelecionadas : lojasFiltro,
        itensSelecionados : itensFiltro,
        marcasSelecionadas : marcasFiltro,        
        referenciasSelecionadas : referenciasFiltro,        
        lojaSelecionada : lojaSelecionada,        
        nomeGrupo : nomeGrupo
    }  

    const visualizarImpressao = async  () => {        
        const classeImpressao = new ImpressaoContagemEstoquePDF(dadosContagemEstoque, dadosContagemEstoqueTotalizador, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));       
    }  

    const exportarExcel = async  () => {        
        const exportarExcel = new ContagemEstoqueXLS(dadosContagemEstoque, dadosContagemEstoqueTotalizador);
        exportarExcel.exportToXLS();
    }  

    const handleExecutar = async() => {                                                                     
        setLoading(true);                                                   
        await getRegistrosDeEstoqueContagem();            
        setLoading(false);              
    } 

    
    const tipoVisaoFiltroToInt = () => {
        const enumValues = {
          'produto' : 1,
          'item': 2,
          'marca' : 3,          
        };
      
        return enumValues;
      };           
    
    const getRegistrosDeEstoqueContagem = async() => {                  
        try 
        {                                                
            let url = `registroestoquemovimento/contagem/${lojaSelecionada.idloja}/${contagemSelecionada.id}`;  
            let urlTotalizador = `registroestoquemovimento/contagem/totalizador/${lojaSelecionada.idloja}/${contagemSelecionada.id}`;         
            
            if (itensFiltro.length > 0) {                
                url += `/${itensFiltro}`;
                urlTotalizador += `/${itensFiltro}`;
            } else {
                url += "/ ";
                urlTotalizador += "/ ";
            }

            if (marcasFiltro.length > 0) {                
                url += `/${marcasFiltro}`;
                urlTotalizador += `/${marcasFiltro}`;
            } else {
                url += "/ ";
                urlTotalizador += "/ ";
            }         

            if (referenciasFiltro.length > 0) {                
                url += `/${referenciasFiltro}`;
                urlTotalizador += `/${referenciasFiltro}`;
            } else {
                url += "/ ";
                urlTotalizador += "/ ";
            }    
            
            url += `/${somenteDiferencas.diferencas}`;           
            
            await setDadosContagemEstoque([]);            
            await SetTenant(); 
            const response = await api.get(url);               
            await setDadosContagemEstoque(response.data);                                                 
           
            setDadosContagemEstoqueTotalizador([]);
            const responseTotalizadores = await api.get(urlTotalizador);
            await setDadosContagemEstoqueTotalizador(responseTotalizadores.data);
        }
        catch (e)
        {
            console.log('erro');
        }        
    }                     

   const handleShowMarcas = () => 
   {
        setShowMarcas(true);
   }      

   const handleShowReferencias = () => 
   {
        setShowReferencias(true);
   }    

   const handleCloseItens = () => 
   {        
        setShowItens(false);                           
   }

   const handleCloseMarcas = () => 
   {
        setShowMarcas(false);
   }

   const handleCloseReferencias = () => 
   {
        setShowReferencias(false);
   }

   const handleShowItens = () => 
   {            
        setShowItens(true);
   }      

    const definirItensSelecionados = (id) => 
    {
        setItensFiltro(id);        
    }

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }  
    
    const definirMarcasSelecionadas = (id) =>
    {
        setMarcasFiltro(id);
    }            

    const definirReferenciasSelecionadas = (id) =>
    {
        setReferenciasFiltro(id);
    }         

    const rowsProdutos = dadosContagemEstoque.map((row) => {           
            const produtoInfo = {
                id : row.id,
                codigo : row.produto.id,
                descricao : row.produto.descricao,
                item : row.produto.grade.produtogrupo.item.descricao,
                marca : row.produto.grade.produtogrupo.marca.descricao,
                referencia : row.produto.grade.referencia,
                tamanho : row.produto.tamanhoproduto.descricao,
                cor : row.produto.grade.cor.descricao,
                qtdeanterior : row.qtde,       
                qtdecontado : row.qtdecontado,                                 
                qtdediferenca : row.qtdediferenca,                                 
            }                      
            return produtoInfo;                      
    });    

    const columnsProdutos = [                    
        { field: "codigo", headerName: "Cód", headerClassName: 'blue-header', headerAlign: 'left', width: 80, align: 'left' },
        { field: "descricao", headerName: "Descrição", headerClassName: 'blue-header', headerAlign: 'left', width: 265, align: 'left'},                
        { field: "item", headerName: "Item",  headerClassName: 'blue-header', headerAlign: 'left', width: 165, align: 'left'}, 
        { field: "marca", headerName: "Marca",  headerClassName: 'blue-header', headerAlign: 'left', width: 125, align: 'left'}, 
        { field: "referencia", headerName: "Referência",  headerClassName: 'blue-header', headerAlign: 'left', width: 100, align: 'left'}, 
        { field: "tamanho", headerName: "Tamanho",  headerClassName: 'blue-header', headerAlign: 'left', width: 90, align: 'left'}, 
        { field: "cor", headerName: "Cor",  headerClassName: 'blue-header', headerAlign: 'left', width: 100, align: 'left'},         
        { field: "qtdeanterior", 
        renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
        Qtde<br/>Anterior</div>),
        headerClassName: 'blue-header', headerAlign: 'left', width: 70, align: 'left'}, 

        { field: "qtdecontado", headerName: "Contado",  headerClassName: 'blue-header', headerAlign: 'right', width: 70, align: 'right'}, 
        { field: "qtdediferenca", headerName: "Diferença",  headerClassName: 'blue-header', headerAlign: 'right', width: 85, align: 'right'}              
    ];            

    const contextvalues = {
        definirItensSelecionados,
        definirLojasSelecionadas,
        definirMarcasSelecionadas,        
        definirReferenciasSelecionadas
    }  
    
    function CustomToolbar() {
        return (
        <>
        <div className='row'>
            <div className='col'>
                <CustomToolBar print={visualizarImpressao} exportExcel={exportarExcel}/>       
            </div>                        
        </div>
        </>
        );
      }         
    
    if (loading) {
        return (
            <LoadingScreen/>
        );
      }

     const blueHeader = 'blue-cell'; 

     const totalizador = dadosContagemEstoque.reduce((total, obj) => {       
        total.qtde += obj.qtde;
        total.qtdecontado += obj.qtdecontado;        
        total.qtdediferenca += obj.qtdediferenca;        
        return total;             
    }, { qtde: 0, qtdecontado: 0, qtdediferenca: 0 });

     const renderTableTotalizador = () => {        
          return (
            <table class="table">              
                <thead>                        
                    <th scope="col" style={{width: '590px'}}></th>                 
                    <th scope="col" className="text-right" style={{width: '30px', fontSize: '13px'}}></th>                
                    <th scope="col" className="text-right" style={{width: '30px', fontSize: '13px'}}></th>                
                    <th scope="col" className="text-right" style={{width: '30px', fontSize: '13px'}}></th>                                        
                </thead>
                <tbody>
                    <tr >
                    <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {!isEmpty(totalizador) ? totalizador.qtde : 0}
                    </td>
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {(!isEmpty(totalizador) ? totalizador.qtdecontado : 0)}
                    </td> 
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}
                        >
                        {(!isEmpty(totalizador) ? totalizador.qtdediferenca : 0)}
                    </td>                                                               
                    </tr>              
                </tbody>
            </table>        
          );
        }

      const renderTable = () => {
        
          return (
            <table class="table" style={{ marginTop: '20px' }}>
            <thead>
                <th scope="col" style={{ width: '580px' }}></th>
                <th scope="col" className="text-right" style={{ width: '580px', fontSize: '13px' }}></th>                
            </thead>
            <tbody>
                <tr>
                    <td className="text-left" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        <span style={{ float: 'left' }}>Custo Total Antes da Contagem:</span>
                        <span style={{ float: 'right' }}>{(!isEmpty(dadosContagemEstoqueTotalizador) ? dadosContagemEstoqueTotalizador.custototalantescontagem : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}</span>
                    </td>
                    <td className="text-left" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        <span style={{ float: 'left' }}>Valor Venda Total Antes da Contagem:</span>
                        <span style={{ float: 'right' }}>{(!isEmpty(dadosContagemEstoqueTotalizador) ? dadosContagemEstoqueTotalizador.valorvendatotalantescontagem : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}</span>
                    </td>
                </tr>
                <tr>
                    <td className="text-left" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        <span style={{ float: 'left' }}>Custo Total Contagem:</span>
                        <span style={{ float: 'right' }}>{(!isEmpty(dadosContagemEstoqueTotalizador) ? dadosContagemEstoqueTotalizador.custototalcontagem : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}</span>
                    </td>
                    <td className="text-left" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        <span style={{ float: 'left' }}>Valor Venda Total Contagem:</span>
                        <span style={{ float: 'right' }}>{(!isEmpty(dadosContagemEstoqueTotalizador) ? dadosContagemEstoqueTotalizador.valorvendatotalcontagem : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}</span>
                    </td>
                </tr>
                <tr>
                    <td className="text-left" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        <span style={{ float: 'left' }}>Custo Total Diferenças:</span>
                        <span style={{ float: 'right' }}>{(!isEmpty(dadosContagemEstoqueTotalizador) ? dadosContagemEstoqueTotalizador.custototaldiferencas : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}</span>
                    </td>
                    <td className="text-left" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        <span style={{ float: 'left' }}>Valor Venda Total Diferenças:</span>
                        <span style={{ float: 'right' }}>{(!isEmpty(dadosContagemEstoqueTotalizador) ? dadosContagemEstoqueTotalizador.valorvendatotaldiferenca : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}</span>
                    </td>
                </tr>
            </tbody>
        </table>
          );              
      };     
            
      const getRowClassName = (params) => {        
            if (params.value == null) {
              return '';
            }

            const saldo = params.row.estoque;

            if (saldo > 0)
            {
                return 'super-app positive'
            }
            else
            {
                return 'super-app negative'
            }                 
      };
            
      const handleChange = async (event) => {        
        const contagemSelecionada = contagens.find(contagem => contagem.descricao === event.target.value);

        await setContagemSelecionada({
            id: contagemSelecionada.id,
            descricao: contagemSelecionada.descricao
        });       
      };

      const handleChangeLojas = async (event) => {
        const selectedLoja = lojasFiltro.find(loja => loja.nome === event.target.value);
    
        await setLojaSelecionada({
            idloja: selectedLoja.idloja,
            nome: selectedLoja.nome
        });            
    };

    const handleChangeFiltroDiferencas = (event) => {
        debugger;
        setSomenteDiferencas({ ...somenteDiferencas, [event.target.name]: event.target.checked });
      };
     
    return (
        <>       
        <ContagemEstoqueContext.Provider value={contextvalues}>             
            <form className="form">                
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Contagem de Estoque</label>
            </div>                                                
                <Card.Body>                    
                    <Container>                                                                                 
                        {/* <Card>                                       
                        <div className='align-left'>                        
                            <div className='row'>
                            <div className='col'>
                            <label className='text' style={{ fontWeight: 'bold' }}>Visão</label>  
                                <Card style={{width: 480 }}>                        
                                    <div className="row">
                                        <div className="col-md-4">                                                                                                                                
                                            <Radio
                                                checked={tipoVisaoSelect === 'produto'}
                                                onChange={() => {
                                                    setTipoVisaoSelect('produto'); 
                                                    setDadosContagemEstoque([]);                                          
                                                }}          
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="produtoRadio" 
                                            />   
                                            <label className="form-check-label" htmlFor="produtoRadio" style={{cursor: 'pointer'}}>
                                                Por Produto
                                            </label>                                                
                                        </div>
                                        
                                        <div className="col">   
                                            <Radio
                                                checked={tipoVisaoSelect === 'item'}
                                                onChange={() => {
                                                    setTipoVisaoSelect('item'); 
                                                    setDadosContagemEstoque([]);                                          
                                                }}          
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="itemRadio" 
                                            />   
                                            <label className="form-check-label" htmlFor="itemRadio" style={{cursor: 'pointer'}}>
                                                Por Item
                                            </label>                                                                            
                                        </div>

                                        <div className="col">                                                                                           
                                            <Radio
                                                checked={tipoVisaoSelect === 'marca'}
                                                onChange={() => {
                                                    setTipoVisaoSelect('marca'); 
                                                    setDadosContagemEstoque([]);                                                                
                                                }}           
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="marcaRadio" 
                                            />   
                                            <label className="form-check-label" htmlFor="marcaRadio" style={{cursor: 'pointer'}}>
                                                Por Marca
                                            </label>           
                                        </div>
                                    </div>
                                </Card>  
                            </div>                                                                        
                        </div> 
                        </div>                                                                   
                        </Card>                                                                                                                                                             */}
                    </Container>

                    <Container>
                        <Card>                                                                                                                                                                                                            
                            <div className="filtros">
                            <div className="align-left">                            
                                <div className="row align-items-start" style={{marginTop: '20px'}}>
                                    <div className="col-lg" style={{width: '350px'}}>

                                    <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Loja</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={lojaSelecionada.nome}
                                            label="Loja"
                                            onChange={handleChangeLojas}
                                            >                                                                      
                                            {lojasFiltro.map((loja) => (
                                                <MenuItem key={loja.idloja} value={loja.nome}>                                       
                                                {loja.nome}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>             

                                   </div>                                             

                                    <div className="col-lg">
                                        <ButtonItens
                                            onClick={handleShowItens}
                                            width={'185px'}
                                            itensFiltro={itensFiltro}
                                        />
                                    </div>                               
                                    <div className="col-lg">
                                        <ButtonMarcas 
                                            onClick={handleShowMarcas}
                                            width={'185px'}
                                            marcasFiltro={marcasFiltro}
                                        />                                         
                                    </div>    
                                    <div className="col-lg">
                                        <ButtonReferencias
                                        onClick={handleShowReferencias}
                                        width={'185px'}                                  
                                        referenciasFiltro={referenciasFiltro}                                                
                                        />
                                    </div>                                                                                                                         
                                </div>                               

                                <div className="row" style={{marginTop: '30px', width: '900px'}}>
                                    <div className="col-6">  
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Contagem</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={contagemSelecionada.descricao}
                                            label="Contagem"
                                            onChange={handleChange}
                                            >                                                                      
                                            {contagens.map((contagem) => (
                                                <MenuItem key={contagem.id} value={contagem.descricao}>                                       
                                                {contagem.descricao}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className='col-md'>
                                    <FormControlLabel 
                                        control={<Switch checked={somenteDiferencas.diferencas} onChange={handleChangeFiltroDiferencas} name="diferencas" />}
                                        label= {<Typography variant="body1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                            Somente Diferenças:
                                            </Typography>
                                        }
                                        />
                                    </div>
                                </div>

                            </div>
                
                                                                                         
                            </div>        

                            <div class="row" style={{marginTop: '30px'}}>
                                <div className='col-2'>
                                    <div className="actions">
                                    <ButtonExecute 
                                        width={'185px'}
                                        onClick={handleExecutar}
                                    />   
                                    </div>                    
                                </div>
                                <div className='col-8' style={{marginLeft: '15px'}}>
                                    <ButtonClear                                         
                                        onClick={handleLimparFiltros}
                                    />   
                                </div>
                            </div>   
                        </Card>
                    </Container>                 
                </Card.Body>                     
            </form>     
                            
            <ModalItem show={showItens} value={ContagemEstoqueContext} onHide={handleCloseItens}/>
            <ModalMarcas show={showMarcas} value={ContagemEstoqueContext} onHide={handleCloseMarcas}/>               
            <ModalReferencias show={showReferencias} value={ContagemEstoqueContext} onHide={handleCloseReferencias}/>

            {dadosContagemEstoque && dadosContagemEstoque.length > 0 && (
            <Container>                                          
                <CustomStyledDataGrid 
                    slots={{
                        toolbar: CustomToolbar,
                        pagination: CustomPagination                   
                    }}      
                    loading={loading}                                          
                    columns={columnsProdutos}                   
                    rows={rowsProdutos}                       
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}                                                                                                                                                                         
                    sx={{
                        '@media print': {
                          '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' }                          
                        },
                      }}                    
                    getRowClassName={getRowClassName}
                    />                                                                                             
            </Container>              
            )}                       
        </ContagemEstoqueContext.Provider>
        </>
    )
  }

export default ContagemEstoqueProvider;
