import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import './styles.css'
import { Button, Card, Container }  from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableVendedores = ({contexto}) => {  
  const [vendedores, setVendedores] = useState([]);  
  const [vendedoresData, setVendedoresData] = useState([]);  
  const [selectedVendedores, setSelectedVendedores] = useState([]);
  const [busca, setBusca] = useState('');
  const { definirVendedoresSelecionados } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedVendedores = sessionStorage.getItem('selectedVendedores');
    if (storedSelectedVendedores) {
      setSelectedVendedores(JSON.parse(storedSelectedVendedores));      
    }    
    pesquisaRef.current.focus();
  }, []);

  useEffect(() => {
    
    if (selectedVendedores.length > 0)
   {
      sessionStorage.setItem('selectedVendedores', JSON.stringify(selectedVendedores));
   }       
  }, [selectedVendedores, vendedores]);  

  const handleVendedoresSelection = (id) => {
    const updatedIndexes = selectedVendedores.includes(id)
      ? selectedVendedores.filter((index) => index !== id)
      : [...selectedVendedores, id];
      setSelectedVendedores(updatedIndexes);   

    const selectedVendedoresIds = updatedIndexes.map((index) => index.id);
    definirVendedoresSelecionados(selectedVendedoresIds);
  };

  const handleRemoverVendedoresSelecionadosClick = (id) => {
    const updatedVendedoresSelecionados = selectedVendedores.includes(id)
      ? selectedVendedores.filter((index) => index !== id)
      : [...selectedVendedores, id];
      setSelectedVendedores(updatedVendedoresSelecionados);   

    const selectedVendedoresIds = updatedVendedoresSelecionados.map((index) => index.id);
    definirVendedoresSelecionados(selectedVendedoresIds);

    if (selectedVendedoresIds.length === 0)
    {
      sessionStorage.removeItem('selectedVendedores');
    }
  };

  const handleRowClick = (itemId) => {
    setVendedores((prevVendedores) =>
      prevVendedores.filter((item) => item.id !== itemId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!vendedores.includes(item.id))
    {
        const updatedVendedores = [...vendedores, item];
        setVendedores(updatedVendedores);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedVendedores(vendedores);
       setVendedores([]);
       
       const selectedVendedoresIds = vendedores.map((index) => index.id);
       definirVendedoresSelecionados(selectedVendedoresIds);       
       sessionStorage.setItem('selectedVendedores', selectedVendedores);      
  }

  const handleRemoverTodos = () => {     
      if (!vendedoresData || vendedoresData.length === 0)
      {
        getVendedores(); 
      }              
      setVendedores(vendedoresData);      
      setSelectedVendedores([]);    
      definirVendedoresSelecionados([]);         
      sessionStorage.removeItem('selectedVendedores');      
  }

  const handlePesquisar = () => {
    getVendedores();
  };

  const getVendedores = async () => {   
    try {
      let url = 'funcionario/vendedor/todos';

      if (busca !== '') {
        url = `funcionario/vendedor/relatorio/nome/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);

      const selectedVendedoresIds = selectedVendedores.map(vendedor => vendedor.id);    
      const filteredData = response.data.filter(vendedor => 
        !selectedVendedoresIds.includes(vendedor.id)
      );
      
      setVendedores(filteredData);
      setVendedoresData(filteredData);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
    }
  }

  return (
    <>
      <div style={{ maxHeight: '700px', overflowY: 'auto' }}>       
        <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
          <input
                  name="campopesquisa"
                  type="text"
                  className="form-control"
                  id="loja"
                  placeholder="Digite o Vendedor"
                  value={busca}
                  onKeyDown={handleKeyDown}
                  ref={pesquisaRef}
                  onChange={(ev) => setBusca(ev.target.value)}
                />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />                              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
            <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos
                    onClick={handleAdicionarTodos}
                  />                
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table-dark mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {vendedores.map((vendedor) => {
                  if (selectedVendedores.some((vend) => vend.id === vendedor.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={vendedor.id}
                        onClick={() => {
                          handleVendedoresSelection(vendedor);
                          handleRowClick(vendedor.id);
                        }}
                      >
                        <td>{vendedor.id}</td>
                        <td>{vendedor.nome}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
              <div className='row justify-content-end'>
                  <div className='col text-left'> 
                    <label className='labelSelecionar'>Selecionados: {selectedVendedores.length}</label>
                  </div>
                <div className='col text-right'>               
                  <ButtonRemoverTodos
                    onClick={handleRemoverTodos}
                  />
                </div>
              </div>         
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedVendedores.map((item) => (
                    <tr key={item.id}
                        onClick={() => {
                          handleRemoverVendedoresSelecionadosClick(item);
                          handleRowSelectedClick(item)
                        }}
                    >
                      <td>{item.id}</td>
                      <td>{item.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>            
      </div>
    </>
  );
};

export default TableVendedores;

