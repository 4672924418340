import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableCategorias = ({contexto}) => {  
  const [categorias, setCategorias] = useState([]);  
  const [categoriasData, setCategoriasData] = useState([]);
  const [selectedCategorias, setSelectedCategorias] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirCategoriasSelecionadas } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedCategorias = sessionStorage.getItem('selectedCategorias');
    if (storedSelectedCategorias) {    
      setSelectedCategorias(JSON.parse(storedSelectedCategorias));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedCategorias.length > 0)
   {
      sessionStorage.setItem('selectedCategorias', JSON.stringify(selectedCategorias));
   }       
  }, [selectedCategorias, categorias]);  

  const handleCategoriaSelection = (id) => {
    const updatedIndexes = selectedCategorias.includes(id)
      ? selectedCategorias.filter((index) => index !== id)
      : [...selectedCategorias, id];
    setSelectedCategorias(updatedIndexes);   

    const selectedCategoriasIds = updatedIndexes.map((index) => index.id);
    definirCategoriasSelecionadas(selectedCategoriasIds);
  };

  const handleRemoverCategoriasSelecionadasClick = (id) => {
    const updatedCategoriasSelecionadas = selectedCategorias.includes(id)
      ? selectedCategorias.filter((index) => index !== id)
      : [...selectedCategorias, id];
    setSelectedCategorias(updatedCategoriasSelecionadas);   

    const selectedCategoriasIds = updatedCategoriasSelecionadas.map((index) => index.id);
    definirCategoriasSelecionadas(selectedCategoriasIds);

    if (selectedCategoriasIds.length === 0)
    {
      sessionStorage.removeItem('selectedCategorias');
    }
  };

  const handleRowClick = (categoriaId) => {
    setCategorias((prevCategorias) =>
      prevCategorias.filter((categoria) => categoria.id !== categoriaId)
    );
  };

  const handleRowSelectedClick = (categoria) => {

    if (!categorias.includes(categoria.id))
    {
        const updatedCategorias = [...categorias, categoria];
        setCategorias(updatedCategorias);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedCategorias(categorias);
       setCategorias([]);
       
       const selectedCategoriasIds = categorias.map((index) => index.id);
       definirCategoriasSelecionadas(selectedCategoriasIds);       
       sessionStorage.setItem('selectedCategorias', selectedCategorias);      
  }

  const handleRemoverTodos = async() => {         
      if (!categoriasData || categoriasData.length === 0)
      {
        getCategorias(); 
      }      
      setCategorias(categoriasData);
      setSelectedCategorias([]);    
      definirCategoriasSelecionadas([]);         
      sessionStorage.removeItem('selectedCategorias');      
  }

  const getCategorias = async () => {   
    console.log('pesquisando');
    try {
      let url = 'categoria';

      if (busca !== '') {
        url = `categoria/descricao/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setCategorias(response.data);   
      setCategoriasData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getCategorias();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da categoria"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>                 
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody style={{cursor: 'pointer'}}>
                {categorias.map((categoria) => {
                  if (selectedCategorias.some((mrc) => mrc.id === categoria.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={categoria.id}
                        onClick={() => {
                          handleCategoriaSelection(categoria);
                          handleRowClick(categoria.id);
                        }}
                      >
                        <td>{categoria.id}</td>
                        <td>{categoria.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedCategorias.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedCategorias.map((categoria) => (
                    <tr key={categoria.id}
                        onClick={() => {
                          handleRemoverCategoriasSelecionadasClick(categoria);
                          handleRowSelectedClick(categoria)
                        }}
                    >
                      <td>{categoria.id}</td>
                      <td>{categoria.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableCategorias;

