import React, { createContext, useState, useEffect, useRef } from 'react';
import { Pagination, Typography, Box, Container, Radio, TextField } from '@mui/material'
import {    
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector
} from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import PaginationItem from '@mui/material/PaginationItem';
import './styles.css'
import { api } from '../../services/api';
import { isEmpty } from 'lodash';
import { startOfMonth, endOfMonth } from 'date-fns';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { Card } from 'react-bootstrap';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import { ptBR } from 'date-fns/locale';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import ModalLojas from '../../components/Modal/ModalLojas';
import { ImpressaoComprasFornecedorPDF } from '../../components/Impressao/ImpressaoComprasFornecedorPDF';
import ButtonFornecedores from '../../components/Buttons/ButtonFornecedores';
import ModalFornecedores from '../../components/Modal/ModalFornecedores';
import { format, parse } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import { HelpOutlined } from '@mui/icons-material';
import ModalAjudaRelatorioComprasFornecedor from '../../components/Modal/MensagensAjuda/ModalAjudaRelatorioComprasFornecedor';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;  

export const ComprasContext = createContext();

const ComprasFornecedorProvider = () => {
  const sessionStorageUtils = new SessionStorageUtils();  
  const PAGE_SIZE = 50;
  const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0});  
  const [nomeGrupo, setNomeGrupo] = useState([]);
  const [showLojas, setShowLojas] = useState(false);          
  const [showFornecedores, setShowFornecedores] = useState(false);   
  const [showAjuda, SetShowAjuda] = useState(false);   
  const [dadosComprasVendas, setDadosComprasVendas] = useState([]);    
  const [tipoVisaoSelect, setTipoVisaoSelect] = useState('compra');
  const [periodoDataFiltro, setTipoDataFiltro] = useState('emissao');  
  const [lojasFiltro, setLojasFiltro] = useState([]);   
  const [fornecedoresFiltro, setFornecedoresFiltro] = useState([]);    
  const [notaFiscalFiltro, setNotaFiscalFiltro] = useState('');    
  const headerClassName = 'blue-header';      
  const [loading, setLoading] = useState(true);   
  const [limparFiltros, SetLimparFiltros] = useState(false);     
  const executeRef = useRef(null);      
  const [dataIntervaloCompraFiltro, setDataIntervaloCompraFiltro] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date())
  ]);
  const navigate = useNavigate();
  const permissao = new Permissoes(); 
    
  useEffect(() => {
        setTimeout(() => {                 
            setLoading(true);       
            if (ValidarPermissoes() == false) { return; } 
            LimparFiltrosSelecionados();                           
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []);    

    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('50da04b6c20733ac293539832948a787');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  

    useEffect(() => {        
      if (limparFiltros)
      {
          definirLojaInicial();            
          SetLimparFiltros(false);
      }        
    }, [limparFiltros]);
    
    const handleLimparFiltros = async () => {   
      resetarDataInicial();
      LimparFiltrosSelecionados()                
      SetLimparFiltros(true);
    }
    
    function LimparFiltrosSelecionados() {
      sessionStorage.removeItem('selectedLojas');                           
      sessionStorage.removeItem('selectedFornecedores'); 
      setLojasFiltro([]);
      setFornecedoresFiltro([]);      
      setNotaFiscalFiltro('');
      setTipoDataFiltro('emissao');
      setDadosComprasVendas([]);
    }
    
    const resetarDataInicial = () => {
      setDataIntervaloCompraFiltro([
        startOfMonth(new Date()),
        new Date()
      ]);
    };

    const visualizarImpressao = async  () => {        
      const classeImpressao = new ImpressaoComprasFornecedorPDF(dadosComprasVendas, filtrosSelecionados);
      const documento = await classeImpressao.PreparaDocumento();
      pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }   
    
    const handleCloseModalAjuda = () => 
    {        
          SetShowAjuda(false);                           
    } 
    
    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
      setNomeGrupo(groupName);
    }

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);

      const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
      const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
         
      return (
          <>            
          <div>           
              <div className='table-align'>
                  {renderTable()}
              </div>                        
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                      <Typography variant="subtitle2" color="textSecondary">
                          Total de registros: {totalRowCount}
                      </Typography>
                  </div>

                  <div style={{marginLeft: '50px'}}>
                      <Typography variant="subtitle2" color="textSecondary" >
                          Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                      </Typography>
                  </div>
              </div>
                  
                  <div style={{ alignSelf: 'flex-end'}}>
                      <Pagination
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          page={page + 1}
                          count={pageCount}                                                    
                          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                          onChange={(event, value) => apiRef.current.setPage(value - 1)}
                      />
                  </div>
              </div>            
          </div>
       
        </>
        );        
    }

    const handleShowLojas = () => 
    {            
        setShowLojas(true);
    }         

    const handleShowFornecedores = () => 
    {            
        setShowFornecedores(true);
    }                  
            
    const handleCloseLojas = () => 
    {        
        setShowLojas(false);                           
    }

    const handleCloseFornecedores = () => 
    {        
        setShowFornecedores(false);                           
    }
    
    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }         
    
    const definirFornecedoresSelecionados = (fornecedores) => 
    {
        setFornecedoresFiltro(fornecedores);        
    }       

  const definirLojaInicial = async()  => 
  {
      const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                
      if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
      {          
          const userId = JSON.parse(desencryptJSONUser).id;         
          await SetTenant();                        
          const response = await api.get(`loja/lojascadastradas/${userId}`);
          const firstId = response.data[0].idloja;             
          await setLojasFiltro(firstId);               
      }          
  }

  const handleExecutar = async() => {           
      setLoading(true);
      await getComprasVendas();                                         
      setLoading(false);                  
  } 

  const columnsCompra = [   
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },          
    { field: "lojaid", headerName: "Loja",  headerClassName: 'blue-header', 
       width: 65, headerHeight : 10 },
    { field: "dataemissao", 
       headerName: "Data Emissão",  
       type : 'date',
       valueFormatter: (params) => { return format(new Date(params.value), 'dd/MM/yyyy', { locale: dateFnsPtBR })
         },
       sortComparator: (v1, v2, cellParams1, cellParams2) => {                
           const date1 = typeof v1 === 'string' ? parse(v1, 'MMMM/yyyy', new Date()) : v1;
           const date2 = typeof v2 === 'string' ? parse(v2, 'MMMM/yyyy', new Date()) : v2;
     
           return date1.getTime() - date2.getTime();
         },   
       headerClassName: 'blue-header', width: 100 
    },
    { field: "dataentrada", 
       headerName: "Data Entrada",  
       type : 'date',
       valueFormatter: (params) => { return format(new Date(params.value), 'dd/MM/yyyy', { locale: dateFnsPtBR })
         },
       sortComparator: (v1, v2, cellParams1, cellParams2) => {                
           const date1 = typeof v1 === 'string' ? parse(v1, 'MMMM/yyyy', new Date()) : v1;
           const date2 = typeof v2 === 'string' ? parse(v2, 'MMMM/yyyy', new Date()) : v2;
     
           return date1.getTime() - date2.getTime();
         },   
       headerClassName: 'blue-header', width: 100 
    },    
    { field: "numero", headerName: "Nota Fiscal",  headerClassName: 'blue-header',  
      width: 110, align:'right', headerAlign: 'right', headerHeight : 10 },   
    { field: "fornecedorid", headerName: "Cód",  headerClassName: 'blue-header', 
       width: 65, headerHeight : 10, renderCell: (params) => { return<strong>{params.value}</strong> } },
    { field: "fornecedornome", headerName: "Fornecedor",  headerClassName: 'blue-header', 
       width: 310, headerHeight : 10 , renderCell: (params) => { return<strong>{params.value}</strong> }},
    { field: "compraqtde", headerName: "Qtde Itens",  headerClassName: 'blue-header', 
       width: 90, headerHeight : 10 , headerAlign: 'right', align: 'right', renderCell: (params) => { return params.value}},
    { field: "compravalor", headerName:"Compra R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 155, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      } , headerHeight : 10 ,  cellClassName: () => {return 'blue-cell-light'}
    },   
    { field: "valorprodutos", headerName:"Previsão de Venda R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 155, align: 'right',     
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    } , headerHeight : 10 ,  cellClassName: () => {return 'green-cell-light'}
    }    
  ];         
  
  const columnsFornecedores = [   
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    }, 
    { field: "fornecedorid", headerName: "Cód",  headerClassName: 'blue-header', 
       width: 80, headerHeight : 10, renderCell: (params) => { return<strong>{params.value}</strong> } },     
    { field: "fornecedornome", headerName: "Fornecedor",  headerClassName: 'blue-header', 
       width: 450, headerHeight : 10, renderCell: (params) => { return <strong>{params.value}</strong> } 
    },    
    { field: "compraqtde", headerName: "Qtde Itens",  headerClassName: 'blue-header', 
       width: 200, headerHeight : 10 , headerAlign: 'right', align:  'right', renderCell: (params) => { return params.value}},
    { field: "compravalor", headerName:"Compra R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 210, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      } , headerHeight : 10,  cellClassName: () => {return 'blue-cell-light'}
    },   
    { field: "valorprodutos", headerName:"Previsão de Venda R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 210, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      } , headerHeight : 10,  cellClassName: () => {return 'green-cell-light'} 
    }    
  ];           
  
  const filtrosSelecionados = {    
    tipoVisaoSelect,  
    periodoDataFiltro, 
    dataIntervaloCompraFiltro,   
    fornecedoresFiltro,      
    notaFiscalFiltro,   
    lojasFiltro,        
    nomeGrupo : nomeGrupo
  }    
 
  const getComprasVendas = async() => {                   
    try 
    {           
      debugger                
        const dataInicial = (`${dataIntervaloCompraFiltro[0].getFullYear()}-${dataIntervaloCompraFiltro[0].getMonth()+1}-${dataIntervaloCompraFiltro[0].getDate()}`);
        const dataFinal = (`${dataIntervaloCompraFiltro[1].getFullYear()}-${dataIntervaloCompraFiltro[1].getMonth()+1}-${dataIntervaloCompraFiltro[1].getDate()}`);                
        
        let url = `compra/relatorio/compras/visao/${tipoVisaoSelect}/${lojasFiltro}/${periodoDataFiltro}/${dataInicial}/${dataFinal}`; 

        if (notaFiscalFiltro !== "")
        {
            url += `/${notaFiscalFiltro}`
        }
        else
        {
            url += "/ ";
        }

        if (fornecedoresFiltro.length > 0) {                
            url += `/${fornecedoresFiltro}`;
        } else {
            url += "/ ";
        }       
               
        setDadosComprasVendas([]);                  
        await SetTenant();
        const response = await api.get(url);                                                                           
        setDadosComprasVendas(response.data);               
    }
    catch (e)
    {
        console.log('erro');
    }        
  }          

  const contextvalues = {    
    definirLojasSelecionadas,
    definirFornecedoresSelecionados
  }  

  function CustomToolbar() {
    return (
      <CustomToolBar print={visualizarImpressao}/>         
    );
  }           

  const rowsCompra = dadosComprasVendas.map((row) => {  
    if (tipoVisaoSelect === 'compra')       
    {
        const CompraInfo = {
          id : row.id ,
          lojaid : row.lojaid,
          dataemissao : row.dataemissao,
          dataentrada : row.dataentrada,
          numero : row.numero,
          fornecedorid : row.fornecedorid,
          fornecedornome : row.fornecedornome,
          compraqtde : row.compraqtde,       
          compravalor: row.compravalor,
          valorprodutos : row.valorprodutos                                
        }                      
        return CompraInfo;                
    }

    const CompraFornecedorInfo = {
      id : row.id ,      
      fornecedorid : row.fornecedorid,
      fornecedornome : row.fornecedornome,
      compraqtde : row.compraqtde,       
      compravalor: row.compravalor,
      valorprodutos : row.valorprodutos                                
  }                      
  return CompraFornecedorInfo;                
      
  });    
  
  const totalizador = dadosComprasVendas.reduce((total, obj) => {         
        total.compraqtde += obj.compraqtde;
        total.compravalor += obj.compravalor;        
        total.valorprodutos += obj.valorprodutos;       
        return total;    
    }, { compraqtde: 0, compravalor: 0, valorprodutos: 0 });

  if (loading) {
    return (
       <LoadingScreen/>
    );
  }   

  const renderTable = () => {
    if (tipoVisaoSelect === 'compra') {
      return (
        <table class="table">              
              <thead>                        
                  <th scope="col" style={{ width: '750px', fontSize: '20px' }}></th>                                   
                  <th scope="col" className="text-left" style={{width: '90px', fontSize: '20px'}}></th>                  
                  <th scope="col" className="text-right" style={{width: '155px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '155px', fontSize: '20px'}}></th>                                                                                                                                        
              </thead>
              <tbody>
                  <tr >
                  <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold'}}>Total</th>                  
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.compraqtde : 0)}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#b3e5fc', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.compravalor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>                  
                  <td className="text-right" 
                      style={{ backgroundColor: '#a5d6a7', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.valorprodutos : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>                                                    
                  </tr>
              </tbody>
              </table> 
      );
    } else {
      return (
        <table class="table">              
        <thead>                        
            <th scope="col" style={{ width : '530px', fontSize: '20px' }}></th>                                   
            <th scope="col" className="text-left" style={{width: '200px', fontSize: '20px'}}></th>
            <th scope="col" className="text-right" style={{width: '210px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '210px', fontSize: '20px'}}></th>                                            
        </thead>
        <tbody>
            <tr >
            <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
            <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.compraqtde : 0)}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#b3e5fc', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.compravalor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>                  
                  <td className="text-right" 
                      style={{ backgroundColor: '#a5d6a7', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.valorprodutos : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>             
            </tr>
        </tbody>
        </table> 
      );
    }
  };     
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;
  
      if (name === 'notafiscal') {
        executeRef.current.focus();                   
      }
    }    
  }

  return (    
    <>
    <ModalAjudaRelatorioComprasFornecedor show={showAjuda} onHide={handleCloseModalAjuda}/>    
    <ComprasContext.Provider value={contextvalues}>        
    <form className="form"> 
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Compras - Fornecedor</label>            
          </div>                                           
          <Card.Body>                    
              <Container>
                  <Card>
                  <div className='align-left'>
                      <div className='col'>

                      <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>                      
                      <Card style={{width: 460}}>
                        <div className="row">                     
                          <div className="col-md-6">
                            <div className="form-check">                            
                                <Radio
                                  checked={tipoVisaoSelect === 'compra'}
                                  onChange={() => {
                                    setTipoVisaoSelect('compra'); 
                                    setDadosComprasVendas([]);                                                                   
                                  } }
                                  value={tipoVisaoSelect}
                                  name="radio-button-demo"
                                  inputProps={{ 'aria-label': 'A' }}
                                  size="small"
                                  id="compraRadio" 
                                />
                                <label class="form-check-label" for="compraRadio" style={{cursor: 'pointer'}}>
                                    Por Compra
                                </label>                          
                            </div>                      
                          </div>                      

                          <div className="col">                                  
                              <Radio
                                checked={tipoVisaoSelect === 'fornecedor'}
                                onChange={() => {
                                  setTipoVisaoSelect('fornecedor'); 
                                  setDadosComprasVendas([]);                                                                   
                                  setNotaFiscalFiltro('');
                                } }
                                value={tipoVisaoSelect}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'B' }}
                                size="small"
                                id="fornecedorRadio"
                              />
                                <label class="form-check-label" for="fornecedorRadio" style={{cursor: 'pointer'}}>
                                    Por Fornecedor
                                </label>                                                  
                          </div>                
                        </div>                    
                    </Card>   
                  </div>
                  </div>
                </Card>
              </Container>

              <Container>
                <Card>                                                                                                                                                                                                            
                      <div className="filtros">
                      <div className="align-left">
                      <div className='row'>
                        <div className='col'>
                          <div className="row" style={{ width: '506px' }}>
                            <div className="col">
                            <label className='text' style={{ fontWeight: 'bold', marginBottom: '0px' }}>Período: </label>                             
                            <Card style={{ width: 460 }}>
                                <div className="row">                     
                                <div className="col-md-6">
                                    <div className="form-check">                            
                                        <Radio
                                        checked={periodoDataFiltro === 'emissao'}
                                        onChange={() => {
                                            setTipoDataFiltro('emissao'); 
                                            setDadosComprasVendas([]);                                                                   
                                        } }
                                        value={periodoDataFiltro}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        size="small"
                                        id="emissaoRadio" 
                                        />
                                        <label class="form-check-label" for="emissaoRadio" style={{cursor: 'pointer'}}>
                                            Emissão
                                        </label>                          
                                    </div>                      
                                </div>                      

                                <div className="col">                                  
                                    <Radio
                                        checked={periodoDataFiltro === 'entrada'}
                                        onChange={() => {
                                        setTipoDataFiltro('entrada'); 
                                        setDadosComprasVendas([]);                                                                   
                                        } }
                                        value={periodoDataFiltro}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'B' }}
                                        size="small"
                                        id="entradaRadio"
                                    />
                                    <label class="form-check-label" for="entradaRadio" style={{cursor: 'pointer'}}>
                                        Entrada
                                    </label>                                                  
                                </div>                
                                </div>                    
                            </Card>       
                            </div>
                          </div>            
                        </div>

                        <div className='col'>                        
                        <div className='row' style={{ marginTop: '10px', width: '440px' }}>
                            <LocalizationProvider  
                                dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                                               
                            >                                                           
                              <DateRangePicker                               
                                localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                value={dataIntervaloCompraFiltro}      
                                onChange={(newValue) => setDataIntervaloCompraFiltro(newValue)}                                 
                              />                     
                            </LocalizationProvider>    
                            </div>          
                        </div>
                                                      
                      </div>                  
                      </div>       

                      <div className="align-left">
                          <div className="row align-items-start" style={{ marginTop: '20px' }}>
                              <div className="col-lg" style={{ marginTop: '10px' }}>
                                  <ButtonLojas
                                      onClick={handleShowLojas}
                                      width={'220px'}                                  
                                      lojasFiltro={lojasFiltro}
                                  />
                              </div>                                
                              <div className="col-lg" style={{ marginTop: '10px' }} >
                                  <ButtonFornecedores
                                      onClick={handleShowFornecedores}
                                      width={'220px'}
                                      fornecedoresFiltro={fornecedoresFiltro}
                                  />
                              </div>         

                              <div className="col-lg" style={{visibility: tipoVisaoSelect === 'compra' ? 'visible' : 'hidden'}}>                               
                                    <TextField 
                                    type="text"                                        
                                    id="outlined-basic" 
                                    name="notafiscal" 
                                    label="Nota Fiscal"                                       
                                    variant="outlined"     
                                    onKeyDown={handleKeyDown}                         
                                    style={{
                                        marginLeft: '17px',
                                        width: '190px',
                                        marginBottom: '20px'
                                    }}                               
                                    onChange={(e) => {
                                        setNotaFiscalFiltro(e.target.value);                                       
                                    }}
                                    value={notaFiscalFiltro} 
                                    InputProps={{
                                        style: {                                                                                   
                                        marginTop: '10px', 
                                        maxHeight: '35px',
                                        },
                                    }}                           
                                />     
                                                                                                                
                              </div>                                   
                          </div>                               
                      </div>                                                 
                   </div>                              

                  <div class="row" style={{marginTop: '10px'}}>                        
                    <div className='col-2'>
                      <div className="actions">
                        <ButtonExecute 
                            width={'220px'}
                            onClick={handleExecutar}
                            refFilter={executeRef}         
                        />   
                      </div> 
                    </div>
                    <div className='col-8' style={{marginLeft: '55px'}}>
                      <ButtonClear                           
                          onClick={handleLimparFiltros}
                      />   
                  </div>                 
                    <div className='col-1'>
                       <HelpOutlined onClick={()=> SetShowAjuda(true)} style={{ marginLeft: '30px', marginTop: '10px', cursor:'pointer', color: '#007CB0'}}/>
                    </div>
                  </div> 

                  </Card>
              </Container>                 
          </Card.Body>          
      </form>     
          
      <ModalLojas show={showLojas} value={ComprasContext} onHide={handleCloseLojas}/>                      
      <ModalFornecedores show={showFornecedores} value={ComprasContext} onHide={handleCloseFornecedores}/>                                     
      
      <Box
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: 'blue',
          },
        }}
      >
        {dadosComprasVendas && dadosComprasVendas.length > 0 && (
        <Container>        
        <CustomStyledDataGrid                  
          columns={tipoVisaoSelect === 'compra'
            ? columnsCompra
            : columnsFornecedores}
          rows={rowsCompra}                      
          slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination                          
          }}       
          headerHeight={20}                             
          headerClassName={headerClassName}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[PAGE_SIZE]}                                                                                 
        />                                
        </Container>
        )}
      </Box>
      
    </ComprasContext.Provider>         
    </>
  );
}

export default ComprasFornecedorProvider;

