import  React , { createContext, useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import "react-datepicker/dist/react-datepicker.css";
import { Container, Grid, Paper, Stack } from '@mui/material';
import { api } from '../../services/api';
import { Card } from 'react-bootstrap';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import '../../pages/Financeiro/styles.css';
import { isEmpty } from 'lodash';
import { startOfMonth, endOfMonth } from 'date-fns';
import ButtonPrint from '../../components/Buttons/ButtonPrint';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import { ImpressaoFinanceiroPDF } from  '../../components/Impressao/ImpressaoFinanceiroPDF';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import { HelpOutlined } from '@mui/icons-material';
import ModalAjudaRelatorioFinanceiro from '../../components/Modal/MensagensAjuda/ModalAjudaRelatorioFinanceiro';
import ModalDetalheFinanceiro from '../../components/Modal/ModalDetalheFinanceiro';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const FinanceiroContext = createContext();

  export const FinanceiroProvider = () => {   
    const sessionStorageUtils = new SessionStorageUtils();      
    const [showLojas, setShowLojas] = useState(false);   
    const [showAjuda, SetShowAjuda] = useState(false);   
    const [showDetalheFinanceiro, SetShowDetalheFinanceiro] = useState(false);   
    const [dadosFinanceiro, SetDadosFinanceiros] = useState([]);      
    const [dadosDetalheRow, SetDadosDetalheRow] = useState();      
    const [lojasFiltro, setLojasFiltro] = useState([]);     
    const [limparFiltros, SetLimparFiltros] = useState(false);
    const [loading, setLoading] = useState(true);      
    const [nomeGrupo, setNomeGrupo] = useState([]);                   
    const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([
        startOfMonth(new Date()),
        endOfMonth(new Date())
      ]);     
    const navigate = useNavigate();
    const permissao = new Permissoes(); 
 
    useEffect(() => {
        setTimeout(() => {     
            setLoading(true); 
            if (ValidarPermissoes() == false) { return; }       
            sessionStorage.removeItem('selectedLojas');            
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []); 

    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('ae600b9f2fe038414e3b5f0fa1705551');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  

    useEffect(() => {        
      if (limparFiltros)
      {
          definirLojaInicial();            
          SetLimparFiltros(false);
      }        
    }, [limparFiltros]);
    
    const handleLimparFiltros = async () => {   
      resetarDataInicial();
      LimparFiltrosSelecionados()                
      SetLimparFiltros(true);
    }
    
    function LimparFiltrosSelecionados() {
      sessionStorage.removeItem('selectedLojas');
      setLojasFiltro([]);      
      SetDadosFinanceiros([]);
    }
    
    const resetarDataInicial = () => {
      setDataIntervaloFiltro([
        startOfMonth(new Date()),
        new Date()
      ]);
    };     
    
    const getDetailPanelContent = React.useCallback(
        ({ row}) => <DetailPanelContent row={row}/>,
        [],
      );     
      
      function handleRowClick(params) {
        debugger  
        SetDadosDetalheRow(params.row);        
        SetShowDetalheFinanceiro(true)
      }
      
      function DetailPanelContent({ row: rowProp }) {  
          return (
            <Container sx={{ height: '100%' }}>
              <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
                <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
                  <Stack direction="column" spacing={0} sx={{ height: '100%' }}>           
                    <Grid container sx={{ flexGrow: 0 }}>
                      <Grid item md={6}>                
                      </Grid>
                    </Grid>
                    <Container sx={{ height: '100%' }}>           
                      <CustomStyledDataGrid                                                                   
                        autoHeight
                        style={{cursor: 'pointer'}}
                        onRowClick={handleRowClick}                
                        density="compact"
                        columns={
                          rowProp.registros.listacrediarios ?
                          columnsDetailCrediarios
                          : rowProp.registros.listacontasreceitas
                            ? columnsDetailReceitaDespesa
                            : rowProp.registros.listadespesas 
                            ? columnsDetailReceitaDespesa
                            : rowProp.registros.listafornecedores
                              ? columnsDetailFornecedores
                                : null
                          }               
                        rows={
                          rowProp.registros.listacrediarios 
                            ? rowProp.registros.listacrediarios 
                            : rowProp.registros.listacontasreceitas                         
                              ? rowProp.registros.listacontasreceitas
                              : rowProp.registros.listadespesas
                                ? rowProp.registros.listadespesas
                                 :  rowProp.registros.listafornecedores
                                 ?  rowProp.registros.listafornecedores
                                   : null                          
                        }        
                        sx={{ flex: 1, height: '100%' }}                  
                        hideFooter                  
                      />
                    </Container>
                   
                  </Stack>
                </Paper>
              </Stack>
            </Container>
          );
        }  
      
        const columnsDetailFornecedores= [    
          { field: "id", headerName: "Cód",  headerClassName: 'header-detail', width: 50},       
          { field: "fornecedor", headerName: "Fornecedor",  headerClassName: 'header-detail', width: 300 },
          { field: "qtde", headerName: "Quantidade",  headerClassName: 'header-detail', width: 100,  align: 'right' },            
          { field: "valor", headerName:"Valor Líquido R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', 
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            } 
          }
        ];   
      
        const columnsDetailCrediarios= [    
          { field: "clienteid", headerName: "Cód",  headerClassName: 'header-detail', width: 50 },
          { field: "clientenome", headerName: "Cliente",  headerClassName: 'header-detail', width: 300 },
          { field: "qtde", headerName: "Quantidade",  headerClassName: 'header-detail', align: 'right', width: 100 },            
          { field: "valor", headerName:"Valor Líquido R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', 
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            } 
          }
        ];  
      
        const columnsDetailReceitaDespesa = [    
          { field: "id", headerName: "Cód",  headerClassName: 'header-detail', width: 50 },
          { field: "contadescricao", headerName: "Conta",  headerClassName: 'header-detail', width: 300 },
          { field: "qtde", headerName: "Quantidade",  headerClassName: 'header-detail', align: 'right', width: 100 },            
          { field: "valor", headerName:"Valor Líquido R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', 
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            } 
          }
          
        ];    
    
    const DefinirNomeGrupo = async () => {
        const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
        const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
        setNomeGrupo(groupName);
    }
  
    const definirLojaInicial = async()  => 
    {        
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                             
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
        {                        
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant(); 
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;             
            await setLojasFiltro(firstId);               
        }          
    }

    const handleExecutar = async() => {                                                                     
        setLoading(true);
        await getFinanceiro();         
        setLoading(false);
    }    
    
    const getFinanceiro = async() => {                  
        try 
        {           
            const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
            const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);                                       
            let url = `caixamovimento/resumofinanceiro/${lojasFiltro}/${dataIni}/${dataFin}`;                                

            SetDadosFinanceiros([]);         
            await SetTenant();    
            const response = await api.get(url);                                                                           
            SetDadosFinanceiros(response.data);               
        }
        catch (e)
        {
            console.log('erro');
        }        
    }            

   const handleShowLojas = () => 
   {            
        setShowLojas(true);
   }     
    
    const handleCloseLojas = () => 
    {        
          setShowLojas(false);                           
    }  

    const handleCloseModalAjuda = () => 
    {        
          SetShowAjuda(false);                           
    }  

    const handleCloseDetalheFinanceiro = () => 
    {        
          SetShowDetalheFinanceiro(false);                           
    }  

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }         
    
    const filtrosSelecionados = {        
        lojasSelecionadas : lojasFiltro,
        datasFiltro : dataIntervaloFiltro,
        nomeGrupo : nomeGrupo
      }  

    const visualizarImpressao = async  () => {   
      if (!isEmpty(dadosFinanceiro))
      {
        const classeImpressao = new ImpressaoFinanceiroPDF(dadosFinanceiro, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
      }        
    }  

    const dadosReceita = {  
        "Vendas à Vista" :  {
          valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.vendasavista) : 0)          
        },                   
        "Crediários Recebidos":  {
          valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.listaCrediariosRecebidos.reduce((acc, conta) => acc + conta.valor, 0)) : 0), 
          listacrediarios: dadosFinanceiro.listaCrediariosRecebidos,
          detalhar: true
        },       
        "Cartões Recebidos" :  {                             
            valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.cartoesrecebidos) : 0)            
        },
        "Cheques Recebidos" : {                             
          valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.chequesrecebidos) : 0)          
        },         
        "Receitas" : {
          valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.listaContasReceitas.reduce((acc, conta) => acc + conta.valor, 0)) : 0), 
          listacontasreceitas: dadosFinanceiro.listaContasReceitas,
          detalhar: true        
        },   
        "Valor Total Entradas" :  {                             
          valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.valortotalentradas) : 0)
        },                       
        };

        const dadosDespesa = {                
          "Despesas" : {
            valor: (!isEmpty(dadosFinanceiro) 
              ? (dadosFinanceiro.listaDespesas.reduce((acc, conta) => acc + conta.valor, 0))                 
              : 0), 
            listadespesas: dadosFinanceiro.listaDespesas,
            detalhar: true
          },                  
          "Fornecedores" : {
            valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.listaContasAPagarFornecedores.reduce((acc, conta) => acc + conta.valor, 0)) : 0), 
            listafornecedores: dadosFinanceiro.listaContasAPagarFornecedores,
            detalhar: true
          },               
          "Valor Total Saídas" : {                             
            valor: (!isEmpty(dadosFinanceiro) ? (dadosFinanceiro.valortotaldespesas) : 0)
          },                       
    };

    const rowsReceita = Object.keys(dadosReceita).map((key) => ({
        id : key,
        descricao: key.toString({fontSize: '25px'}),
        valor: dadosReceita[key].valor,
        registros : dadosReceita[key],
        detalhar : dadosReceita[key].detalhar,
        tipo: dadosReceita[key].tipo
    }));

    const rowsSaidas = Object.keys(dadosDespesa).map((key) => ({
        id : key,
        descricao: key.toString({fontSize: '25px'}),
        valor: dadosDespesa[key].valor,
        registros : dadosDespesa[key],
        detalhar : dadosDespesa[key].detalhar,
        tipo: dadosDespesa[key].tipo
    }));

    const columnsEntradas = [
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => (
            params.row.detalhar && params.row.valor > 0 ? (<CustomDetailPanelToggle id={params.id} value={params.value} />) : null
          ),
        },
        { field: "descricao", fontSize: '25px', headerName: "Entradas", headerClassName: 'entrada-header', headerAlign: 'left', width: 600, align: 'left', 
           cellClassName: () => { return 'description-cell-financeiro' } 
        },
        { field: "valor", headerName: "Valor R$", headerClassName: 'entrada-header', headerAlign: 'right', width: isEmpty(dadosFinanceiro) ? 550 : 500, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, cellClassName: () => { return 'value-cell-financeiro' } 
        },        
      ];   
      
    const columnsSaidas = [
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => (
            params.row.detalhar && params.row.valor > 0 ? (<CustomDetailPanelToggle id={params.id} value={params.value} />) : null
          ),
        },
        { field: "descricao", fontSize: '25px', headerName: "Saídas", headerClassName: 'saidas-header', headerAlign: 'left', width: 600, align: 'left', 
            cellClassName: () => { return 'value-cell-financeiro' } },
        { field: "valor", headerName: "Valor R$", headerClassName: 'saidas-header', headerAlign: 'right', width: isEmpty(dadosFinanceiro) ?  550 : 500, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, cellClassName: () => { return 'value-cell-financeiro' } 
        },
      ];  

      const getRowClassName = (params) => {
        if (params.row.descricao === "Valor Total Saídas" || params.row.descricao === "Valor Total Entradas")  {
          return 'bold-row'; 
        }
        return '';
      };  

    const contextvalues = {        
        definirLojasSelecionadas,
        dadosDetalheRow,
        dataIntervaloFiltro,
        lojasFiltro  
    }  

    registerLocale('pt-BR', dateFnsPtBR);
    setDefaultLocale('pt-BR');    
    
    if (loading) {
        return (
            <LoadingScreen/>
        );
      }     
      
      const renderTable = () => {        
          return (
            <table class="table">              
                <thead>                        
                    <th scope="col" style={{width: '540px'}}></th>                                                
                </thead>
                <tbody>
                    <tr >
                    <th className="text-left" scope="row" style={{ backgroundColor: 'rgb(228,238,253)', fontWeight: 'bold', fontSize: '20px' }}>Total Líquido</th>                   
                    <td className="text-right" 
                        style={{ backgroundColor: 'rgb(228,238,253)', fontWeight: 'bold', color: 'blue', fontSize: '20px'}}>
                        {((!isEmpty(dadosFinanceiro)) ? dadosFinanceiro.totalliquido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}                           
                    </td>                             
                    </tr>              
                </tbody>
            </table>        
          ); 
      }

    return (
        <>       
        <ModalAjudaRelatorioFinanceiro show={showAjuda} onHide={handleCloseModalAjuda}/>       

        <FinanceiroContext.Provider value={contextvalues}>             
            <form className="form">                
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório Financeiro &#40;Entradas x Saídas&#41;</label>
            </div>                                                
                <Card.Body>                                        
                    <Container>
                        <Card>                                                                                                                                                                                                            
                            <div className="filtros">
                            <div className="align-left">
                                <div className="row align-items-start" style={{marginTop: '20px', width:'460px' }}>
                                    <LocalizationProvider  
                                        dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                                               
                                    >                                                           
                                    <DateRangePicker                                          
                                        localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                        value={dataIntervaloFiltro}      
                                        onChange={(newValue) => setDataIntervaloFiltro(newValue)}                        
                                    />                     
                                    </LocalizationProvider>     
                                </div>
                                <div className="row align-items-start" style={{marginTop: '25px'}}>
                                    <div className="col-lg">
                                    <ButtonLojas
                                        width={'195px'}
                                        onClick={handleShowLojas}
                                        lojasFiltro={lojasFiltro}
                                    />
                                    </div>                                                                                                        
                                </div>                                                          
                            </div>
                        </div>      
                       
                        <div class="row" style={{marginTop: '30px'}}>
                            <div className='col-2'>
                                <div className="actions">
                                <ButtonExecute 
                                    width={'195px'}
                                    onClick={handleExecutar}
                                />   
                                </div>                    
                            </div>
                            <div className='col-7' style={{marginLeft: '15px'}}>
                                <ButtonClear                                     
                                    onClick={handleLimparFiltros}
                                />   
                            </div>
                            <div className='col'>
                              <ButtonPrint onClick={visualizarImpressao} style={{ cursor:'pointer', color: '#007CB0'}}/>
                            </div>
                            <div className='col'>
                              <HelpOutlined onClick={()=> SetShowAjuda(true)} style={{  marginTop: '10px', cursor:'pointer', color: '#007CB0'}}/>
                            </div>
                        </div>                                                                                   
                        
                        </Card>
                    </Container>                 
                </Card.Body>                     
            </form>     
                
            <ModalLojas show={showLojas} value={FinanceiroContext} onHide={handleCloseLojas}/>  
            <ModalDetalheFinanceiro show={showDetalheFinanceiro} onHide={handleCloseDetalheFinanceiro} 
              contexto={FinanceiroContext} dataFiltro={dataIntervaloFiltro}/>                                                        

            <Container>                                
                <DataGridPro   
                    style={{ fontFamily:  [
                      '-apple-system',
                      'BlinkMacSystemFont',
                      '"Segoe UI"',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                      '"Apple Color Emoji"',
                      '"Segoe UI Emoji"',
                      '"Segoe UI Symbol"',
                    ].join(','),                         
                    }}                   
                    getRowClassName={getRowClassName}                                           
                    columns={columnsEntradas}                        
                    rows={rowsReceita}     
                    rowHeight={35}                                                           
                    hideFooter                                       
                    columnHeaderHeight={30}   
                    getDetailPanelHeight={() => 'auto'} 
                    getDetailPanelContent={!isEmpty(dadosFinanceiro) ? getDetailPanelContent : null}                                          
                    
                    />                              
            </Container>        

             <Container style={{marginTop:'4px'}}>                                
                <DataGridPro    
                    style={{ fontFamily:  [
                      '-apple-system',
                      'BlinkMacSystemFont',
                      '"Segoe UI"',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                      '"Apple Color Emoji"',
                      '"Segoe UI Emoji"',
                      '"Segoe UI Symbol"',
                    ].join(','),                         
                    }}                               
                    getRowClassName={getRowClassName}                           
                    columns={columnsSaidas}                        
                    rows={rowsSaidas}       
                    rowHeight={35}
                    hideFooter                                       
                    columnHeaderHeight={30}   
                    getDetailPanelHeight={() => 'auto'} 
                    getDetailPanelContent={!isEmpty(dadosFinanceiro) ? getDetailPanelContent : null}                         
                    />   
            <div style={{marginTop: '2px'}}>
              {renderTable()}
            </div>                                   
            </Container>         
                         
        </FinanceiroContext.Provider>
        </>
    )
  }

export default FinanceiroProvider;
