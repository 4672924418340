import React from 'react';
import { Container, Modal} from 'react-bootstrap';
import ButtonConfirmar from '../Buttons/ButtonConfimar';
import TableClientes from '../Table/TableClientes'
import 'bootstrap/dist/css/bootstrap.css';

function ModalClientes(props) {
  const handleClose = () => props.onHide();

  return (    
    <Modal onHide={handleClose}
      {...props} 
      size="lg"
      aria-labelledby="contained-modal-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">
          Selecione os clientes
        </Modal.Title>
      </Modal.Header>
        <Modal.Body className="show-grid">
            <Container>
            <div className='col'>                            
                <TableClientes contexto={props.value}/>                
            </div>                          
            </Container>
        </Modal.Body>
      <Modal.Footer>      
      <ButtonConfirmar
          onClick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalClientes;
