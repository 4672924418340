import { isEmpty } from 'lodash';
import * as XLSX from 'xlsx';
const { format } = require('date-fns');

//IMPRESSÃO DE RELATÓRIO DE CONTAGEM DE ESTOQUE

export class CLIENTES_XLS {
    constructor(dadosParaImpressao, tipovisaoSelect, tipoAnaliseSelect) {
      this.dadosParaImpressao = dadosParaImpressao;
      this.tipovisaoSelect = tipovisaoSelect;
      this.tipoAnaliseSelect = tipoAnaliseSelect;
    }
  
    exportToXLS () {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(this.dataExportXLS());
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `CLIENTES.xlsx`);
    }
  
    dataExportXLS = () => {     
      if (this.tipovisaoSelect === 'CADASTRO' && this.tipoAnaliseSelect === 'TODOS')
      {
        const dataRows = this.dadosParaImpressao.map((dt) => ({                
          NOME: dt.nome,
          LOJAVENDA: dt.lojavendaid,
          ÚLTIMA_VENDA : dt.ultimavenda ? new Date(dt.ultimavenda).toLocaleDateString('pt-BR') : '',
          PARENTESCO: dt.parentesco,
          NOME_CADASTRO: dt.nomeparente,
          SEXO: dt.sexo,
          CPF_CNPJ: dt.cpfcnpj,
          RG_IE: dt.rgie,
          NASCIMENTO: !isEmpty(dt.nascimento) ? new Date(dt.nascimento).toLocaleDateString('pt-BR') : '',
          CELULAR: dt.celular,
          TELFIXO: dt.telfixo,
          ESCOLARIDADE: dt.escolaridade,
          EMAIL: dt.email,
          INSTAGRAM: !isEmpty(dt.instagram) ? '@'+ dt.instagram : '',
          ESTADO_CIVIL: dt.estadocivil,
          CEP: dt.cep,
          ENDERECO: dt.endereco,
          NUMEROEND: dt.numeroend,
          BAIRRO: dt.bairro,
          COMPLEMENTO: dt.complemento,
          ESTADO: dt.estado,
          CIDADE: dt.cidade,
          MANEQ_SUP : dt.roupa,
          MANEQ_INF : dt.roupainferior,
          MANEQ_INF : dt.roupainferior,
          CALÇADO : dt.calcado,
          CLIENTE_CATEGORIA_ID: !isEmpty(dt.clientecategoria) ? dt.clientecategoria.id : '',
          CLIENTE_CATEGORIA_DESCRICAO: !isEmpty(dt.clientecategoria) ? dt.clientecategoria.descricao : '',
          PROFISSAO:  dt.clientetrabalho !== null ?  dt.clientetrabalho.profissao != null ? dt.clientetrabalho.profissao.descricao : '' : '',
          BLOQUEADO: dt.bloqueado ? 'SIM' : 'NÃO',                     
        }));          

        return [...dataRows];
      }
      else if (this.tipovisaoSelect === 'CADASTRO' && this.tipoAnaliseSelect !== 'TODOS')
        {
          const dataRows = this.dadosParaImpressao.map((dt) => ({                
            LOJAVENDA: dt.lojavendaid,
            ÚLTIMA_VENDA : dt.ultimavenda ? new Date(dt.ultimavenda).toLocaleDateString('pt-BR') : '',
            NOME: dt.nome,                        
            PARENTESCO: dt.parentesco,
            NOME_CADASTRO: dt.nomeparente,
            SEXO: dt.sexo,                        
            NASCIMENTO: !isEmpty(dt.nascimento) ? new Date(dt.nascimento).toLocaleDateString('pt-BR') : '',
            MANEQ_SUP : dt.roupa,
            MANEQ_INF : dt.roupainferior,
            MANEQ_INF : dt.roupainferior,
            CALÇADO : dt.calcado,
            CELULAR: dt.celular,                             
          }));          
  
          return [...dataRows];
        }
        else if (this.tipovisaoSelect === 'VENDA')
        {
          const dataRows = this.dadosParaImpressao.map((dt) => ({                
            LOJAVENDA: dt.lojavendaid,
            ÚLTIMA_VENDA : dt.ultimavenda ? new Date(dt.ultimavenda).toLocaleDateString('pt-BR') : '',
            NOME: dt.nome,    
            SEXO: dt.sexo,                                  
            NASCIMENTO: !isEmpty(dt.nascimento) ? new Date(dt.nascimento).toLocaleDateString('pt-BR') : '',
            QTDE_VENDA : dt.qtdevendas,
            QTDE_ITENS : dt.qtdeitens,
            VALOR_TOTAL_VENDA_R$ : this.formatDecimal(dt.valortotalvenda),
            VALOR_MÉDIO_R$ : this.formatDecimal(dt.valormedio),
            CELULAR: dt.celular,                             
          }));          

          const totalalizadorTabela = {            
            LOJAVENDA: 'TOTAL',
            ÚLTIMA_VENDA : '',
            NOME: '',    
            SEXO: '',                                  
            NASCIMENTO: '',
            QTDE_VENDA : this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdevendas, 0),
            QTDE_ITENS : this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdeitens, 0),
            VALOR_TOTAL_VENDA_R$ : this.formatDecimal(this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valortotalvenda, 0)),
            VALOR_MÉDIO_R$ : this.formatDecimal(this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valortotalvenda, 0) / this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdevendas, 0)),
            CELULAR: '',               
          };
  
          return [...dataRows, totalalizadorTabela];
        }                
    };
   
    formatDecimal = (value) => {      
      if (!isNaN(value)) {
        return parseFloat(value).toFixed(2);
      }
      return value; 
    };
}

