import moment from 'moment';
import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';

export class ImpressaoVendaPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;          
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {
   if (this.filtros.tipoVisao === 'venda')
   {
      const headerVenda = [
        { text: 'Loja', bold: true, fontSize: 9, margin: [4, 4, 0, 0] },
        { text: 'Data', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},
        { text: 'Venda', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Nota', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },        
        { text: 'Vendedor', bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Qtde itens', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Desconto R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },        
        { text: 'Acréscimo R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },        
        { text: 'Crédito R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },        
        { text: 'Valor Líquido R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }          
      ];
      return headerVenda;  
   }

    const headerDiaMes = [
        { text: 'Loja', bold: true, fontSize: 9, margin: [4, 4, 4, 4] },
        { text: 'Data', bold: true, alignment: 'left', fontSize: 9, margin:[4, 4, 4, 4]  },
        { text: 'Qtde Itens', bold: true, alignment: 'right', fontSize: 9, margin: [4, 4, 4, 4] },
        { text: 'Qtde Vendas', bold: true, alignment: 'right', fontSize: 9, margin:[4, 4, 4, 4]  },
        { text: 'Ticket Médio R$', bold: true, alignment: 'right', fontSize: 9, margin: [4, 4, 4, 4] },
        { text: 'Desconto R$', bold: true, alignment: 'right', fontSize: 9, margin:[4, 4, 4, 4]  },
        { text: 'Valor Líquido R$', bold: true, alignment: 'right', fontSize: 9, margin: [4, 4, 4, 4] }                  
      ];
    return headerDiaMes;       
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }

  GetBody() {   
    
    if (this.filtros.tipoVisao === 'dia')
    {
        const bodyDia = this.dadosParaImpressao.map((prod) => {
          return  [          
            { text: prod.lojaid, fontSize: 8, margin: [4, 0, 0, 0], border: [] },
            { text: new Date(prod.data).toLocaleDateString('pt-BR', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }), fontSize: 8, margin:[0, 0, 4, 0] , border: []},
            { text: prod.qtdeitens, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0], border: [] },
            { text: prod.qtdevendas, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0], border: [] },
            { text: prod.ticketmedio.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: []},
            { text: prod.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: []},
            { text: prod.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0], border: []}           
          ]                   
        }
      );      
      return bodyDia;
    }
    else if (this.filtros.tipoVisao === 'mes')
    {         
      const bodyMes = this.dadosParaImpressao.map((prod) => {    
          return  [          
            { text: prod.lojaid, fontSize: 8, margin:[4, 0, 0, 0], border: []},
            { text: this.primeiraLetraMaiuscula(new Date(prod.data).toLocaleDateString('pt-BR', {
              month: 'long',
              year: 'numeric',
            })) , fontSize: 8, margin:[4, 0, 0, 0] , border: []},            
            { text: prod.qtdeitens, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] , border: []},
            { text: prod.qtdevendas, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] , border: []},
            { text: prod.ticketmedio.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: []},
            { text: prod.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
            { text: prod.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0] , border: []}           
          ]                
        }
      );
      return bodyMes;
    }                 
    else if (this.filtros.tipoVisao === 'venda') {
      const bodyVenda = this.dadosParaImpressao.flatMap((prod) => {
        const vendaRows = prod.vendas.map((venda) => [
              { text: venda.lojaid, fontSize: 8, margin:[4, 0, 0, 0] , border: []},
              { text: new Date(venda.data).toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }), fontSize: 8, margin:[0, 0, 4, 0] , border: []},
              { text: venda.id, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
              { text: venda.numeronfce, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
              { text: venda.vendedornome.substring(0, 10), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] , border: []},
              { text: venda.qtdeitens, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
              { text: venda.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
              { text: venda.acrescimo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
              { text: venda.credito.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
              { text: venda.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
          
        ]);

        if (this.filtros.tipoImpressao === 'detalhado')
        {
          const detalheRows = [
            {
              table: {
                widths: [50, 35, 90, 34, 45, 40, 40, 40, 23, 35, 35], 
                headerRows: 1, 
                body: [                  
                  [{ text: 'Vendedor', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                 
                   { text: 'Produto', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                   { text: 'Descrição Produto', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                   { text: 'Tamanho', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                   { text: 'Cor', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },     
                   { text: 'Desc. R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },     
                   { text: 'Acrésc. R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },     
                   { text: 'Crédito R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },       
                   { text: 'Qtde', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },       
                   { text: 'V. Uni. R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' }, 
                   { text: 'V. Líq. R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                 
                  ],
  
                  ...prod.vendaMovimentos.map((vendmov) => [
                    { text: vendmov.vendedornome.substring(0, 10), fontSize: 8, margin:[4, 0, 0, 0] , border: []},                                  
                    { text: vendmov.produtoid, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                    { text: vendmov.produtodescricao.substring(0, 18), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] , border: []},
                    { text: vendmov.produtotamanho, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                    { text: vendmov.produtocor, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                    { text: vendmov.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[4, 0, 0, 0] , border: []},                
                    { text: vendmov.acrescimo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                    { text: vendmov.credito.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                    { text: vendmov.qtde.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                    { text: vendmov.valorunitario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                    { text: vendmov.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                  ]),              
                ],
              },
              fontSize: 8,
              colSpan: 10,
              margin: [0, 0, 0, 6], 
            },
          ];
      
          return [...vendaRows, detalheRows];
        }
        else
        {
            return [...vendaRows];
        }        
      });
    
      return bodyVenda;
    }
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: this.filtros.tipoVisao === 'venda' ? 10 : 7
      }
    ];

    const lineSpace = [
      {
        text:
        '\n\n\n',
        fontSize: 5,
        colSpan: this.filtros.tipoVisao === 'venda' ? 10 : 7
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody(), lineSpace];     
    content.push(this.GetTotalizadores());         
    return content;
  } 

  GetTotalizadores() {
    if (this.filtros.tipoVisao === 'venda')
    {
        const totalEstoque =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].qtdeitens, 0);          
        const totalDesconto = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].desconto, 0);
        const totalAcrescimo = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].acrescimo, 0);
        const totalCredito =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].credito, 0);
        const totalValorLiquido = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].valorliquido, 0);                     
        const totalVendas =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdevendas, 0); 

        const totalRowVenda = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },
          { text: 'Qtde Vendas', bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0] },  
          { text: totalVendas, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: ''},          
          { text: ''},                                        
          { text: totalEstoque, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },      
          { text: totalDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalAcrescimo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalValorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  }      
        ];    
        return totalRowVenda;      
    }

      const totalEstoque =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdeitens, 0);   
      const totalVendas =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdevendas, 0);       
      const totalDesconto = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.desconto, 0);
      const totalValorLiquido = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorliquido, 0);   
      const totalTicketMedio =  totalValorLiquido / totalVendas; 

      const totalRowVenda = [
        { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },
        { text: ''},
        { text: totalEstoque, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },      
        { text: totalVendas, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
        { text: totalTicketMedio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }),
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },      
        { text: totalDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
        { text: totalValorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  }      
      ];    
      return totalRowVenda;    
  }
  
  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE VENDAS POR PERÍODO', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {
          text: `\nVisão: Por ${
            this.filtros.tipoVisao === 'mes' ? 'Mês' : this.primeiraLetraMaiuscula(this.filtros.tipoVisao)
          }` 
            + `  |  Período: ${this.filtros.tipoVisao === 'mes' 
                ?    (this.primeiraLetraMaiuscula(format(new Date(this.filtros.datasFiltro[0]), 'MMMM/yyyy', { locale: dateFnsPtBR })) + ' a ' 
                     + this.primeiraLetraMaiuscula(format(new Date(this.filtros.datasFiltro[1]), 'MMMM/yyyy', { locale: dateFnsPtBR })))
                :  this.formatarData(this.filtros.datasFiltro[0]) + ' a ' + this.formatarData(this.filtros.datasFiltro[1])}`


                
            + `  |  Lojas: ${this.filtros.lojasSelecionadas}`
            + `  |  Clientes: ${(this.filtros.clientesFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.clientesFiltro))}`
            + `  |  Vendedores: ${(this.filtros.vendedoresFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.vendedoresFiltro))}`,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: 
              this.filtros.tipoVisao === 'venda' 
              ? [ 25, 50, 30, 25, 55, 50, 60, 60 , 65, 75 ]            
              : [ 30, 120, 60, 70, 90, 70, 80],              
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}