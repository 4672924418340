import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, createSession } from '../services/api';
import EncryptionUtil from '../Utils/EncryptionUtil';
import ModalMensagemUsuarioSemAcesso from '../components/Modal/MensagensAlerta/ModalMensagemUsuarioSemAcesso';


export const AuthContext = createContext();

export const AuthProvider = ({children}) => {    
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);  
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(()=> {
       const userString = sessionStorage.getItem('user');               
       const token = sessionStorage.getItem('token');             

       if (userString)
       {            
          const desencryptJSON = new EncryptionUtil(); 
          const desencryptJSONObj = desencryptJSON.decryptJSON(sessionStorage.getItem('user'));                    
          setUser(JSON.parse(desencryptJSONObj));        
          api.defaults.headers.Authorization = `Bearer ${token}`;  
       }

       setLoading(false);
    },[]);

    if (loading)
    {
        <div>Carregando...</div>
    }

    const usuario = (id, login, nomegrupo, plano, loja, permissoes) => {
        return {
          id: id,
          login: login,
          nomegrupo : nomegrupo,
          plano : plano,
          loja : loja,
          permissoes : permissoes, 
        };
      };
   
    const login = async (login, senha, loja, name) => { 
        try {                   
        const response = await createSession(login, senha, loja, name);        
        if (response.data) {
            const loggedUser = response.data.login;
            const token = response.data.token;
            api.defaults.headers.Authorization = `Bearer ${token}`;
                  
            const usuarioAcesso = await api.get(`usuario/login/${login}`);
            const userid = usuarioAcesso.data.id;
            const userLogin = usuarioAcesso.data.login;
            const nomegrupo = usuarioAcesso.data.nomegrupo;
            const plano = usuarioAcesso.data.plano;
            const permissoes = usuarioAcesso.data.permissoes;
      
            const encryptedJSON = new EncryptionUtil();
            const encryptedJSONObj = encryptedJSON.encryptJSON(JSON.stringify(usuario(userid, userLogin, nomegrupo, plano, loja, permissoes)));
      
            sessionStorage.setItem('user', encryptedJSONObj);
            sessionStorage.setItem('token', token);
      
            setUser(loggedUser);
            navigate("/home");          
          }           
        } catch (error) {          
           setShowErrorMessage(true);           
        }
    };

    const logout = () => {         
        const desencryptJSONHeader = new EncryptionUtil(); 
        const desencryptJSONObj = desencryptJSONHeader.decryptJSON(sessionStorage.getItem('header'));                
        var codCliente = JSON.parse(desencryptJSONObj.Tenant).CodCliente;        
        var Name = JSON.parse(desencryptJSONObj.Tenant).Name;        

        const encryptionObj = new EncryptionUtil(); 
        const encryptedString = encryptionObj.encryptText(codCliente); 
        const encryptedNameString = encryptionObj.encryptText(Name); 

        sessionStorage.clear();
        api.defaults.headers.Authorization = null;
        setUser(null);        
        navigate(`/login/${encodeURIComponent(encryptedString)}/${encodeURIComponent(encryptedNameString)}`);
    };

    return (
        <AuthContext.Provider         
            value={{authenticated: !!user, user, loading, login, 
                logout, showErrorMessage, setShowErrorMessage}}        
        >
         {children}   
        </AuthContext.Provider>
    );
};