import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from "../../contexts/auth";
import "./styles.css";
import background from '../../img/background.jpg';
import logo from '../../img/logomascsvg.svg';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { TextField } from '@mui/material';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginPage = () => {    
    const { login, showErrorMessage, setShowErrorMessage } = useContext(AuthContext);
    const [loginUser, setLogin] = useState("");
    const [senha, setSenha] = useState("");
    const [loja, setLoja] = useState("");       
    const [ name, setName] = useState("");       
    const { idLoja, dealer } = useParams();          
    const senhaRef = useRef(null);
    const entrarRef = useRef(null);                     
    const [values, setValues] = useState({showPassword: false,});    
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {                            
        const desciptedObj = new EncryptionUtil(); 
        const descriptedString = desciptedObj.decryptText(decodeURIComponent(idLoja));         
        const descriptedNameString = desciptedObj.decryptText(decodeURIComponent(dealer)); 
        setLoja(descriptedString);   
        setName(descriptedNameString);
        setShowErrorMessage(false);
      }, []);

    const handleSubmit = (e) => {         
        e.preventDefault();                   
        loginExecute();
    }

    const loginExecute = () => {
        login(loginUser, senha, loja, name);                             
    }

    const handleInputValueChange = (event) => {
        const uppercaseValue = event.target.value.toUpperCase();
        setLogin(uppercaseValue);        
    }

    const handleKeyDown  = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const { name } = e.target;
    
          if (name === 'login') {
            senhaRef.current.focus();
          } else if (name === 'senha') {
            loginExecute();             
          }               
        }
      };     
    
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };    

    return (
        <>
        <div className='col' style={{ 
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',                
                backgroundSize: 'cover' }}>
            <div id="login">                    
                <form className="form" style={{borderRadius: '20px'}} onSubmit={handleSubmit}>                  
                <h1 className="title" style={{color:'rgb(0, 38, 103)', fontFamily: 'sans-serif', marginLeft: '160px'}}>
                
                <img
                        alt="Logo"
                        src={logo}
                        style={{ width: '130px', height: '29.57px', marginLeft: '20px' }}                                      
                        />    
                </h1>      
                    <div className="field">                                                                        
                        <TextField 
                            type="text"
                            id="outlined-basic" 
                            name="login" 
                            label="Login" 
                            value={loginUser}
                            variant="outlined"                            
                            style={{ width: '450px',  marginLeft: '8px' }}                                
                            onChange={handleInputValueChange}
                            onKeyDown={handleKeyDown}                                                  
                            autoFocus                                
                        />                                                
                    </div>

                    <div className="field"  style={{marginTop: '5px'}}>                                   
                        <TextField 
                            type={values.showPassword ? 'text' : 'password'}
                            id="outlined-basic" 
                            name="senha" 
                            label="Senha" 
                            value={senha} 
                            variant="outlined"                                             
                            onChange={(e) => setSenha(e.target.value.toUpperCase())}
                            onKeyDown={handleKeyDown}                         
                            style={{ width: '450px', marginLeft: '8px' }}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton 
                                onClick={handleClickShowPassword} 
                                aria-label="toggle password visibility"                                    
                                onMouseDown={handleMouseDownPassword}                                    
                                edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                            inputRef={senhaRef}                                                  
                        />                       

                    </div>
                     { showErrorMessage &&<label className="text" style={{marginLeft: '130px', color:'red'}}>Usuário ou senha incorretos</label>}
                    <div className="actions">                        
                        <Button name="entrar" 
                            style={{                                
                                backgroundColor: '#004A82',                                 
                                width: '450px',
                                border : '0px',                                
                                height: '45px',  
                                marginTop: '35px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} 
                            type="submit"
                            ref={entrarRef} 
                        >
                            Entrar</Button>                                          
                    </div>                                                                                                                
                </form>                                                                
            </div>  
        </div>    
        </>
    );
}

export default LoginPage;
