import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Button, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { get } from 'lodash';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableTamanhos = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [tamanhos, setTamanhos] = useState([]);  
  const [tamanhosData, setTamanhosData] = useState([]);
  const [selectedTamanhos, setSelectedTamanhos] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirTamanhosSelecionados } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedTamanhos = sessionStorage.getItem('selectedTamanhos');
    if (storedSelectedTamanhos) {    
      setSelectedTamanhos(JSON.parse(storedSelectedTamanhos));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedTamanhos.length > 0)
   {
      sessionStorage.setItem('selectedTamanhos', JSON.stringify(selectedTamanhos));
   }       
  }, [selectedTamanhos, tamanhos]);  

  const handletamanhoselection = (id) => {
    const updatedIndexes = selectedTamanhos.includes(id)
      ? selectedTamanhos.filter((index) => index !== id)
      : [...selectedTamanhos, id];
    setSelectedTamanhos(updatedIndexes);   

    const selectedTamanhosIds = updatedIndexes.map((index) => index.descricao);
    definirTamanhosSelecionados(selectedTamanhosIds);
  };

  const handleRemovertamanhosSelecionadasClick = (id) => {
    const updatedTamanhosSelecionados = selectedTamanhos.includes(id)
      ? selectedTamanhos.filter((index) => index !== id)
      : [...selectedTamanhos, id];
    setSelectedTamanhos(updatedTamanhosSelecionados);   

    const selectedTamanhosIds = updatedTamanhosSelecionados.map((index) => index.descricao);
    definirTamanhosSelecionados(selectedTamanhosIds);

    if (selectedTamanhosIds.length === 0)
    {
      sessionStorage.removeItem('selectedTamanhos');
    }
  };

  const handleRowClick = (tamanhoId) => {
    setTamanhos((prevtamanhos) =>
      prevtamanhos.filter((tamanho) => tamanho.id !== tamanhoId)
    );
  };

  const handleRowSelectedClick = (tamanho) => {

    if (!tamanhos.includes(tamanho.id))
    {
        const updatedtamanhos = [...tamanhos, tamanho];
        setTamanhos(updatedtamanhos);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedTamanhos(tamanhos);
       setTamanhos([]);
       
       const selectedTamanhosIds = tamanhos.map((index) => index.descricao);
       definirTamanhosSelecionados(selectedTamanhosIds);       
       sessionStorage.setItem('selectedTamanhos', selectedTamanhos);      
  }

  const handleRemoverTodos = async() => {         
      if (!tamanhosData || tamanhosData.length === 0)
      {
        gettamanhos(); 
      }      
      setTamanhos(tamanhosData);
      setSelectedTamanhos([]);    
      definirTamanhosSelecionados([]);         
      sessionStorage.removeItem('selectedTamanhos');      
  }

  const gettamanhos = async () => {       
    try {          
      let url = `tamanhoproduto/listagem/false/${busca.toUpperCase()}`;     
      await SetTenant(); 
      const response = await api.get(url);
      setTamanhos(response.data);   
      setTamanhosData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    gettamanhos();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da tamanho"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>                 
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>                    
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {tamanhos.map((tamanho) => {
                  if (selectedTamanhos.some((mrc) => mrc.id === tamanho.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={tamanho.id}
                        onClick={() => {
                          handletamanhoselection(tamanho);
                          handleRowClick(tamanho.id);
                        }}
                      >                        
                        <td>{tamanho.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedTamanhos.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>                    
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedTamanhos.map((tamanho) => (
                    <tr key={tamanho.id}
                        onClick={() => {
                          handleRemovertamanhosSelecionadasClick(tamanho);
                          handleRowSelectedClick(tamanho)
                        }}
                    >
                      <td>{tamanho.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableTamanhos;

