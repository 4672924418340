import React from 'react';
import { Container, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Alert from '@mui/material/Alert';

function ModalAjudaRelatorioComprasFornecedor(props) {  
  const handleClose = () => props.onHide();

  return (    
    <Modal onHide={handleClose}
      {...props} 
      size='lg'      
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
    <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>  
      <Modal.Title id="contained-modal-title" >                               
        <Alert severity="info" style={{marginLeft: '250px'}}>          
            OBJETIVO DO RELATÓRIO
        </Alert>

       </Modal.Title>    
        </Modal.Header>
            <Modal.Body className="show-grid">
            <Container>
              <div className='row'>
                <label style={{ textAlign: 'center', fontSize: '20px', fontFamily: 'arial' }}>
                  <strong>Apresentar as compras realizadas por Fornecedor</strong><br/><br/>      
                  <strong>DEFINIÇÕES</strong><br/><br/>
                </label>
                <label style={{ textAlign: 'left', fontSize: '20px' }}>        
                  <strong>- Compras: </strong> Valor de custo total dos produtos comprados<br />
                  <strong>- Previsão Vendas:</strong> Valor de venda previsto com base na quantidade de produtos comprados vezes o preço de venda bruto informado no cadastro de produtos<br />                                
                </label>
              </div>
            </Container>
            </Modal.Body>
          <Modal.Footer>              
      </Modal.Footer>
    </Modal>   

  );
}

export default ModalAjudaRelatorioComprasFornecedor;
