import  React , { createContext, useState, useEffect } from 'react'; 
import {    
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
  } from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import PaginationItem from '@mui/material/PaginationItem';
import "react-datepicker/dist/react-datepicker.css";
import { Container, Grid, Pagination, Paper, Radio, Typography, setRef } from '@mui/material';
import { api } from '../../services/api';
import { Card, Stack  } from 'react-bootstrap';
import { format } from 'date-fns';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalImpressaoResumidoDetalhado from '../../components/Modal/ModalImpressaoResumidoDetalhado';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { ImpressaoVendaProdutoPDF } from '../../components/Impressao/ImpressaoVendaProdutoPDF';
import '../../pages/VendaProduto/vendaprodutostyles.css';
import { isEmpty } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Box } from '@mui/system';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonItens from '../../components/Buttons/ButtonItens';
import ButtonMarcas from '../../components/Buttons/ButtonMarcas';
import ButtonCategorias from '../../components/Buttons/ButtonCategorias';
import ButtonDepartamentos from '../../components/Buttons/ButtonDepartamentos';
import ModalItem from '../../components/Modal/ModalItens';
import ModalCategorias from '../../components/Modal/ModalCategorias';
import ModalDepartamentos from '../../components/Modal/ModalDepartamentos';
import ModalMarcas from '../../components/Modal/ModalMarcas';
import ModalColecoes from '../../components/Modal/ModalColecoes';
import ModalTamanhos from '../../components/Modal/ModalTamanhos';
import ModalCores from '../../components/Modal/ModalCores';
import ModalReferencias from '../../components/Modal/ModalReferencias';
import ButtonColecoes from '../../components/Buttons/ButtonColecoes';
import ButtonTamanhos from '../../components/Buttons/ButtonTamanhos';
import ButtonCores from '../../components/Buttons/ButtonCores';
import ButtonReferencias from '../../components/Buttons/ButtonReferencias';
import ButtonClear from '../../components/Buttons/ButtonClear';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonVendedores from '../../components/Buttons/ButtonVendedores';
import ModalVendedores from '../../components/Modal/ModalVendedores';
import ButtonClientes from '../../components/Buttons/ButtonClientes';
import ModalClientes from '../../components/Modal/ModalClientes';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
function DetailPanelContent({ row: rowProp }) {     
    return (
      <Container sx={{ marginTop: '5px', marginBottom: '5px' }}>
        <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
          <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
            <Stack direction="column" spacing={0} sx={{ height: '100%' }}>           
              <Grid container sx={{ flexGrow: 1 }}>
                <Grid item md={6}>                
                </Grid>
              </Grid>
              <Container sx={{ height: '100%' }}>           
                <CustomStyledDataGrid                                                                   
                  density="compact"                
                  columns={rowProp.vendaMovimentos
                    ? columnsDetailCliente
                    : null}                   
                    rows={rowProp.vendaMovimentos 
                        ? rowProp.vendaMovimentos 
                        : null}        
                  sx={{ flex: 1, height: '100%' }}                  
                  hideFooter
                />
              </Container>             
            </Stack>
          </Paper>
        </Stack>
      </Container>
    );
  }

  const headerClassName = 'blue-header';  

  const columnsDetailCliente = [                
    { field: "lojaid", headerName: "Loja", headerClassName: 'header-detail', width: 50 },    
    { field: "produtoid", headerName: "Código",  headerClassName: 'header-detail', width: 70 },    
    { field: "produtodescricao", headerName: "Produto",  headerClassName: 'header-detail', headerAlign: 'left', width: 200, align: 'left'},      
    { field: "produtotamanho", headerName: "Tamanho",  headerClassName: 'header-detail', headerAlign: 'left', width: 70, align: 'left'},           
    { field: "produtocor", headerName: "Cor",  headerClassName: 'header-detail', headerAlign: 'left', width: 115, align: 'left'},    
    { field: "datavenda", headerName: "Data",  type: 'date', valueGetter: (params) => params.row.datavenda,   
        valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
        headerClassName: 'header-detail',  headerAlign: 'center', 
        width: 93, align: 'right'},     
    { field: "vendaid", headerName: "Venda",  headerClassName: 'header-detail', headerAlign: 'right', width: 70, align: 'right'},   
    { field: "qtde", headerName: "Qtde",  headerClassName: 'header-detail', headerAlign: 'right', width: 40, align: 'right'},   
    { field: "valorunitario", headerName:"V. Unitário R$",  headerClassName: 'header-detail', headerAlign: 'right', width: 98, align: 'right', 
        valueFormatter: (params) => {
            return parseFloat(params.value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
    },               
    { field: "valorliquido", headerName:"V. Líquido R$",  headerClassName: 'header-detail', headerAlign: 'right', width: 98, align: 'right', 
      valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
        }
    },       
    { field: "vendedornome", headerName: "Vendedor",  headerClassName: 'header-detail', headerAlign: 'left', width: 120, align: 'left'}, 
];     
  
export const VendaProdutoContext = createContext();

  export const VendaProdutoProvider = () => {   
    const sessionStorageUtils = new SessionStorageUtils();  
    const PAGE_SIZE = 50; 
    const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0,});         
    const [nomeGrupo, setNomeGrupo] = useState([]);
    const [showLojas, setShowLojas] = useState(false);           
    const [showItens, setShowItens] = useState(false);
    const [showMarcas, setShowMarcas] = useState(false);
    const [showCategorias, setShowCategorias] = useState(false);
    const [showDepartamentos, setShowDepartamentos] = useState(false);
    const [showColecoes, setShowColecoes] = useState(false);
    const [showTamanhos, setShowTamanhos] = useState(false);
    const [showCores, setShowCores] = useState(false);
    const [showReferencias, setShowReferencias] = useState(false);
    const [showVendedores, setShowVendedores] = useState(false);
    const [showClientes, setShowClientes] = useState(false);
    const [dadosVenda, setDadosVenda] = useState([]);          
    const [lojasFiltro, setLojasFiltro] = useState([]);     
    const [itensFiltro, setItensFiltro] = useState([]);
    const [marcasFiltro, setMarcasFiltro] = useState([]);   
    const [categoriasFiltro, setCategoriasFiltro] = useState([]);   
    const [departamentosFiltro, setDepartamentosFiltro] = useState([]);   
    const [referenciasFiltro, setReferenciasFiltro] = useState([]);  
    const [colecoesFiltro, setColecoesFiltro] = useState([]);  
    const [tamanhosFiltro, setTamanhosFiltro] = useState([]);   
    const [coresFiltro, setCoresFiltro] = useState([]);   
    const [vendedoresFiltro, setVendedoresFiltro] = useState([]);   
    const [clientesFiltro, setClientesFiltro] = useState([]);   
    const [loading, setLoading] = useState(true);    
    const [limparFiltros, SetLimparFiltros] = useState(false);  
    const [tipoVisaoSelect, SetTipoVisaoSelect] = useState('produto');                     
    const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([startOfMonth(new Date()), new Date()]);              
    const [showTipoImpRel, setShowTipoImpRel] = useState(false);
    const [tipoImpRel, setTipoImpRel] = useState('');
    const navigate = useNavigate();
    const permissao = new Permissoes(); 
 
    useEffect(() => {
        setTimeout(() => {     
            setLoading(true);  
            if (ValidarPermissoes() == false) { return; }      
            LimparFiltrosSelecionados();
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []);  

    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('43f29f33616124826d7ac38bcbd160a7');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  

    useEffect(() => {
        if (dadosVenda && dadosVenda.length > 0 && tipoImpRel !== '')
        {
            ImprimirRelatorio();          
        }      
    }, [tipoImpRel]);  
 
    useEffect(() => {        
        if (limparFiltros)
        {
            definirLojaInicial();            
            SetLimparFiltros(false);
        }        
      }, [limparFiltros]);

    function LimparFiltrosSelecionados() {
        sessionStorage.removeItem('selectedLojas');
        sessionStorage.removeItem('selectedMarcas');
        sessionStorage.removeItem('selectedItens'); 
        sessionStorage.removeItem('selectedCategorias');   
        sessionStorage.removeItem('selectedDepartamentos');  
        sessionStorage.removeItem('selectedColecoes');   
        sessionStorage.removeItem('selectedTamanhos');
        sessionStorage.removeItem('selectedCores');
        sessionStorage.removeItem('selectedReferencias');  
        sessionStorage.removeItem('selectedVendedores');  
        sessionStorage.removeItem('selectedClientes');  
        setLojasFiltro([]);
        setMarcasFiltro([]);
        setItensFiltro([]);        
        setCategoriasFiltro([]);        
        setDepartamentosFiltro([]);        
        setColecoesFiltro([]);        
        setTamanhosFiltro([]);        
        setCoresFiltro([]);        
        setReferenciasFiltro([]);        
        setVendedoresFiltro([]);        
        setClientesFiltro([]);        
        setDadosVenda([]);                         
    } 

    const handleLimparFiltros = async () => {   
        resetarDataInicial();
        LimparFiltrosSelecionados();                         
        SetLimparFiltros(true);
      }
      
    const resetarDataInicial = () => {
        setDataIntervaloFiltro([
          startOfMonth(new Date()),
          new Date()
        ]);
      };            

    const ImprimirRelatorio = async () => {
        const classeImpressao = new ImpressaoVendaProdutoPDF(dadosVenda, filtrosSelecionados);      
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));   
        setShowTipoImpRel(false);                 
    }

    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;      
      setNomeGrupo(groupName);      
    }
    
    const renderTable = () => {       
        if (tipoVisaoSelect === 'produto')
        {
            return (
                <div className='table-align'>
                    <table className="table">
                    <thead>
                        <th scope="col" style={{width: '490px'}}></th>
                        <th scope="col" className="text-right" style={{width: '50px' }}></th>
                        <th scope="col" className="text-right" style={{width: '65px' }}></th>
                        <th scope="col" className="text-right" style={{width: '100px'}}></th>
                        <th scope="col" className="text-right" style={{width: '100px' }}></th>
                        <th scope="col" className="text-right" style={{width: '85px' }}></th>
                        <th scope="col" className="text-right" style={{width: '80px' }}></th>
                        <th scope="col" className="text-right" style={{width: '80px' }}></th>
                        <th scope="col" className="text-right" style={{width: '100px' }}></th>                
                    </thead>
                    <tbody>
                        <tr>
                        <th className="text-left" scope="row" style={{backgroundColor: '#DAEBF2'}}>Total R$</th>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '48px' }}>
                            {(!isEmpty(totalizador) ? totalizador.qtdeprodutos : 0)}
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '62px' }}>
                            {'100%'}
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '98px'}}>
                            {'R$\n' + (!isEmpty(totalizador) ? totalizador.valorliquido : 0)
                                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '80px' }}>
                            {'R$\n' + (!isEmpty(totalizador) ? totalizador.custo : 0)
                                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '95px'}}>
                            {'R$\n' + (!isEmpty(totalizador) ? totalizador.diferencatroca : 0)
                                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '80px'}}>
                            {(!isEmpty(totalizador) ? (dadosVenda.reduce((acc, row) => acc + row.markup, 0) / dadosVenda.reduce((acc, row) => acc + row.qtdeprodutos, 0)) : 0)
                                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%'}
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '91px' }}>                  
                            {(!isEmpty(totalizador) ? (dadosVenda.reduce((acc, row) => acc + row.markupliquido, 0) / dadosVenda.reduce((acc, row) => acc + row.qtdeprodutos, 0)) : 0)
                                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%' }
                        </td>
                        <td className="text-center"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '88px' }}>
                            {'R$\n' + (!isEmpty(totalizador) ?  totalizador.lucro : 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>                  
            )        
        }

        return (
            <div className='table-align'>
                <table className="table">
                <thead>
                    <th scope="col" style={{width: '325px'}}></th>
                    <th scope="col" className="text-right"  style={{width: '75px' }}></th>
                    <th scope="col" className="text-right"  style={{width: '75px' }}></th>
                    <th scope="col" className="text-right"  style={{width: '70px' }}></th>
                    <th scope="col" className="text-right"  style={{width: '115px'}}></th>
                    <th scope="col" className="text-right"  style={{width: '115px' }}></th>
                    <th scope="col" className="text-center" style={{width: '95px' }}></th>
                    <th scope="col" className="text-center" style={{width: '80px' }}></th>
                    <th scope="col" className="text-center" style={{width: '100px' }}></th>
                    <th scope="col" className="text-center" style={{width: '100px' }}></th>                
                </thead>
                <tbody>
                    <tr>
                    <th className="text-left" scope="row" style={{backgroundColor: '#DAEBF2'}}>Total R$</th>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '48px' }}>
                        {(!isEmpty(totalizador) ? totalizador.qtdevendas : 0)}
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '48px' }}>
                        {(!isEmpty(totalizador) ? totalizador.qtdeprodutos : 0)}
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '62px' }}>
                        {'100%'}
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '98px'}}>
                        {'R$\n' + (!isEmpty(totalizador) ? totalizador.valorliquido : 0)
                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '80px' }}>
                        {'R$\n' + (!isEmpty(totalizador) ? totalizador.custo : 0)
                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '95px'}}>
                        {'R$\n' + (!isEmpty(totalizador) ? totalizador.diferencatroca : 0)
                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '80px'}}>
                         {(!isEmpty(totalVendas) ? ((totalVendas.markup / totalVendas.qtdetotal)) : 0)
                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%' }
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '91px' }}>                  
                        {(!isEmpty(totalVendas) ? ((totalVendas.markupliquido / totalVendas.qtdetotal)) : 0)
                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%' }
                    </td>
                    <td className="text-right"
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc', fontSize: '12px', maxWidth: '88px' }}>
                        {'R$\n' + (!isEmpty(totalizador) ?  totalizador.lucro : 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>                  
            )        
       
     }

    function CustomPagination() {     
              const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
        const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
      
        return (    
            <>
            <div>           
                <div className='table-align'>
                {renderTable()}
                </div>            
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            Total de registros: {totalRowCount}
                        </Typography>
                    </div>

                    <div style={{marginLeft: '50px'}}>
                        <Typography variant="subtitle2" color="textSecondary" >
                            Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                        </Typography>
                    </div>
                </div>
                    
                    <div style={{ alignSelf: 'flex-end'}}>
                        <Pagination
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            page={page + 1}
                            count={pageCount}                            
                            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                            onChange={(event, value) => apiRef.current.setPage(value - 1)}
                        />
                    </div>
                </div>            
            </div>         
          </>
          );        
      }    
     
    const definirLojaInicial = async()  => 
    {        
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                             
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
        {                        
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant(); 
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;             
            await setLojasFiltro(firstId);               
        }          
    }

    const filtrosSelecionados = {
      nomeGrupo : nomeGrupo,
      tipoVisao : tipoVisaoSelect,
      lojasSelecionadas : lojasFiltro,
      datasFiltro : dataIntervaloFiltro,            
      itensFiltro : itensFiltro,            
      marcasFiltro : marcasFiltro,            
      categoriasFiltro : categoriasFiltro,            
      departamentosFiltro : departamentosFiltro,            
      colecoesFiltro : colecoesFiltro,            
      tamanhosFiltro : tamanhosFiltro,            
      coresFiltro : coresFiltro,            
      referenciasFiltro : referenciasFiltro,
      vendedoresFiltro : vendedoresFiltro,
      clientesFiltro : clientesFiltro,
      tipoImpressao : tipoImpRel
    }  

    const visualizarImpressao = async  () => {        
        if (tipoVisaoSelect === 'cliente')
        {
           await handleShowTipoImpressao();                                 
        }
        else
        {
            await ImprimirRelatorio();          
        }      
        await setTipoImpRel('');    
    }   

    const getDetailPanelContent = React.useCallback(
        ({ row}) => <DetailPanelContent row={row}/>,
        [],
      );

    const handleExecutar = async() => {                                                                     
        setLoading(true);
        await getVendas();                         
        setLoading(false);
    }; 

   const totalizador = dadosVenda.reduce((total, obj) => {    
        if (tipoVisaoSelect === 'produto')
        {
            total.qtdeprodutos += obj.qtdeprodutos;
            total.valorliquido += obj.valorliquido;        
            total.custo += obj.custo;        
            total.diferencatroca += obj.diferencatroca;             
            total.lucro += obj.lucro;                             
            return total;    
        }        
        else if (tipoVisaoSelect === 'cliente')
        {
            total.qtdevendas += obj.vendas.reduce((total, vendas) => {return total + vendas.qtdevendas}, 0);  
            total.qtdeprodutos += obj.vendas.reduce((total, vendas) => {return total + vendas.qtdeprodutos}, 0);  
            total.valorliquido += obj.vendas.reduce((total, vendas) => {return total + vendas.valorliquido}, 0);  
            total.custo += obj.vendas.reduce((total, vendas) => {return total + vendas.custo}, 0);  
            total.diferencatroca += obj.vendas.reduce((total, vendas) => {return total + vendas.diferencatroca}, 0);  
            total.lucro += obj.vendas.reduce((total, vendas) => {return total + vendas.lucro}, 0);                                       
            return total;
        }        
    }, { qtdeprodutos: 0, qtdevendas: 0 , valorliquido: 0,  custo: 0, diferencatroca: 0, lucro: 0});

    function AddParams(value) {
        if (value.length > 0)
            return `/${value}`

        return '/ '        
    }      

    function Equals(valueX, valueY){
       return valueX === valueY
    }

    const getVendas = async() => {                  
        try 
        {      
            let dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
            let dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);                                           
            let url = `venda/relatorio/${tipoVisaoSelect}/${dataIni}/${dataFin}/${lojasFiltro}`;            

            url += AddParams(itensFiltro);
            url += AddParams(categoriasFiltro); 
            url += AddParams(marcasFiltro); 
            url += AddParams(departamentosFiltro); 
            url += AddParams(colecoesFiltro); 
            url += AddParams(tamanhosFiltro); 
            url += AddParams(coresFiltro); 
            url += AddParams(referenciasFiltro); 

            if (Equals(tipoVisaoSelect, 'cliente'))
            {
                url += AddParams(vendedoresFiltro); 
                url += AddParams(clientesFiltro); 
            }             
           
            setDadosVenda([]);     
            await SetTenant();        
            const response = await api.get(url);                                                                           
            setDadosVenda(response.data);             
        }
        catch (e)
        {
            console.log('erro');
        }        
    }        
    
    const handleShowLojas = () => 
    {            
          setShowLojas(true);
    }   
    
    const handleShowItens = () => 
    {            
          setShowItens(true);
    }      

    const handleShowMarcas = () => 
    {
          setShowMarcas(true);
    }   
    
    const handleShowCategorias = () => 
    {
          setShowCategorias(true);
    }

    const handleShowVendedores = () => 
    {
          setShowVendedores(true);
    }

    const handleShowClientes = () => 
    {
          setShowClientes(true);
    }

    const handleShowDepartamentos = () => 
    {
          setShowDepartamentos(true);
    }

    const handleShowColecoes = () => 
    {
          setShowColecoes(true);
    }

    const handleShowTamanhos = () => 
    {
          setShowTamanhos(true);
    }

    const handleShowCores = () => 
    {
          setShowCores(true);
    }

    const handleShowReferencias = () => 
    {
          setShowReferencias(true);
    }

    const handleShowTipoImpressao = () => 
    {            
         setShowTipoImpRel(true);
    }  

    const handleCloseLojas = () => 
    {        
          setShowLojas(false);                           
    }

    const handleCloseItens = () => 
    {        
          setShowItens(false);                           
    }

    const handleCloseMarcas = () => 
    {
          setShowMarcas(false);
    }

    const handleCloseCategorias = () => 
    {
          setShowCategorias(false);
    }
    
    const handleCloseDepartamentos = () => 
    {
          setShowDepartamentos(false);
    }

    const handleCloseColecoes = () => 
    {
          setShowColecoes(false);
    }

    const handleCloseTamanhos = () => 
    {
          setShowTamanhos(false);
    }

    const handleCloseCores = () => 
    {
          setShowCores(false);
    }

    const handleCloseReferencias = () => 
    {
          setShowReferencias(false);
    }      

    const handleCloseVendedores = () => 
    {
          setShowVendedores(false);
    }      

    const handleCloseClientes = () => 
    {
          setShowClientes(false);
    }      
    
    const handleCloseTipoImpressao = () => 
    {            
        setShowTipoImpRel(false);
    }  

    const definirItensSelecionados = (id) => 
    {
        setItensFiltro(id);        
    }

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }  
    
    const definirMarcasSelecionadas = (id) =>
    {
        setMarcasFiltro(id);
    }    

    const definirCategoriasSelecionadas = (id) =>
    {
        setCategoriasFiltro(id);
    }    

    const definirDepartamentosSelecionados = (id) =>
    {
        setDepartamentosFiltro(id);
    }    
    
    const definirColecoesSelecionadas = (id) =>
    {
        setColecoesFiltro(id);
    }    

    const definirTamanhosSelecionados = (id) =>
    {
        setTamanhosFiltro(id);
    }   
    
    const definirCoresSelecionadas = (id) =>
    {
        setCoresFiltro(id);
    }   

    const definirReferenciasSelecionadas = (id) =>
    {
        setReferenciasFiltro(id);
    }      

    const definirVendedoresSelecionados = (id) =>
    {
        setVendedoresFiltro(id);
    }      

    const definirClientesSelecionados = (id) =>
    {
        setClientesFiltro(id);
    }     

    const definirTipoImpressaoRelatorio= (bool) =>
    {
        setTipoImpRel(bool);
    }  
    
    const totalVendas = dadosVenda.reduce((total, obj) => {             
        if (tipoVisaoSelect === 'cliente')
        {
            total.qtdetotal += obj.vendas.reduce((total, vendas) => {return total + vendas.qtdeprodutos;}, 0);                
            total.markup += obj.vendas.reduce((total, vendas) => {return total + vendas.markup * vendas.qtdeprodutos}, 0);                
            total.markupliquido += obj.vendas.reduce((total, vendas) => {return total + vendas.markupliquido * vendas.qtdeprodutos}, 0);                
            return total;           
        }            
     }, { qtdetotal: 0, markup : 0, markupliquido: 0});
               
    const rows = dadosVenda.map((array) => {     
        if (tipoVisaoSelect === 'cliente')
        {            
            if (array.vendas && array.vendas.data !== null) {   
                const venda = array.vendas[0];                                          
                const vendaMovimentos = array.vendaMovimentos.map((vendamov) => {                
                  return {          
                    id : vendamov.id,
                    lojaid : vendamov.lojaid,
                    produtoid : vendamov.produtoid,
                    produtodescricao : vendamov.produtodescricao,        
                    produtotamanho : vendamov.produtotamanho,        
                    produtocor : vendamov.produtocor,        
                    vendaid: vendamov.vendaid,                                                  
                    datavenda: new Date(vendamov.datavenda),   
                    qtde : vendamov.qtde,                                      
                    valorliquido: vendamov.valorliquido,          
                    valorunitario: vendamov.valorunitario,          
                    vendedornome: vendamov.vendedornome,                    
                  };
                });
                   
                venda.vendaMovimentos = vendaMovimentos;

                const pagarInfo = {
                  id: venda.clienteid,
                  clienteid : venda.clienteid,
                  clientenome : venda.clientenome,                                    
                  qtdeprodutos : venda.qtdeprodutos,                  
                  qtdepercentual : (venda.qtdeprodutos / totalVendas.qtdetotal) * 100,                  
                  qtdevendas : venda.qtdevendas,   
                  valorliquido: venda.valorliquido,
                  custo: venda.custo,                  
                  lucro: venda.lucro,                  
                  diferencatroca: venda.diferencatroca,
                  markup: venda.markup,
                  markupliquido : venda.markupliquido,
                  vendaMovimentos : venda.vendaMovimentos
                };
            
                return pagarInfo;
            }    
        }       
                             
        const vendaClienteInfo = {
            id : array.produtoid,            
            produtodescricao : array.produtodescricao,
            produtotamanho: array.produtotamanho,           
            produtocor: (array.produtocor),
            qtdeprodutos: array.qtdeprodutos,                          
            qtdepercentual: (array.qtdeprodutos / dadosVenda.reduce((acc, row) => acc + row.qtdeprodutos, 0)) * 100,
            valorliquido: array.valorliquido,
            custo: array.custo,
            diferencatroca: array.diferencatroca,
            lucro: array.lucro,
            markup: array.markup / array.qtdeprodutos,
            markupliquido: array.markupliquido / array.qtdeprodutos
            }   
        return vendaClienteInfo;
        }                    
    );

    const columnsProduto = [                              
        { field: "id", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Cód<br/><p></p></div>),
          headerClassName: 'blue-header', headerAlign: 'left', width: 80, align: 'left'},    
        { field: "produtodescricao", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Produto<br/><p></p></div>),
          headerClassName: 'blue-header', headerAlign: 'left', width: 225, align: 'left'},  
        { field: "produtotamanho", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Tamanho<br/><p></p></div>),
          headerClassName: 'blue-header', headerAlign: 'left', width: 70, align: 'left'},           
        { field: "produtocor", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Cor<br/><p></p></div>),
          headerClassName: 'blue-header', headerAlign: 'left', width: 115, align: 'left'},     
        { field: "qtdeprodutos", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Qtde<br/><p></p></div>), 
          headerClassName: 'blue-header', headerAlign: 'right', width: 33, align: 'right'},
        { field: "qtdepercentual", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Qtde<br/> %</div>), 
          headerClassName: 'blue-header', headerAlign: 'right', width: 66, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              })+'%';
            }
        },        
        { field: "valorliquido", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Valor Líquido<br /> R$</div>),
          headerClassName: 'blue-header', headerAlign: 'right', width: 99, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },       
        { field: "custo", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Custo<br /> R$</div>),
          headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },        
        { field: "diferencatroca", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Dif. Troca<br /> R$</div>),
          headerClassName: 'blue-header', headerAlign: 'right', width: 85, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },       
        { field: "markup", 
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
            Markup<br /> %</div>),  
            headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }
        },           
        { field: "markupliquido", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Markup <br />Real %</div>),  
          headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },              
        { field: "lucro", 
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
            Lucro<br /> R$</div>),  headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
            valueFormatter: (params) => {
            return parseFloat(params.value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            }
        }
    ];     

    const columnsCliente = [            
        {
            headerClassName: 'blue-header',
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
              <CustomDetailPanelToggle id={params.id} value={params.value} />
            ),
          },     
        { field: "clienteid",  headerName: "Cód",  headerClassName: 'blue-header', headerAlign: 'left', width: 60, align: 'left'},          
        { field: "clientenome",  headerName: "Cliente",  headerClassName: 'blue-header', headerAlign: 'left', width: 215, align: 'left'},          
        { field: "qtdevendas",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
            Qtde <br /> Vendas</div>), headerClassName: 'blue-header', width: 75, headerAlign: 'right', align: 'right'},
        { field: "qtdeprodutos",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
            Qtde <br /> Produtos</div>), headerClassName: 'blue-header', width: 75, headerAlign: 'right', align: 'right'},                      
        { field: "qtdepercentual", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Qtde<br /> %</div>),  headerClassName: 'blue-header', headerAlign: 'right', width: 70, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },        
        { field: "valorliquido", 
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
            Valor Líquido<br /> R$</div>),  headerClassName: 'blue-header', headerAlign: 'right', width: 115, align: 'right', 
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },       
        { field: "custo", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Custo<br /> R$</div>),  headerClassName: 'blue-header', headerAlign: 'right', width: 115, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },        
        { field: "diferencatroca",
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Dif. Troca<br /> R$</div>),  headerClassName: 'blue-header', headerAlign: 'right', width: 95, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },       
        { field: "markup",
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Markup<br /> %</div>),  
          headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },              
        { field: "markupliquido", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Markup <br />Real %</div>), 
          headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
          valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
            }
        },               
        { field: "lucro", 
          renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
          Lucro<br /> R$</div>),  headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
          valueFormatter: (params) => {
            return parseFloat(params.value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
          }
        }
    ];        

    const contextvalues = {        
        definirLojasSelecionadas,        
        definirTamanhosSelecionados,
        definirItensSelecionados,        
        definirMarcasSelecionadas,
        definirCategoriasSelecionadas,
        definirDepartamentosSelecionados,
        definirColecoesSelecionadas,
        definirTamanhosSelecionados,
        definirCoresSelecionadas,
        definirReferenciasSelecionadas,
        definirVendedoresSelecionados,
        definirClientesSelecionados,
        definirTipoImpressaoRelatorio 
    }         
    
    if (loading) {
        return (
            <LoadingScreen/>
        );
      }      
      
      function CustomToolbar() {
        return (
          <CustomToolBar print={visualizarImpressao}/>         
        );
      }          

    return (
        <>       
        <VendaProdutoContext.Provider value={contextvalues}>             
            <form className="form">                
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Vendas por Produto</label>
            </div>                                                
            <Card.Body>                   
                <Container>
                  <Card>
                  <div className='align-left'>
                      <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>

                    
                        <Card style={{width: 388}}>
                            <div className="row">                     
                              <div className="col-md-6">
                                <div className="form-check">                            
                                    <Radio
                                      checked={tipoVisaoSelect === 'produto'}
                                      onChange={() => {
                                        SetTipoVisaoSelect('produto'); 
                                        setDadosVenda([]);                                                                   
                                      } }
                                      value={tipoVisaoSelect}
                                      name="radio-button-demo"
                                      inputProps={{ 'aria-label': 'A' }}
                                      size="small"
                                      id="produtoRadio" 
                                    />
                                    <label class="form-check-label" for="produtoRadio" style={{cursor: 'pointer'}}>
                                        Por Produto
                                    </label>                          
                                </div>                      
                              </div>                      
      
                              <div className="col">                                  
                                  <Radio
                                    checked={tipoVisaoSelect === 'cliente'}
                                    onChange={() => {
                                      SetTipoVisaoSelect('cliente'); 
                                      setDadosVenda([]);                                                                   
                                    } }
                                    value={tipoVisaoSelect}
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'B' }}
                                    size="small"
                                    id="clienteRadio"
                                  />
                                    <label class="form-check-label" for="clienteRadio" style={{cursor: 'pointer'}}>
                                        Por Cliente
                                    </label>                                                  
                              </div>                                      
                            </div>                    
                          </Card>  
                                       
                                    
                  </div>
                </Card>
              </Container>                                         
                    <Container>
                        <Card>                                                                                                                                                                                                            
                        <div className="filtros">
                            <div className="align-left">
                                <div className="row" style={{ width: '408px' }}>
                                    <div className="col-md">
                                        <LocalizationProvider  
                                            dateAdapter={AdapterDateFns} 
                                            adapterLocale={ptBR}                                                               
                                        >                                                                                               
                                            <DateRangePicker
                                                localeText={{ start: 'Data Inicial', end: 'Data Final' }}
                                                value={dataIntervaloFiltro}
                                                onChange={(newValue) => setDataIntervaloFiltro(newValue)}
                                                renderInput={(startProps, endProps) => (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <input {...startProps.inputProps} />
                                                    <span> to </span>
                                                    <input {...endProps.inputProps} />
                                                </Box>
                                                )}
                                            />                                          
                                        </LocalizationProvider>                                
                                    </div>                                                               
                                </div>

                                <div className="row align-items-start" style={{marginTop: '30px'}}>
                                    <div className="col-lg">
                                        <ButtonLojas
                                            onClick={handleShowLojas}
                                            width={'185px'}                                  
                                            lojasFiltro={lojasFiltro}
                                        />
                                    </div>                                
                                    <div className="col-lg">
                                        <ButtonItens
                                            onClick={handleShowItens}
                                            width={'185px'}
                                            itensFiltro={itensFiltro}
                                        />
                                    </div>                               
                                    <div className="col-lg">
                                        <ButtonMarcas 
                                            onClick={handleShowMarcas}
                                            width={'185px'}
                                            marcasFiltro={marcasFiltro}
                                        />                                         
                                    </div>    
                                    <div className="col-lg">
                                        <ButtonReferencias
                                            onClick={handleShowReferencias}
                                            width={'185px'}                                  
                                            referenciasFiltro={referenciasFiltro}                                                
                                        />
                                    </div>                                      
                                    <div className="col-lg">
                                        <ButtonDepartamentos 
                                            onClick={handleShowDepartamentos}
                                            width={'185px'}
                                            departamentosFiltro={departamentosFiltro}
                                        />                                         
                                    </div>                                                                   
                                </div>                               
                            </div>

                            <div className="align-left">                
                                <div className="row align-items-start" style={{marginTop: '20px'}}>
                                    <div className="col">
                                        <ButtonColecoes
                                            onClick={handleShowColecoes}
                                            width={'185px'}                                  
                                            colecoesFiltro={colecoesFiltro}
                                        />
                                    </div>   
                                    <div className="col-lg">
                                        <ButtonTamanhos
                                            onClick={handleShowTamanhos}
                                            width={'185px'}                                  
                                            tamanhosFiltro={tamanhosFiltro}                                                
                                        />
                                    </div>   
                                    <div className="col-lg">
                                        <ButtonCores
                                            onClick={handleShowCores}
                                            width={'185px'}                                  
                                            coresFiltro={coresFiltro}                                                
                                        />
                                    </div>   
                                    <div className="col-lg">
                                        <ButtonCategorias 
                                            onClick={handleShowCategorias}
                                            width={'185px'}
                                            categoriasFiltro={categoriasFiltro}
                                        />                                         
                                    </div> 
                                    { tipoVisaoSelect === 'cliente' && (
                                            <div className="col-lg">
                                            <ButtonVendedores
                                                onClick={handleShowVendedores}
                                                width={'185px'}
                                                vendedoresFiltro={vendedoresFiltro}
                                            />                                         
                                        </div> 
                                    ) }                                                                            
                                </div> 

                                { tipoVisaoSelect === 'cliente' && (
                                <div className="col-lg" style={{marginTop: '50px'}}>
                                    <ButtonClientes
                                        onClick={handleShowClientes}
                                        width={'185px'}
                                        clientesFiltro={clientesFiltro}
                                    />                                         
                                </div> 
                                 ) }   
                            </div>                                               
                        </div>        

                        <div class="row" style={{marginTop: '30px'}}>
                            <div className='col-2'>
                                <div className="actions">
                                <ButtonExecute 
                                    width={'185px'}
                                    onClick={handleExecutar}
                                />   
                                </div>                    
                            </div>
                            <div className='col-8' style={{marginLeft: '15px'}}>
                                <ButtonClear                                     
                                    onClick={handleLimparFiltros}
                                />   
                            </div>
                        </div>  
                        </Card>
                    </Container>                 
                </Card.Body>                     
            </form>     
                
            <ModalLojas show={showLojas} value={VendaProdutoContext} onHide={handleCloseLojas}/>         
            <ModalItem show={showItens} value={VendaProdutoContext} onHide={handleCloseItens}/>         
            <ModalMarcas show={showMarcas} value={VendaProdutoContext} onHide={handleCloseMarcas}/>         
            <ModalCategorias show={showCategorias} value={VendaProdutoContext} onHide={handleCloseCategorias}/>         
            <ModalDepartamentos show={showDepartamentos} value={VendaProdutoContext} onHide={handleCloseDepartamentos}/>
            <ModalColecoes show={showColecoes} value={VendaProdutoContext} onHide={handleCloseColecoes}/>                       
            <ModalTamanhos show={showTamanhos} value={VendaProdutoContext} onHide={handleCloseTamanhos}/>
            <ModalCores show={showCores} value={VendaProdutoContext} onHide={handleCloseCores}/>
            <ModalReferencias show={showReferencias} value={VendaProdutoContext} onHide={handleCloseReferencias}/>            
            <ModalVendedores show={showVendedores} value={VendaProdutoContext} onHide={handleCloseVendedores}/>                                                  
            <ModalClientes show={showClientes} value={VendaProdutoContext} onHide={handleCloseClientes}/>
            <ModalImpressaoResumidoDetalhado show={showTipoImpRel} value={VendaProdutoContext} onHide={handleCloseTipoImpressao}/>  

             {dadosVenda && dadosVenda.length > 0 && ( 
              <Container>                      
               <CustomStyledDataGrid                  
                columns={tipoVisaoSelect === 'produto' 
                 ? columnsProduto
                 : columnsCliente}                        
                rows={rows}                
                slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination                          
                }}       
                headerHeight={20}        
                getDetailPanelHeight={() => 'auto'} 
                getDetailPanelContent={tipoVisaoSelect === 'cliente' ? getDetailPanelContent : null}
                headerClassName={headerClassName}                        
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[PAGE_SIZE]}                                                                                 
                />                                                                                                                       
              </Container>   
            )}                                                            
        </VendaProdutoContext.Provider>
        </>
    )
  }

export default VendaProdutoProvider;
