import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.css';
import { SetTenant } from '../../services/api';
import { api } from '../../services/api';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import { Card } from 'react-bootstrap';

function ModalDetalheProvisao(props) {
  const handleClose = () => props.onHide();
  const [dadosDetalheRateio, setDadosDetalheRateio] = useState([]);
  const [dadosDetalhePagar, setDadosDetalhePagar] = useState([]);

  useEffect(() => {
        setTimeout(() => {     
            if (props.pagarid !== 0)  
            {
              setDadosDetalheRateio([]); 
              GetDetalheRateio();                          
            }               
              
        }, 500);                      
    }, [props.pagarid]); 

    const GetDetalheRateio = async() => {                  
        try 
        {                    
            let url = `pagar/detalhe/${props.pagarid}`;                                             
            await SetTenant();    
            const response = await api.get(url);                                                                           
            await setDadosDetalheRateio(response.data.despesaProvisionamentos);                           
            await setDadosDetalhePagar(response.data);                           
        }
        catch (e)
        {
            console.log('erro');
        }        
    }              

  const columns= [                   
    { field: "competencia",  type: 'date', valueGetter: (params) => new Date(params.row.competencia),
      headerName: "Data",  headerClassName: 'header-detail', 
      headerAlign: 'left', width: 480, align: 'left', cellClassName: () => { return 'value-cell-black' } },  
    { field: "valor", headerName:"Valor R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 150, align: 'right', 
      valueFormatter: (params) => {
        return "R$ " + (params.value > 0 ? parseFloat(params.value) : 0).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) ;
      }, cellClassName: () => { return 'value-cell-black' } 
    },
    { field: "porcentagem", headerName:"%",  headerClassName: 'header-detail',  headerAlign: 'center', width: 110, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) +  "%" ;
      } , cellClassName: () => { return 'value-cell-black' }
    }
  ];   

  const rows = dadosDetalheRateio.map((despesa) => ({        
    id: despesa.id,
    competencia : despesa.competencia,    
    valor: despesa.valor,
    porcentagem: despesa.porcentagem
  }));

  return (    
    <>
    <Modal onHide={handleClose}
      {...props} 
      size="lg"
      centered
      aria-labelledby="contained-modal-title-xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> 
        <div className='row'>
          <div  style={{ fontFamily: 'Helvetica', marginLeft: '20px', textAlign: 'center'  }}>Detalhe do Provisionamento Contas a Pagar</div>          
          <div className='row'>                            
             <div  style={{ fontFamily: 'Helvetica', marginLeft: '20px', fontSize: '20px', textAlign: 'center' }}>Loja Emissão: {dadosDetalhePagar.lojaid}</div>                   
             <div style={{ fontFamily: 'Helvetica', fontSize: '20px', textAlign: 'center' }}>Valor Parcela R$: {!isEmpty(dadosDetalhePagar) ? dadosDetalhePagar.valorparcela.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              }) : ""}
              </div>          
          </div>
          </div>                   
        </Modal.Title>
      </Modal.Header>
        <Modal.Body className="show-grid">
            <Container >            
                 <DataGridPro   
                    style={{ fontFamily:  [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),                         
                    }}                   
                    columns={columns}               
                    rows={rows}                                 
                    rowHeight={30}                                         
                    getDetailPanelHeight={() => 'auto'}                                            
                    hideFooter                                      
                    getRowClassName={() => 'even'}                 
                    columnHeaderHeight={25}                                                                            
                  />              
            </Container>
        </Modal.Body>
      <Modal.Footer>        
      </Modal.Footer>
    </Modal>    
    </>
  );
}

export default ModalDetalheProvisao;