import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonLojasPagamento({ onClick, lojasPagamentoFiltro, disabled }) {
  return (
    
    <Button disabled={disabled}
      style={{
            backgroundColor: '#007CB0', width: '220px', height: '30px', 
            marginLeft: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', 
            border: '0px',
            borderRadius: '15px' 
      }}            
       onClick={onClick} >
       + Loja(s) Pagamento
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {lojasPagamentoFiltro.length > 0 ? lojasPagamentoFiltro.length : ''}
            </span>
    </Button>    
  );
}

export default ButtonLojasPagamento;


