import  React , { createContext, useState, useEffect, useRef } from 'react';
import {        
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
} from '@mui/x-data-grid';
import PaginationItem from '@mui/material/PaginationItem';
import "react-datepicker/dist/react-datepicker.css";
import { Container, Pagination, Radio, Typography, setRef } from '@mui/material';
import { api } from '../../services/api';
import { Card, Button } from 'react-bootstrap';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalItem from '../../components/Modal/ModalItens';
import ModalMarcas from '../../components/Modal/ModalMarcas';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import ButtonItens from '../../components/Buttons/ButtonItens';
import LoadingScreen from '../../components/Loading/TelaLoading';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import '../../pages/Estoque/styles.css';
import { isEmpty } from 'lodash';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { ImpressaoEstoquePDF } from '../../components/Impressao/ImpressaoEstoquePDF';
import ButtonMarcas from '../../components/Buttons/ButtonMarcas';
import clsx from 'clsx';
import ButtonCategorias from '../../components/Buttons/ButtonCategorias';
import ModalCategorias from '../../components/Modal/ModalCategorias';
import ButtonDepartamentos from '../../components/Buttons/ButtonDepartamentos';
import ModalDepartamentos from '../../components/Modal/ModalDepartamentos';
import ButtonColecoes from '../../components/Buttons/ButtonColecoes';
import ModalColecoes from '../../components/Modal/ModalColecoes';
import ButtonTamanhos from '../../components/Buttons/ButtonTamanhos';
import ModalTamanhos from '../../components/Modal/ModalTamanhos';
import ButtonCores from '../../components/Buttons/ButtonCores';
import ModalCores from '../../components/Modal/ModalCores';
import ButtonReferencias from '../../components/Buttons/ButtonReferencias';
import ModalReferencias from '../../components/Modal/ModalReferencias';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { ExportarXLS } from '../../components/Impressao/Excel/ExportarXLS';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const EstoqueContext = createContext();

  export const EstoqueProvider = () => {   
    const sessionStorageUtils = new SessionStorageUtils();  
    const PAGE_SIZE = 50;
    const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0});  
    const [showLojas, setShowLojas] = useState(false);   
    const [showItens, setShowItens] = useState(false);
    const [showMarcas, setShowMarcas] = useState(false);
    const [showCategorias, setShowCategorias] = useState(false);
    const [showDepartamentos, setShowDepartamentos] = useState(false);
    const [showColecoes, setShowColecoes] = useState(false);
    const [showTamanhos, setShowTamanhos] = useState(false);
    const [showCores, setShowCores] = useState(false);
    const [showReferencias, setShowReferencias] = useState(false);
    const [dadosEstoque, setDadosEstoque] = useState([]);      
    const [tipoVisaoSelect, setTipoVisaoSelect] = useState('item');
    const [listarEstoque, setListarEstoque] = useState('comestoque')
    const [lojasFiltro, setLojasFiltro] = useState([]); 
    const [itensFiltro, setItensFiltro] = useState([]);
    const [marcasFiltro, setMarcasFiltro] = useState([]);   
    const [categoriasFiltro, setCategoriasFiltro] = useState([]);   
    const [departamentosFiltro, setDepartamentosFiltro] = useState([]);   
    const [colecoesFiltro, setColecoesFiltro] = useState([]);  
    const [tamanhosFiltro, setTamanhosFiltro] = useState([]);   
    const [coresFiltro, setCoresFiltro] = useState([]);   
    const [referenciasFiltro, setReferenciasFiltro] = useState([]);   
    const [nomeGrupo, setNomeGrupo] = useState([]);
    const [saldoNegativoNosTotalizadores, SetSaldoNegativoNosTotalizadores] = useState(false);
    const [loading, setLoading] = useState(true);  
    const [limparFiltros, SetLimparFiltros] = useState(false); 
    const navigate = useNavigate();
    const permissao = new Permissoes();       
 
    useEffect(() => {
        setTimeout(() => {     
            setLoading(true); 
            if (ValidarPermissoes() == false) { return; }      
            LimparFiltrosSelecionados();
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []);   
    
    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('1506f1e306f68021acc3d4343c745712');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  

    useEffect(() => {
        TestarProdutoComSaldoNegativo();
    }, [dadosEstoque]);

    useEffect(() => {        
        if (limparFiltros)
        {
            definirLojaInicial();            
            SetLimparFiltros(false);
        }        
      }, [limparFiltros]);
      
      const handleLimparFiltros = async () => {    
        LimparFiltrosSelecionados()                
        SetLimparFiltros(true);
      }
      
      function LimparFiltrosSelecionados() {
        sessionStorage.removeItem('selectedLojas');
        sessionStorage.removeItem('selectedMarcas');
        sessionStorage.removeItem('selectedItens'); 
        sessionStorage.removeItem('selectedCategorias');   
        sessionStorage.removeItem('selectedDepartamentos');  
        sessionStorage.removeItem('selectedColecoes');   
        sessionStorage.removeItem('selectedTamanhos');
        sessionStorage.removeItem('selectedCores');
        sessionStorage.removeItem('selectedReferencias');
        setLojasFiltro([]);
        setMarcasFiltro([]);
        setItensFiltro([]);
        setCategoriasFiltro([]);
        setDepartamentosFiltro([]);
        setColecoesFiltro([]);
        setTamanhosFiltro([]);
        setCoresFiltro([]);
        setReferenciasFiltro([]);       
        setDadosEstoque([]);
      }      
 
    const DefinirNomeGrupo = async () => {
        const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
        const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
        setNomeGrupo(groupName);
    }

    function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
    const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
    
    return (
        <>
        <div>           
            <div className='table-align'>
                {renderTable()}
            </div>                        
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <Typography variant="subtitle2" color="textSecondary">
                        Total de registros: {totalRowCount}
                    </Typography>
                </div>

                <div style={{marginLeft: '50px'}}>
                    <Typography variant="subtitle2" color="textSecondary" >
                        Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                    </Typography>
                </div>
            </div>
                
                <div style={{ alignSelf: 'flex-end'}}>
                    <Pagination
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        page={page + 1}
                        count={pageCount}                                                    
                        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}
                    />
                </div>
            </div>            
        </div>
        
        </>
        );        
    }  

    const definirLojaInicial = async()  => 
    {        
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                             
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
        {                        
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant();  
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;             
            await setLojasFiltro(firstId);               
        }          
    }

    const totalizador = dadosEstoque.reduce((total, obj) => {       
        total.saldo += obj.saldo;
        total.custoproduto += obj.custoproduto;        
        total.custoreal += obj.custoreal;
        total.valorvenda += obj.valorvenda;
        return total;             
    }, { saldo: 0, custoproduto: 0, custoreal: 0, valorvenda: 0 });
  
    const filtrosSelecionados = {
        tipoVisao : tipoVisaoSelect,
        listarEstoque : listarEstoque,
        lojasSelecionadas : lojasFiltro,
        itensSelecionados : itensFiltro,
        marcasSelecionadas : marcasFiltro,
        categoriasSelecionadas : categoriasFiltro,
        departamentosSelecionados : departamentosFiltro,
        colecoesSelecionadas : colecoesFiltro,
        tamanhosSelecionados : tamanhosFiltro,
        coresSelecionadas : coresFiltro,
        referenciasSelecionadas : referenciasFiltro,
        saldoNegativoNosTotalizadores : saldoNegativoNosTotalizadores,
        nomeGrupo : nomeGrupo
    }  

    const visualizarImpressao = async  () => {        
        const classeImpressao = new ImpressaoEstoquePDF(dadosEstoque, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }  

    const handleExecutar = async() => {                                                                     
        setLoading(true);                                               
        await SetSaldoNegativoNosTotalizadores(false);
        await getEstoque();    
        await TestarProdutoComSaldoNegativo();
        setLoading(false);              
    } 

    const TestarProdutoComSaldoNegativo = async() => 
    {
        if (dadosEstoque.some(item => item.saldo < 0))
        {
            SetSaldoNegativoNosTotalizadores(true);
        }
    }

    const tipoVisaoFiltroToInt = () => {
        const enumValues = {
          'item': 1,
          'marca' : 2,
          'produto' : 3,
        };
      
        return enumValues;
      };    

    const tipoVisaoToInt = tipoVisaoFiltroToInt();

    const ListarEstoqueFiltroToInt = () => {
        const enumValues = {
          'comestoque': 1,
          'todos' : 2          
        };
      
        return enumValues;
      };    

    const filtroEstoqueToInt = ListarEstoqueFiltroToInt();
    
    const getEstoque = async() => {                  
        try 
        {                                                
            let url = `estoque/saldo/${tipoVisaoToInt[tipoVisaoSelect]}/${filtroEstoqueToInt[listarEstoque]}/${lojasFiltro}`;          
            
            if (itensFiltro.length > 0) {                
                url += `/${itensFiltro}`;
            } else {
                url += "/ ";
            }

            if (marcasFiltro.length > 0) {                
                url += `/${marcasFiltro}`;
            } else {
                url += "/ ";
            }         
            
            if (categoriasFiltro.length > 0) {                
                url += `/${categoriasFiltro}`;
            } else {
                url += "/ ";
            }      
            
            if (departamentosFiltro.length > 0) {                
                url += `/${departamentosFiltro}`;
            } else {
                url += "/ ";
            }       

            if (colecoesFiltro.length > 0) {                
                url += `/${colecoesFiltro}`;
            } else {
                url += "/ ";
            }    
            
            if (tamanhosFiltro.length > 0) {                
                url += `/${tamanhosFiltro}`;
            } else {
                url += "/ ";
            }    

            if (coresFiltro.length > 0) {                
                url += `/${coresFiltro}`;
            } else {
                url += "/ ";
            }    

            if (referenciasFiltro.length > 0) {                
                url += `/${referenciasFiltro}`;
            } else {
                url += "/ ";
            }    

            await setDadosEstoque([]);            
            await SetTenant(); 
            const response = await api.get(url);                                                                                                  
            await setDadosEstoque(response.data);              
        }
        catch (e)
        {
            console.log('erro');
        }        
    }                  

   const handleShowLojas = () => 
   {            
        setShowLojas(true);
   }   

   const handleShowMarcas = () => 
   {
        setShowMarcas(true);
   }   

   const handleShowCategorias = () => 
   {
        setShowCategorias(true);
   }

   const handleShowDepartamentos = () => 
   {
        setShowDepartamentos(true);
   }

   const handleShowColecoes = () => 
   {
        setShowColecoes(true);
   }

   const handleShowTamanhos = () => 
   {
        setShowTamanhos(true);
   }
   
   const handleShowCores = () => 
   {
        setShowCores(true);
   }

   const handleShowReferencias = () => 
   {
        setShowReferencias(true);
   }
    
   const handleCloseLojas = () => 
   {        
        setShowLojas(false);                           
   }

   const handleCloseItens = () => 
   {        
        setShowItens(false);                           
   }

   const handleCloseMarcas = () => 
   {
        setShowMarcas(false);
   }

   const handleCloseCategorias = () => 
   {
        setShowCategorias(false);
   }
   
   const handleCloseDepartamentos = () => 
   {
        setShowDepartamentos(false);
   }

   const handleCloseColecoes = () => 
   {
        setShowColecoes(false);
   }

   const handleCloseTamanhos = () => 
   {
        setShowTamanhos(false);
   }

   const handleCloseCores = () => 
   {
        setShowCores(false);
   }

   const handleCloseReferencias = () => 
   {
        setShowReferencias(false);
   }

   const handleShowItens = () => 
   {            
        setShowItens(true);
   }      

    const definirItensSelecionados = (id) => 
    {
        setItensFiltro(id);        
    }

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }  
    
    const definirMarcasSelecionadas = (id) =>
    {
        setMarcasFiltro(id);
    }    

    const definirCategoriasSelecionadas = (id) =>
    {
        setCategoriasFiltro(id);
    }    

    const definirDepartamentosSelecionados = (id) =>
    {
        setDepartamentosFiltro(id);
    }    
    
    const definirColecoesSelecionadas = (id) =>
    {
        setColecoesFiltro(id);
    }    

    const definirTamanhosSelecionados = (id) =>
    {
        setTamanhosFiltro(id);
    }   
    
    const definirCoresSelecionadas = (id) =>
    {
        setCoresFiltro(id);
    }   

    const definirReferenciasSelecionadas = (id) =>
    {
        setReferenciasFiltro(id);
    }   
    
    const rows = dadosEstoque.map((row) => {       
        if (tipoVisaoSelect === 'item' || tipoVisaoSelect === 'marca')      
        {
            const produtoInfo = {
            id : row.id + row.lojaid + row.descricao + row.saldo + row.custoproduto + row.custoreal + row.valorvenda,
            codigo : row.id,
            lojaid : row.lojaid,
            item : row.descricao,
            estoque : row.saldo,       
            custoproduto: row.custoproduto,
            custoreal : row.custoreal,            
            valorvenda : row.valorvenda            
            }
            return produtoInfo;
    }});    

    const rowsProdutos = dadosEstoque.map((row) => {   
        if (tipoVisaoSelect === 'produto')      
        {
            const produtoInfo = {
                id : row.id + row.lojaid + row.descricao + row.saldo + row.custoproduto + row.custoreal + row.valorvenda,
                codigo : row.id,
                lojaid : row.lojaid,
                item : row.descricao,
                tamanho : row.tamanho,
                cor : row.cor,
                estoque : row.saldo,       
                custoproduto: row.custoproduto,
                custoreal : row.custoreal,            
                valorvenda : row.valorvenda            
            }                      
            return produtoInfo;
        }               
    });    

    const columns = [            
        { field: "lojaid", headerName: "Loja",  headerClassName: 'blue-header', headerAlign: 'right', width: 50, align: 'left'},           
        { field: "codigo", headerName: "Cód", headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right',
            cellClassName: (params) => {
                if (params.value == null) {
                return '';
                }

                return clsx('super-app', {
                negative: params.row.estoque < 0,
                positive: params.row.estoque > 0,
                });
            },
        },
        { field: "item", headerName: 
          tipoVisaoSelect === 'item' 
          ? "Item" 
          : tipoVisaoSelect === 'marca' 
          ? "Marca" 
          : "Produto", 
          headerClassName: 'blue-header', width: 350,
            cellClassName: (params) => {
                if (params.value == null) {
                return '';
                }
        
                return clsx('super-app', {
                negative: params.row.estoque < 0,
                positive: params.row.estoque > 0,
                });
            },
        },                        
        { field: "estoque", headerName: "Estoque",  headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
            type: 'number', 
             cellClassName: (params) => {
                if (params.value == null) { return '' }                                     

                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },
        },           
        { field: "custoproduto", headerName:"Custo Produto R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 190, align: 'right',
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, 
            cellClassName: (params) => {
                if (params.value == null) {
                  return '';
                }
          
                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },
        },        
        { field: "custoreal", headerName:"Custo Real R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 190, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, 
            cellClassName: (params) => {
                if (params.value == null) {
                  return '';
                }
          
                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },
        },        
        { field: "valorvenda", headerName:"Valor Venda R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 190, align: 'right',
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            cellClassName: (params) => {
                if (params.value == null) {
                  return '';
                }
          
                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },                   
        }        
    ];    
    
    const columnsProdutos = [            
        { field: "lojaid", headerName: "Loja",  headerClassName: 'blue-header', headerAlign: 'right', width: 30, align: 'left'},           
        { field: "codigo", headerName: "Cód", headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right',
            cellClassName: (params) => {
                if (params.value == null) { return '' }
                return clsx('super-app', {
                    negative: params.row.estoque < 0,
                    positive: params.row.estoque > 0,
                });
            },
        },
        { field: "item", headerName: 
            tipoVisaoSelect === 'item' 
            ? "Item" 
            : tipoVisaoSelect === 'marca' 
            ? "Marca" 
            : "Produto", 
            headerClassName: 'blue-header', width: 330,
            cellClassName: (params) => {
            if (params.value == null) {
                return '';
            }
    
            return clsx('super-app', {
                negative: params.row.estoque < 0,
                positive: params.row.estoque > 0,
            });
        },
        },                        
        { field: "tamanho", headerName: "Tamanho",  headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right',
            cellClassName: (params) => {
                if (params.value == null) {
                return '';
                }
        
                return clsx('super-app', {
                negative: params.row.estoque < 0,
                positive: params.row.estoque > 0,
                });
            },
        },           
        { field: "cor", headerName: "Cor",  headerClassName: 'blue-header', headerAlign: 'left', width: 130, align: 'left',
            cellClassName: (params) => {
                if (params.value == null) {
                return '';
                }
        
                return clsx('super-app', {
                negative: params.row.estoque < 0,
                positive: params.row.estoque > 0,
                });
            },
        },           
        { field: "estoque", headerName: "Estoque",  headerClassName: 'blue-header', headerAlign: 'right', width: 75, align: 'right', 
            cellClassName: (params) => {
                if (params.value == null) { return '' }       
                                 
                return clsx('super-app', {
                negative: params.value < 0,
                positive: params.value > 0,
                });
            },
        },           
        { field: "custoproduto", headerName:"Custo Produto R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right',
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, cellClassName: (params) => {
                if (params.value == null) {
                  return '';
                }
          
                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },
        },        
        { field: "custoreal", headerName:"Custo Real R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, cellClassName: (params) => {
                if (params.value == null) {
                  return '';
                }
          
                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },
        },        
        { field: "valorvenda", headerName:"Valor Venda R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 145, align: 'right',
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            cellClassName: (params) => {
                if (params.value == null) {
                  return '';
                }
          
                return clsx('super-app', {
                  negative: params.value < 0,
                  positive: params.value > 0,
                });
              },
        }        
    ];    

    const contextvalues = {
        definirItensSelecionados,
        definirLojasSelecionadas,
        definirMarcasSelecionadas,
        definirCategoriasSelecionadas,
        definirDepartamentosSelecionados,
        definirColecoesSelecionadas,
        definirTamanhosSelecionados,
        definirCoresSelecionadas,
        definirReferenciasSelecionadas
    }  
    
    function CustomToolbar() {
        return (
        <>
        <div className='row'>
            <div className='col'>
                <CustomToolBar print={visualizarImpressao}/>       
            </div>
            <div className='col-5'>
                {(listarEstoque === 'todos') 
                    && (saldoNegativoNosTotalizadores) 
                    && (<label className='text' 
                        style={{color: 'red', marginTop: '15px'}}>* Valores de produtos com estoque negativo interferem nos totalizadores</label>)  }
            </div>
        </div>
        </>
        );
      }     

    registerLocale('pt-BR', dateFnsPtBR);
    setDefaultLocale('pt-BR');    
    
    if (loading) {
        return (
            <LoadingScreen/>
        );
      }

     const blueHeader = 'blue-cell'; 

      const renderTable = () => {
        if (tipoVisaoSelect === 'produto') {
          return (
            <table class="table">              
                <thead>                        
                    <th scope="col" style={{width: '670px'}}></th>                 
                    <th scope="col" className="text-right" style={{width: '75px', fontSize: '13px'}}></th>                
                    <th scope="col" className="text-right" style={{width: '130px', fontSize: '13px'}}></th>                
                    <th scope="col" className="text-right" style={{width: '130px', fontSize: '13px'}}></th>       
                    <th scope="col" className="text-right" style={{width: '145px', fontSize: '13px'}}></th>                 
                </thead>
                <tbody>
                    <tr >
                    <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {!isEmpty(totalizador) ? totalizador.saldo : 0}
                    </td>
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {(!isEmpty(totalizador) ? totalizador.custoproduto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td> 
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}
                        >
                        {(!isEmpty(totalizador) ? totalizador.custoreal : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>                   
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'}}>
                        {(!isEmpty(totalizador) ? totalizador.valorvenda : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}                           
                    </td>                             
                    </tr>              
                </tbody>
            </table>        
          );
        } else {
          return (
            <table class="table">              
                <thead>                        
                    <th scope="col" style={{width: '480px'}}></th>                 
                    <th scope="col" className="text-right" style={{width: '100px', fontSize: '13px'}}></th>                
                    <th scope="col" className="text-right" style={{width: '190px', fontSize: '13px'}}></th>                
                    <th scope="col" className="text-right" style={{width: '190px', fontSize: '13px'}}></th>       
                    <th scope="col" className="text-right" style={{width: '190px', fontSize: '13px'}}></th>                 
                </thead>
                <tbody>
                    <tr >
                    <th className="text-left" scope="row"  style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {!isEmpty(totalizador) ? totalizador.saldo : 0}
                    </td>
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {(!isEmpty(totalizador) ? totalizador.custoproduto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td> 
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        {(!isEmpty(totalizador) ? totalizador.custoreal : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>                   
                    <td className="text-right" 
                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'}}>
                        {(!isEmpty(totalizador) ? totalizador.valorvenda : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}                           
                    </td>                             
                    </tr>              
                </tbody>
            </table>        
          );
        }
      };     
            
      const getRowClassName = (params) => {        
            if (params.value == null) {
              return '';
            }

            const saldo = params.row.estoque;

            if (saldo > 0)
            {
                return 'super-app positive'
            }
            else
            {
                return 'super-app negative'
            }                 
      };

     
    return (
        <>       
        <EstoqueContext.Provider value={contextvalues}>             
            <form className="form">                
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Saldo de Estoque</label>
            </div>                                                
                <Card.Body>                    
                    <Container>                                                                                 
                        <Card>                                       
                        <div className='align-left'>                        
                            <div className='row'>
                            <div className='col'>
                            <label className='text' style={{ fontWeight: 'bold' }}>Visão</label>  
                                <Card style={{width: 480 }}>                        
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-check">                                               
                                                 <Radio
                                                    checked={tipoVisaoSelect === 'item'}
                                                    onChange={() => {
                                                        setTipoVisaoSelect('item'); 
                                                        setDadosEstoque([]);                                          
                                                    }}          
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    size="small"
                                                    id="itemRadio" 
                                                />   
                                                <label className="form-check-label" htmlFor="itemRadio" style={{cursor: 'pointer'}}>
                                                    Por Item
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div className="col">                                            
                                            <Radio
                                                checked={tipoVisaoSelect === 'marca'}
                                                onChange={() => {
                                                    setTipoVisaoSelect('marca'); 
                                                    setDadosEstoque([]);                                                                
                                                }}           
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="marcaRadio" 
                                            />   
                                            <label className="form-check-label" htmlFor="marcaRadio" style={{cursor: 'pointer'}}>
                                                Por Marca
                                            </label>                                            
                                        </div>

                                        <div className="col">                                                                                           
                                            <Radio
                                                checked={tipoVisaoSelect === 'produto'}
                                                onChange={() => {
                                                    setTipoVisaoSelect('produto'); 
                                                    setDadosEstoque([]);                                          
                                                }}          
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="produtoRadio" 
                                            />   
                                            <label className="form-check-label" htmlFor="produtoRadio" style={{cursor: 'pointer'}}>
                                                Por Produto
                                            </label>
                                        </div>
                                    </div>
                                </Card>  
                            </div>                         
                    
                            <div className='col'>                   
                            <label className='text' style={{ fontWeight: 'bold' }}>Listar:</label>
                                <Card style={{width: 450}}>                        
                                <div className="row">                     
                                    <div className="col-8">
                                        <div className="form-check">                                        
                                            <Radio
                                                checked={listarEstoque === 'comestoque'}
                                                onChange={() => {
                                                    setListarEstoque('comestoque'); 
                                                    setDadosEstoque([]);                                          
                                                } }                
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="estoqueRadio" 
                                            />   
                                            <label class="form-check-label" for="estoqueRadio" style={{cursor: 'pointer'}}>
                                                Somente com Estoque
                                            </label>
                                        </div>
                                    </div>                          
                                        <div className="col">                                                                                       
                                            <Radio
                                                checked={listarEstoque === 'todos'}
                                                onChange={() => {
                                                    setListarEstoque('todos'); 
                                                    setDadosEstoque([]);                                                                
                                                }}                  
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                                id="estoquetodosRadio" 
                                            />   
                                            <label class="form-check-label" for="estoquetodosRadio" style={{cursor: 'pointer'}}>
                                                Todos
                                            </label>                                            
                                        </div>                                                 
                                    </div>  
                                </Card>     
                            </div>                
                        </div> 
                        </div>                                                                   
                        </Card>                                                                                                                                                            
                    </Container>

                    <Container>
                        <Card>                                                                                                                                                                                                            
                            <div className="filtros">
                            <div className="align-left">
                                <div className="row align-items-start" style={{marginTop: '20px'}}>
                                    <div className="col-lg">
                                        <ButtonLojas
                                            onClick={handleShowLojas}
                                            width={'185px'}                                  
                                            lojasFiltro={lojasFiltro}
                                        />
                                    </div>                                
                                    <div className="col-lg">
                                        <ButtonItens
                                            onClick={handleShowItens}
                                            width={'185px'}
                                            itensFiltro={itensFiltro}
                                        />
                                    </div>                               
                                    <div className="col-lg">
                                        <ButtonMarcas 
                                            onClick={handleShowMarcas}
                                            width={'185px'}
                                            marcasFiltro={marcasFiltro}
                                        />                                         
                                    </div>    
                                    <div className="col-lg">
                                        <ButtonCategorias 
                                            onClick={handleShowCategorias}
                                            width={'185px'}
                                            categoriasFiltro={categoriasFiltro}
                                        />                                         
                                    </div> 
                                    <div className="col-lg">
                                        <ButtonDepartamentos 
                                            onClick={handleShowDepartamentos}
                                            width={'185px'}
                                            departamentosFiltro={departamentosFiltro}
                                        />                                         
                                    </div>                                                                   
                                </div>                               
                            </div>

                            <div className="align-left">                
                                <div className="row align-items-start" style={{marginTop: '20px'}}>
                                        <div className="col">
                                            <ButtonColecoes
                                                onClick={handleShowColecoes}
                                                width={'185px'}                                  
                                                colecoesFiltro={colecoesFiltro}
                                            />
                                        </div>   
                                        <div className="col-lg">
                                         {tipoVisaoSelect === 'produto' &&  (<ButtonTamanhos
                                                onClick={handleShowTamanhos}
                                                width={'185px'}                                  
                                                tamanhosFiltro={tamanhosFiltro}                                                
                                            />)}
                                        </div>   
                                        <div className="col-lg">
                                         {tipoVisaoSelect === 'produto' &&  (<ButtonCores
                                                onClick={handleShowCores}
                                                width={'185px'}                                  
                                                coresFiltro={coresFiltro}                                                
                                            />)}
                                        </div>   
                                        <div className="col-lg">
                                         {tipoVisaoSelect === 'produto' &&  (<ButtonReferencias
                                                onClick={handleShowReferencias}
                                                width={'185px'}                                  
                                                referenciasFiltro={referenciasFiltro}                                                
                                            />)}
                                        </div>   
                                </div> 
                            </div>                                               
                        </div>        

                            <div class="row" style={{marginTop: '30px'}}>
                                <div className='col-2'>
                                    <div className="actions">
                                    <ButtonExecute 
                                        width={'185px'}
                                        onClick={handleExecutar}
                                    />   
                                    </div>                    
                                </div>
                                <div className='col-8' style={{marginLeft: '15px'}}>
                                    <ButtonClear                                         
                                        onClick={handleLimparFiltros}
                                    />   
                                </div>
                            </div>   
                        </Card>
                    </Container>                 
                </Card.Body>                     
            </form>     
                
            <ModalLojas show={showLojas} value={EstoqueContext} onHide={handleCloseLojas}/>                      
            <ModalItem show={showItens} value={EstoqueContext} onHide={handleCloseItens}/>
            <ModalMarcas show={showMarcas} value={EstoqueContext} onHide={handleCloseMarcas}/>   
            <ModalCategorias show={showCategorias} value={EstoqueContext} onHide={handleCloseCategorias}/>                       
            <ModalDepartamentos show={showDepartamentos} value={EstoqueContext} onHide={handleCloseDepartamentos}/>                       
            <ModalColecoes show={showColecoes} value={EstoqueContext} onHide={handleCloseColecoes}/>                       
            <ModalTamanhos show={showTamanhos} value={EstoqueContext} onHide={handleCloseTamanhos}/>
            <ModalCores show={showCores} value={EstoqueContext} onHide={handleCloseCores}/>
            <ModalReferencias show={showReferencias} value={EstoqueContext} onHide={handleCloseReferencias}/>

            {dadosEstoque && dadosEstoque.length > 0 && (
            <Container>                                          
                <CustomStyledDataGrid 
                    slots={{
                        toolbar: CustomToolbar,
                        pagination: CustomPagination                   
                    }}      
                    loading={loading}                                          
                    columns={
                        tipoVisaoSelect === 'produto' 
                        ? [...columnsProdutos]
                        : [...columns]}                   
                    rows={
                        tipoVisaoSelect === 'produto' 
                        ? [...rowsProdutos]
                        : [...rows]}                       
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}                                                                                                                                                                         
                    sx={{
                        '@media print': {
                          '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' }                          
                        },
                      }}                    
                    getRowClassName={getRowClassName}
                    />                                                                                             
            </Container>              
            )}                       
        </EstoqueContext.Provider>
        </>
    )
  }

export default EstoqueProvider;
