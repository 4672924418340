import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonMarcas({ onClick, width, marcasFiltro }) {
  return (    
    <Button style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',                                  
        borderRadius: '15px' 
        }}  
        onClick={onClick}>
            + Marca
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {marcasFiltro.length > 0 ? marcasFiltro.length : ''}
            </span>
        </Button>         
  );
}

export default ButtonMarcas;
