import moment from 'moment';

export class ImpressaoComissaoVendedorPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;          
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {      
      const headerComissao = [        
        { text: 'Loja', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },
        { text: 'Vendedor', bold: true, alignment: 'left', fontSize: 8, margin: [4, 4, 4, 4] },
        { text: 'Nome', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },
        { text: 'Qtde Vendas', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },        
        { text: 'Qtde Itens', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'Valor Venda R$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: '% Comissão', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'V.Comissão Total', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: '% Repres.', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] }        
      ];
      return headerComissao;                 
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; 
  }

  GetBody() {        
    const bodyComissao = this.dadosParaImpressao.flatMap((vend) => {
      const comissaoRows = vend.vendedor.map((venda) => [
            { text: venda.lojaid, fontSize: 8, margin:[4, 0, 0, 0], boder: [] },           
            { text: venda.vendedorid , fontSize: 8, margin:[4, 0, 0, 0], boder: [] },           
            { text: venda.vendedornome , fontSize: 8, margin:[4, 0, 0, 0], boder: [] },                        
            { text: venda.qtdevendas, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], boder: [] },                                 
            { text: venda.qtdeitens , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], boder: [] },            
            { text: venda.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], boder: [] },
            { text: venda.percentualcomissao.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], boder: [] },
            { text: venda.valorcomissao.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], boder: [] },
            { text: venda.percentualrepresentatividade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], boder: [] }            
        
      ]);

      if (this.filtros.tipoImpressao === 'detalhado')
      {
        const detalheRows = [
          {
            table: {
              widths: [40, 48, 88, 80, 64, 72, 112], 
              headerRows: 1,              
              body: [    
                              
                [{ text: 'Loja', fontSize: 8, alignment: 'left', fillColor: '#DAEBF2' },                                  
                 { text: 'Venda', fontSize: 8, alignment: 'left', fillColor: '#DAEBF2' },                 
                 { text: 'Data', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2'},           
                 { text: 'Qtde Itens', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },
                 { text: 'Valor Venda R$', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },     
                 { text: '%  Comissão', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },     
                 { text: 'V. Comissão Total', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2'},                                     
                ],

                ...vend.vendedorDetalhe.map((vendDetalhe) => [
                  { text: vendDetalhe.lojaid, fontSize: 8, margin:[4, 0, 0, 0], border: [] },                                                    
                  { text: vendDetalhe.vendaid, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0], border: [] },                  
                  { text: this.formatarData(new Date(vendDetalhe.data)), border: [], fontSize: 8, alignment: 'center', margin:[0, 0, 4, 0]},                  
                  { text: vendDetalhe.qtdeitens, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                  { text: vendDetalhe.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), border: [], fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
                  { text: vendDetalhe.percentualcomissao.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), border: [], fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
                  { text: vendDetalhe.valorcomissao.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), border: [], fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},                  
                  ]),              
                ],              
            },
            fontSize: 8,
            colSpan: 9,
            margin: [0, 0, 0, 6], 
          },
        ];
    
        return [...comissaoRows, detalheRows];
     }
      else
      {
         return [...comissaoRows];
      }                
    });
    
    return bodyComissao;
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: 9
      }
    ];

    const lineSpace = [
      {
        text:
        '\n\n\n',
        fontSize: 5,
        colSpan: 9
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody(), lineSpace];     
    content.push(this.GetTotalizadores());         
    return content;
  } 

  GetTotalizadores() {      
        const totalItens = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendedor[0].qtdeitens, 0);        
        const totalVenda = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendedor[0].valorliquido, 0);        
        const totalComissao = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendedor[0].valorcomissao, 0);        
       
        const totalRowVenda = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 9, margin:[4, 0, 0, 0]   },
          { text: '' },  
          { text: '' , bold: true, alignment: 'right', fontSize: 8, margin:[4, 0, 0, 0] },  
          { text: '', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: totalItens, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: 'R$ ' + totalVenda.toLocaleString('pt-BR', { 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
            }), bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: '', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: 'R$ ' + totalComissao.toLocaleString('pt-BR', { 
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
              })
            , bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: '', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
        
        ];    
        return totalRowVenda;      
  }
  
  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE COMISSÃO', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {                    
           text: `\nVisão: ${this.primeiraLetraMaiuscula(this.filtros.tipoVisao)}` 
             + `  |  Período: ` + this.formatarData(this.filtros.datasFiltro[0]) + ' a ' + this.formatarData(this.filtros.datasFiltro[1])                
             + `  |  Lojas: ` + this.filtros.lojasSelecionadas
             + `  |  Vendedores: ` + `${this.filtros.vendedoresFiltro.length === 0 ? 'Todos ' : this.OrdenarVetor(this.filtros.vendedoresFiltro)}` 
             + `  ${this.filtros.considerarAcrescimos ? '  |  Considerar Acréscimos' : ''} `,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths:  [25, 44, 80, 54, 54, 64, 56, 64 , 64],              
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}