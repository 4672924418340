import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonLojasEmissao({ onClick, width, lojasEmissaoFiltro }) {
  return (
    
    <Button 
        style={{ 
            backgroundColor: '#007CB0', 
            width: width, 
            height: '30px', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',  
            border : '0px',
            borderRadius: '15px'                             
            }
        } 
       onClick={onClick} >
       + Loja(s) Emissão
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {lojasEmissaoFiltro.length > 0 ? lojasEmissaoFiltro.length : 1}
            </span>
    </Button>    
  );
}

export default ButtonLojasEmissao;


