import moment from 'moment';

export class ImpressaoComissaoRecebimentoPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;          
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {      
      const headerComissao = [        
        { text: 'Loja', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },
        { text: 'Vendedor', bold: true, alignment: 'left', fontSize: 8, margin: [4, 4, 4, 4] },
        { text: 'Nome', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },                
        { text: 'Valor Recebido R$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: '% Comissão', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'V.Comissão Total R$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: '% Representatividade', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] }        
      ];
      return headerComissao;                 
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; 
  }

  GetBody() {            
    const bodyDRE = this.dadosParaImpressao.flatMap((array) => {                
      const contasRows = [
          { text: array.lojaid, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0] },
          { text: array.vendedorid, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },                                                                                          
          { text: array.vendedornome , fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },                                                            
          { text: array.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                                                            
          { text: array.percentualcomissao.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                                                            
          { text: array.valorcomissao.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                                                            
          { text: array.percentualrepresentatividade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] }                                                               
      ]           
      
      if (this.filtros.tipoImpressao === 'detalhado')
      {
        const detalheRows = [
          {
            table: {
              widths: [70, 70, 70, 70, 70, 70, 82], 
              headerRows: 1, 
              body: [                  
                [{ text: ''},  
                 { text: 'Crediário R$'},                                                                                                                         
                 { text: 'Cartões R$'},                 
                 { text: 'Cheques R$'},                 
                 { text: 'Dinheiro R$'},                 
                 { text: 'PIX R$'},                 
                 { text: 'Vale Presente R$'}
                ],                    
                   [                    
                    { text: '', border: [] },      
                    { text: array.detalhe.crediario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), alignment: 'right', margin:[0, 0, 4, 0], border: [] },                                                                                            
                    { text: array.detalhe.cartoes.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},                                       
                    { text: array.detalhe.cheques.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })  , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},                                       
                    { text: array.detalhe.dinheiro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })  , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},                                       
                    { text: array.detalhe.pix.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},                                       
                    { text: array.detalhe.trocavalepresente.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},                                       
                  ] ,                
              ],
            },
            fontSize: 8,
            colSpan: 7,
            margin: [0, 0, 0, 6], 
          },
        ];      
        return [contasRows, detalheRows] ;  
      }
      else
      {
          return [contasRows];
      }                           
    });    
    return bodyDRE;           
}

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: 7
      }
    ];

    const lineSpace = [
      {
        text:
        '\n\n\n',
        fontSize: 5,
        colSpan: 7
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody(), lineSpace];     
    content.push(this.GetTotalizadores());         
    return content;
  } 

  GetTotalizadores() {              
        const totalVenda = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorliquido, 0);        
        const totalComissao = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorcomissao, 0);        
       
        const totalRowVenda = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 9, margin:[4, 0, 0, 0]   },
          { text: '' },            
          { text: '', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                                                                                     
          { text: 'R$ ' + totalVenda.toLocaleString('pt-BR', { 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
            }), bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: '', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: 'R$ ' + totalComissao.toLocaleString('pt-BR', { 
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
              })
            , bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: '', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                              
        ];    
        return totalRowVenda;      
  }
  
  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE COMISSÃO POR RECEBIMENTO', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {                    
           text: `\nVisão: ${this.primeiraLetraMaiuscula(this.filtros.tipoVisao)}` 
             + `  |  Período: ` + this.formatarData(this.filtros.datasFiltro[0]) + ' a ' + this.formatarData(this.filtros.datasFiltro[1])                
             + `  |  Lojas: ` + this.filtros.lojasSelecionadas
             + `  |  Vendedores: ` + `${this.filtros.vendedoresFiltro.length === 0 ? 'Todos ' : this.OrdenarVetor(this.filtros.vendedoresFiltro)}` 
             + `  ${this.filtros.considerarAcrescimos ? '  |  Considerar Acréscimos' : ''} `
             + `  ${this.filtros.considerarJurosMultas ? '  |  Considerar Juros/Multa' : ''} `,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths:  [25, 44, 125, 80, 70, 85, 90],              
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}