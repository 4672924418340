import { Typography } from '@mui/material';
import React from 'react';

function TypographyCustom({ titulo }) {
  return (    
    <Typography
        variant="h6"
        style={{
            position: 'absolute',
            top: -25,
            left: 15,
            backgroundColor: 'white',
            padding: '8px',
        }}
    >
        <label style={{ fontSize: '15px', fontWeight: 'bold' }}>{titulo}</label>
    </Typography>
  );
}

export default TypographyCustom;
