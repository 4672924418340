import moment from 'moment';
import { ptBR } from 'date-fns/locale';

export class ImpressaoContagemEstoquePDF {  
  constructor(dadosParaImpressao, dadosParaImpressaoTotalizadores, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.dadosParaImpressaoTotalizadores = dadosParaImpressaoTotalizadores;
    this.filtros = filtrosSelecionados;               
  }

  
  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {    
      const header = [
        { text: 'Cód', bold: true,  alignment: 'left', fontSize: 9, margin: [4, 4, 4, 4] },
        { text: 'Descrição', bold: true,  alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Item', bold: true,  alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Marca', bold: true,  alignment: 'left', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Referência', bold: true,   alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Tamanho', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Cor', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Qtde Anterior', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Contado', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Diferença', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
      ];
      return header;          
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }


  GetBody() {                
      const bodyProduto = this.dadosParaImpressao.map((prod) => {
        debugger;
          return  [
            { text: prod.produto.id, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: prod.produto.descricao.substring(0, 16), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: prod.produto.grade.produtogrupo.item.descricao.substring(0, 9), fontSize: 8, alignment: 'left',  margin:[0, 0, 0, 0] },
            { text: prod.produto.grade.produtogrupo.marca.descricao.substring(0, 9), fontSize: 8, alignment: 'left',  margin:[0, 0, 0, 0] },
            { text: prod.produto.grade.referencia, fontSize: 8, alignment: 'left', margin:[0, 0, 0, 0]},    
            { text: prod.produto.tamanhoproduto.descricao, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},       
            { text: prod.produto.grade.cor.descricao.substring(0, 9), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: prod.qtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.qtdecontado, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.qtdediferenca, fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
          ];
        }
      );      
      return bodyProduto;
  }        
  
  GetTotalizadores() {
    const totalQtdeAnterior = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtde, 0);
    const totalQtdeContado = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdecontado, 0);
    const totalQtdeDiferenca = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdediferenca, 0);
        
        const totalRowProduto = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 9, margin:[4, 0, 4, 0] },      
          { text: ''},
          { text: ''},  
          { text: ''},
          { text: ''},    
          { text: ''},    
          { text: '', bold: true, alignment: 'right', fontSize: 9, margin:[0, 0, 4, 0] },      
          { text: totalQtdeAnterior, 
              bold: true, alignment: 'right', fontSize: 9, margin:[0, 0, 4, 0] },
          { text: totalQtdeContado, 
              bold: true, alignment: 'right', fontSize: 9, margin:[0, 0, 4, 0] },
          { text: totalQtdeDiferenca, 
              bold: true, alignment: 'right', fontSize: 9, margin:[0, 0, 4, 0] },             
        ];    
        return totalRowProduto;          
  }


  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
          '__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        alignment: 'center',
        fontSize: 5,
        colSpan: 10
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];       
    content.push(this.GetTotalizadores());
    content.push([ { text:'', colSpan: 10 }]);
    content.push([
      {
        text: 
        `\n\nCusto Total Antes da Contagem:         ${(this.dadosParaImpressaoTotalizadores.custototalantescontagem).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}\n` +
        `Custo Total Contagem:                          ${(this.dadosParaImpressaoTotalizadores.custototalcontagem).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}\n` +
        `Custo Total Diferenças:                         ${(this.dadosParaImpressaoTotalizadores.custototaldiferencas).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}\n` +
        `\nValor Venda Total Antes Contagem:   ${(this.dadosParaImpressaoTotalizadores.valorvendatotalantescontagem).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}\n` +        
        `Valor Venda Total Contagem:               ${(this.dadosParaImpressaoTotalizadores.valorvendatotalcontagem).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}\n` +        
        `Valor Venda Total Diferenças:              ${(this.dadosParaImpressaoTotalizadores.valorvendatotaldiferenca).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}`
        ,
        colSpan: 10, 
        fontSize: 10,
        alignment: 'left'
      }
    ]);

    return content;
  }
    
  OrdenarVetor(vetor) {
     return vetor.sort((x,y) => {
      if (x > y) return 1;
      if (x < y) return - 1;
        return 0
      } 
    ).join(', ')
  }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,
        fontSize: 10,        
        alignment: 'left'    
      },

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      },

      saldoNegativo: {        
        color : 'red',
        alignment : 'right',
        fontSize  : 10
      }
    }   

    const documento = {
      pageSize: 'A4',
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 0],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: `RELATÓRIO DE CONTAGEM DE ESTOQUE\n`, 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito',
        },
        {
          text: `\nVisão: Por ` + this.primeiraLetraMaiuscula(this.filtros.tipoVisao) +            
            '  |  Loja: ' + this.filtros.lojaSelecionada.nome +
             '  |  Itens: ' + (this.filtros.itensSelecionados.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.itensSelecionados)) +
             '  |  Marcas: ' + (this.filtros.marcasSelecionadas.length === 0 ? 'Todas' :  this.OrdenarVetor(this.filtros.marcasSelecionadas)) +                       
             '  |  Referências: ' + (this.filtros.referenciasSelecionadas.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.referenciasSelecionadas)),              
          style: 'campoFiltros'
        },        
        {
          layout: 'noBorders',
          table: {
            marginTop: 10,
            headerRows: 1,
            widths: [40, 90, 50, 50, 50, 45, 47, 40, 40, 43],
            body: corpoDocumento
            .map((row, index) => row.map(cell => (
              {
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' }
               )))
          }
        }
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:                  
                  '____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 15
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };
    return documento;
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}