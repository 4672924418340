import moment from 'moment';
import { getYear } from 'date-fns';

export class ImpressaoCrediarioPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;          
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {   
    if (this.filtros.statusCrediario === 'pendentes')
    {
      const headerCrediariosPendentes = [
        { text: 'Cód', bold: true, fontSize: 9, margin: [4, 4, 0, 0] },
        { text: 'Nome', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},      
        { text: '', margin: [0, 0, 0, 0] },
        { text: '', margin: [0, 0, 0, 0]},
        { text: 'Qtde Parcelas', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Valor Parcela R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },        
        { text: 'Multa/Juros R$', bold: true, fontSize: 9, alignment: 'right', margin: [0, 4, 0, 0] },
        { text: 'Valor Líquido R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
      ];
      return headerCrediariosPendentes;   
    }      
    else if (this.filtros.statusCrediario === 'baixados')
    {
      const headerCrediariosBaixados = [
        { text: 'Cód', bold: true, fontSize: 9, margin: [4, 4, 0, 0] },
        { text: 'Nome', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},   
        { text: ''},
        { text: ''},
        { text: 'Qtde Parcelas', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },   
        { text: 'Valor Parcela R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] }, 
        { text: 'Desconto R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Multa/Juro R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },             
        { text: 'Desc Juro R$', bold: true, fontSize: 9, alignment: 'right', margin: [0, 4, 0, 0] },
        { text: 'Valor Líquido R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
      ];
      return headerCrediariosBaixados;   
    }
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }

  GetBody() {    
    
    if (this.filtros.statusCrediario === 'pendentes')
    {
      const bodyCrediariosPendentes = this.dadosParaImpressao.flatMap((cred) => {
        const crediarioRows = cred.clientes.map((cred) => [
              { text: cred.id, fontSize: 8, margin:[4, 0, 0, 0] },
              { text: cred.nome, fontSize: 8, margin:[0, 0, 4, 0] },
              { text: ''},
              { text: ''},
              { text: cred.crediarios.length, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: cred.crediarios.reduce((acc, crediario) => acc + crediario.valorpendente, 0)
                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: ((cred.crediarios.reduce((acc, crediario) => acc + crediario.jurosmultacalculado, 0))
                    ? (cred.crediarios.reduce((acc, crediario) => acc + crediario.jurosmultacalculado, 0))
                    : 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text:cred.crediarios.reduce((acc, crediario) => acc + crediario.valorliquido, 0)
                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] }
        ]);

        if (this.filtros.tipoVisao === 'detalhado')
        {
          const detalheRows = [
            {
              table: {
                widths: [38, 60, 60, 60, 60, 70, 70, 70], 
                headerRows: 1, 
                body: [                  
                  [
                    { text: 'Loja', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2'   },                 
                    { text: 'Documento', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                    { text: 'Data Emissão', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                    { text: 'Data Venc.', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                    { text: 'Último Venc.', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                    
                    { text: 'Valor Parcela R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },     
                    { text: 'Multa/Juro R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },    
                    { text: 'Valor Líquido R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },   
                  ],
                    ...cred.crediarios.map((credMov) => [
                      { text: credMov.lojaid, fontSize: 8, margin:[4, 0, 0, 0], border: [] },                                  
                      { text: credMov.documento, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                      { text: this.formatarData(new Date(credMov.emissao)), alignment: 'center', fontSize: 8, margin:[0, 0, 4, 0], border: [] },
                      { text:  this.formatarData(new Date(credMov.vencimento)), alignment: 'center', fontSize: 8, margin:[0, 0, 4, 0] , border: []},
                      { text: (getYear(credMov.ultimovencimento) > 1970) ?
                        this.formatarData(new Date(credMov.ultimovencimento)) : '', alignment: 'center', fontSize: 8, margin:[0, 0, 4, 0], border: [] },  
                      { text: credMov.valorpendente.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                      { text: credMov.jurosmultacalculado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[4, 0, 0, 0], border: [] },                
                      { text: credMov.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] }
                    ])                                     
                  ],
              },
              fontSize: 8,
              colSpan: 8,
              margin: [0, 0, 0, 6], 
            },
          ];
      
          return [...crediarioRows, detalheRows];         
        }
        else
        {
            return [...crediarioRows];
        }        
      });
    
      return bodyCrediariosPendentes;    
    }
    else if (this.filtros.statusCrediario === 'baixados')
    {
      const bodyCrediariosBaixados = this.dadosParaImpressao.flatMap((cred) => {
        const crediarioRows = cred.clientes.map((cred) => [
              { text: cred.id, fontSize: 8, margin:[4, 0, 0, 0] },
              { text: cred.nome, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
              { text: ''},
              { text: ''},
              { text: cred.crediariosMovimento.length, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: cred.crediariosMovimento.reduce((acc, crediario) => acc + crediario.valorparcela, 0)
                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },              
              { text: cred.crediariosMovimento.reduce((acc, crediario) => acc + crediario.desconto, 0)
                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: ((cred.crediariosMovimento.reduce((acc, crediario) => acc + crediario.juromulta, 0))
                    ? (cred.crediariosMovimento.reduce((acc, crediario) => acc + crediario.juromulta, 0))
                    : 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text:cred.crediariosMovimento.reduce((acc, crediario) => acc + crediario.descontojuro, 0)
                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text:cred.crediariosMovimento.reduce((acc, crediario) => acc + crediario.valorpgto, 0)
                .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] }
        ]);
   
        if (this.filtros.tipoImpressao === 'detalhado')
        {
          const detalheRows = [
            {
              table: {
                widths: [22, 50, 50, 50, 50, 50, 50, 50, 50, 50], 
                headerRows: 1, 
                body: [                  
                  [
                    { text: 'Loja', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                 
                    { text: 'Documento', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                    { text: 'Data Emissão', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                    { text: 'Data Vencimento', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                    { text: 'Data Baixa', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                    
                    { text: 'Valor Parcela R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },     
                    { text: 'Desc R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },     
                    { text: 'Multa/Juro R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },    
                    { text: 'Desc Juro R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },    
                    { text: 'Valor Líquido R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },   
                  ],
                    ...cred.crediariosMovimento.map((credMov) => [
                      { text: credMov.lojaid, fontSize: 8, margin:[4, 0, 0, 0], border: [] },                                  
                      { text: credMov.documento, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0], border: [] },
                      { text: this.formatarData(new Date(credMov.emissao)), alignment: 'center', fontSize: 8, margin:[0, 0, 4, 0], border: [] },
                      { text: this.formatarData(new Date(credMov.vencimento)), alignment: 'center', fontSize: 8, margin:[0, 0, 4, 0] , border: []},
                      { text: this.formatarData(new Date(credMov.datapagto)), alignment: 'center', fontSize: 8, margin:[0, 0, 4, 0] , border: []},  
                      { text: credMov.valorparcela.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                      { text: credMov.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[4, 0, 0, 0], border: [] },                
                      { text: credMov.juromulta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                      { text: credMov.descontojuro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },
                      { text: credMov.valorpgto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] }
                    ])                                     
                  ],
              },
              fontSize: 8,
              colSpan: 10,
              margin: [0, 0, 0, 6], 
            },
          ];
      
          return [...crediarioRows, detalheRows];         
        }
        else
        {
            return [...crediarioRows];
        }        
      });
    
      return bodyCrediariosBaixados;      
    }      
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: this.filtros.statusCrediario === 'pendentes' ? 8 : 10 
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];         
    content.push(this.GetTotalizadores());         
    return content;
  }     

  GetTotalizadores() {
    if (this.filtros.statusCrediario === 'pendentes')
    {
        const totalQtdeParcelas =  this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediarios.length, 0);    
        const totalValorParcela = this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediarios.reduce((acc, crediario) => acc + crediario.valorpendente, 0), 0);
        const totalMultaJuros = this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediarios.reduce((acc, crediario) => acc + crediario.jurosmultacalculado, 0), 0); 
        const totalValorLiquido =  this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediarios.reduce((acc, crediario) => acc + crediario.valorliquido, 0), 0);       
    
        const totalRowCrediarios = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },
          { text: ''},          
          { text: ''},
          { text: ''},      
          { text: totalQtdeParcelas, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalValorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalMultaJuros.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalValorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  }      
        ];    
        return totalRowCrediarios;      
    }

    const totalQtdeParcelas =  this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediariosMovimento.length, 0);    
    const totalValorParcela = this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediariosMovimento.reduce((acc, crediariosMovimento) => acc + crediariosMovimento.valorparcela, 0), 0);
    const totalDesconto = this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediariosMovimento.reduce((acc, crediariosMovimento) => acc + crediariosMovimento.desconto, 0), 0);
    const totalMultaJuros = this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediariosMovimento.reduce((acc, crediariosMovimento) => acc + crediariosMovimento.juromulta, 0), 0); 
    const totalDescJuro = this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediariosMovimento.reduce((acc, crediariosMovimento) => acc + crediariosMovimento.descontojuro, 0), 0); 
    const totalValorLiquido =  this.dadosParaImpressao.reduce((acc, cred) => acc + cred.crediariosMovimento.reduce((acc, crediariosMovimento) => acc + crediariosMovimento.valorpgto, 0), 0);       
    
    const totalRowCrediarios = [
      { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },
      { text: ''},                  
      { text: ''},
      { text: ''},      
      { text: totalQtdeParcelas, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalValorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalMultaJuros.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalDescJuro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalValorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  }      
    ];    
    return totalRowCrediarios;    
  }  

  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE CREDIÁRIO', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {          
           text: `\nVisão: ${
             this.primeiraLetraMaiuscula(this.filtros.tipoVisao)
           }` 
             + `  |  Data: ${this.formatarData(this.filtros.dataIntervaloFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloFiltro[1])}`
             + `  |  Período: ${ this.primeiraLetraMaiuscula(this.filtros.periodoDataFiltro)}`
             + `  |  Status: ${ this.primeiraLetraMaiuscula(this.filtros.statusCrediario)}`
             + `\nLojas: ${this.filtros.lojasSelecionadas}`
             + `  |  Clientes: ${(this.filtros.clientesFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.clientesFiltro))}`,          
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: this.filtros.statusCrediario === 'pendentes' 
              ? [ 25, 180, 0, 0, 70, 75, 75, 80 ] 
              : [ 25, 65, 0, 0, 60, 70, 65, 65, 65, 75 ],
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}