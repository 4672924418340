import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonItens({ onClick, width, itensFiltro }) {
  return (    
    <Button 
        style={{ 
            backgroundColor: '#007CB0',
            width: width, 
            height: '30px', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '15px'                                                
            }} 
            onClick={onClick}>
                + Item
                <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {itensFiltro.length > 0 ? itensFiltro.length : ''}
            </span>
    </Button>    
  );
}

export default ButtonItens;



                                    