import React, { useState, useContext, useEffect } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { SetTenant } from '../../services/api';

export const TableLojasPagamento = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [lojas, setLojas] = useState([]);  
  const [selectedLojasPagamento, setSelectedLojasPagamento] = useState([]);  
  const [loading, setLoading] = useState(true);
  const [lojasData, setLojasData] = useState([]);
  const { definirLojasPagamentoSelecionadas } = useContext(contexto); 
  const [limparLoja, setLimparLoja] = useState(false);

  useEffect(() => {    
      if (loading)
      {
        getLojas(); 
        setLoading(false);
      }        
  }, [loading]);

  useEffect(() => {    
      const storedSelectedLojas = sessionStorage.getItem('selectedLojasPagamento');
      if (storedSelectedLojas) {
        setSelectedLojasPagamento(JSON.parse(storedSelectedLojas));
      }      
  }, []);
  

  useEffect(() => {        
      if (selectedLojasPagamento.length > 0)
      {
          sessionStorage.setItem('selectedLojasPagamento', JSON.stringify(selectedLojasPagamento));
      }          
  }, [selectedLojasPagamento, lojas]);  

  const handleRemoveSelectedLoja = (idloja) => {

    const updatedSelectedLojas = selectedLojasPagamento.filter(loja => loja.idloja !== idloja);

    setSelectedLojasPagamento(updatedSelectedLojas);  
    setLojas((prevLojas) => {
      const lojaToAddBack = lojasData.find((loja) => loja.idloja === idloja);
        
      if (!prevLojas.some((loja) => loja.idloja === idloja)) {
        return [...prevLojas, lojaToAddBack];
      }
  
      return prevLojas;
    });

    const selectedLojaIds = updatedSelectedLojas.map((index) => index.idloja);
    definirLojasPagamentoSelecionadas(selectedLojaIds);
    sessionStorage.setItem('selectedLojasPagamento', selectedLojaIds);   
    
    if (selectedLojaIds.length === 0)
    {
      sessionStorage.removeItem('selectedLojasPagamento');
    }
  };

  const handleLojaSelection = async (idloja) => {
    const updatedIndexes = selectedLojasPagamento.includes(idloja)
      ? selectedLojasPagamento.filter((index) => index !== idloja)
      : [...selectedLojasPagamento, idloja];
    await setSelectedLojasPagamento(updatedIndexes);   

    const selectedLojaIds = updatedIndexes.map((index) => index.idloja);
    definirLojasPagamentoSelecionadas(selectedLojaIds);
  };

  const handleRowClick = (idloja) => {
    setLojas((prevLojas) =>
      prevLojas.filter((item) => item.idloja !== idloja)
    );
  };

  const handleAdicionarTodos = () => {
       setSelectedLojasPagamento(lojasData);
       setLojas([]);
       
       const selectedLojaIds = lojasData.map((index) => index.idloja);
       definirLojasPagamentoSelecionadas(selectedLojaIds);       
       sessionStorage.setItem('selectedLojasPagamento', selectedLojasPagamento);      
  }

  const handleRemoverTodos = () => {              
      setLojas(lojasData);            
      setSelectedLojasPagamento([]);    
      definirLojasPagamentoSelecionadas([]);         
      sessionStorage.removeItem('selectedLojasPagamento');    
      setLimparLoja(true);
  } 

  const getLojas = async () => {       
    try {            
      const userData = await sessionStorage.getItem('user');       
      
      if (userData)
      {        
        const desencryptJSON = new EncryptionUtil(); 
        const desencryptJSONLojas = desencryptJSON.decryptJSON(userData); 
        const user = JSON.parse(desencryptJSONLojas) ;
        await SetTenant();
        const response = await api.get(`loja/lojascadastradas/${user.id}`);                       
        await setLojas(response.data);   
        await setLojasData(response.data);
      }      
    } catch (e) {
      console.log('erro');
    }
  };   

  const handleRowSelectedClick = (loja) => {

    if (!lojas.includes(loja.idloja))
    {
      lojas.filter((loja) => loja.idloja !== loja.idloja)
        const updatedLojas = [...lojas, loja];
        setLojas(updatedLojas);
    }    
  };

  return (
    <>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>       
        <Container>
          <div className="col">
            <Card>      
              <div className='col'>
                <ButtonAdicionarTodos 
                  onClick={handleAdicionarTodos}
                />                
              </div>                                             
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                    {lojas.filter((item) => !selectedLojasPagamento.some((loja) => loja.idloja === item.idloja))
                      .map((item) => (
                        <tr
                          key={item.idloja}
                          onClick={() => {
                            handleLojaSelection(item);
                            handleRowClick(item.idloja);
                          }}
                        >
                          <td>{item.idloja}</td>
                          <td>{item.nome}</td>
                        </tr>
                      ))
                    }
                  </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                  <div className='col text-left'> 
                    <label className='labelSelecionar' >Selecionados: {  selectedLojasPagamento.length }</label>
                  </div>
                <div className='col text-right'>               
                <ButtonRemoverTodos 
                  onClick={handleRemoverTodos}
                />
                </div>
              </div>        
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-dark mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedLojasPagamento.map((item) => (
                    <tr key={item.idloja}  
                     onClick={() => {
                        handleRemoveSelectedLoja(item.idloja);                        
                     }                      
                    }                      
                    >
                      <td>{item.idloja}</td>
                      <td>{item.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TableLojasPagamento;
