import React from 'react';
import { Container, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Alert from '@mui/material/Alert';

function ModalMensagemRelatorioMascPlusPro(props) {  
  const handleClose = () => props.onHide();

  return (    
    <Modal onHide={handleClose}
      {...props}       
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
    <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>  
      <Modal.Title id="contained-modal-title" >                               
        <Alert severity="warning" style={{marginLeft: '140px'}}>          
          Recurso sem Acesso
        </Alert>

       </Modal.Title>    
    </Modal.Header>
        <Modal.Body className="show-grid">
            <Container>
              <div className='row'>
                <label style={{ textAlign: 'center', fontSize: '20px', fontFamily: 'arial' }}>Relatório disponível a partir do plano PLUS. Entre em contato com nosso time comercial!</label>
              </div>
            </Container>
        </Modal.Body>
      <Modal.Footer>      
        
      </Modal.Footer>
    </Modal>   

  );
}

export default ModalMensagemRelatorioMascPlusPro;
