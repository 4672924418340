import React, { createContext, useState, useEffect } from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector
} from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import PaginationItem from '@mui/material/PaginationItem';
import "react-datepicker/dist/react-datepicker.css";
import { Container, Pagination, Typography, Stack, Grid, Paper, Radio, FormControlLabel, Switch, Tooltip, Grow, Fade } from '@mui/material';
import { api } from '../../services/api';
import { Card } from 'react-bootstrap';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalVendedores from '../../components/Modal/ModalVendedores';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { ImpressaoVendaPDF } from '../../components/Impressao/ImpressaoVendaPeriodoPDF';
import './stylesComissaoRecebimento.css';
import { isEmpty } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Box } from '@mui/system';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonVendedores from '../../components/Buttons/ButtonVendedores';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
import detalhar from '../../img/eyerIcon.png';
import ModalDetalheVenda from '../../components/Modal/ModalDetalheVenda';
import { ImpressaoComissaoPDF } from '../../components/Impressao/ImpressaoComissaoVendedorPDF';
import ModalImpressaoResumidoDetalhado from '../../components/Modal/ModalImpressaoResumidoDetalhado';
import { ComissaoXLS } from '../../components/Impressao/Excel/ComissaoXLS';
import GraficoBarrasComissao from '../../components/GraficoDeBarras/GraficoBarrasComissaoFixa';
import GraficoBarrasComissaoRecebimento from '../../components/GraficoDeBarras/GraficoBarrasComissaoRecebimento';
import { ImpressaoComissaoRecebimentoPDF } from '../../components/Impressao/ImpressaoComissaoRecebimentoPDF';
import { ComissaoRecebimentoXLS } from '../../components/Impressao/Excel/ComissaoRecebimentoXLS';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export const ComissaoRecebimentoContext = createContext();

export const ComissaoRecebimentoProvider = () => {
  const sessionStorageUtils = new SessionStorageUtils();
  const PAGE_SIZE = 50;
  const [paginationModel, setPaginationModel] = useState({ pageSize: PAGE_SIZE, page: 0, });
  const [nomeGrupo, setNomeGrupo] = useState([]);
  const [showLojas, setShowLojas] = useState(false);
  const [showVendedores, setShowVendedores] = useState(false);
  const [dadosVenda, setDadosVenda] = useState([]);
  const [tipoVisaoSelect, setTipoVisaoSelect] = useState('Recebimento');
  const [lojasFiltro, setLojasFiltro] = useState([]);
  const [clientesFiltro, setClientesFiltro] = useState([]);
  const [vendedoresFiltro, setVendedoresFiltro] = useState([]);
  const [showTipoImpRel, setShowTipoImpRel] = useState(false);
  const [tipoImpRel, setTipoImpRel] = useState('');
  const [loading, setLoading] = useState(true);
  const [limparFiltros, SetLimparFiltros] = useState(false);
  const [considerarAcrescimos, setConsiderarAcrescimos] = useState({ consideraracrescimos: false });
  const [exibirGrafico, setExibirGrafico] = useState({ exibir: false });
  const [considerarJurosMultas, setConsiderarJurosMulta] = useState({ considerar: false });
  const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([startOfMonth(new Date()), new Date()]);
  const [showDetalheVenda, setShowDetalheVenda] = useState(false);
  const [vendaIdDetalhe, setVendaIdDetalhe] = useState(0);
  const navigate = useNavigate();
  const permissao = new Permissoes();

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      if (ValidarPermissoes() == false) { return; }
      LimparFiltrosSelecionados();
      definirLojaInicial();
      DefinirNomeGrupo();
      setLoading(false);
    }, 500);
  }, []);

  async function ValidarPermissoes() {
    const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('aa3670cde890704ff3f3a570752933bc');
    if (autorizado) { return true; }
    navigate('/home')
    return false;
  }

  const handleDetalheVenda = async (vendaid) => {
    if (vendaid !== 0) {
      await setVendaIdDetalhe(vendaid);
      await setShowDetalheVenda(true);
    }
  };

  useEffect(() => {
    if (limparFiltros) {
      definirLojaInicial();
      SetLimparFiltros(false);
    }
  }, [limparFiltros]);

  const handleLimparFiltros = async () => {
    resetarDataInicial();
    LimparFiltrosSelecionados();
    SetLimparFiltros(true);
  }

  const resetarDataInicial = () => {
    setDataIntervaloFiltro([
      startOfMonth(new Date()),
      new Date()
    ]);
  };

  function LimparFiltrosSelecionados() {
    sessionStorage.removeItem('selectedLojas');    
    sessionStorage.removeItem('selectedVendedores');
    setLojasFiltro([]);    
    setVendedoresFiltro([]);
    setDadosVenda([]);
  }

  const ImprimirRelatorio = async () => {
    const classeImpressao = new ImpressaoComissaoRecebimentoPDF(dadosVenda, filtrosSelecionados);
    const documento = await classeImpressao.PreparaDocumento();
    pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    setShowTipoImpRel(false);
  }

  const DefinirNomeGrupo = async () => {
    const desencryptJSONObj = await sessionStorageUtils.GetUsuario();
    const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
    setNomeGrupo(groupName);
  }

  const totalizador = dadosVenda.reduce((total, obj) => {
    total.valorliquido += obj.valorliquido;
    total.valorcomissao += obj.valorcomissao;
    return total;

  }, { valorliquido: 0, valorcomissao: 0 });

  const renderTable = () => {

    return (
      <div className='table-align'>
        <table className="table">
          <thead>
            <th scope="col" style={{ width: '550px' }}></th>
            <th scope="col" className="text-right" style={{ width: '170px', fontSize: '13px' }}></th>
            <th scope="col" className="text-right" style={{ width: '100px', fontSize: '13px' }}></th>
            <th scope="col" className="text-right" style={{ width: '160px', fontSize: '13px' }}></th>
            <th scope="col" className="text-right" style={{ width: '170px', fontSize: '13px' }}></th>
          </thead>
          <tbody>
            <tr>
              <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2' }}>Total</th>
              <td className="text-right"
                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                {(!isEmpty(totalizador) ? totalizador.valorliquido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
              </td>
              <td className="text-right"
                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                {""}
              </td>
              <td className="text-right"
                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                {(!isEmpty(totalizador) ? totalizador.valorcomissao : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
              </td>
              <td className="text-right"
                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                {""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )

  }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);

    return (
      <>
        <div>
          <div className='table-align'>
            {renderTable()}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Typography variant="subtitle2" color="textSecondary">
                  Total de registros: {totalRowCount}
                </Typography>
              </div>

              <div style={{ marginLeft: '50px' }}>
                <Typography variant="subtitle2" color="textSecondary" >
                  Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                </Typography>
              </div>
            </div>

            <div style={{ alignSelf: 'flex-end' }}>
              <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />
            </div>
          </div>
        </div>

      </>
    );

  }


  const definirLojaInicial = async () => {
    const desencryptJSONUser = await sessionStorageUtils.GetUsuario();
    if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0) {
      const userId = JSON.parse(desencryptJSONUser).id;
      await SetTenant();
      const response = await api.get(`loja/lojascadastradas/${userId}`);
      const firstId = response.data[0].idloja;
      await setLojasFiltro(firstId);
    }
  }

  const filtrosSelecionados = {
    tipoVisao: tipoVisaoSelect,
    lojasSelecionadas: lojasFiltro,
    datasFiltro: dataIntervaloFiltro,
    clientesFiltro: clientesFiltro,
    vendedoresFiltro: vendedoresFiltro,
    nomeGrupo: nomeGrupo,
    tipoImpressao: tipoImpRel,
    considerarAcrescimos: considerarAcrescimos.consideraracrescimos,
    considerarJurosMultas: considerarJurosMultas.considerar
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );

  const visualizarImpressao = async () => {
    await handleShowTipoImpressao();
    await setTipoImpRel('');
  }

  const handleExecutar = async () => {
    setLoading(true);
    await getVendaDetalhe();
    setLoading(false);
  };

  const getVendaDetalhe = async () => {
    try {
      let url = `venda/relatorio/comissao/recebimento`;

      let dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth() + 1}-${dataIntervaloFiltro[0].getDate()}`);
      let dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth() + 1}-${dataIntervaloFiltro[1].getDate()}`);
      url += `/${dataIni}/${dataFin}/${lojasFiltro}`;

      if (vendedoresFiltro.length > 0) {
        url += `/${vendedoresFiltro}`;
      } else {
        url += "/ ";
      }

      url += `/${considerarAcrescimos.consideraracrescimos}/${considerarJurosMultas.considerar}`;

      setDadosVenda([]);
      await SetTenant();
      const response = await api.get(url);

      console.log(response);

      setDadosVenda(response.data);
    }
    catch (e) {
      console.log('erro');
    }
  }

  const handleShowLojas = () => {
    setShowLojas(true);
  }

  const handleShowVendedores = () => {
    setShowVendedores(true);
  }

  const handleCloseLojas = () => {
    setShowLojas(false);
  }

  const handleCloseVendedores = () => {
    setShowVendedores(false);
  }

  useEffect(() => {
    if (dadosVenda && dadosVenda.length > 0 && tipoImpRel !== '') {
      ImprimirRelatorio();
    }
  }, [tipoImpRel]);

  const handleCloseTipoImpressao = () => {
    setShowTipoImpRel(false);
  }

  const handleShowTipoImpressao = () => {
    setShowTipoImpRel(true);
  }

  const definirClientesSelecionados = (id) => {
    setClientesFiltro(id);
  }

  const definirLojasSelecionadas = (lojas) => {
    setLojasFiltro(lojas);
  }

  const definirVendedoresSelecionados = (id) => {
    setVendedoresFiltro(id);
  }

  const definirTipoImpressaoRelatorio = (bool) => {
    setTipoImpRel(bool);
  }

  const rows = dadosVenda.map((array) => {
    const detalhe = ({
      crediario: array.detalhe.crediario
    });

    return {
      id: array.id,
      lojaid: array.lojaid,
      vendedorid: array.vendedorid,
      vendedornome: array.vendedornome,
      valorliquido: array.valorliquido,
      percentualcomissao: array.percentualcomissao,
      valorcomissao: array.valorcomissao,
      percentualrepresentatividade: array.percentualrepresentatividade,
      detalhe: [array.detalhe]
    };
  });

  const columns = [
    {
      headerClassName: 'blue-header',
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    { field: "lojaid", headerName: "Loja", headerClassName: 'blue-header', width: 60 },
    { field: "vendedorid", headerName: "Vendedor", headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right' },
    { field: "vendedornome", headerName: "Nome", headerClassName: 'blue-header', headerAlign: 'left', width: 360, align: 'left' },
    {
      field: "valorliquido", headerName: "Valor Recebido R$", headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    {
      field: "percentualcomissao", headerName: "% Comissão", headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    {
      field: "valorcomissao", headerName: "V. Comissão Total", headerClassName: 'blue-header', headerAlign: 'right', width: 160, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    {
      field: "percentualrepresentatividade", headerName: "% Representatividade", headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    }
  ];


  const columnDetalhe = [
    {
      field: "crediario", headerName: "Crediário R$", headerClassName: 'header-detail', headerAlign: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }, width: 140, align: 'right'
    },
    {
      field: "cartoes", headerName: "Cartões R$", headerClassName: 'header-detail', headerAlign: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }, width: 140, align: 'right'
    },
    {
      field: "cheques", headerName: "Cheque R$", headerClassName: 'header-detail', headerAlign: 'right', width: 140, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    {
      field: "dinheiro", headerName: "Dinheiro R$", headerClassName: 'header-detail', headerAlign: 'right', width: 140, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    {
      field: "pix", headerName: "Pix R$", headerClassName: 'header-detail', headerAlign: 'right', width: 140, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    {
      field: "trocavalepresente", headerName: "Troca Vale Presente R$", headerClassName: 'header-detail', headerAlign: 'right', width: 180, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    }
  ];


  function DetailPanelContent({ row: rowProp }) {
    return (
      <Container sx={{ height: '100%' }}>
        <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
          <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
              <Grid container sx={{ flexGrow: 1 }}>
                <Grid item md={6}>
                </Grid>
              </Grid>
              <Container sx={{ height: '100%' }}>
                <CustomStyledDataGrid
                  density="compact"
                  rowHeight={40}
                  autoHeight
                  columns={columnDetalhe}
                  getDetailPanelHeight={() => 'auto'}
                  rows={rowProp.detalhe}
                  sx={{ flex: 1, height: '100%' }}
                  hideFooter
                />
              </Container>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    );
  }


  const contextvalues = {
    definirClientesSelecionados,
    definirLojasSelecionadas,
    definirVendedoresSelecionados,
    definirTipoImpressaoRelatorio
  }

  if (loading) {
    return (
      <LoadingScreen />
    );
  }

  function CustomToolbar() {
    return (
      <>
        <div className='row'>
          <div className='col'>
            <CustomToolBar print={visualizarImpressao} exportExcel={exportarExcel} />
          </div>
        </div>
      </>
    );
  }

  const handleChangeConsiderarJurosMulta = (event) => {
    setConsiderarJurosMulta({ ...considerarJurosMultas, [event.target.name]: event.target.checked });
  };

  const handleChangeConsiderarAcrescimo = (event) => {
    setConsiderarAcrescimos({ ...considerarAcrescimos, [event.target.name]: event.target.checked });
  };

  const handleChangeExibirGrafico = (event) => {
    setExibirGrafico({ ...exibirGrafico, [event.target.name]: event.target.checked });
  };

  const handleCloseDetalheVenda = () => {
    setShowDetalheVenda(false);
  }

  const exportarExcel = async () => {
    const exportarExcel = new ComissaoRecebimentoXLS(dadosVenda);
    exportarExcel.exportToXLS();
  }

  return (
    <>
      <ModalDetalheVenda show={showDetalheVenda} vendaid={vendaIdDetalhe} onHide={handleCloseDetalheVenda} />

      <ComissaoRecebimentoContext.Provider value={contextvalues}>
        <form className="form">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Comissão | Por Recebimento</label>
          </div>
          <Card.Body>
            <Container>
              <Card>
                <div className="row" style={{ marginLeft: '5px', marginTop: '30px', width: '100%' }}>
                  <div className="col-md-5">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ptBR}
                    >
                      {
                        <DateRangePicker
                          localeText={{ start: 'Data Inicial', end: 'Data Final' }}
                          value={dataIntervaloFiltro}
                          onChange={(newValue) => setDataIntervaloFiltro(newValue)}
                          renderInput={(startProps, endProps) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <input {...startProps.inputProps} />
                              <span> to </span>
                              <input {...endProps.inputProps} />
                            </Box>
                          )}
                        />
                      }
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="filtros">
                  <div className="align-left">
                    <div className="row align-items-start" style={{ marginTop: '20px' }}>
                      <div className="col-lg">
                        <ButtonLojas
                          onClick={handleShowLojas}
                          width={'200px'}
                          lojasFiltro={lojasFiltro}
                        />
                      </div>
                      <div className="col-lg">
                        <ButtonVendedores
                          onClick={handleShowVendedores}
                          width={'200px'}
                          vendedoresFiltro={vendedoresFiltro}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col text-right' >
                  <FormControlLabel
                    control={<Switch checked={considerarAcrescimos.consideraracrescimos} onChange={handleChangeConsiderarAcrescimo} name="consideraracrescimos" />}
                    label={<Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Considerar Acréscimos:
                    </Typography>}
                  />
                </div>
                <div class="row" style={{ marginTop: '0px' }}>
                  <div className='col-2'>
                    <div className="actions">
                      <ButtonExecute
                        width={'200px'}
                        onClick={handleExecutar}
                      />
                    </div>
                  </div>
                  <div className='col' style={{ marginLeft: '35px' }}>
                    <ButtonClear
                      onClick={handleLimparFiltros}
                    />
                  </div>
                  <div className='col-2' >
                    <FormControlLabel
                      control={<Switch checked={exibirGrafico.exibir} onChange={handleChangeExibirGrafico} name="exibir" />}
                      label={<Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Exibir Gráfico:
                      </Typography>}
                    />
                  </div>


                  <div className='col-3 text-right' >
                    <FormControlLabel
                      control={<Switch checked={considerarJurosMultas.considerar} onChange={handleChangeConsiderarJurosMulta} name="considerar" />}
                      label={<Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Considerar Juros/Multa:
                      </Typography>}
                    />
                  </div>

                </div>

              </Card>
            </Container>
          </Card.Body>
        </form>

        <ModalLojas show={showLojas} value={ComissaoRecebimentoContext} onHide={handleCloseLojas} />
        <ModalVendedores show={showVendedores} value={ComissaoRecebimentoContext} onHide={handleCloseVendedores} />
        <ModalImpressaoResumidoDetalhado show={showTipoImpRel} value={ComissaoRecebimentoContext} onHide={handleCloseTipoImpressao} />

        {dadosVenda && dadosVenda.length > 0 && (
          <>
            {exibirGrafico.exibir && (
              <Fade in={exibirGrafico.exibir} style={{ transformOrigin: '0 0 0' }}
                {...(exibirGrafico.exibir ? { timeout: 2200 } : {})}
              >
                <div>
                  <Container >
                    <GraficoBarrasComissaoRecebimento
                      apiData={dadosVenda.filter(p => p.valorcomissao > 0).sort((a, b) => b.valorcomissao - a.valorcomissao)}
                      visao={tipoVisaoSelect}
                    />
                  </Container>
                </div>
              </Fade>
            )}
            
            <Container>
              <CustomStyledDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[PAGE_SIZE]}
                slots={{
                  toolbar: CustomToolbar,
                  pagination: CustomPagination
                }}
              />
            </Container>
          </>
        )}

      </ComissaoRecebimentoContext.Provider>
    </>
  )
}

export default ComissaoRecebimentoProvider;

