import React from 'react';
import EncryptionUtil from '../Utils/EncryptionUtil';

class SessionStorageUtils extends React.Component {
  constructor(props) {
    super(props);   
  }

  GetHeader = async () => {
    const header = sessionStorage.getItem('header');
    const desencryptJSONHeader = new EncryptionUtil();
    const desencryptJSONObj = await desencryptJSONHeader.decryptJSON(header);    
    return desencryptJSONObj;
  }
  
  GetUsuario = async () => {
    const desencryptJSON = new EncryptionUtil();
    const user = await sessionStorage.getItem('user');
    const desencryptJSONUser = await desencryptJSON.decryptJSON(user);
    return desencryptJSONUser;
  }   
}

export default SessionStorageUtils;