import moment from 'moment';
import { getYear } from 'date-fns';

export class ImpressaoCondicionalPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;          
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {       
    const headerCondicionalCliente = [
      { text: 'Cód', bold: true, fontSize: 9, margin: [4, 4, 0, 0] },
      { text: (this.filtros.tipoVisao === 'cliente' ? 'Cliente' : 'Vendedor'), bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},      
      { text: (this.filtros.tipoVisao === 'cliente' ? 'Celular' : ''), bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0] },
      { text: 'Qtde Total',  bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
      { text: 'Valor Total R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
      { text: 'Devolvido', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },        
      { text: 'Valor Devolvido R$', bold: true, fontSize: 9, alignment: 'right', margin: [0, 4, 0, 0] },
      { text: 'Vendido', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
      { text: 'Valor Vendido R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
      { text: 'Pendente', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
      { text: 'Valor Pendente R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
    ];
    return headerCondicionalCliente;     
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }

  GetBody() {        
      const bodyCondicional = this.dadosParaImpressao.map((condi) => {        
          return [
              { text: condi.clienteid, fontSize: 8, margin:[4, 0, 0, 0] },
              { text: this.filtros.tipoVisao === 'cliente' 
                  ? (condi.clientenome + ' ' + condi.clientesobrenome)
                  : (condi.vendedornome), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
              { text: (this.filtros.tipoVisao === 'cliente' ? condi.celular : ''), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
              { text: condi.qtdetotal, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]  },
              { text: condi.valortotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: condi.qtdedevolvidas, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },              
              { text: condi.valordevolvidas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: condi.qtdevendidas, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: condi.valorvendidas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: condi.qtdependentes, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: condi.valorpendentes.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] }             
          ]             
      });    
      return bodyCondicional;
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: 11 
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];         
    content.push(this.GetTotalizadores());         
    return content;
  }     

  GetTotalizadores() {   
    const totalQtde =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdetotal, 0);    
    const totalValor = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valortotal, 0);
    const totalQtdeDevolvidas = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdedevolvidas, 0);
    const totalValorDevolvidas = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valordevolvidas, 0);
    const totalQtdeVendidas = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdevendidas, 0);
    const totalValorVendidas =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorvendidas, 0);
    const totalQtdePendentes =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdependentes, 0);
    const totalValorPendentes =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorpendentes, 0);
    
    const totalRowCondicional = [
      { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },
      { text: ''},                  
      { text: ''},         
      { text: totalQtde, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalQtdeDevolvidas ,bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalValorDevolvidas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalQtdeVendidas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalValorVendidas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalQtdePendentes, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
      { text: totalValorPendentes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
        bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },     
    ];    
    return totalRowCondicional;    
  }  

  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageOrientation: 'landscape',
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE CONDICIONAL', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {       
           text: `\nVisão: ${
             this.primeiraLetraMaiuscula(this.filtros.tipoVisao)
           }` 
             + `  |  Data: ${this.formatarData(this.filtros.dataIntervaloFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloFiltro[1])}`             
             + `  |  Status: ${ this.primeiraLetraMaiuscula(this.filtros.statusFiltro)}`
             + `  |  Lojas: ${this.filtros.lojasSelecionadas}`
             + `  |  Clientes: ${(this.filtros.clientesFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.clientesFiltro))}`
             + `  |  Vendedores: ${(this.filtros.vendedoresFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.vendedoresFiltro))}`,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: [ 25, 135, 65, 60, 60, 50, 80, 50, 80, 50, 80 ],
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '_______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                                                                               © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}