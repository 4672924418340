import React from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoBarrasComissaoVendedor = ({ apiData }) => {

  const chartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
        barHeight: 30
      }
    },
    colors: ['rgba(0, 200, 255)'],
    series: [
      {
        name: 'Valor Comissão',
        data:

          apiData.map((item) => ({
            x: item.vendedor[0].vendedornome,
            y: item.vendedor[0].valorcomissao,

          }))
      },
    ],
    xaxis: {
      categories: apiData.map(item => item.vendedor[0].vendedornome),
      labels: {
        formatter: function (value) {        
          return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        },
      },

    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      style: {
        fontSize: '12px',
        textAlign: 'left',
        width: '200px',
      },
      position: 'topRight',
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const originalValue = series[seriesIndex][dataPointIndex];
        const color = w.config.colors[seriesIndex];

        return '<div class="custom-tooltip">' +
          '<div style="display: flex; align-items: center; padding:4px" >' +
          '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: ' + color + '; margin-right: 5px;"></div>' +
          '<span>' + 'Valor de Comissão Calculada R$: <b>' + originalValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '</b> </span>' +
          '</div>';

      },
    },
    dataLabels: {
      enabled: true,
      distributed: true,
      textAnchor: 'start',
      formatter: function (value) {

        return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },
      style: {
        colors: ['#000000'],
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      show: true,
      showForSingleSeries: true,
      customLegendItems:
        ['R$ Valor Total Comissão'],
      markers: {
        fillColors: ['rgba(0, 200, 255)', '#775DD0']
      }
    },
  };

  return (
    <div>
      <ReactApexChart options={chartOptions} series={chartOptions.series} type="bar" height={100 + ((apiData.length >= 15 ? 15 : apiData.length) * 50)}
      />
    </div>
  );
};

export default GraficoBarrasComissaoVendedor;