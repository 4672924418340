import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Button, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { get } from 'lodash';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableMarcas = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [marcas, setMarcas] = useState([]);  
  const [marcasData, setMarcasData] = useState([]);
  const [selectedMarcas, setSelectedMarcas] = useState([]);
  const [busca, setBusca] = useState('');  
  const pesquisaRef = useRef(null);
  const { definirMarcasSelecionadas } = useContext(contexto); 

  useEffect(() => {    
    const storedSelectedMarcas = sessionStorage.getItem('selectedMarcas');
    if (storedSelectedMarcas) {    
      setSelectedMarcas(JSON.parse(storedSelectedMarcas));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedMarcas.length > 0)
   {
      sessionStorage.setItem('selectedMarcas', JSON.stringify(selectedMarcas));
   }       
  }, [selectedMarcas, marcas]);  

  const handleMarcaSelection = (id) => {
    const updatedIndexes = selectedMarcas.includes(id)
      ? selectedMarcas.filter((index) => index !== id)
      : [...selectedMarcas, id];
    setSelectedMarcas(updatedIndexes);   

    const selectedMarcasIds = updatedIndexes.map((index) => index.id);
    definirMarcasSelecionadas(selectedMarcasIds);
  };

  const handleRemoverMarcasSelecionadasClick = (id) => {
    const updatedMarcasSelecionadas = selectedMarcas.includes(id)
      ? selectedMarcas.filter((index) => index !== id)
      : [...selectedMarcas, id];
    setSelectedMarcas(updatedMarcasSelecionadas);   

    const selectedMarcasIds = updatedMarcasSelecionadas.map((index) => index.id);
    definirMarcasSelecionadas(selectedMarcasIds);

    if (selectedMarcasIds.length === 0)
    {
      sessionStorage.removeItem('selectedMarcas');
    }
  };

  const handleRowClick = (marcadId) => {
    setMarcas((prevMarcas) =>
      prevMarcas.filter((marca) => marca.id !== marcadId)
    );
  };

  const handleRowSelectedClick = (marca) => {

    if (!marcas.includes(marca.id))
    {
        const updatedMarcas = [...marcas, marca];
        setMarcas(updatedMarcas);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedMarcas(marcas);
       setMarcas([]);
       
       const selectedMarcasIds = marcas.map((index) => index.id);
       definirMarcasSelecionadas(selectedMarcasIds);       
       sessionStorage.setItem('selectedMarcas', selectedMarcas);      
  }

  const handleRemoverTodos = async() => {         
      if (!marcasData || marcasData.length === 0)
      {
        getMarcas(); 
      }      
      setMarcas(marcasData);
      setSelectedMarcas([]);    
      definirMarcasSelecionadas([]);         
      sessionStorage.removeItem('selectedMarcas');      
  }

  const getMarcas = async () => {   
    try {
      let url = 'marca';

      if (busca !== '') {
        url = `marca/descricao/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setMarcas(response.data);   
      setMarcasData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getMarcas();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da marca"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>                 
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {marcas.map((marca) => {
                  if (selectedMarcas.some((mrc) => mrc.id === marca.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={marca.id}
                        onClick={() => {
                          handleMarcaSelection(marca);
                          handleRowClick(marca.id);
                        }}
                      >
                        <td>{marca.id}</td>
                        <td>{marca.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedMarcas.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody style={{cursor: 'pointer'}}>
                  {selectedMarcas.map((marca) => (
                    <tr key={marca.id}
                        onClick={() => {
                          handleRemoverMarcasSelecionadasClick(marca);
                          handleRowSelectedClick(marca)
                        }}
                    >
                      <td>{marca.id}</td>
                      <td>{marca.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableMarcas;

