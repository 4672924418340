import moment from 'moment';

export class ImpressaoComprasFornecedorPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {    
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;             
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {  
    if (this.filtros.tipoVisaoSelect === 'compra')
    {
        const headerComprasVisaoCompra = [        
          { text: 'Loja', bold: true, alignment: 'left', fontSize: 9, margin: [4, 4, 0, 0]},                                                          
          { text: 'Emissão', bold: true, alignment: 'center', fontSize: 9, margin: [0, 4, 0, 0]},      
          { text: 'Entrada', bold: true, alignment: 'center', fontSize: 9, margin: [0, 4, 0, 0] },   
          { text: 'Nota F.', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
          { text: 'Fornecedor', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0] },
          { text: 'Qtde Itens', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
          { text: 'Compra', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
          { text: 'Previsão Venda', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] }        
        ];
        return headerComprasVisaoCompra;       
    }     
    else if (this.filtros.tipoVisaoSelect === 'fornecedor')
    {
        const headerComprasVisaoFornecedor = [        
          { text: 'Fornecedor', bold: true, alignment: 'left', fontSize: 9, margin: [4, 4, 0, 0]},                                                          
          { text: 'Qtde Itens', bold: true, alignment: 'center', fontSize: 9, margin: [0, 4, 0, 0]},                
          { text: 'Compra', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
          { text: 'Previsão Venda', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] }        
        ];
        return headerComprasVisaoFornecedor;       
    }      
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2';
  }

  GetBody() {        
    if (this.filtros.tipoVisaoSelect === 'compra')
    {     
          const bodyCompra = this.dadosParaImpressao.map((comp) => {
            return  [
              { text: comp.lojaid, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
              { text:  new Date(comp.dataemissao).toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }) , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: new Date(comp.dataentrada).toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }) ,
                fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},                          
              { text: comp.numero, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: (comp.fornecedorid + '-'+ comp.fornecedornome).substring(0, 29) ,
                fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},
              { text: comp.compraqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.compravalor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.valorprodutos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}             
            ];
          }
        );      
        return bodyCompra;
      }      
      else if (this.filtros.tipoVisaoSelect === 'fornecedor')
      {
          const bodyCompra = this.dadosParaImpressao.map((comp) => {
            return  [
              { text: comp.fornecedorid + ' - ' + comp.fornecedornome, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },             
              { text: comp.compraqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.compravalor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.valorprodutos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) , 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}             
            ];
          }
        );      
        return bodyCompra;
      }
    }    
  
  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: this.filtros.tipoVisaoSelect === 'compra' ? 8 : 4
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];         
    content.push(this.GetTotalizadores());         
    return content;
  }     

  GetTotalizadores() {
    if (this.filtros.tipoVisaoSelect === 'compra')
    {     
      const totalQtdeCompra = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.compraqtde, 0);
      const totalValorCompra = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.compravalor, 0);      
      const totalValorVenda = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.valorprodutos , 0);                
      
      const totalRowContasPagar = [
        { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },           
        { text: ''},                         
        { text: ''},                         
        { text: ''},                         
        { text: ''},                         
        { text: totalQtdeCompra, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },                
        { text: totalValorCompra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },        
        { text: totalValorVenda.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },               
      ];    
      return totalRowContasPagar;     
    }      
    else if (this.filtros.tipoVisaoSelect === 'fornecedor')
    {     
      const totalQtdeCompra = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.compraqtde, 0);
      const totalValorCompra = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.compravalor, 0);      
      const totalValorVenda = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.valorprodutos , 0);                
      
      const totalRowContasPagar = [
        { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },                                      
        { text: totalQtdeCompra, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },                
        { text: totalValorCompra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },        
        { text: totalValorVenda.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },               
      ];    
      return totalRowContasPagar;     
    }      
  }  

  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE COMPRAS - FORNECEDOR', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {                 
            text: `\nVisão: Por ${this.primeiraLetraMaiuscula(this.filtros.tipoVisaoSelect)}`           
               + `  |  Período: ${this.primeiraLetraMaiuscula(this.filtros.periodoDataFiltro === 'emissao' ? 'Emissão' : this.filtros.periodoDataFiltro)} | ${this.formatarData(this.filtros.dataIntervaloCompraFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloCompraFiltro[1])}` 
               + `  |  Lojas: ${this.filtros.lojasFiltro}`              
               + `  |  Fornecedores: ${(this.filtros.fornecedoresFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.fornecedoresFiltro))}`      
               + `   ${(this.filtros.notaFiscalFiltro.length === 0 ? '' : '\nNota Fiscal: ' + this.filtros.notaFiscalFiltro)}`                            
          ,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: this.filtros.tipoVisaoSelect === 'compra'
              ? [ 23, 50, 50, 45, 147, 50, 70, 70 ]
              : [ 300, 58, 90, 90 ],
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}