import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableProfissoes = ({ contexto }) => {
  const [profissoes, setProfissoes] = useState([]);
  const [profissoesData, setProfissoesData] = useState([]);
  const [selectedProfissoes, setSelectedProfissoes] = useState([]);
  const [busca, setBusca] = useState('');
  const pesquisaRef = useRef(null);
  const { definirProfissoesSelecionadas } = useContext(contexto);

  useEffect(() => {
    const storedSelectedProfissoes = sessionStorage.getItem('selectedProfissoes');
    if (storedSelectedProfissoes) {
      setSelectedProfissoes(JSON.parse(storedSelectedProfissoes));
    }
    pesquisaRef.current.focus();
  }, []);

  useEffect(() => {

    if (selectedProfissoes.length > 0) {
      sessionStorage.setItem('selectedProfissoes', JSON.stringify(selectedProfissoes));
    }
  }, [selectedProfissoes, profissoes]);

  const handleProfissaoSelection = (id) => {
    const updatedIndexes = selectedProfissoes.includes(id)
      ? selectedProfissoes.filter((index) => index !== id)
      : [...selectedProfissoes, id];
    setSelectedProfissoes(updatedIndexes);

    const selectedProfissoesIds = updatedIndexes.map((index) => index.id);
    definirProfissoesSelecionadas(selectedProfissoesIds);
  };

  const handleRemoverProfissoesSelecionadasClick = (id) => {
    const updatedProfissoesSelecionadas = selectedProfissoes.includes(id)
      ? selectedProfissoes.filter((index) => index !== id)
      : [...selectedProfissoes, id];
    setSelectedProfissoes(updatedProfissoesSelecionadas);

    const selectedProfissoesIds = updatedProfissoesSelecionadas.map((index) => index.id);
    definirProfissoesSelecionadas(selectedProfissoesIds);

    if (selectedProfissoesIds.length === 0) {
      sessionStorage.removeItem('selectedProfissoes');
    }
  };

  const handleRowClick = (profissaodId) => {
    setProfissoes((prevProfissoes) =>
      prevProfissoes.filter((profissao) => profissao.id !== profissaodId)
    );
  };

  const handleRowSelectedClick = (profissao) => {

    if (!profissoes.includes(profissao.id)) {
      const updatedProfissao = [...profissoes, profissao];
      setProfissoes(updatedProfissao);
    }
  };

  const handleAdicionarTodos = () => {
    setSelectedProfissoes(profissoes);
    setProfissoes([]);

    const selectedProfissoesIds = profissoes.map((index) => index.id);
    definirProfissoesSelecionadas(selectedProfissoesIds);
    sessionStorage.setItem('selectedProfissoes', selectedProfissoes);
  }

  const handleRemoverTodos = async () => {
    if (!profissoesData || profissoesData.length === 0) {
      getProfissoes();
    }
    setProfissoes(profissoesData);
    setSelectedProfissoes([]);
    definirProfissoesSelecionadas([]);
    sessionStorage.removeItem('selectedProfissoes');
  }

  const getProfissoes = async () => {
    try {
      let url = `profissao/listagem/${busca.toUpperCase()}`;
      await SetTenant();
      const response = await api.get(url);
      console.log(response.data);
      const selectedProfissaoIds = selectedProfissoes.map(profissao => profissao.id);
      const filteredData = response.data.filter(profissao => !selectedProfissaoIds.includes(profissao.id));
      setProfissoes(filteredData);
      setProfissoesData(filteredData);
    } catch (e) {
      console.log('erro');
    }
  };

  const handlePesquisar = () => {
    getProfissoes();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }
    }
  }

  return (
    <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
      <Card style={{ border: '0px' }}>
        <div className="align-left">
          <div className="row">
            <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da profissão"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>
          </div>
        </div>
      </Card>

      <Container>
        <div className="col">
          <Card>
            <div className='col'>
              <ButtonAdicionarTodos onClick={handleAdicionarTodos} />
            </div>
          </Card>
          <Card>
            <table className="table table-striped table-hover">
              <thead className="table mt-3">
                <tr>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Cód</th>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {profissoes.map((profissao) => {
                  if (selectedProfissoes.some((mrc) => mrc.id === profissao.id)) {
                    return null;
                  } else {
                    return (
                      <tr
                        key={profissao.id}
                        onClick={() => {
                          handleProfissaoSelection(profissao);
                          handleRowClick(profissao.id);
                        }}
                      >
                        <td>{profissao.id}</td>
                        <td>{profissao.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Card>
        </div>

        <div className="col">
          <Card>
            <Card>
              <div className='row justify-content-end'>
                <div className='col text-left'>
                  <label className='labelSelecionar'>Selecionados: {selectedProfissoes.length}</label>
                </div>
                <div className='col text-right'>
                  <ButtonRemoverTodos onClick={handleRemoverTodos} />
                </div>
              </div>
            </Card>
            <table className="table table-striped table-hover">
              <thead className="table-info mt-3">
                <tr>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Cód</th>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {selectedProfissoes.map((profissao) => (
                  <tr key={profissao.id}
                    onClick={() => {
                      handleRemoverProfissoesSelecionadasClick(profissao);
                      handleRowSelectedClick(profissao)
                    }}
                  >
                    <td>{profissao.id}</td>
                    <td>{profissao.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default TableProfissoes;

