import React from 'react';
import { GridToolbarColumnsButton, GridToolbarFilterButton, 
    GridToolbarDensitySelector, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import ButtonPrint from './ButtonPrint';      
import { GridToolbarExportContainer } from '@mui/x-data-grid';
import ButtonExportExcel from './ButtonExportExcel';

function CustomToolBar({ print, exportExcel }) {
  return (
    <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />   
        <GridToolbarFilterButton />        
        { exportExcel == null ? <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> : null}
        <ButtonPrint onClick={print} />           
        {exportExcel != null  ? <ButtonExportExcel onClick={exportExcel} /> : null }     
    </GridToolbarContainer>
  );
}

export default CustomToolBar;