import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import ButtonConfirmar from '../Buttons/ButtonConfimar';
import 'bootstrap/dist/css/bootstrap.css';
import TableManequimInferior from '../Table/TableManequimInferior';

function ModalManequimInferior(props) {
  const handleClose = () => props.onHide();

  return (
    <Modal onHide={handleClose}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">
          Selecione o Manequim Inferior
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <div className='col'>
            <TableManequimInferior contexto={props.value} />
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ButtonConfirmar onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalManequimInferior;
