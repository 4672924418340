import React from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import TableDetalheCondi from '../Table/TableDetalheCondi'
import 'bootstrap/dist/css/bootstrap.css';

function ModalDetalheCondi(props) {
  const handleClose = () => props.onHide();

  return (    
    <Modal onHide={handleClose}
      {...props} 
      size="xl"
      aria-labelledby="contained-modal-title-xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
           Condicionais : {props.value}
        </Modal.Title>
      </Modal.Header>
        <Modal.Body className="show-grid">
            <Container>
            <div className='col'>                              
                <TableDetalheCondi />
            </div>    
            </Container>
        </Modal.Body>
      <Modal.Footer>        
      </Modal.Footer>
    </Modal>    
  );
}

export default ModalDetalheCondi;
