import moment from 'moment';

export class ImpressaoContasAPagarPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {    
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;             
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {   
    if (this.filtros.statusContasAPagar === 'pendentes')
    {
      const headerContasPagarPendentes = [        
        { text: 'Fornecedor', bold: true, alignment: 'left', fontSize: 9, margin: [4, 4, 0, 0]},                                                          
        { text: 'Conta', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},      
        { text: '', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },   
        { text: '', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Qtde Parcelas', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Valor R$', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
      ];
      return headerContasPagarPendentes;   
    }      
    else if (this.filtros.statusContasAPagar === 'baixados')
    {
      const headerCrediariosBaixados = [
        { text: 'Fornecedor', bold: true, alignment: 'left', fontSize: 9, margin: [4, 4, 0, 0]},                                                          
        { text: 'Conta', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},                           
        { text: '', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },      
        { text: '', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },      
        { text: 'Qtde Parcelas', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },   
        { text: 'Valor Parcela', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] }, 
        { text: 'Desconto', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Acréscimo', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },             
        { text: 'Multa', bold: true, fontSize: 9, alignment: 'right', margin: [0, 4, 0, 0] },
        { text: 'Juro', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
        { text: 'Valor Pago', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0] }, 
      ];
      return headerCrediariosBaixados;   
    }
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }

  GetBody() {        
    if (this.filtros.statusContasAPagar === 'pendentes')
    {
      const bodyContasPagarPendentes = this.dadosParaImpressao.flatMap((array) => {
  
        const fornecedor = array.pagarDetalhes[0].fornecedorid != null ? (array.pagarDetalhes[0].fornecedorid + ' - ' + array.pagarDetalhes[0].fornecedor) : '';
        const conta = array.pagarDetalhes[0].contaid != null ? array.pagarDetalhes[0].contaid + ' - ' + array.pagarDetalhes[0].conta : '';
        
          const contasRows = [
              { text: fornecedor, fontSize: 8, margin:[4, 0, 0, 0] },
              { text: conta, fontSize: 8, margin:[0, 0, 4, 0] },              
              { text: '', fontSize: 8, margin:[0, 0, 4, 0] },              
              { text: '', fontSize: 8, margin:[0, 0, 4, 0] },  
              { text: array.pagarDetalhes.length,  fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},              
              { text: (array.pagarDetalhes.reduce((acc, pag) => acc + pag.valorpendente, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
          ]                                       

        if (this.filtros.tipoImpressao === 'detalhado')
        {
          const detalheRows = [
            {
              table: {
                widths: [30, 60, 60, 60, 60, 235], 
                headerRows: 1, 
                body: [                  
                  [{ text: 'Loja', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                 
                   { text: 'Documento', fontSize: 8, alignment: 'left', fillColor: '#DAEBF2' },
                   { text: 'Emissão', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                   { text: 'Vencimento', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Valor Parcela R$', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Observação', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                  ],
  
                  ...array.pagarDetalhes.map((pagas) => [
                    { text: pagas.lojaid , fontSize: 8, alignment: 'center', margin:[4, 0, 0, 0], border: [] },                                  
                    { text: pagas.documento, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] , border: []},
                    { text: this.formatarData(new Date(pagas.emissao)), fontSize: 8, alignment: 'center', margin:[0, 0, 4, 0] , border: []},                   
                    { text: this.formatarData(new Date( pagas.vencimento)), fontSize: 8, alignment: 'center', margin:[0, 0, 4, 0] , border: []},                   
                    { text: pagas.valorpendente.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                    { text: pagas.observacao, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] , border: []}                   
                  ]),              
                ],
              },
              fontSize: 8,
              colSpan: 6,
              margin: [0, 0, 0, 6], 
            },
          ];
      
          return [contasRows, detalheRows];     
        }
        else
        {
            return [contasRows];
        }        
      });
    
      return bodyContasPagarPendentes;    
    }
    else if (this.filtros.statusContasAPagar === 'baixados')
    {
      const bodyContasPagarBaixadas = this.dadosParaImpressao.flatMap((array) => {
        
        array.pagarMovimentoDetalhes.sort((a, b) => b.datapagto - a.datapagto);

        const fornecedor = array.pagarMovimentoDetalhes[0].fornecedorid != null ? (array.pagarMovimentoDetalhes[0].fornecedorid + ' - ' + array.pagarMovimentoDetalhes[0].fornecedor) : '';
        const conta = array.pagarMovimentoDetalhes[0].contaid != null ? array.pagarMovimentoDetalhes[0].contaid + ' - ' + array.pagarMovimentoDetalhes[0].conta : '';
        
          const contasRows = [
              { text: fornecedor, fontSize: 8, margin:[4, 0, 0, 0] },
              { text: conta, fontSize: 8, margin:[0, 0, 4, 0] },                                       
              { text: '', fontSize: 8, margin:[0, 0, 4, 0] },     
              { text: '', fontSize: 8, margin:[0, 0, 4, 0] },                                 
              { text: array.pagarMovimentoDetalhes.length,  fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},              
              { text: (array.pagarMovimentoDetalhes.reduce((acc, pag) => acc + pag.valorparcela, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
              { text: (array.pagarMovimentoDetalhes.reduce((acc, pag) => acc + pag.desconto, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
              { text: (array.pagarMovimentoDetalhes.reduce((acc, pag) => acc + pag.acrescimo, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
              { text: (array.pagarMovimentoDetalhes.reduce((acc, pag) => acc + pag.multa, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
              { text: (array.pagarMovimentoDetalhes.reduce((acc, pag) => acc + pag.juro, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
              { text: (array.pagarMovimentoDetalhes.reduce((acc, pag) => acc + pag.valorpgto, 0)
                 .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                        
          ]                                       

        if (this.filtros.tipoImpressao === 'detalhado')
        {
          const detalheRows = [
            {
              table: {
                widths: [25, 42, 47, 47, 47, 45, 40, 40, 40, 40, 47], 
                headerRows: 1, 
                body: [                  
                  [{ text: 'Lj / Lj Baixa', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Documento', fontSize: 8, alignment: 'left', fillColor: '#DAEBF2' },
                   { text: 'Emissão', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },
                   { text: 'Vencimento', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Pagamento', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Valor Parcela', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Desc', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Acrés.', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Multa', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Juro', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' },                                    
                   { text: 'Valor Pago', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2' }
                  ],
  
                  ...array.pagarMovimentoDetalhes.map((pagas) => [
                    { text: (pagas.lojaorigem + '/' +  pagas.lojabaixa) , fontSize: 8, alignment: 'center', margin:[4, 0, 0, 0], border: [] },                                                      
                    { text: pagas.documento, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0], border: [] },
                    { text: this.formatarData(new Date(pagas.emissao)), fontSize: 8, alignment: 'center', margin:[0, 0, 4, 0] , border: []},                   
                    { text: this.formatarData(new Date( pagas.vencimento)), fontSize: 8, alignment: 'center', margin:[0, 0, 4, 0], border: [] },                   
                    { text: this.formatarData(new Date( pagas.datapagto)), fontSize: 8, alignment: 'center', margin:[0, 0, 4, 0] , border: []},                   
                    { text: pagas.valorparcela.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                    { text: pagas.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                    { text: pagas.acrescimo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                    { text: pagas.multa.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                    { text: pagas.juro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                    { text: pagas.valorpgto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                                       
                  ]),              
                ],
              },
              fontSize: 8,
              colSpan: 11,
              margin: [0, 0, 0, 6], 
            },
          ];
      
          return [contasRows, detalheRows];     
        }
        else
        {
            return [contasRows];
        }        
      });
    
      return bodyContasPagarBaixadas;       
    }      
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan:  this.filtros.statusContasAPagar === 'pendentes' ? 6 : 11
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];         
    content.push(this.GetTotalizadores());         
    return content;
  }     

  GetTotalizadores() {
    if (this.filtros.statusContasAPagar === 'pendentes')
    {
        const totalQtdeParcelas =  this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarDetalhes.length, 0);    
        const totalValorParcela = this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarDetalhes.reduce((acc, pagar) => acc + pagar.valorpendente, 0), 0);           
    
        const totalRowCrediarios = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },
          { text: ''},          
          { text: ''},
          { text: ''},      
          { text: totalQtdeParcelas, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: totalValorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  }       
        ];    
        return totalRowCrediarios;      
    }

    const totalQtdeParcelas =  this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.length, 0);    
    const totalValorParcela = this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.reduce((acc, pagmov) => acc + pagmov.valorparcela, 0), 0);
    const totalDesconto = this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.reduce((acc, pagmov) => acc + pagmov.desconto, 0), 0);
    const totalAcrescimo = this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.reduce((acc, pagmov) => acc + pagmov.acrescimo, 0), 0); 
    const totalMulta = this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.reduce((acc, pagmov) => acc + pagmov.multa, 0), 0); 
    const totalJuro = this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.reduce((acc, pagmov) => acc + pagmov.juro, 0), 0); 
    const totalValorPago =  this.dadosParaImpressao.reduce((acc, pag) => acc + pag.pagarMovimentoDetalhes.reduce((acc, pagmov) => acc + pagmov.valorpgto, 0), 0);       
    
    const totalRowContasPagar = [
      { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },                      
      { text: ''},      
      { text: ''},      
      { text: ''},      
      { text: totalQtdeParcelas, bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
      { text: totalValorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
        bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
      { text: totalDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
        bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
      { text: totalAcrescimo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
        bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
      { text: totalMulta.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
        bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
      { text: totalJuro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
        bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
      { text: totalValorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
        bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  }      
    ];    
    return totalRowContasPagar;    
  }  

  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE CONTAS A PAGAR', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {               
           text: `\nVisão: : ${this.primeiraLetraMaiuscula(this.filtros.tipoVisaoSelect)} |  Status: ${
             this.primeiraLetraMaiuscula(this.filtros.statusContasAPagar)
           }` 
             + `  |  Data: ${this.formatarData(this.filtros.dataIntervaloFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloFiltro[1])}`
             + `  |  Período: ${this.filtros.periodoDataFiltro === 'emissao' ? 'Emissão' : this.primeiraLetraMaiuscula(this.filtros.periodoDataFiltro)}`             
             + `  |  Lojas Emissão: ${this.filtros.lojasEmissaoSelecionadas}`
             + `${this.filtros.lojasPagamentoSelecionadas.length === 0 ? '' : ('\nLojas Pagamento: ' + this.OrdenarVetor(this.filtros.lojasPagamentoSelecionadas))}`
             + `\nFornecedores: ${(this.filtros.fornecedoresFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.fornecedoresFiltro))}`      
             + `  |  Contas: ${(this.filtros.contasFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.contasFiltro))}`
             + `${this.filtros.documentoFiltro !== '' ? ('  |  Documento: ' + this.filtros.documentoFiltro) : ''}`,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: this.filtros.statusContasAPagar === 'pendentes' 
              ? [ 180, 180, 0, 0, 80, 80 ] 
              : [ 70, 70, 0, 0, 55, 55, 50, 50, 36, 36, 58 ],
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}