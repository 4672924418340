import  React , { useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useState, createContext} from 'react';
import { Container, Pagination, Radio, Typography } from '@mui/material';
import {   
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
  } from '@mui/x-data-grid';
import { api } from '../../services/api';
import { Card, Button } from 'react-bootstrap';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalClientes from '../../components/Modal/ModalClientes';
import ModalVendedores from '../../components/Modal/ModalVendedores';
import ModalDetalheCondi from '../../components/Modal/ModalDetalheCondi';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import { ImpressaoCondicionalPDF } from '../../components/Impressao/ImpressaoCondicionalPDF';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import ButtonClientes from '../../components/Buttons/ButtonClientes';
import ButtonVendedores from '../../components/Buttons/ButtonVendedores';
import LoadingScreen from '../../components/Loading/TelaLoading';
import '../../pages/Condicional/styles.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ptBR } from 'date-fns/locale';
import { startOfMonth } from 'date-fns';
import PaginationItem from '@mui/material/PaginationItem';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import ModalMensagemUsuarioSemAcesso from '../../components/Modal/MensagensAlerta/ModalMensagemUsuarioSemAcesso';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const CondicionalContext = createContext();

  export const CondicionalProvider = () => {
   const sessionStorageUtils = new SessionStorageUtils();  
   const permissao = new Permissoes();   
   const PAGE_SIZE = 50;
   const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0,});   
   const [nomeGrupo, setNomeGrupo] = useState([]);
   const [dadosCondicional, setDadosCondicional] = useState([]);      
   const [showLojas, setShowLojas] = useState(false);   
   const [showClientes, setShowClientes] = useState(false);
   const [showVendedores, setShowVendedores] = useState(false);
   const [showDetalheCondi, setShowDetalheCondi] = useState(false);
   const [statusFiltro, setStatusFiltro] = useState('Pendentes');
   const [lojasFiltro, setLojasFiltro] = useState([]);     
   const [clientesFiltro, setClientesFiltro] = useState([]);
   const [vendedoresFiltro, setVendedoresFiltro] = useState([]);      
   const [tipoVisaoSelect, setTipoVisaoSelect] = useState('cliente');   
   const [clienteDetalhe, setClienteDetalhe] = useState('');  
   const [clienteDetalheNome, setClienteDetalheNome] = useState('');
   const [loading, setLoading] = useState(true);      
   const [limparFiltros, SetLimparFiltros] = useState(false);
   const [showUsuarioSemAcesso, SetShowUsuarioSemAcesso] = useState(false);
   const navigate = useNavigate();
   const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([
    startOfMonth(new Date()),
    new Date()
  ]);

   useEffect(() => {
     setTimeout(() => { 
        setLoading(true);
        if (ValidarPermissoes() == false) { return; }                     
        LimparFiltrosSelecionados();    
        definirLojaInicial();  
        DefinirNomeGrupo();
        setLoading(false);     
        }, 500);             
    }, []);      
        
    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('49c721cdf04d3a6f9721cdf073562527');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  

  const handleCloseUsuarioSemAcesso = () => {
    SetShowUsuarioSemAcesso(false);
}
    
    useEffect(() => {        
        if (limparFiltros)
        {
            definirLojaInicial();            
            SetLimparFiltros(false);
        }        
    }, [limparFiltros]);

    const handleLimparFiltros = async () => {   
        resetarDataInicial();
        LimparFiltrosSelecionados();                                
        SetLimparFiltros(true);
    }

    const resetarDataInicial = () => {
        setDataIntervaloFiltro([
          startOfMonth(new Date()),
          new Date()
        ]);
      };
    
    function LimparFiltrosSelecionados() {
        sessionStorage.removeItem('selectedLojas');
        sessionStorage.removeItem('selectedClientes');
        sessionStorage.removeItem('selectedVendedores');
        setLojasFiltro([]);
        setClientesFiltro([]);
        setVendedoresFiltro([]);        
        setDadosCondicional([]);  
    }
        
    const DefinirNomeGrupo = async () => {
        const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
        const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
        setNomeGrupo(groupName);
      }

    const definirLojaInicial = async()  => 
    {
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
        {          
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant();
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;             
            await setLojasFiltro(firstId);               
        }          
    }

    const totalizador = dadosCondicional.reduce((total, obj) => {       
        total.qtdetotal += obj.qtdetotal;
        total.valortotal += obj.valortotal;        
        total.qtdedevolvidas += obj.qtdedevolvidas; 
        total.valordevolvidas += obj.valordevolvidas; 
        total.qtdevendidas += obj.qtdevendidas;             
        total.valorvendidas += obj.valorvendidas; 
        total.qtdependentes += obj.qtdependentes; 
        total.valorpendentes += obj.valorpendentes; 
        return total;             
    }, { qtdetotal: 0, valortotal: 0, qtdedevolvidas: 0, valordevolvidas: 0, qtdevendidas : 0, valorvendidas: 0, qtdependentes : 0, valorpendentes: 0});

    const renderTable = () => {       
          return (
            <table class="table">              
            <thead>                        
                <th scope="col" style={{width: '400px', fontSize: '20px'}}></th>                   
                <th scope="col" className="text-left" style={{width: '76px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '113px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '74px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '113px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '74px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '113px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '72px', fontSize: '13px'}}></th>
                <th scope="col" className="text-right" style={{width: '113px', fontSize: '13px'}}></th>                        
            </thead>
            <tbody>
                <tr >
                <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2'}}>Total</th>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                    {!isEmpty(totalizador) ? totalizador.qtdetotal : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valortotal : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                    {!isEmpty(totalizador) ? totalizador.qtdedevolvidas : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valordevolvidas : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                    {!isEmpty(totalizador) ? totalizador.qtdevendidas : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorvendidas : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                    {!isEmpty(totalizador) ? totalizador.qtdependentes : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc'}}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorpendentes : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                </tr>                                    
            </tbody>
            </table> 
          );        
    }

    function CustomPagination() {        
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
        const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
      
        return (
            <>
            <div>           
                <div className='table-align'>
                    {renderTable()}
                </div>                        
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            Total de registros: {totalRowCount}
                        </Typography>
                    </div>

                    <div style={{marginLeft: '50px'}}>
                        <Typography variant="subtitle2" color="textSecondary" >
                            Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                        </Typography>
                    </div>
                </div>
                    
                    <div style={{ alignSelf: 'flex-end'}}>
                        <Pagination
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            page={page + 1}
                            count={pageCount}                                                    
                            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                            onChange={(event, value) => apiRef.current.setPage(value - 1)}
                        />
                    </div>
                </div>            
            </div>
         
          </>
          );        
      }

   const handleCloseLojas = () => 
   {        
        setShowLojas(false);                           
   }

   const handleShowLojas = () => 
   {            
        setShowLojas(true);
   }   

   const handleShowVendedores = () => 
   {
        setShowVendedores(true);
   }

   const handleCloseClientes = () => 
   {        
        setShowClientes(false);                           
   }

   const handleCloseVendedores = () => 
   {
        setShowVendedores(false);
   }

   const handleCloseDetalheCondi = () => 
   {
        setShowDetalheCondi(false);
   }

   const handleShowClientes = () => 
   {            
        setShowClientes(true);
   }           

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }    

    const definirClientesSelecionados = (id) => 
    {
        setClientesFiltro(id);        
    }

    const definirVendedoresSelecionados = (id) =>
    {
        setVendedoresFiltro(id);
    }

    const handleExecutar = async() => {     
        setLoading(true);                                
        await getCondicional();                             
        setLoading(false);
    }         

    const tipoVisaoFiltroToInt = () => {
        const enumValues = {
          'cliente' : 1,
          'vendedor': 2,                    
        };
      
        return enumValues;
      };

    const tipoVisaoToInt = tipoVisaoFiltroToInt();

    const filtrosSelecionados = {
        tipoVisao : tipoVisaoSelect,
        lojasSelecionadas : lojasFiltro,
        dataIntervaloFiltro : dataIntervaloFiltro,
        statusFiltro : statusFiltro,
        clientesFiltro : clientesFiltro,
        vendedoresFiltro : vendedoresFiltro,
        nomeGrupo : nomeGrupo
    }  

    const visualizarImpressao = async  () => {        
        const classeImpressao = new ImpressaoCondicionalPDF(dadosCondicional, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }  
    
    const getCondicional = async() => {                  
        try 
        {                        
            const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
            const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);               

            let url = `condicional/relatorio/${statusFiltro}/${tipoVisaoToInt[tipoVisaoSelect]}/${dataIni}/${dataFin}/${lojasFiltro}`;
            
            if (clientesFiltro.length > 0) {                
                url += `/${clientesFiltro}`;
            } else {
                url += "/ ";
            }
            
            if (vendedoresFiltro.length > 0) {
                url += `/${vendedoresFiltro}`;
            } else {
                url += "/ ";
            }            

            setDadosCondicional([]);       
            await SetTenant();                
            const response = await api.get(url);                                                               
            setDadosCondicional(response.data);                         
        }
        catch (e)
        {
            console.log('erro');
        }        
    }           
    
    const columnsVendedor = [
        {field: "expandir", headerName: "", headerClassName: 'blue-header', align: 'center', width: 2, cellClassName: () => {return 'blue-cell'}},    
        {field: "id", headerName: "Cód",  headerClassName: 'blue-header', width: 15 },
        {field: "vendedor", headerName: "Vendedor",  headerClassName: 'blue-header', width: 302 },
        {field: "qtdetotal", headerName: "Qtde Total",  headerClassName: 'blue-header', width: 77, align: 'right'},           
        {field: "valortotal", headerName:"Valor Total R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 112, align: 'right'},        
        {field: "qtdedevolvidas", headerName:"Devolvido",   headerClassName: 'blue-header', width: 76, align: 'right', cellClassName: () => {return 'blue-cell'}},            
        {field: "valordevolvidas", headerName:"Valor R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 112, align: 'right', cellClassName: () => {return 'blue-cell'}},  
        {field: "qtdevendidas", headerName:"Vendido",  headerClassName: 'blue-header', width: 73, align: 'right'},            
        {field: "valorvendidas", headerName:"Valor R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 112, align: 'right'}, 
        {field: "qtdependentes", headerName:"Pendente",  headerClassName: 'blue-header', width: 73, align: 'right', cellClassName: () => {return 'blue-cell'}},            
        {field: "valorpendentes", headerName:"Valor R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 112, align: 'right', cellClassName: () => {return 'blue-cell'}},           
    ];  

    const rowsVendedor = dadosCondicional.map((row) => ({        
        id: row.funcionarioid,
        vendedor: row.vendedornome,
        qtdetotal: (row.qtdetotal),
        valortotal: (row.valortotal)
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        qtdedevolvidas : row.qtdedevolvidas,
        valordevolvidas : row.valordevolvidas
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        qtdevendidas : row.qtdevendidas,
        valorvendidas : row.valorvendidas
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        qtdependentes: row.qtdependentes,
        valorpendentes: (row.valorpendentes)
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
      }));
    
    const columnsCliente = [      
        {field: "expandir", headerName: "", headerClassName: 'blue-header', align: 'center', width: 2 , cellClassName: () => {return 'blue-cell-bold'}},   
        {field: "id", headerName:"Cód",  headerClassName: 'blue-header', width: 15},             
        {field: "cliente", headerName:"Cliente", headerClassName: 'blue-header', width: 172},             
        {field: "celular", headerName:"Celular", headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right'},
        {field: "qtdetotal", headerName:"Qtde Total", headerClassName: 'blue-header', width: 77, align: 'right'},            
        {field: "valortotal", headerName:"Valor Total R$", headerClassName: 'blue-header', headerAlign: 'right', headerStyle: {textAlign: 'right'}, width: 112, align: 'right'},        
        {field: "qtdedevolvidas", headerName:"Devolvido", headerClassName: 'blue-header', width: 76, align: 'right', cellClassName: () => {return 'blue-cell'}},            
        {field: "valordevolvidas", headerName:"Valor R$", headerClassName: 'blue-header', headerAlign: 'right', width: 112, align: 'right', cellClassName: () => {return 'blue-cell'}},  
        {field: "qtdevendidas", headerName:"Vendido", headerClassName: 'blue-header',width: 73, align: 'right'},            
        {field: "valorvendidas", headerName:"Valor R$", headerClassName: 'blue-header', headerAlign: 'right', width: 112, align: 'right'}, 
        {field: "qtdependentes", headerName:"Pendente", headerClassName: 'blue-header', width: 73, align: 'right',cellClassName: () => {return 'blue-cell'}},            
        {field: "valorpendentes", headerName:"Valor R$", headerClassName: 'blue-header', headerAlign: 'right', width: 112, align: 'right', cellClassName: () => {return 'blue-cell'}},           
    ];           

    const rowsCliente = dadosCondicional.map((row) => ({
        expandir : "↓",
        id: row.clienteid,               
        cliente : row.clientenome + ' ' + row.clientesobrenome,
        celular : row.celular,
        qtdetotal: (row.qtdetotal),
        valortotal: (row.valortotal)
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        qtdedevolvidas : row.qtdedevolvidas,
        valordevolvidas : row.valordevolvidas
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        qtdevendidas : row.qtdevendidas,
        valorvendidas : row.valorvendidas
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        qtdependentes: row.qtdependentes,
        valorpendentes: (row.valorpendentes)
            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
        }))      
     
    const handleTipoFitro = (e) => 
    {
        setStatusFiltro(e.target.value);        
    }   

    function CustomToolbar() {
        return (
          <CustomToolBar print={visualizarImpressao}/>         
        );
      }       

    const contextValues = {
        definirLojasSelecionadas,
        definirClientesSelecionados,
        definirVendedoresSelecionados,
        lojasFiltro,
        clientesFiltro,
        vendedoresFiltro,
        tipoVisaoSelect: tipoVisaoSelect,
        dataIntervaloFiltro,        
        clienteDetalhe,
        tipoFiltro: statusFiltro,
        clienteDetalheNome
      };     

      const handleRowClick = async(params) => { 
        switch (tipoVisaoToInt[tipoVisaoSelect])
        {
            case 1: //Cliente
            {
                setClienteDetalhe(params.row.id);    
                setClienteDetalheNome(params.row.cliente);                
                setShowDetalheCondi(true);       
                break;
            }                         
            default : return;               
        }                                           
      };                         

        const headerClassName = 'blue-header';

        if (loading) {
            return (
               <LoadingScreen/>
            );
          }

       return (          
        <>
        <ModalMensagemUsuarioSemAcesso show={showUsuarioSemAcesso} onHide={handleCloseUsuarioSemAcesso}/>
        
        <CondicionalContext.Provider value={contextValues}>            
            <form className="form"> 
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Condicional</label>
            </div>                                  
                <Card.Body>                                    
                    <Container>      
                    <Card>  
                    <div className='align-left'>                                                               
                        <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>
                        <Card style={{width: 482}}>
                            <div className="row">                     
                                <div className="col-md-5">
                                    <div className="form-check">                               
                                        <Radio
                                            checked={tipoVisaoSelect === 'cliente'}
                                            onChange={() => {
                                                setTipoVisaoSelect('cliente'); 
                                                setDadosCondicional([]);                                            
                                            }}                 
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'A' }}
                                            size="small"
                                            id="clienteRadio" 
                                        />   
                                        <label class="form-check-label" for="clienteRadio" style={{cursor: 'pointer'}}>
                                            Cliente
                                        </label>
                                    </div>
                                </div>                          
                                <div className="col">                                                                       
                                    <Radio
                                        checked={tipoVisaoSelect === 'vendedor'}
                                        onChange={() => {
                                            setTipoVisaoSelect('vendedor'); 
                                            setDadosCondicional([]);                                         
                                        } }                  
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        size="small"
                                        id="vendedorRadio" 
                                    />   
                                    <label class="form-check-label" for="vendedorRadio" style={{cursor: 'pointer'}}>
                                        Vendedor
                                    </label>                                    
                                </div>                                               
                            </div>  
                        </Card>               
                    </div>  
                    </Card>
                    </Container>

                    <Container>
                        <Card>                            
                        <div className="d-flex align-items-center">
                            <div className="btnstatus">
                                <label className="text"  style={{ fontWeight: 'bold' }}>Status</label>
                                <select className="form-select" id="inputGroupSelect01" value={statusFiltro} onChange={handleTipoFitro}>
                                <option selected>Pendentes</option>
                                <option>Todos</option>
                                <option>Devolvidos</option>
                                <option>Vendidos</option>
                                </select>
                            </div>

                            <Container>
                                <div className="row" style={{ marginTop: '30px', width: '408px' }}>
                                    <div className="col-md">
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>                                                                                                                       
                                            <DateRangePicker                             
                                                localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                                value={dataIntervaloFiltro}      
                                                onChange={(newValue) => setDataIntervaloFiltro(newValue)}                        
                                            />                                                     
                                        </LocalizationProvider> 
                                    </div>
                                </div>
                            </Container>
                            </div>                       

                            <div className="filtros">
                            <div className="align-left">
                                <div className="row align-items-start" style={{ marginTop: '20px'}}>
                                    <div className="col-lg">

                                    <ButtonLojas
                                        onClick={handleShowLojas}
                                        width={'182px'}                                  
                                        lojasFiltro={lojasFiltro}
                                    />    
                                   </div>                                                                                 

                                    <div className="col-lg">
                                    <ButtonClientes                                        
                                        onClick={handleShowClientes}
                                        width={'182px'}
                                        clientesFiltro={clientesFiltro}
                                    />                                    
                                    </div>                               
                                    <div className="col-lg">
                                    <ButtonVendedores
                                        onClick={handleShowVendedores}
                                        width={'182px'}
                                        vendedoresFiltro={vendedoresFiltro}
                                    />                                        
                                    </div>                                                               
                                </div>                            
                            </div>
                        </div>        

                        <div class="row" style={{marginTop: '30px'}}>
                            <div className='col-2'>
                                <div className="actions">
                                <ButtonExecute 
                                    width={'182px'}
                                    onClick={handleExecutar}
                                />   
                                </div>                    
                            </div>
                            <div className='col-8' style={{marginLeft: '15px'}}>
                                <ButtonClear                                     
                                    onClick={handleLimparFiltros}
                                />   
                            </div>
                        </div>                                            
                        </Card>
                    </Container>                 
                </Card.Body>                     
            </form>     
                
            <ModalLojas show={showLojas} value={CondicionalContext} onHide={handleCloseLojas}/>                      
            <ModalClientes show={showClientes} value={CondicionalContext} onHide={handleCloseClientes}/>
            <ModalVendedores show={showVendedores} value={CondicionalContext} onHide={handleCloseVendedores}/>          
            <ModalDetalheCondi show={showDetalheCondi} onHide={handleCloseDetalheCondi} value={clienteDetalheNome}/>

            {dadosCondicional && dadosCondicional.length > 0 && (                        
            <Container>                                          
                <CustomStyledDataGrid
                    headerClassName={headerClassName}                                                                        
                    rows={tipoVisaoSelect === 'vendedor'
                            ? [...rowsVendedor]
                            : tipoVisaoSelect === 'cliente'
                            ? rowsCliente
                            : []}                    
                    columns={tipoVisaoSelect === 'vendedor'
                            ? columnsVendedor
                            : tipoVisaoSelect === 'cliente'
                            ? columnsCliente
                            : []}                                                                                                   
                    onRowClick={handleRowClick}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}     
                    slots={{
                        toolbar: CustomToolbar,
                        pagination: CustomPagination                   
                    }}                                                                                                                     
                                                   
                    />                                                                        
            </Container>  
            )}          
        </CondicionalContext.Provider>
        </>
       );         
}

export default CondicionalProvider;
