import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonExecute({ onClick, width, refFilter }) {
  return (
    <Button style={{         
       backgroundColor: 'rgba(57, 142, 30, 1)',                
        border : '0px',
        width: width,
        height: '30px',  
        margin: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',              
        borderRadius: '15px'
        }} 
    onClick={onClick}
    ref={refFilter}
    ><CheckOutlined style={{marginRight: '5px'}}/> Executar
  </Button>
  );
}

export default ButtonExecute;