import { isEmpty } from 'lodash';
import moment from 'moment';

export class ImpressaoClientesPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;    
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {
    if (this.filtros.tipoVisao === 'VENDA')
    {
      const header = [
        { text: 'Lj', bold: true,  alignment: 'left', fontSize: 9, margin: [4, 4, 4, 4] },
        { text: 'Últ. Venda', bold: true,  alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Cliente', bold: true,  alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Sexo', bold: true,  alignment: 'left', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Nascimento', bold: true,   alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Qtde Vendas', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Qtde Itens', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Total Vendas R$', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Valor Médio R$', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
        { text: 'Celular', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
      ];
      return header;
    }    
    else if (this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect === 'CLIENTE')
    {
      const header = [
        { text: this.filtros.tipoCadastroSelect === 'CLIENTE' ? 'Cliente' : this.filtros.tipoCadastroSelect === 'DEPENDENTE' ? 'Dependente' : 'Cliente/Dependente', bold: true, fontSize: 9,  margin: [4, 4, 4, 4]  },
        { text: 'Sexo', bold: true, fontSize: 9,  margin: [0, 4, 4, 4] },
        { text: 'Nascimento', bold: true, fontSize: 9, margin: [0, 4, 4, 4] },          
        { text: 'Man. Sup', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4] },
        { text: 'Man. Inf', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4] },
        { text: 'Man. Calç', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4] },        
        { text: 'Celular', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4] },      
      ];
      return header;
    }
    else if (this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect !== 'CLIENTE')
      {
        const header = [
          { text: this.filtros.tipoCadastroSelect === 'CLIENTE' ? 'Cliente' : this.filtros.tipoCadastroSelect === 'DEPENDENTE' ? 'Dependente' : 'Cliente/Dependente', bold: true, fontSize: 9,  margin: [4, 4, 4, 4]  },
          { text: 'Sexo', bold: true, fontSize: 9,  margin: [0, 4, 4, 4]  },
          { text: 'Nascimento', bold: true, fontSize: 9, margin: [0, 4, 4, 4]  },          
          { text: 'Man. Sup', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
          { text: 'Man. Inf', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
          { text: 'Man. Calç', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
          { text: 'Parentesco', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
          { text: 'Cliente', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 4, 4]  },      
          { text: 'Celular', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
        ];
        return header;
      }
    else
    {
      const header = [
        { text: 'Lj', bold: true, fontSize: 9,  margin: [4, 4, 4, 4]  },
        { text: 'Última Venda', bold: true, fontSize: 9,  margin: [4, 4, 4, 4]  },
        { text: 'Cliente/Dependente', bold: true, fontSize: 9,  margin: [4, 4, 4, 4]  },
        { text: 'Sexo', bold: true, fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Nascimento', bold: true, fontSize: 9, margin: [0, 4, 4, 4]  },          
        { text: 'Man. Sup', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Man. Inf', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Man. Calç', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Parent.', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
        { text: 'Cliente', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 4, 4]  },      
        { text: 'Celular', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
      ];
      return header;
    }
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }

  GetBody() {   
    if (this.filtros.tipoVisao === 'VENDA')  
    {
        const bodyProduto = this.dadosParaImpressao.map((cli) => {
          return  [
            { text: cli.lojavendaid !== 0 ? cli.lojavendaid : '', fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: this.formatarData(new Date(cli.ultimavenda)) , fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: cli.nome.substring(0, 23), fontSize: 8, alignment: 'left',  margin:[0, 0, 0, 0] },
            { text: cli.sexo, fontSize: 8, alignment: 'left', margin:[0, 0, 0, 0]},    
            { text: (!isEmpty(cli.nascimento) ? this.formatarData(new Date(cli.nascimento)) : ''), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},       
            { text: cli.qtdevendas, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: cli.qtdeitens, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},            
            { text: cli.valortotalvenda.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: cli.valormedio.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
            { text: cli.celular, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]}
          ];
        }
      );      
      return bodyProduto;
    }
    else if (this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect === 'CLIENTE')  
    {    
      const bodyItemMarca = this.dadosParaImpressao.map((cli) => {    
          return  [
            { text: cli.nome, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: cli.sexo, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: (!isEmpty(cli.nascimento) ? this.formatarData(new Date(cli.nascimento)) : ''), alignment: 'left', fontSize: 8, },                          
            { text: cli.roupa, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: cli.roupainferior,
              fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: cli.calcado, 
              fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},           
            { text: cli.celular, 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
          ];
        }
      );     
      return bodyItemMarca; 
    }
    else if (this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS'  && this.filtros.tipoCadastroSelect !== 'CLIENTE')  
      {    
        const bodyItemMarca = this.dadosParaImpressao.map((cli) => {    
            return  [
              { text: cli.nome, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
              { text: cli.sexo, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
              { text: (!isEmpty(cli.nascimento) ? this.formatarData(new Date(cli.nascimento)) : ''), alignment: 'left', fontSize: 8, },                          
              { text: cli.roupa, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: cli.roupainferior,
                fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
              { text: cli.calcado, 
                fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
              { text: cli.parentesco, 
                fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},
              { text: !isEmpty(cli.nomeparente) ? cli.nomeparente.substring(0, 40) : '', 
                fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},
              { text: cli.celular, 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
            ];
          }
        );     
        return bodyItemMarca; 
      }
    else 
    {    
      const bodyItemMarca = this.dadosParaImpressao.map((cli) => {    
          return  [
            { text: cli.lojavendaid !== 0 ? cli.lojavendaid : '', fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: (!isEmpty(cli.ultimavenda) ? this.formatarData(new Date(cli.ultimavenda)) : '') , fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: cli.nome.substring(0, 30), fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: cli.sexo, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: (!isEmpty(cli.nascimento) ? this.formatarData(new Date(cli.nascimento)) : ''), alignment: 'left', fontSize: 8, },                          
            { text: cli.roupa, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: cli.roupainferior,
              fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: cli.calcado, 
              fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: cli.parentesco, 
              fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},
            { text:  !isEmpty(cli.nomeparente) ? cli.nomeparente.substring(0, 40) : '', 
              fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},
            { text: cli.celular, 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
          ];
        }
      );     
      return bodyItemMarca; 
    }
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
          '__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        alignment: 'center',
        fontSize: 5,
        colSpan: this.filtros.tipoVisao === 'VENDA' 
          ? 10 
          : (this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect === 'CLIENTE') 
            ? 7 
              : (this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect !== 'CLIENTE') 
                ? 9 
            : 11
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];       
    if (this.filtros.tipoVisao === 'VENDA')
    {
        content.push(this.GetTotalizadores());
    }      
    return content;
  }

  GetTotalizadores() {       
    const qtdeitens = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdeitens, 0);
    const qtdevendas = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdevendas, 0);
    const valortotalvenda = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valortotalvenda, 0);
    const valormedio = valortotalvenda / qtdevendas;

      const totalRowProduto = [
        { text: '' },      
        { text: 'Total', bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 4, 0]},
        { text: ''},  
        { text: ''},
        { text: ''},                 
        { text: qtdevendas, 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
        { text: qtdeitens, 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
        { text: valortotalvenda
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
        { text: valormedio
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },             
        { text: ''},  
      ];    
      return totalRowProduto;            
  }

  OrdenarVetor(vetor) {
     return vetor.sort((x,y) => {
      if (x > y) return 1;
      if (x < y) return - 1;
        return 0
      } 
    ).join(', ')
  }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,
        fontSize: 10,        
        alignment: 'left'    
      },

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      },

      saldoNegativo: {        
        color : 'red',
        alignment : 'right',
        fontSize  : 10
      }
    }   

    const documento = {
      pageSize: 'A4',
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 0],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: `RELATÓRIO DE CLIENTE\n`, 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito',
        },
        {
          text: 
          this.filtros.tipoVisao === 'VENDA' ?
            `\nVisão: ` + this.primeiraLetraMaiuscula(this.filtros.tipoVisao) +
            `  |  Análise: ` + (this.filtros.tipoAnaliseVenda === 'VENDASNOPERIODO' ? 'VENDAS NO PERÍODO' : this.filtros.tipoAnaliseVenda === 'ULTIMAVENDA' ? 'ÚLTIMA VENDA' : '') +
            `  |  Período: ${this.formatarData(this.filtros.dataIntervaloFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloFiltro[1])}`  +
            `  |  Lojas: ${this.filtros.lojasFiltro}`  +
            `${this.filtros.diaAniversarioFiltro !== 0 ? ` | Dia Aniversário: ${this.filtros.diaAniversarioFiltro} `: '' } ` +
            `${this.filtros.mesAniversarioFiltro !== 'TODOS' ? ` | Mês Aniversário: ${this.filtros.mesAniversarioFiltro} ` : ''}` +
            `${this.filtros.sexoFiltro !== 'TODOS' ? ` | Sexo: ${this.filtros.sexoFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.rankingFiltroValor) ? `\nRanking Top ${this.filtros.rankingFiltroValor} por ${this.filtros.rankingFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.valorMinimoFiltro) ? ` | Valor ${this.filtros.tipoValorFiltro} Mínimo ${this.filtros.valorMinimoFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.valorMaximoFiltro) ? ` | Valor ${this.filtros.tipoValorFiltro} Máximo ${this.filtros.valorMaximoFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.qtdeVendasMinimaFiltro) ? ` | Qtde Vendas Mínima: ${this.filtros.qtdeVendasMinimaFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.qtdeVendasMaximaFiltro) ? ` | Qtde Vendas Máxima: ${this.filtros.qtdeVendasMaximaFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.qtdeItensMinimoFiltro) ? ` | Qtde Itens Mínimo: ${this.filtros.qtdeItensMinimoFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.qtdeItensMaximoFiltro) ? ` | Qtde Itens Máximo: ${this.filtros.qtdeItensMaximoFiltro}` : ''} ` +            
            `${!isEmpty(this.filtros.clienteCategoriasFiltro) ? ` | Categorias Cliente: ${this.filtros.clienteCategoriasFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.profissaoFiltro) ? ` | Profissões: ${this.filtros.profissaoFiltro}` : ''} ` +          
            `${!isEmpty(this.filtros.itensFiltro) ? ` | Itens: ${this.filtros.itensFiltro}` : ''} ` +
            `${!isEmpty(this.filtros.marcasFiltro) ? ` | Marcas: ${this.filtros.marcasFiltro}` : ''} ` + 
            `${!isEmpty(this.filtros.tamanhosFiltro) ? ` | Tamanhos: ${this.filtros.tamanhosFiltro}` : ''} `                        
            :      
               `\nVisão: ` + this.primeiraLetraMaiuscula(this.filtros.tipoVisao) +
               `  |  Análise: ` + (this.filtros.tipoAnaliseCadastro === 'CLIENTESCOMVENDA' 
                      ? 'CLIENTES COM VENDA' : this.filtros.tipoAnaliseCadastro === 'CLIENTESSEMVENDA' 
                      ? 'CLIENTES SEM VENDA' : this.filtros.tipoAnaliseCadastro === 'ULTIMAVENDA' 
                      ? 'ÚLTIMA VENDA' : this.filtros.tipoAnaliseCadastro) +
               `  |  Tipo Cadastro: ${this.filtros.tipoCadastroSelect}` +
               `  |  Período: ${this.formatarData(this.filtros.dataIntervaloFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloFiltro[1])}`  +
               `  |  Lojas: ${this.filtros.lojasFiltro}` +
               `${this.filtros.diaAniversarioFiltro !== 0 ? ` | Dia Aniversário: ${this.filtros.diaAniversarioFiltro} `: '' } ` +
               `${this.filtros.mesAniversarioFiltro !== 'TODOS' ? ` | Mês Aniversário: ${this.filtros.mesAniversarioFiltro} ` : ''}` +
               `${this.filtros.sexoFiltro !== 'TODOS' ? ` | Sexo: ${this.filtros.sexoFiltro}` : ''} ` +               
               `${!isEmpty(this.filtros.clienteCategoriasFiltro) ? ` | Categorias Cliente: ${this.filtros.clienteCategoriasFiltro}` : ''} ` +
               `${!isEmpty(this.filtros.profissaoFiltro) ? ` | Profissões: ${this.filtros.profissaoFiltro}` : ''} ` +   
               `${!isEmpty(this.filtros.manequimSuperiorFiltro) ? ` | Manequim Superior: ${this.filtros.manequimSuperiorFiltro}` : ''} ` +   
               `${!isEmpty(this.filtros.manequimInferiorFiltro) ? ` | Manequim Inferior: ${this.filtros.manequimInferiorFiltro}` : ''} ` +   
               `${!isEmpty(this.filtros.manequimCalcadoFiltro) ? ` | Manequim Calçado: ${this.filtros.manequimCalcadoFiltro}` : ''} ` ,                                        
          style: 'campoFiltros'
        },       
        {
          layout: 'noBorders',
          table: {
            marginTop: 10,
            headerRows: 1,
            widths:
              (this.filtros.tipoVisao === 'VENDA'
                ? [18, 50, 82, 25, 54, 40, 40, 60, 60, 65]
                :  this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect === 'CLIENTE' ?
                   [200, 32, 60, 45, 45, 45,  90]
                   : this.filtros.tipoVisao === 'CADASTRO' && this.filtros.tipoAnaliseCadastro === 'TODOS' && this.filtros.tipoCadastroSelect !== 'CLIENTE' ?
                     [80, 30, 60, 45, 45, 45, 30, 80, 90]
                       : [18, 47, 100, 25, 53, 30, 30, 40, 40, 42 , 65]),
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };
    return documento;
  }
  
  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }

  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}