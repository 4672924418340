import * as XLSX from 'xlsx';
const { format } = require('date-fns');

//IMPRESSÃO DE RELATÓRIO DE CONTAGEM DE ESTOQUE

export class ContagemEstoqueXLS {
    constructor(dadosParaImpressao, totalizadoresImpressao) {
      this.dadosParaImpressao = dadosParaImpressao;
      this.totalizadoresImpressao = totalizadoresImpressao;
    }
  
    exportToXLS () {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(this.dataExportXLS());
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `CONTAGEM_ESTOQUE_${format(new Date(), 'dd_MM_yyyy')}.xlsx`);
    }
  
    dataExportXLS = () => {      
      const dataRows = this.dadosParaImpressao.map((dt) => ({        
        COD: dt.produto.id,
        DESCRICAO: dt.produto.descricao,
        ITEM: dt.produto.grade.produtogrupo.item.descricao,
        MARCA: dt.produto.grade.produtogrupo.marca.descricao,
        REFERENCIA: dt.produto.grade.referencia,
        TAMANHO: dt.produto.tamanhoproduto.descricao,
        COR: dt.produto.grade.cor.descricao,        
        QTDE_ANTERIOR: dt.qtde,        
        CONTADO: dt.qtdecontado,        
        DIFERENCA: dt.qtdediferenca,        
      }));          

      const totalalizadorTabela = { 
        COD : 'TOTAL',               
        QTDE_ANTERIOR: this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtde, 0),
        CONTADO: this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdecontado, 0),
        DIFERENCA: this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdediferenca, 0) 
      };

      const totalRow0 = {        
        COR: '',
        QTDE_ANTERIOR: '',
        CONTADO: '',
        DIFERENCA: ''   
      };    

      const totalRow1 = {        
        TAMANHO: 'CUSTO_TOTAL_ANTES_CONTAGEM_R$',
        COR: this.formatDecimal(this.totalizadoresImpressao.custototalantescontagem),
        CONTADO: 'VALOR_VENDA_TOTAL_ANTES_CONTAGEM_R$',
        DIFERENCA: this.formatDecimal(this.totalizadoresImpressao.valorvendatotalantescontagem),                
      };

      const totalRow2 = {        
        TAMANHO: 'CUSTO_TOTAL_CONTAGEM_R$',
        COR: this.formatDecimal(this.totalizadoresImpressao.custototalcontagem),
        CONTADO: 'VALOR_VENDA_TOTAL_CONTAGEM_R$',
        DIFERENCA: this.formatDecimal(this.totalizadoresImpressao.valorvendatotalcontagem),                
      };

      const totalRow3 = {        
        TAMANHO: 'CUSTO_TOTAL_DIFERENCAS_R$',
        COR: this.formatDecimal(this.totalizadoresImpressao.custototaldiferencas),
        CONTADO: 'VALOR_VENDA_TOTAL_DIFERENCAS_R$',
        DIFERENCA: this.formatDecimal(this.totalizadoresImpressao.valorvendatotaldiferenca),                
      };
    
     
      return [...dataRows, totalalizadorTabela, totalRow0, totalRow1, totalRow2, totalRow3 ];
    };

    calcTotal = (property) => {
      return this.dadosParaImpressao.reduce((sum, dt) => sum + dt[property], 0);
    }

    formatDecimal = (value) => {      
      if (!isNaN(value)) {
        return parseFloat(value).toFixed(2);
      }
      return value; 
    };
}

