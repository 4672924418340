import React, {useContext, useEffect, useState} from 'react';
import { Card,Modal } from 'react-bootstrap';
import { SetTenant, api } from '../../services/api';
import { TableDetalheCondiContext } from '../Table/TableDetalheCondi'
import './styles.css'
import 'bootstrap/dist/css/bootstrap.css';

function ModalDetalheProdutoCondi(props) {   
  const { condiDetalhe } = useContext(TableDetalheCondiContext);
  const [condiProdDetalhe, setCondiProdDetalhe] = useState([]);    
  const dataLancFormatada = new Date(condiDetalhe.data)
    .toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric'});
  const horaFormatada = condiDetalhe.hora != null ? (condiDetalhe.hora).substring(0, 8) : '';    

  useEffect(() => {       
     getDetalheProdCondicional();                     
  }, [condiDetalhe.id, condiDetalhe.lojaid]);

  const handleClose = () => {    
    props.onHide();    
  };  

  const getDetalheProdCondicional = async() => 
  {                    
    try 
    {         
        let url = `condicional/relatorio/detalheprodutos/${condiDetalhe.id}/${condiDetalhe.lojaid}`;     
        await SetTenant();
        const response = await api.get(url);                                                               
        setCondiProdDetalhe(response.data);                 
    }
    catch (e)
    {
        console.log('erro');
    }           
  }

  return (   
    <>    
    <Modal onHide={handleClose} 
      {...props} 
      size="fullscreen" 
      aria-labelledby="contained-modal-title-fullscreen">
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Detalhe de Produtos do Condicional
      </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card style={{ width: 'auto' }}>          
            <div className="row">
              <div className="col">
                <strong>Loja: </strong>{condiDetalhe.lojanome}
              </div>
              <div className="col">
                 <strong>Cliente: </strong> {condiDetalhe.clientenome + ' ' + condiDetalhe.clientesobrenome} 
              </div>
              <div className="col">
                <strong>Vendedor: </strong> {condiDetalhe.vendedornome}
              </div>
            </div>

            <div className="row">            
              <div className="col">
                <strong>Condicional: </strong> {condiDetalhe.id}
              </div>             
              <div className="col">
                <strong>Data/Hora: </strong> {dataLancFormatada + ' ' + horaFormatada}
              </div>
               <div className="col">
                <strong>Valor Total R$: </strong> {
                  (condiDetalhe.valortotal != null ? condiDetalhe.valortotal : 0)
                    .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
                  }
              </div>
            </div>          
        </Card>

        <Card>      
        <table className="table table-striped table-hover" style={{backgroundColor: '#007CB0'}}>
              <thead className="table mt-3">
                <tr>
                  <th scope="col" className="text-left" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód Produto</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Tamanho</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cor</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Quantidade</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Preço R$</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Status</th>
                  <th scope="col" className="text-right" style={{ backgroundColor:'#007CB0', color: 'white' }}>N° venda</th>
                </tr>
              </thead>   
               <tbody>  
                  {condiProdDetalhe.map((condi) => {                                      
                      return (
                        <tr
                          key={condi.id}                         
                        >
                          <td className="text-left" style={{ backgroundColor: 'white' }}>{condi.produtoid}</td>
                          <td className="text-left"  style={{ backgroundColor: 'white' }}>{condi.produtodescricao}</td>
                          <td className="text-center"  style={{ backgroundColor: 'white' }}>{condi.produtotamanho}</td>                          
                          <td className="text-center"  style={{ backgroundColor: 'white' }}>{condi.produtocor}</td>
                          <td className="text-center" style={{ backgroundColor: 'white' }}>{condi.produtoqtde}</td>
                          <td className="text-center"  style={{ backgroundColor: 'white' }}>{condi.produtopreco
                            .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td className="text-center"  style={{ backgroundColor: 'white' }}>{condi.produtostatus}</td>   
                          <td className="text-right"  style={{ backgroundColor: 'white' }}>{condi.numerovenda}</td>                                               
                        </tr>
                      );
                    }
                  )}                            
               </tbody>                                     
         </table>        
        </Card>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>       
    </> 
  );  
}

export default ModalDetalheProdutoCondi;
