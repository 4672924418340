import { ClearOutlined } from '@ant-design/icons';
import React from 'react';

function ButtonClear({ onClick }) {
  return (
    <>   
  <button 
    type="button" 
    class="btn btn-outline-info"    
    onClick={onClick}    
    style={{               
      borderColor : 'rgba(0, 124, 176, 1)',
      color: 'rgba(0, 124, 176, 1)',
      borderWidth : '1px',
      borderStyle: 'solid',
      width: '110px',      
      height: '30px',  
      margin: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',              
      borderRadius: '15px'
      }} 
    >
    <ClearOutlined style={{marginRight: '5px'}}/>Limpar</button>
  </>
  );
}

export default ButtonClear;