import React from 'react';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';

function ButtonLojas({ onClick, width, lojasFiltro, texto }) {
  return (
    
    <Button 
        style={{ 
            backgroundColor: '#007CB0',
            width: width, 
            height: '30px', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',  
            border : '0px',
            borderRadius: '15px'                            
            }
        } 
       onClick={onClick} >
       + {isEmpty(texto) ? 'Lojas' : texto}
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {lojasFiltro.length > 0 ? lojasFiltro.length : 1}
            </span>
    </Button>    
  );
}

export default ButtonLojas;


