import React from 'react';

const LoadingScreen = () => {
  return (    
      <div className="loading">
        <div className="loading-spinner"></div>
        <div className="loading-message">Carregando...</div>
      </div>    
  );
};

export default LoadingScreen;