import React, { useEffect, useRef } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useState, createContext } from 'react';
import { Container, FormControlLabel, Pagination, Radio, Switch, Typography } from '@mui/material';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
} from '@mui/x-data-grid';
import { api } from '../../services/api';
import { Card } from 'react-bootstrap';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalClientes from '../../components/Modal/ModalClientes';
import ModalVendedores from '../../components/Modal/ModalVendedores';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import { ImpressaoCondicionalPDF } from '../../components/Impressao/ImpressaoCondicionalPDF';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import ButtonClientes from '../../components/Buttons/ButtonClientes';
import ButtonVendedores from '../../components/Buttons/ButtonVendedores';
import LoadingScreen from '../../components/Loading/TelaLoading';
import '../../pages/AnaliseCondicional/AnaliseCondicionalstyles.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ptBR } from 'date-fns/locale';
import { startOfMonth } from 'date-fns';
import PaginationItem from '@mui/material/PaginationItem';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import ButtonMarcas from '../../components/Buttons/ButtonMarcas';
import ModalMarcas from '../../components/Modal/ModalMarcas';
import ButtonColecoes from '../../components/Buttons/ButtonColecoes';
import ModalColecoes from '../../components/Modal/ModalColecoes';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
import GraficoBarrasAnaliseCondicional from '../../components/GraficoDeBarras/GraficoBarrasAnaliseCondicional';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const AnaliseCondicionalContext = createContext();

export const AnaliseCondicionalProvider = () => {
    const sessionStorageUtils = new SessionStorageUtils();
    const PAGE_SIZE = 50;
    const [paginationModel, setPaginationModel] = useState({ pageSize: PAGE_SIZE, page: 0, });
    const [nomeGrupo, setNomeGrupo] = useState([]);
    const [dadosCondicional, setDadosCondicional] = useState([]);
    const [showLojas, setShowLojas] = useState(false);
    const [showClientes, setShowClientes] = useState(false);
    const [showVendedores, setShowVendedores] = useState(false);
    const [showMarcas, setShowMarcas] = useState(false);
    const [showColecoes, setShowColecoes] = useState(false);
    const [lojasFiltro, setLojasFiltro] = useState([]);
    const [clientesFiltro, setClientesFiltro] = useState([]);
    const [vendedoresFiltro, setVendedoresFiltro] = useState([]);
    const [marcasFiltro, setMarcasFiltro] = useState([]);
    const [colecoesFiltro, setColecoesFiltro] = useState([]);
    const [tipoVisaoSelect, setTipoVisaoSelect] = useState('vendedor');
    const [analiseConversaoSelect, setAnaliseConversaoSelect] = useState('valor');
    const [loading, setLoading] = useState(true);
    const [limparFiltros, SetLimparFiltros] = useState(false);
    const navigate = useNavigate();
    const permissao = new Permissoes();
    const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([
        startOfMonth(new Date()),
        new Date()
    ]);
    const [dataIntervaloVendaFiltro, setDataIntervaloVendaFiltro] = useState([
        startOfMonth(new Date()),
        new Date()
    ]);
    const [combinarPeriodo, setCombinarPeriodo] = useState({
        periodovenda: false
    });
    const [exibirValoresMedios, setExibirValoresMedios] = useState({
        exibir: false
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
            if (ValidarPermissoes() == false) { return; }
            sessionStorage.removeItem('selectedLojas');
            sessionStorage.removeItem('selectedClientes');
            sessionStorage.removeItem('selectedVendedores');
            sessionStorage.removeItem('selectedMarcas');
            sessionStorage.removeItem('selectedColecoes');
            definirLojaInicial();
            DefinirNomeGrupo();
            setLoading(false);
        }, 500);
    }, []);

    async function ValidarPermissoes() {
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('50967e74b1313709c384b61c4b32d566');
        if (autorizado) { return true; }
        navigate('/home')
        return false;
    }

    useEffect(() => {
        if (limparFiltros) {
            definirLojaInicial();
            SetLimparFiltros(false);
        }
    }, [limparFiltros]);


    const DefinirNomeGrupo = async () => {
        const desencryptJSONObj = await sessionStorageUtils.GetUsuario();
        const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
        setNomeGrupo(groupName);
    }

    const definirLojaInicial = async () => {
        debugger
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0) {
            const userId = JSON.parse(desencryptJSONUser).id;
            await SetTenant();
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;
            setLojasFiltro([firstId]);
        }
    }

    const totalizador = dadosCondicional.reduce((total, obj) => {
        total.qtdecondicional += obj.qtdecondicional;
        total.qtdepecas += obj.qtdepecas;
        total.qtdepecasporcondicional += obj.qtdepecasporcondicional;
        total.valorenviado += obj.valorenviado;
        total.desconto += obj.desconto;
        total.valorvendidas += obj.valorvendidas;
        total.valorconvertido += obj.valorconvertido;
        total.valorliquido += obj.valorliquido;
        total.qtdepecasconvertidas += obj.qtdepecasconvertidas;
        return total;
    }, {
        qtdecondicional: 0, qtdepecas: 0, qtdepecasporcondicional: 0, valorenviado: 0, desconto: 0, valorvendidas: 0, valorliquido: 0, qtdepecasconvertidas: 0,
        valorconvertido: 0
    });

    const renderTable = () => {
        if (analiseConversaoSelect === 'peca') {
            return (
                <table class="table">
                    <thead>
                        {exibirValoresMedios.exibir && (
                            <>
                                <th scope="col" style={{ width: tipoVisaoSelect === 'marca' ? '455px' : '325px', fontSize: '20px' }}></th>
                                {tipoVisaoSelect !== 'marca' && (<th scope="col" className="text-right" style={{ width: '130px', fontSize: '13px' }}></th>)}
                                <th scope="col" className="text-right" style={{ width: '130px', fontSize: '13px' }}></th>
                                <th scope="col" className="text-right" style={{ width: '150px', fontSize: '13px' }}></th>
                                <th scope="col" className="text-right" style={{ width: '100px', fontSize: '13px' }}></th>
                                <th scope="col" className="text-right" style={{ width: '150px', fontSize: '13px' }}></th>
                                <th scope="col" className="text-right" style={{ width: '165px', fontSize: '13px' }}></th>
                            </>
                        )}
                        {!exibirValoresMedios.exibir && (
                            <>
                                <th scope="col" style={{ width: tipoVisaoSelect === 'marca' ? '770px' : '635px', fontSize: '20px' }}></th>
                                {tipoVisaoSelect !== 'marca' && (<th scope="col" className="text-right" style={{ width: '130px', fontSize: '13px' }}></th>)}
                                <th scope="col" className="text-right" style={{ width: '130px', fontSize: '13px' }}></th>
                                <th scope="col" className="text-right" style={{ width: '150px', fontSize: '13px' }}></th>
                                <th scope="col" className="text-right" style={{ width: '100px', fontSize: '13px' }}></th>
                            </>
                        )}

                    </thead>
                    <tbody>
                        <tr >
                            <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2' }}>Total</th>
                            {tipoVisaoSelect !== 'marca' && (
                                <>
                                    <td className="text-right"
                                        style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                        {(!isEmpty(totalizador) ? totalizador.qtdecondicional : 0)}
                                    </td>
                                </>)}
                            <td className="text-right"
                                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                {(!isEmpty(totalizador) ? totalizador.qtdepecas : 0)}
                            </td>
                            <td className="text-right"
                                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                {(!isEmpty(totalizador) ? totalizador.qtdepecasconvertidas : 0)}
                            </td>
                            <td className="text-right"
                                style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                {!isEmpty(totalizador)
                                    ? ((((dadosCondicional.reduce((acc, row) => acc + row.qtdepecasconvertidas, 0) * 100)
                                        / (dadosCondicional.reduce((acc, row) => acc + row.qtdepecas, 0))))
                                        .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%')
                                    : 0
                                }
                            </td>
                            {exibirValoresMedios.exibir && (
                                <>
                                    <td className="text-right"
                                        style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                        {(!isEmpty(totalizador) ? (dadosCondicional.reduce((acc, row) => acc + row.qtdepecasporcondicional, 0) / dadosCondicional.length) : 0)
                                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    <td className="text-right"
                                        style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                        {(!isEmpty(totalizador) ? (dadosCondicional.reduce((acc, row) => acc + row.qtdepecasconvertidasporcondicional, 0) / dadosCondicional.length) : 0)
                                            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                </>
                            )}
                        </tr>
                    </tbody>
                </table>
            );
        }
        return (
            <table class="table">
                <thead>
                    {!exibirValoresMedios.exibir && (
                        <>
                            <th scope="col" style={{ width: tipoVisaoSelect === 'marca' ? '500px' : '390px', fontSize: '20px' }}></th>
                            {tipoVisaoSelect !== 'marca' && (<th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>)}
                            <th scope="col" className="text-right" style={{ width: '80px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '120px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '120px', fontSize: '13px' }}></th>
                        </>)}

                    {exibirValoresMedios.exibir && (
                        <>
                            <th scope="col" style={{ width: tipoVisaoSelect === 'marca' ? '275px' : '165px', fontSize: '20px' }}></th>
                            {tipoVisaoSelect !== 'marca' && (<th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>)}
                            <th scope="col" className="text-right" style={{ width: '80px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '110px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '90px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '80px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '120px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '140px', fontSize: '13px' }}></th>
                            <th scope="col" className="text-right" style={{ width: '145px', fontSize: '13px' }}></th>
                        </>
                    )}
                </thead>
                <tbody>
                    <tr >
                        <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2' }}>Total</th>
                        {tipoVisaoSelect !== 'marca' && (
                            <>
                                <td className="text-right"
                                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                    {(!isEmpty(totalizador) ? totalizador.qtdecondicional : 0)}
                                </td>
                            </>)}
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {!isEmpty(totalizador) ? totalizador.qtdepecas : 0}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {(!isEmpty(totalizador) ? totalizador.valorenviado : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {(!isEmpty(totalizador) ? totalizador.valorconvertido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {!isEmpty(totalizador)
                                ? ((((dadosCondicional.reduce((acc, row) => acc + row.valorconvertido, 0) * 100)
                                    / (dadosCondicional.reduce((acc, row) => acc + row.valorenviado, 0))))
                                    .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%')
                                : 0
                            }
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#A5D6A7', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {(!isEmpty(totalizador) ? totalizador.desconto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#A5D6A7', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {(!isEmpty(totalizador) ? totalizador.valorliquido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                        </td>
                        {exibirValoresMedios.exibir && (
                            <>
                                <td className="text-right"
                                    style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                    {(!isEmpty(totalizador) ? (dadosCondicional.reduce((acc, row) => acc + row.valormedioenviadoporpeca, 0) / dadosCondicional.length) : 0)
                                        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                                </td>
                                <td className="text-right"
                                    style={{ backgroundColor: '#A9DDF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                    {(!isEmpty(totalizador) ? (dadosCondicional.reduce((acc, row) => acc + row.valormedioconvertidoporpeca, 0) / dadosCondicional.length) : 0)
                                        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                                </td>
                            </>
                        )}
                    </tr>
                </tbody>
            </table>
        );
    }

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
        const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);

        return (
            <>
                <div>
                    <div className='table-align'>
                        {renderTable()}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total de registros: {totalRowCount}
                                </Typography>
                            </div>

                            <div style={{ marginLeft: '50px' }}>
                                <Typography variant="subtitle2" color="textSecondary" >
                                    Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                                </Typography>
                            </div>
                        </div>

                        <div style={{ alignSelf: 'flex-end' }}>
                            <Pagination
                                color="primary"
                                variant="outlined"
                                shape="rounded"
                                page={page + 1}
                                count={pageCount}
                                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                            />
                        </div>
                    </div>
                </div>

            </>
        );
    }

    const handleCloseLojas = () => {
        setShowLojas(false);
    }

    const handleShowLojas = () => {
        setShowLojas(true);
    }

    const handleShowVendedores = () => {
        setShowVendedores(true);
    }

    const handleShowMarcas = () => {
        setShowMarcas(true);
    }

    const handleShowColecoes = () => {
        setShowColecoes(true);
    }

    const handleCloseClientes = () => {
        setShowClientes(false);
    }

    const handleCloseVendedores = () => {
        setShowVendedores(false);
    }

    const handleCloseMarcas = () => {
        setShowMarcas(false);
    }

    const handleCloseColecoes = () => {
        setShowColecoes(false);
    }

    const handleShowClientes = () => {
        setShowClientes(true);
    }

    const handleChange = (event) => {
        setCombinarPeriodo({ ...combinarPeriodo, [event.target.name]: event.target.checked });
    };

    const handleChangeValoresMedios = (event) => {
        setExibirValoresMedios({ ...exibirValoresMedios, [event.target.name]: event.target.checked });
    };

    const definirLojasSelecionadas = (lojas) => {
        setLojasFiltro(lojas);
    }

    const definirClientesSelecionados = (id) => {
        setClientesFiltro(id);
    }

    const definirVendedoresSelecionados = (id) => {
        setVendedoresFiltro(id);
    }

    const definirMarcasSelecionadas = (id) => {
        setMarcasFiltro(id);
    }

    const definirColecoesSelecionadas = (id) => {
        setColecoesFiltro(id);
    }

    const handleExecutar = async () => {
        setLoading(true);
        await getCondicional();
        setLoading(false);
    }

    const handleLimparFiltros = async () => {
        resetarDataInicial();
        sessionStorage.removeItem('selectedLojas');
        sessionStorage.removeItem('selectedClientes');
        sessionStorage.removeItem('selectedVendedores');
        sessionStorage.removeItem('selectedMarcas');
        sessionStorage.removeItem('selectedColecoes');
        setClientesFiltro([]);
        setVendedoresFiltro([]);
        setMarcasFiltro([]);
        setColecoesFiltro([]);
        setDadosCondicional([]);
        setLojasFiltro([]);
        SetLimparFiltros(true);
    }

    const resetarDataInicial = () => {
        setDataIntervaloFiltro([
            startOfMonth(new Date()),
            new Date()
        ]);
    };

    const tipoVisaoFiltroToInt = () => {
        const enumValues = {
            'cliente': 1,
            'vendedor': 2,
        };

        return enumValues;
    };

    const tipoVisaoToInt = tipoVisaoFiltroToInt();

    const filtrosSelecionados = {
        tipoVisao: tipoVisaoSelect,
        lojasSelecionadas: lojasFiltro,
        dataIntervaloFiltro: dataIntervaloFiltro,
        clientesFiltro: clientesFiltro,
        vendedoresFiltro: vendedoresFiltro,
        nomeGrupo: nomeGrupo
    }

    const visualizarImpressao = async () => {
        const classeImpressao = new ImpressaoCondicionalPDF(dadosCondicional, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    function AddParams(value) {
        if (value.length > 0)
            return `/${value}`

        return '/ '
    }

    const getCondicional = async () => {
        try {
            const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth() + 1}-${dataIntervaloFiltro[0].getDate()}`);
            const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth() + 1}-${dataIntervaloFiltro[1].getDate()}`);
            const dataIniVenda = (`${dataIntervaloVendaFiltro[0].getFullYear()}-${dataIntervaloVendaFiltro[0].getMonth() + 1}-${dataIntervaloVendaFiltro[0].getDate()}`);
            const dataFinVenda = (`${dataIntervaloVendaFiltro[1].getFullYear()}-${dataIntervaloVendaFiltro[1].getMonth() + 1}-${dataIntervaloVendaFiltro[1].getDate()}`);

            let url = `condicional/relatorio/analisecondicional/${tipoVisaoSelect}/${analiseConversaoSelect}/${combinarPeriodo.periodovenda}/${dataIni}/${dataFin}/${dataIniVenda}/${dataFinVenda}`;

            url += AddParams(lojasFiltro)
            url += AddParams(clientesFiltro);
            url += AddParams(vendedoresFiltro);
            url += AddParams(marcasFiltro);
            url += AddParams(colecoesFiltro);

            setDadosCondicional([]);
            await SetTenant();
            const response = await api.get(url);
            setDadosCondicional(response.data);
        }
        catch (e) {
            console.log('erro');
        }
    }

    const columnsAnaliseValor = [
        { field: "id", headerName: "Cód", headerClassName: 'blue-header', width: 60 },
        {
            field: tipoVisaoSelect === 'loja'
                ? "lojanome"
                : tipoVisaoSelect === 'vendedor'
                    ? "vendedornome"
                    : tipoVisaoSelect === 'cliente'
                        ? "clientenome"
                        : tipoVisaoSelect === 'marca'
                            ? "marcanome"
                            : "lojanome",
            headerName:
                tipoVisaoSelect === 'loja'
                    ? "Loja"
                    : tipoVisaoSelect === 'vendedor'
                        ? "Vendedor"
                        : tipoVisaoSelect === 'cliente'
                            ? "Cliente"
                            : tipoVisaoSelect === 'marca'
                                ? "Marca"
                                : "Loja", headerClassName: 'blue-header',
            width:
                exibirValoresMedios.exibir && tipoVisaoSelect === 'marca' ? 215 :
                    !exibirValoresMedios.exibir && tipoVisaoSelect === 'marca' ? 440 :
                        exibirValoresMedios.exibir && tipoVisaoSelect !== 'marca' ? 105 : 330
        },
        {
            field: "qtdecondicional",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Qtde <br /> Condicional</div>), hide: tipoVisaoSelect === 'marca', headerClassName: 'blue-header', width: 110, headerAlign: 'center', align: 'right'
        },
        {
            field: "qtdepecas",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Qtde <br /> Peças</div>), headerClassName: 'blue-header', width: 80, headerAlign: 'center', align: 'right'
        },
        {
            field: "valorenviado",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                V. Enviado<br />R$</div>), height: 80, headerClassName: 'blue-header', headerAlign: 'right', align: 'right', width: 110
        },
        {
            field: "valorconvertido",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                V. Convertido<br />R$</div>), height: 80, headerClassName: 'blue-header', headerAlign: 'right', align: 'right', width: 110
        },
        {
            field: "taxaconversaovalor", renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                Conversão<br />%</div>), headerClassName: 'blue-header', width: exibirValoresMedios.exibir ? 90 : 120, headerAlign: 'right', align: 'right'
        },
        {
            field: "desconto",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                Desconto<br />R$</div>), headerClassName: 'blue-header', width: exibirValoresMedios.exibir ? 80 : 110, headerAlign: 'right', align: 'right', cellClassName: () => { return 'green-cell-light ' }
        },
        {
            field: "valorliquido", renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                V. Líquido<br />R$</div>), headerClassName: 'blue-header', headerAlign: 'right', width: 120, align: 'right', cellClassName: () => { return 'green-cell-light ' }
        },
        {
            field: "valormedioenviadoporpeca", hide: !exibirValoresMedios.exibir, renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                V. Médio  Enviado<br />Por Peça R$</div>), headerClassName: 'blue-header', width: 140, headerAlign: 'right', align: 'right', cellClassName: () => { return 'blue-cell' }
        },
        {
            field: "valormedioconvertidoporpeca", hide: !exibirValoresMedios.exibir, renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                V. Médio Convertido<br />Por Peça R$</div>), headerClassName: 'blue-header', width: 145, headerAlign: 'right', align: 'right', cellClassName: () => { return 'blue-cell' }
        }
    ];

    const columnsAnalisePeca = [
        { field: "id", headerName: "Cód", headerClassName: 'blue-header', width: 60 },
        {
            field: tipoVisaoSelect === 'loja'
                ? "lojanome"
                : tipoVisaoSelect === 'vendedor'
                    ? "vendedornome"
                    : tipoVisaoSelect === 'cliente'
                        ? "clientenome"
                        : tipoVisaoSelect === 'marca'
                            ? "marcanome"
                            : "lojanome",
            headerName:
                tipoVisaoSelect === 'loja'
                    ? "Loja"
                    : tipoVisaoSelect === 'vendedor'
                        ? "Vendedor"
                        : tipoVisaoSelect === 'cliente'
                            ? "Cliente"
                            : tipoVisaoSelect === 'marca'
                                ? "Marca"
                                : "Loja", headerClassName: 'blue-header',
            width:
                exibirValoresMedios.exibir && tipoVisaoSelect === 'marca' ? 395 :
                    !exibirValoresMedios.exibir && tipoVisaoSelect === 'marca' ? 710 :
                        exibirValoresMedios.exibir && tipoVisaoSelect !== 'marca' ? 264 : 580
        },
        {
            field: "qtdecondicional",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Qtde<br /> Condicional</div>), hide: tipoVisaoSelect === 'marca', headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right'
        },
        {
            field: "qtdepecas", renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Qtde<br /> Peças</div>), headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right'
        },
        {
            field: "qtdepecasconvertidas", renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Qtde Peça <br />Convertida </div>), headerClassName: 'blue-header', headerAlign: 'right', width: 150, align: 'right'
        },
        {
            field: "taxaconversaopeca", renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Conversão<br />%</div>), headerClassName: 'blue-header', width: 100, headerAlign: 'right', align: 'right'
        },
        {
            field: "qtdepecasporcondicional", hide: !exibirValoresMedios.exibir, renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                Qtd Média Enviada<br />Por Condicional</div>), headerClassName: 'blue-header', width: 150, headerAlign: 'right', align: 'right', cellClassName: () => { return 'blue-cell' }
        },
        {
            field: "qtdepecasconvertidasporcondicional", hide: !exibirValoresMedios.exibir, renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho' >
                Qtd Média Convertida<br />Por Condicional</div>), headerClassName: 'blue-header', width: 165, headerAlign: 'right', align: 'right', cellClassName: () => { return 'blue-cell' }
        }
    ];

    const rowsAnaliseValor = dadosCondicional.map((row) => ({
        id: tipoVisaoSelect === 'loja'
            ? row.lojaid
            : tipoVisaoSelect === 'vendedor'
                ? row.vendedorid
                : tipoVisaoSelect === 'cliente'
                    ? row.clienteid
                    : tipoVisaoSelect === 'marca'
                        ? row.marcaid
                        : row.lojaid,
        lojanome: row.lojanome,
        clientenome: row.clientenome,
        vendedornome: row.vendedornome,
        marcanome: row.marcanome,
        qtdecondicional: row.qtdecondicional,
        qtdepecas: row.qtdepecas,
        qtdepecasporcondicional: row.qtdepecasporcondicional
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        valorenviado: (row.valorenviado)
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        valorconvertido: (row.valorconvertido)
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        desconto: (row.desconto)
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        valorliquido: row.valorliquido
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        taxaconversaovalor: row.taxaconversaovalor
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        valormedioenviadoporpeca: row.valormedioenviadoporpeca
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        valormedioconvertidoporpeca: row.valormedioconvertidoporpeca
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    }))

    const rowsAnalisePeca = dadosCondicional.map((row) => ({
        id: tipoVisaoSelect === 'loja'
            ? row.lojaid
            : tipoVisaoSelect === 'vendedor'
                ? row.vendedorid
                : tipoVisaoSelect === 'cliente'
                    ? row.clienteid
                    : tipoVisaoSelect === 'marca'
                        ? row.marcaid
                        : row.lojaid,
        lojanome: row.lojanome,
        clientenome: row.clientenome,
        vendedornome: row.vendedornome,
        marcanome: row.marcanome,
        qtdecondicional: row.qtdecondicional,
        qtdepecas: row.qtdepecas,
        qtdepecasporcondicional: row.qtdepecasporcondicional
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        qtdepecasconvertidas: (row.qtdepecasconvertidas),
        qtdepecasconvertidasporcondicional: (row.qtdepecasconvertidasporcondicional)
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        taxaconversaopeca: row.taxaconversaopeca
            .toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }))

    function CustomToolbar() {
        return (
            <CustomToolBar print={visualizarImpressao} />
        );
    }

    const contextValues = {
        definirLojasSelecionadas,
        definirClientesSelecionados,
        definirVendedoresSelecionados,
        definirMarcasSelecionadas,
        definirColecoesSelecionadas,
        lojasFiltro,
        clientesFiltro,
        vendedoresFiltro,
        tipoVisaoSelect: tipoVisaoSelect,
        dataIntervaloFiltro
    };
    const headerClassName = 'blue-header';

    if (loading) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <AnaliseCondicionalContext.Provider value={contextValues}>
            <form className="form">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Análise de Condicional</label>
                </div>
                <Card.Body>
                    <Container>
                        <Card>
                            <div className='align-left'>
                                <div className='row'>

                                    <div className='col'>
                                        <label className='text' style={{ fontWeight: 'bold' }}>Visão</label>
                                        <Card style={{ width: 550 }}>
                                            <div className="row">
                                                <div className="col-md">
                                                    <Radio
                                                        checked={tipoVisaoSelect === 'vendedor'}
                                                        onChange={() => {
                                                            setTipoVisaoSelect('vendedor');
                                                            setDadosCondicional([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="vendedorRadio"
                                                    />
                                                    <label class="form-check-label" for="vendedorRadio" style={{ cursor: 'pointer' }}>
                                                        Vendedor
                                                    </label>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={tipoVisaoSelect === 'cliente'}
                                                            onChange={() => {
                                                                setTipoVisaoSelect('cliente');
                                                                setDadosCondicional([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="clienteRadio"
                                                        />
                                                        <label class="form-check-label" for="clienteRadio" style={{ cursor: 'pointer' }}>
                                                            Cliente
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={tipoVisaoSelect === 'marca'}
                                                            onChange={() => {
                                                                setTipoVisaoSelect('marca');
                                                                setDadosCondicional([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="marcaRadio"
                                                        />
                                                        <label class="form-check-label" for="marcaRadio" style={{ cursor: 'pointer' }}>
                                                            Marca
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={tipoVisaoSelect === 'loja'}
                                                            onChange={() => {
                                                                setTipoVisaoSelect('loja');
                                                                setDadosCondicional([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="lojaRadio"
                                                        />
                                                        <label class="form-check-label" for="lojaRadio" style={{ cursor: 'pointer' }}>
                                                            Loja
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>

                                    <div className='col'>
                                        <label className='text' style={{ fontWeight: 'bold' }}>Análise de Conversão</label>
                                        <Card style={{ width: 440 }}>
                                            <div className="row">
                                                <div className="col-md">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={analiseConversaoSelect === 'valor'}
                                                            onChange={() => {
                                                                setAnaliseConversaoSelect('valor');
                                                                setDadosCondicional([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="valorRadio"
                                                        />
                                                        <label class="form-check-label" for="valorRadio" style={{ cursor: 'pointer' }}>
                                                            Por Valor
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={analiseConversaoSelect === 'peca'}
                                                            onChange={() => {
                                                                setAnaliseConversaoSelect('peca');
                                                                setDadosCondicional([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="pecaConversaoRadio"
                                                        />
                                                        <label class="form-check-label" for="pecaConversaoRadio" style={{ cursor: 'pointer' }}>
                                                            Por Peça
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Container>

                    <Container>
                        <Card>
                            <div className="filtros">
                                <div className="align-left">
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="row" style={{ width: '430px' }}>
                                                <div className="col">
                                                    <label className='text' style={{ fontWeight: 'bold', marginBottom: '20px' }}>Data Emissão</label>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={ptBR}>
                                                        <DateRangePicker
                                                            localeText={{ start: 'Data Inicial', end: 'Data Final' }}
                                                            value={dataIntervaloFiltro}
                                                            onChange={(newValue) => setDataIntervaloFiltro(newValue)}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={combinarPeriodo.periodovenda}
                                                        onChange={handleChange}
                                                        name="periodovenda" />}
                                                label={
                                                    <Typography
                                                        variant="body1"
                                                        style={
                                                            { fontWeight: 'bold' }}
                                                    >
                                                        Combinar Período de Venda
                                                    </Typography>
                                                } />
                                            <div className='row' style={{ marginTop: '5px', width: '430px' }}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                    adapterLocale={ptBR}
                                                >
                                                    <DateRangePicker
                                                        localeText={{ start: 'Data Inicial', end: 'Data Final' }}
                                                        value={dataIntervaloVendaFiltro}
                                                        onChange={(newValue) => setDataIntervaloVendaFiltro(newValue)}
                                                        disabled={!combinarPeriodo.periodovenda}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-start" style={{ marginTop: '20px' }}>
                                        <div className="col-lg">
                                            <ButtonLojas
                                                onClick={handleShowLojas}
                                                width={'182px'}
                                                lojasFiltro={lojasFiltro}
                                            />
                                        </div>
                                        <div className="col-lg">
                                            <ButtonVendedores
                                                onClick={handleShowVendedores}
                                                width={'182px'}
                                                vendedoresFiltro={vendedoresFiltro}
                                            />
                                        </div>
                                        <div className="col-lg">
                                            <ButtonClientes
                                                onClick={handleShowClientes}
                                                width={'182px'}
                                                clientesFiltro={clientesFiltro}
                                            />
                                        </div>
                                        <div className="col-lg">
                                            <ButtonMarcas
                                                onClick={handleShowMarcas}
                                                width={'182px'}
                                                marcasFiltro={marcasFiltro}
                                            />
                                        </div>
                                        <div className="col-lg">
                                            <ButtonColecoes
                                                onClick={handleShowColecoes}
                                                width={'185px'}
                                                colecoesFiltro={colecoesFiltro}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row" style={{ marginTop: '30px' }}>
                                <div className='col-2'>
                                    <div className="actions">
                                        <ButtonExecute
                                            width={'185px'}
                                            onClick={handleExecutar}
                                        />
                                    </div>
                                </div>
                                <div className='col-2' style={{ marginLeft: '15px' }}>
                                    <ButtonClear
                                        onClick={handleLimparFiltros}
                                    />
                                </div>
                                <div className='col'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={exibirValoresMedios.exibir}
                                                onChange={handleChangeValoresMedios}
                                                name="exibir" />}
                                        label={
                                            <Typography
                                                variant="body1"
                                                style={
                                                    { fontWeight: 'bold' }}
                                            >
                                                Exibir Valores Médios
                                            </Typography>
                                        } />
                                </div>
                            </div>
                        </Card>
                    </Container>
                </Card.Body>
            </form>

            <ModalLojas show={showLojas} value={AnaliseCondicionalContext} onHide={handleCloseLojas} />
            <ModalClientes show={showClientes} value={AnaliseCondicionalContext} onHide={handleCloseClientes} />
            <ModalVendedores show={showVendedores} value={AnaliseCondicionalContext} onHide={handleCloseVendedores} />
            <ModalMarcas show={showMarcas} value={AnaliseCondicionalContext} onHide={handleCloseMarcas} />
            <ModalColecoes show={showColecoes} value={AnaliseCondicionalContext} onHide={handleCloseColecoes} />

            {!isEmpty(dadosCondicional) && (
                <>
                    <Container>
                        <GraficoBarrasAnaliseCondicional
                            apiData={dadosCondicional}
                            visao={tipoVisaoSelect}
                            analise={analiseConversaoSelect}
                        />
                    </Container>

                    <Container style={{ marginTop: '10px' }}>
                        <CustomStyledDataGrid
                            headerClassName={headerClassName}
                            rows={analiseConversaoSelect === 'valor' ? rowsAnaliseValor : rowsAnalisePeca}
                            columns={analiseConversaoSelect === 'valor' ? columnsAnaliseValor.filter((column) => !column.hide) : columnsAnalisePeca.filter((column) => !column.hide)}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[PAGE_SIZE]}
                            slots={{
                                pagination: CustomPagination
                            }}

                        />
                    </Container>
                </>
            )}
        </AnaliseCondicionalContext.Provider>
    );
}

export default AnaliseCondicionalProvider;
