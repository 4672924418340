import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.css';
import CustomStyledDataGrid from '../DataGrid/StyledDataGrid';
import { api } from '../../services/api';
import { useContext } from 'react';

function ModalDetalheFinanceiro(props) {
  const { dadosDetalheRow, dataIntervaloFiltro, lojasFiltro } = useContext(props.contexto);
  const [ dadosDetalhe, SetDadosDetalhe ] = useState([]);
  const handleClose = () => props.onHide();

  useEffect(() => {   
    if (dadosDetalheRow != null && dadosDetalheRow != null) 
    {
      GetDadosDetalhe();           
    }      
  }, [dadosDetalheRow]);  

  const tipoDetalheToInt = () => {
    const enumValues = {
      'CREDIARIOSRECEBIDOS': 1,
      'RECEITAS' : 2,
      'DESPESAS' : 3,
      'FORNECEDORES' : 4      
    };
  
    return enumValues;
  };   

  const tipoDetalhe = tipoDetalheToInt();

  function EmptyIfNull(value) {
    if (value != null)
      return value;

    return '';
  }

  async function GetDadosDetalhe() {
    try 
    {           
      debugger
        const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
        const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);                                       
        let url = `caixamovimento/resumofinanceiro/detalhe/${lojasFiltro}/${dataIni}/${dataFin}/${tipoDetalhe[dadosDetalheRow.tipo]}/
          ${EmptyIfNull(dadosDetalheRow.clienteid)}/${EmptyIfNull(dadosDetalheRow.id)}`;                                

        SetDadosDetalhe([]);                    
        const response = await api.get(url);                                                                           
        SetDadosDetalhe(response.data);               
    }
    catch (e)
    {
        console.log('erro');
    }                
  }  

  const columnsCrediariosFornecedores = [                
    { field: "lojaid", headerName: "Loja Baixa",  headerClassName: 'blue-header', headerAlign: 'left', width: 100, align: 'center'},               
    { field: "datapgto",  type: 'date', valueGetter: (params) => params.row.datapgto, 
      valueFormatter: (params) => { return new Date(params.value).toLocaleDateString('pt-BR') }, 
      headerName: "Data Pagamento",  headerClassName: 'header-detail', 
      headerAlign: 'center', width: 350, align: 'center'
    },  
    { field: "valor", headerName: "Valor Líquido R$", headerClassName: 'blue-header', headerAlign: 'right', width: 280, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      } }
  ]   

  const columnsReceitasDespesas = [                
      { field: "lojaid", headerName: "Loja",  headerClassName: 'blue-header', headerAlign: 'left', width: 95, align: 'center'},               
      { field: "data",  type: 'date', valueGetter: (params) => params.row.data, 
        valueFormatter: (params) => { return new Date(params.value).toLocaleDateString('pt-BR') }, 
        headerName: "Data",  headerClassName: 'header-detail', 
        headerAlign: '180', width: 200, align: 'center'
      },  
      { field: "historico", headerName: "Histórico",  headerClassName: 'blue-header', headerAlign: 'left', width: 250, align: 'center'},  
      { field: "valor", headerName: "Valor Líquido R$", headerClassName: 'blue-header', headerAlign: 'right', width: 180, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
        } }
    ]   

    const rows = dadosDetalhe.map((row) => {   
      if (dadosDetalheRow.tipo === 'CREDIARIOSRECEBIDOS' || dadosDetalheRow.tipo === 'FORNECEDORES')      
      {
          const detalheInfo = {
              id : row.id,
              lojaid : row.lojaid,
              documento : row.documento,
              datapgto : row.datapgto,
              valor : row.valor
          }                      
          return detalheInfo;
      }    
      else if (dadosDetalheRow.tipo === 'RECEITAS' || dadosDetalheRow.tipo === 'DESPESAS')       
      {
          const detalheInfo = {
            id : row.id,
            contaid : row.contaid,
            lojaid : row.lojaid,
            data : row.data,            
            historico : row.historico,
            valor : row.valor
        }                      
        return detalheInfo;
      }    
    });      

  function DescricaoDetalhe(value) {
    if (value.contadescricao != null)
      return value.contadescricao
    else if (value.clientenome != null)
      return value.clientenome
    else if (value.fornecedor != null)
      return value.fornecedor
  }

  return (    
   
    <Modal onHide={handleClose}
      {...props} 
      size="lg"
      centered
      aria-labelledby="contained-modal-title-xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
           <label className='text' style={{ marginLeft: '10px' }}>Detalhes: {
              dadosDetalheRow != null ? DescricaoDetalhe(dadosDetalheRow) : '' }</label>
        </Modal.Title>
      </Modal.Header>
        <Modal.Body className="show-grid">
            <Container>
            <div className='col'>                              
            <Container>       
              {dadosDetalhe.length > 0 && (
                <CustomStyledDataGrid      
                    hideFooter      
                    onRowClick={console.log('chegou')}                                                                        
                    columns={
                      dadosDetalheRow.tipo === 'CREDIARIOSRECEBIDOS' || dadosDetalheRow.tipo === 'FORNECEDORES'
                      ? columnsCrediariosFornecedores
                        : dadosDetalheRow.tipo === 'RECEITAS' || dadosDetalheRow.tipo === 'DESPESAS'
                        ? columnsReceitasDespesas
                      : []}                   
                    rows={rows}
                    sx={{
                        '@media print': {
                          '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' }                          
                        },
                      }}                                        
                    />                     
              )}                                                                                                                           
            </Container>         

            </div>    
            </Container>
        </Modal.Body>
      <Modal.Footer>        
      </Modal.Footer>
    </Modal>       
  );
}

export default ModalDetalheFinanceiro;
