import moment from 'moment';

export class ImpressaoEstoquePDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;    
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {
    if (this.filtros.tipoVisao === 'produto')
    {
      const header = [
        { text: 'Loja', bold: true,  alignment: 'left', fontSize: 9, margin: [4, 4, 4, 4] },
        { text: 'Cód', bold: true,  alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Produto', bold: true,  alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Tamanho', bold: true,  alignment: 'left', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Cor', bold: true,   alignment: 'left', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Estoque', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Custo Prod. R$', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Custo Real R$', bold: true,  alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Valor Venda R$', bold: true,  alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
      ];
      return header;
    }    
    else 
    {
      const header = [
        { text: 'Loja', bold: true, fontSize: 9,  margin: [4, 4, 4, 4]  },
        { text: 'Cód', bold: true, fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: this.filtros.tipoVisao === 'marca' ? 'Marca' : this.filtros.tipoVisao === 'item' ? 'Item' : '', bold: true, fontSize: 9, margin: [0, 4, 4, 4]  },          
        { text: 'Estoque', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Custo Prod. R$', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 4]  },
        { text: 'Custo Real R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },
        { text: 'Valor Venda R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4]  },      
      ];
      return header;
    }
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; // Alternating colors
  }


  GetBody() {   
    if (this.filtros.tipoVisao === 'produto')  
    {
        const bodyProduto = this.dadosParaImpressao.map((prod) => {
          return  [
            { text: prod.lojaid, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
            { text: prod.id, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: prod.descricao.substring(0, 23), fontSize: 8, alignment: 'left',  margin:[0, 0, 0, 0] },
            { text: prod.tamanho, fontSize: 8, alignment: 'left', margin:[0, 0, 0, 0]},    
            { text: prod.cor, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0]},       
            { text: prod.saldo, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: prod.custoproduto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.custoreal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.valorvenda.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
          ];
        }
      );      
      return bodyProduto;
    }
    
    const bodyItemMarca = this.dadosParaImpressao.map((prod) => {    
        return  [
          { text: prod.lojaid, fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
          { text: prod.id, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
          { text: prod.descricao, alignment: 'left', fontSize: 8, },                          
          { text: prod.saldo, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
          { text: prod.custoproduto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
          { text: prod.custoreal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
            fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
          { text: prod.valorvenda.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
            fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
        ];
      }
    );     
    return bodyItemMarca; 
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
          '__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        alignment: 'center',
        fontSize: 5,
        colSpan: this.filtros.tipoVisao === 'produto' ? 9 : 7
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];   
    content.push(this.GetTotalizadores());
    return content;
  }

  GetTotalizadores() {
    const totalEstoque = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.saldo, 0);
    const totalCustoProduto = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.custoproduto, 0);
    const totalCustoReal = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.custoreal, 0);
    const totalValorVenda = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorvenda, 0);

    if (this.filtros.tipoVisao === 'produto')
    {
        const totalRowProduto = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 4, 0] },      
          { text: ''},
          { text: ''},  
          { text: ''},
          { text: ''},    
          { text: totalEstoque, bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },      
          { text: totalCustoProduto
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
              bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
          { text: totalCustoReal
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
              bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
          { text: totalValorVenda
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
              bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },             
        ];    
        return totalRowProduto;        
    }

    const totalRowItemMarca = [
      { text: 'Total' , bold: true, alignment: 'left', fontSize: 9, margin:[4, 0, 4, 0] }, 
      { text: ''},
      { text: ''},   
      { text: totalEstoque, bold: true, alignment: 'right', fontSize: 9, margin:[0, 0, 4, 0] },      
      { text: totalCustoProduto
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
      { text: totalCustoReal
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },
      { text: totalValorVenda
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }), 
          bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0] },      
    ];    
    return totalRowItemMarca;    
  }

  OrdenarVetor(vetor) {
     return vetor.sort((x,y) => {
      if (x > y) return 1;
      if (x < y) return - 1;
        return 0
      } 
    ).join(', ')
  }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,
        fontSize: 10,        
        alignment: 'left'    
      },

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      },

      saldoNegativo: {        
        color : 'red',
        alignment : 'right',
        fontSize  : 10
      }
    }   

    const documento = {
      pageSize: 'A4',
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 0],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: `RELATÓRIO DE SALDO DE ESTOQUE\n`, 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito',
        },
        {
          text: `\nVisão: Por ` + this.primeiraLetraMaiuscula(this.filtros.tipoVisao) +
            '  |  Listar : ' + (this.filtros.listarEstoque === 'comestoque' ? 'Somente com Estoque' : 'Todos') +
            '  |  Lojas: ' + this.filtros.lojasSelecionadas +
            '  |  Itens: ' + (this.filtros.itensSelecionados.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.itensSelecionados)) +
            '  |  Marcas: ' + (this.filtros.marcasSelecionadas.length === 0 ? 'Todas' :  this.OrdenarVetor(this.filtros.marcasSelecionadas)) +
            '  |  Categorias: ' + (this.filtros.categoriasSelecionadas.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.categoriasSelecionadas)) +
            '  |  Departamentos: ' + (this.filtros.departamentosSelecionados.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.departamentosSelecionados)) +
            '  |  Coleções: ' + (this.filtros.colecoesSelecionadas.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.colecoesSelecionadas)) +
            '  |  Tamanhos: ' + (this.filtros.tamanhosSelecionados.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.tamanhosSelecionados)) +
            '  |  Cores: ' + (this.filtros.coresSelecionadas.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.coresSelecionadas)) +
            '  |  Referências: ' + (this.filtros.referenciasSelecionadas.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.referenciasSelecionadas)),              
          style: 'campoFiltros'
        },
        {
          text: (this.filtros.saldoNegativoNosTotalizadores ? '\n* Valores de produtos com estoque negativo interferem nos totalizadores' : ''),
          style: 'saldoNegativo',
        },

        {
          layout: 'noBorders',
          table: {
            marginTop: 10,
            headerRows: 1,
            widths:
              (this.filtros.tipoVisao === 'item' || this.filtros.tipoVisao === 'marca'
                ? [30, 30, 150, 80, 80, 80, 70]
                : this.filtros.tipoVisao === 'produto'
                  ? [26, 32, 110, 42, 48, 38, 67, 70, 70]
                  : []),
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };
    return documento;
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}