import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonVendedores({ onClick, width, vendedoresFiltro }) {
  return (    
    <Button 
    style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px'                                   
    }} 
    onClick={onClick}>
        + Vendedores
        <span className="badge badge-light" 
            style={{
                backgroundColor: 'white', 
                color: 'black', 
                marginLeft: '20px', 
                marginTop: '3px'}}>
                {vendedoresFiltro.length > 0 ? vendedoresFiltro.length : ''}
        </span>
    </Button>      
  );
}

export default ButtonVendedores;


