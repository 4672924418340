

import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonConfirmar({ onClick }) {
  return (    
    <Button 
        onClick={onClick} 
        style={{
            backgroundColor:'rgba(57, 142, 30, 1)', 
            marginRight: '30px',
            border: '0px'}
        }
        >Confirmar
    </Button>                             
  );
}

export default ButtonConfirmar;




