import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonReferencias({ onClick, width, referenciasFiltro }) {
  return (    
    <Button style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',    
        borderRadius: '15px'                              
        }}  
        onClick={onClick}>
            + Referência
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {referenciasFiltro.length > 0 ? referenciasFiltro.length : ''}
            </span>
        </Button>         
  );
}

export default ButtonReferencias;
