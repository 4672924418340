import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableManequimCalcado = ({ contexto }) => {
  const [manequimCalcado, setManequimCalcado] = useState([]);
  const [manequimCalcadoData, setManequimCalcadoData] = useState([]);
  const [selectedManequimCalcado, setSelectedManequimCalcado] = useState([]);
  const [busca, setBusca] = useState('');
  const { definirManequimCalcadoSelecionado } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {
    const storedSelectedManequimCalcado = sessionStorage.getItem('selectedManequimCalcado');
    if (storedSelectedManequimCalcado) {
      setSelectedManequimCalcado(JSON.parse(storedSelectedManequimCalcado));
    }
    pesquisaRef.current.focus();
  }, []);

  useEffect(() => {
    if (selectedManequimCalcado.length > 0) {
      sessionStorage.setItem('selectedManequimCalcado', JSON.stringify(selectedManequimCalcado));
    }
  }, [selectedManequimCalcado, manequimCalcado]);

  const handleManequimCalcadoSelection = (id) => {
    const updatedIndexes = selectedManequimCalcado.includes(id)
      ? selectedManequimCalcado.filter((index) => index !== id)
      : [...selectedManequimCalcado, id];
    setSelectedManequimCalcado(updatedIndexes);

    const selectedManequimCalcadoIds = updatedIndexes.map((index) => index.descricao);
    definirManequimCalcadoSelecionado(selectedManequimCalcadoIds);
  };

  const handleRemoverManequimCalcadoSelecionadosClick = (id) => {
    const updatedManequimCalcadoSelecionados = selectedManequimCalcado.includes(id)
      ? selectedManequimCalcado.filter((index) => index !== id)
      : [...selectedManequimCalcado, id];
    setSelectedManequimCalcado(updatedManequimCalcadoSelecionados);

    const selectedManequimCalcadoIds = updatedManequimCalcadoSelecionados.map((index) => index.descricao);
    definirManequimCalcadoSelecionado(selectedManequimCalcadoIds);

    if (selectedManequimCalcadoIds.length === 0) {
      sessionStorage.removeItem('selectedManequimCalcado');
    }
  };

  const handleRowClick = (manequimCalcadoId) => {
    setManequimCalcado((prevManequimCalcado) =>
      prevManequimCalcado.filter((manequimCalcado) => manequimCalcado.id !== manequimCalcadoId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!manequimCalcado.includes(item.id)) {
      const updatedManequimCalcado = [...manequimCalcado, item];
      setManequimCalcado(updatedManequimCalcado);
    }
  };

  const handleAdicionarTodos = () => {
    setSelectedManequimCalcado(manequimCalcado);
    setManequimCalcado([]);

    const selectedManequimCalcadoIds = manequimCalcado.map((index) => index.descricao);
    definirManequimCalcadoSelecionado(selectedManequimCalcadoIds);
    sessionStorage.setItem('selectedManequimCalcado', selectedManequimCalcado);
  }

  const handleRemoverTodos = async () => {
    if (!manequimCalcadoData || manequimCalcadoData.length === 0) {
      getManequimCalcado();
    }
    setManequimCalcado(manequimCalcadoData);
    setSelectedManequimCalcado([]);
    definirManequimCalcadoSelecionado([]);
    sessionStorage.removeItem('selectedManequimCalcado');
  }

  const getManequimCalcado = async () => {
    try {
      let url = `tamanhoproduto/listagem/true/${busca.toUpperCase()}`;  
      await SetTenant();
      const response = await api.get(url);
      const selectedManequimCalcadosIds = selectedManequimCalcado.map(tamanho => tamanho.id);    
      const filteredData = response.data.filter(tamanho => !selectedManequimCalcadosIds.includes(tamanho.id)); 
      setManequimCalcado(filteredData);
      setManequimCalcadoData(filteredData);
    } catch (e) {
      console.log('erro');
    }
  };

  const handlePesquisar = () => {
    getManequimCalcado();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }
    }
  }

  return (
    <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
      <Card style={{ border: '0px' }}>
        <div className="align-left">
          <div className="row">
            <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição do Manequim Calcado"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>
          </div>
        </div>
      </Card>

      <Container>
        <div className="col">
          <Card>
            <div className='col'>
              <ButtonAdicionarTodos onClick={handleAdicionarTodos} />
            </div>
          </Card>
          <Card>
            <table className="table table-striped table-hover">
              <thead className="table mt-3">
                <tr>                  
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white', textAlign: 'center' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {manequimCalcado.map((manequimCalcado) => {
                  if (selectedManequimCalcado.some((mrc) => mrc.id === manequimCalcado.id)) {
                    return null;
                  } else {
                    return (
                      <tr
                        key={manequimCalcado.id}
                        onClick={() => {
                          handleManequimCalcadoSelection(manequimCalcado);
                          handleRowClick(manequimCalcado.id);
                        }}
                      >                        
                        <td className='col text-center'>{manequimCalcado.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Card>
        </div>

        <div className="col">
          <Card>
            <Card>
              <div className='row justify-content-end'>
                <div className='col text-left'>
                  <label className='labelSelecionar'>Selecionados: {selectedManequimCalcado.length}</label>
                </div>
                <div className='col text-right'>
                  <ButtonRemoverTodos onClick={handleRemoverTodos} />
                </div>
              </div>
            </Card>
            <table className="table table-striped table-hover">
              <thead className="table-info mt-3">
                <tr>                  
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white', textAlign: 'center' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {selectedManequimCalcado.map((manequimCalcado) => (
                  <tr key={manequimCalcado.id}
                    onClick={() => {
                      handleRemoverManequimCalcadoSelecionadosClick(manequimCalcado);
                      handleRowSelectedClick(manequimCalcado)
                    }}
                  >                    
                    <td className='col text-center'>{manequimCalcado.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default TableManequimCalcado;

