import moment from 'moment';

export class ImpressaoComprasVendasPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {    
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;             
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {       
      const headerComprasVendasAnaliseItem = [        
        { text: this.filtros.tipoVisaoSelect === 'item' ? 'Item' : 
          this.filtros.tipoVisaoSelect === 'marca' ? 'Marca' : 'Item', bold: true, 
          alignment: 'left', fontSize: 9, margin: [4, 4, 0, 0]},                                                          
        { text: 'Estoque', bold: true, alignment: 'left', fontSize: 9, margin: [0, 4, 0, 0]},      
        { text: 'Estoque R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },   
        { text: 'Compra', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Compra R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 0, 0] },
        { text: 'Venda', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
        { text: 'Venda R$', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
        { text: 'Markup', bold: true, alignment: 'right', fontSize: 9,  margin: [0, 4, 4, 0] },      
        { text: 'Markup Real', bold: true, alignment: 'center', fontSize: 9,  margin: [0, 4, 4, 0] },      
        { text: 'Qtde em Condi', bold: true, alignment: 'center', fontSize: 9,  margin: [0, 4, 4, 0] },      
        { text: 'Qtde Troca', bold: true, alignment: 'center', fontSize: 9,  margin: [0, 4, 4, 0] },      
      ];
      return headerComprasVendasAnaliseItem;       
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2';
  }

  GetBody() {        
    if (this.filtros.tipoVisaoSelect === 'item' || this.filtros.tipoVisaoSelect === 'marca')
    {     
          const bodyItem = this.dadosParaImpressao.map((comp) => {
            return  [
              { text: comp.marcaitemdescricao.substring(0, 17), fontSize: 8, alignment: 'left', margin: [4, 0, 0, 0] },
              { text: comp.estoqueqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: comp.estoquevalor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},                          
              { text: comp.compraqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: comp.compravalor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.vendaqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.vendavalor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.markup.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%', 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.markupreal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%', 
                fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
              { text: comp.condicionalqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
              { text: comp.trocaqtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] }
            ];
          }
        );      
        return bodyItem;
      }      
    }    
  
  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan:  11
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];         
    content.push(this.GetTotalizadores());         
    return content;
  }     

  GetTotalizadores() {
    if (this.filtros.tipoVisaoSelect === 'item' || this.filtros.tipoVisaoSelect === 'marca')
    {
      const totalQtdeEstoque =  this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.estoqueqtde, 0);    
      const totalValorEstoque = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.estoquevalor, 0);
      const totalQtdeCompra = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.compraqtde, 0);
      const totalValorCompra = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.compravalor, 0); 
      const totalQtdeVenda = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.vendaqtde, 0); 
      const totalValorVenda = this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.vendavalor , 0); 
      const totalMarkup = (this.dadosParaImpressao.reduce((acc, row) => acc + row.markup, 0) / this.dadosParaImpressao.length) ;      
      const totalMarkupReal = (this.dadosParaImpressao.reduce((acc, row) => acc + row.markupreal, 0) / this.dadosParaImpressao.length) ;            
      const totalQtdeCondicional =  this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.condicionalqtde, 0);       
      const totalQtdeTroca =  this.dadosParaImpressao.reduce((acc, compvend) => acc + compvend.trocaqtde, 0);             
      
      const totalRowContasPagar = [
        { text: 'Total' , bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0]   },                      
        { text: totalQtdeEstoque, bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },      
        { text: totalValorEstoque.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}),
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0] },                
        { text: totalQtdeCompra, bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0] },                
        { text: totalValorCompra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
        { text: totalQtdeVenda, bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
        { text: totalValorVenda.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'}), 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },        
        { text: totalMarkup.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%', 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
        { text: totalMarkupReal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%', 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
        { text: totalQtdeCondicional,  bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },      
        { text: totalQtdeTroca, bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  }      
      ];    
      return totalRowContasPagar;     
    }      
  }  

  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE COMPRAS X VENDAS', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {               
            text: `\nVisão: Por ${this.primeiraLetraMaiuscula(this.filtros.tipoVisaoSelect)}`           
              + `  |  Período de Venda: ${this.formatarData(this.filtros.dataIntervaloVendaFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloVendaFiltro[1])}`              
              +  `${this.filtros.combinarPeriodo.periodocompra ? `  |  Período de Compra: ${this.formatarData(this.filtros.dataIntervaloCompraFiltro[0])} a ${this.formatarData(this.filtros.dataIntervaloCompraFiltro[1])}` : '' }` 
              + `  |  Lojas: ${this.filtros.lojasFiltro}`              
              + `  |  Itens: ${(this.filtros.itensFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.itensFiltro))}`      
              + `  |  Marcas: ${(this.filtros.marcasFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.marcasFiltro))}`              
              + `\nCategorias: ${(this.filtros.categoriasFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.categoriasFiltro))}`              
              + `  |  Departamentos: ${(this.filtros.departamentosFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.departamentosFiltro))}`              
              + `  |  Coleções: ${(this.filtros.colecoesFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.colecoesFiltro))}`              
              + `  |  Cores: ${(this.filtros.coresFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.coresFiltro))}`              
          ,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: this.filtros.tipoVisaoSelect === 'item' || this.filtros.tipoVisaoSelect === 'marca'
              ? [ 85, 35, 50, 35, 50, 35, 50, 40, 35, 35, 30 ]
              : [ 85, 35, 50, 35, 50, 35, 50, 40, 35, 35, 35 ],
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}