import React from 'react';
import './styles.css'; 

const PaginaPrincipal = () => {  
    return (
        <>
         <div className='background-container'/>
        </>
    );
}

export default PaginaPrincipal;
