import React, { useState, useContext, useEffect, createContext } from 'react';
import api from '../../services/api';
import { CondicionalContext } from '../../pages/Condicional/index';
import { Container, Card } from 'react-bootstrap';
import ModalDetalheProdutoCondi from '../Modal/ModalDetalheProdutoCondi';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { SetTenant } from '../../services/api';

export const TableDetalheCondiContext = createContext();

export const TableDetalheCondi = () => {   
  const sessionStorageUtils = new SessionStorageUtils();   
  const [condicionais, setCondicionais] = useState([]);
  const [condiDetalhe, setCondiDetalhe] = useState([]);
  const { lojasFiltro, vendedoresFiltro, tipoFiltro, 
    dataIntervaloFiltro, clienteDetalhe } = useContext(CondicionalContext);   
  const [showDetProdCondi, setShowDetProdCondi] = useState(false);       

  useEffect((() => {      
    getDetalheCondi();         
  }),[]);

  const handleRowClick = async (props) => 
  {
      await setCondiDetalhe(props);         
      setShowDetProdCondi(true);      
  }  

  const handleCloseDetalheCondi = () => 
  {
      setShowDetProdCondi(false);      
  }

  const tipoStatusFiltroToInt = () => {
    const enumValues = {
      'Pendentes': 1,
      'Todos' : 2,
      'Devolvidos' : 3,
      'Vendidos' : 4,
    };
  
    return enumValues;
  };

  const tipoStatusToInt = tipoStatusFiltroToInt();

  const getDetalheCondi = async () => {
    if (condicionais.length === 0)
    {      
      try 
        {                        
            const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
            const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);   

            let url = `condicional/relatorio/detalhe/${tipoStatusToInt[tipoFiltro]}/${dataIni}/${dataFin}/${lojasFiltro}`;
            
            if (clienteDetalhe !== '') {                
                url += `/${clienteDetalhe}`;
            } else {
                url += "/ ";
            }
            
            if (vendedoresFiltro.length > 0) {
                url += `/${vendedoresFiltro}`;
            } else {
                url += "/ ";
            }
            
            await SetTenant();
            const response = await api.get(url);                                                               
            setCondicionais(response.data);                   
        }
        catch (e)
        {
            console.log('erro ao consultar API');
        }        
    }
  }; 
  
  const contextValues = {
    condiDetalhe    
  }
  
  return (   
    <>                    
      <TableDetalheCondiContext.Provider value={contextValues}>                             
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <Container>
        <div className="col">
          <Card>
          <table className="table table-striped table-hover">
            <thead className="table">
                <tr>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}></th>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Condicional</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Loja</th>
                  <th scope="col" className="text-center" style={{ backgroundColor:'#007CB0', color: 'white' }}>Data</th>                  
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Total</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Valor R$</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Devolvido</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Valor R$</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Vendido</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Valor R$</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Pendente</th>
                  <th scope="col" className="text-right" style={{ backgroundColor: '#007CB0', color: 'white' }}>Valor R$</th>                  
                  </tr>
                </thead>                
                  <tbody style={{cursor: 'pointer'}}>  
                  {condicionais.map((condi) => {
                    const dataFormatada = new Date(condi.data).toLocaleDateString('pt-BR');                      
                      return (
                        <tr
                          key={condi.id}
                          onClick={() => handleRowClick(condi)}
                        >
                          <td className="text-center" style={{ fontWeight: 'bold', fontSize: '18px', backgroundColor: 'rgb(244, 248, 255)' }}> +</td>
                          <td>{condi.id}</td>
                          <td className="text-right" style={{ backgroundColor: 'white' }}>{(condi.lojaid)}</td>
                          <td className="text-right">{dataFormatada}</td>
                          <td className="text-right" style={{ backgroundColor: 'white' }}>{(condi.qtdetotal)}</td>                          
                          <td className="text-right" style={{ backgroundColor: 'white' }}>{(condi.valortotal)
                                .toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td className="text-right" style={{ backgroundColor: 'rgb(244, 248, 255)' }}>{condi.qtdedevolvidas}</td>
                          <td className="text-right" style={{ backgroundColor: 'rgb(244, 248, 255)' }}>{condi.valordevolvidas.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td className="text-right" style={{ backgroundColor: 'white' }}>{condi.qtdevendidas}</td>
                          <td className="text-right" style={{ backgroundColor: 'white' }}>{condi.valorvendidas.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td className="text-right" style={{ backgroundColor: 'rgb(244, 248, 255)' }}>{condi.qtdependentes}</td>
                          <td className="text-right" style={{ backgroundColor: 'rgb(244, 248, 255)' }}>{condi.valorpendentes.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>                          
                        </tr>
                      );
                    }
                  )}                            
                </tbody>                        
              </table>
            </Card>           
          </div>                  
        </Container>
      </div>          
      <ModalDetalheProdutoCondi show={showDetProdCondi} onHide={handleCloseDetalheCondi}/>    
      </TableDetalheCondiContext.Provider>             
      </>              
  );
};

export default TableDetalheCondi;

