import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.css';
import { SetTenant } from '../../services/api';
import { api } from '../../services/api';
import { isEmpty, isMap } from 'lodash';
import { format } from 'date-fns';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

function ModalDetalheVenda(props) {
  const handleClose = () => props.onHide();
  const [dadosDetalheRateio, setDadosDetalheRateio] = useState([]);
  const [produtosVenda, setProdutosVenda] = useState([]);
  const [venda, setVenda] = useState([]);
  const [meioPagamentosVenda, setMeioPagamentosVenda] = useState([]);

  useEffect(() => {
        setTimeout(() => {     
            if (props.vendaid !== 0)  
            {
              setDadosDetalheRateio([]); 
              GetDetalheRateio();                          
            }               
              
        }, 500);                      
    }, [props.vendaid]); 

    const GetDetalheRateio = async() => {                  
        try 
        {                    
            let url = `venda/detalhe/${props.vendaid}`;                                             
            await SetTenant();    
            const response = await api.get(url);                                                                                       
            await setProdutosVenda(response.data.produtos);                           
            await setMeioPagamentosVenda(response.data.meioPagamentos);                           
            await setVenda(response.data);                                       
        }
        catch (e)
        {
            throw('erro');
        }        
    }              

  const columns= [                   
    { field: "competencia",  type: 'date', valueGetter: (params) => new Date(params.row.competencia),
      headerName: "Data",  headerClassName: 'header-detail', 
      headerAlign: 'left', width: 480, align: 'left', cellClassName: () => { return 'value-cell-black' } },  
    { field: "valor", headerName:"Valor R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 150, align: 'right', 
      valueFormatter: (params) => {
        return "R$ " + (params.value > 0 ? parseFloat(params.value) : 0).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) ;
      }, cellClassName: () => { return 'value-cell-black' } 
    },
    { field: "porcentagem", headerName:"%",  headerClassName: 'header-detail',  headerAlign: 'center', width: 110, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) +  "%" ;
      } , cellClassName: () => { return 'value-cell-black' }
    }
  ];   

  const rows = dadosDetalheRateio.map((despesa) => ({        
    id: despesa.id,
    competencia : despesa.competencia,    
    valor: despesa.valor,
    porcentagem: despesa.porcentagem
  }));

  return (    
    <>
    <Modal onHide={handleClose}
      {...props} 
      size="fullscreen" 
      centered      
      aria-labelledby="contained-modal-title-xl">        
      <Modal.Header closeButton style={{backgroundColor:'#EEECEC', maxHeight: 'calc(30vh)'}}>
        <Modal.Title id="contained-modal-title-vcenter" >        
            <div className='row ' >
              <div className='col-auto' >
                <div> <label style={{fontWeight: 'bold', fontSize: 16 , marginLeft: '15%'}}> N° Venda: </label>  </div>
                <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', width: 'calc(33vh)' ,  marginLeft: '15%', textAlign: 'center'}} >
                  {!isEmpty(produtosVenda) ? venda.id : 0}
                </div>     
              </div>

              <div className='col-auto'>
                <div> <label style={{fontWeight: 'bold' , fontSize: 16, marginLeft: '15%' }}> Loja </label>  </div>
                <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', width: 'calc(33vh)' ,  marginLeft: '15%', textAlign: 'center'}} >
                  {(!isEmpty(venda) ? venda.caixa.lojanome : 0)}
                </div>       
              </div>  


              <div className='col-auto'>
                <div> <label style={{fontWeight: 'bold', fontSize: 16, marginLeft: '15%' }}> Data/Hora </label>  </div>
                <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', width: 'calc(33vh)' ,  marginLeft: '15%', textAlign: 'center'}} >
                  {(!isEmpty(venda) ? new Date(venda.data).toLocaleDateString('pt-BR') + " -  " + format(new Date(`2022-01-01T${venda.hora}`), 'HH:mm:ss') : "")}
                </div>       
              </div>  

              <div className='col-auto'>
                <div> <label style={{fontWeight: 'bold', fontSize: 16, marginLeft: '15%' }}> Operador </label>  </div>
                <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', width: 'calc(33vh)' ,  marginLeft: '15%', textAlign: 'center'}} >
                  {(!isEmpty(venda) ? venda.caixa.operadornome : "")}
                </div>       
              </div>  

              <div className='col-auto'>
                <div> <label style={{fontWeight: 'bold', fontSize: 16, marginLeft: '15%' }}> Usuário </label>  </div>
                <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', width: 'calc(33vh)' ,  marginLeft: '15%', textAlign: 'center'}} >
                  {(!isEmpty(venda) ? venda.caixa.usuarionome : "")}
                </div>       
              </div>                
            </div>  

            <div className='row' >
                <div className='col'>
                  <div> <label style={{fontWeight: 'bold', fontSize: 16, marginLeft: '15%' }}> Cliente </label>  </div>
                  <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', marginLeft: '15%', textAlign: 'center'}} >
                    {(!isEmpty(venda) && venda.clientenome  !== "" ? venda.clientenome : "Não Informado")}
                  </div>       
                </div> 

                <div className='col offset-md-7'>
                  <div> <label style={{fontWeight: 'bold', fontSize: 16, marginLeft: '20%'  }}> NFC-e </label>  </div>
                  <div style={{borderRadius: '5px', fontSize: '16px' , backgroundColor: '#FFFFFF', padding: '5px', 
                    width: !isEmpty(venda) && venda.nota  !== "" ? 'auto' : '100px',  marginLeft: '20%' ,textAlign: 'center'}} >
                    {(!isEmpty(venda) ? venda.nota : "")}
                  </div>       
                </div> 
              </div>

        </Modal.Title>
      </Modal.Header>
        <Modal.Body className="show-grid" style={{backgroundColor:'#EEECEC', maxHeight: 'calc(100vh - 265px)', overflowY: 'auto'}}>           
            <>                   
            <div style={{marginTop: '5px'}}>                                 
              <div style={{backgroundColor: '#F3F3F3', borderBottom: '1px solid #ccc'}}></div>
              <label style={{fontWeight: 'bold', marginTop: '10px' }}> Produtos: </label>  
            </div>
            <div style={{ padding: '10px'}}>   
            <div style={{backgroundColor: '#F3F3F3'}}>
              <Table size="small">
                <TableHead > 
                  <TableRow >
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Código</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Descrição</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Cor</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Tamanho</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Qtde</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>P.Unitário R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Preço R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Desconto R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Acréscimo R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Crédito R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Valor Líquido R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Vendedor</TableCell>                    
                  </TableRow>
                </TableHead>
                <TableBody >
                  <>                         
                  {produtosVenda.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.id}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.produtodescricao}
                        </div>
                      </TableCell>                      
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.produtocor}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.produtotamanho}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px'}} >
                          {row.qtde}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.preco.toLocaleString('pt-BR', {style: 'decimal',  minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px' , textAlign:'right'}} >
                          {row.preco.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.desconto.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                       <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.acrescimo.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.credito.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.valorliquido.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px'}} >
                          {row.vendedornome}
                        </div>
                      </TableCell>                                                                
                    </TableRow>                                      
                  ))}                            
                  </> 
                </TableBody>
              </Table>              
              </div>                   
              </div>                   
            </>

          <div style={{marginTop: '5px'}}>                                 
            <div style={{backgroundColor: '#F3F3F3', borderBottom: '1px solid #ccc'}}></div>
            <label style={{fontWeight: 'bold', marginTop: '10px' }}> Meios de Pagamento: </label>  
          </div>
          <div style={{ padding: '10px'}}>                   
            <div style={{backgroundColor: '#F3F3F3'}}>
              <Table size="small">
                <TableHead > 
                  <TableRow >
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Parcela</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Tipo</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Descrição</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Vencimento</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Valor Parcela R$</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Banco</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Agência</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Conta</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Nº Cheque</TableCell>
                    <TableCell style={{color: 'white', backgroundColor: '#008fcc', width: 'auto'}}>Observação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  <>                         
                  {meioPagamentosVenda.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.parcela}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.tipo}
                        </div>
                      </TableCell>                      
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%'}} >
                          {row.descricao}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: '100%', textAlign: 'center'}} >
                          {row.vencimento !== "0001-01-01T00:00:00" ? new Date(row.vencimento).toLocaleDateString('pt-BR') : ""}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.valorparcela.toLocaleString('pt-BR', {style: 'decimal',  minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.banco}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px' , textAlign:'right'}} >
                          {row.agencia}
                        </div>
                      </TableCell>
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.conta}
                        </div>
                      </TableCell>   
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.cheque}
                        </div>
                      </TableCell>                         
                      <TableCell style={{border: 'none'}}>
                        <div style={{borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '5px', textAlign:'right'}} >
                          {row.observacao}
                        </div>
                      </TableCell>                                                                                                                   
                    </TableRow>                                      
                  ))}                            
                  </> 
                </TableBody>
              </Table>              
              </div>                   
            </div>
            <div style={{backgroundColor: '#F3F3F3', borderBottom: '1px solid #ccc'}}></div>
                      
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#F3F3F3', position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', padding: '10px', borderTop: '1px solid #ccc' }}>
          <div className='row justify-content-center' style={{marginRight: 'calc(10vh)'}}>
            <div className='col-auto'>
              <div style={{ textAlign: 'center' }}>
                <label style={{ fontWeight: 'bold' }}> Qtde Itens </label>
              </div>
              <div style={{ borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: 'calc(33vh)', textAlign: 'center' }}>
                {!isEmpty(produtosVenda) ? produtosVenda.reduce((total, pagar) => { return total + pagar.qtde }, 0) : 0}
              </div>
            </div>

            <div className='col-auto'>
              <div style={{ textAlign: 'center' }}>
                <label style={{ fontWeight: 'bold' }}> Valor Total R$ </label>
              </div>
              <div style={{ borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: 'calc(33vh)', textAlign: 'center' }}>
                {(!isEmpty(venda) ? venda.valorprodutos : 0).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
            </div>

            <div className='col-auto'>
              <div style={{ textAlign: 'center' }}>
                <label style={{ fontWeight: 'bold' }}> Descontos R$ </label>
              </div>
              <div style={{ borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: 'calc(33vh)', textAlign: 'center' }}>
                {(!isEmpty(venda) ? venda.descontofinal + venda.descontoitem : 0).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
            </div>

            <div className='col-auto'>
              <div style={{ textAlign: 'center' }}>
                <label style={{ fontWeight: 'bold' }}> Acréscimo R$ </label>
              </div>
              <div style={{ borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: 'calc(33vh)', textAlign: 'center' }}>
                {(!isEmpty(venda) ? venda.acrescimo : 0).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
            </div>

            <div className='col-auto'>
              <div style={{ textAlign: 'center' }}>
                <label style={{ fontWeight: 'bold' }}> Crédito Cliente R$ </label>
              </div>
              <div style={{ borderRadius: '5px', backgroundColor: '#FFFFFF', padding: '8px', width: 'calc(33vh)', textAlign: 'center' }}>
                {(!isEmpty(venda) ? venda.credito : 0).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
            </div>
          </div>
        </Modal.Footer>
    </Modal>    
    </>
  );
}

export default ModalDetalheVenda;