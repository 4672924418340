import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, ClickAwayListener, Collapse, InputLabel } from '@mui/material';
import { AuthContext } from '../contexts/auth';
import './drawerStyles.css';
import { Grade, Gradient, LocalAtmOutlined, LocalMallOutlined, LocalOfferOutlined, Logout, ShoppingCartOutlined, StorefrontOutlined, TipsAndUpdatesOutlined, TipsAndUpdatesRounded, TipsAndUpdatesSharp, TipsAndUpdatesTwoTone, Verified } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import logo from '../img/mascrelatorioscabecalho.png';
import ModalMensagemRelatorioMascPlusPro from '../components/Modal/MensagensAlerta/ModalMensagemRelatorioMascPlusPro';
import ModalMensagemUsuarioSemAcesso from '../components/Modal/MensagensAlerta/ModalMensagemUsuarioSemAcesso';
import Permissoes from '../Utils/Permissoes';

export default function DrawerLeft({ nomegrupo, usuario, plano }) {
  const [open, setOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const permissao = new Permissoes();
  const navigate = useNavigate();

  const [openCollapseCondicional, setOpenCollapseCondicional] = useState(false);
  const [openCollapseFinanceiro, setOpenCollapseFinanceiro] = useState(false);
  const [openCollapseAnaliseDados, setOpenCollapseAnaliseDados] = useState(false);
  const [openCollapseVenda, setOpenCollapseVenda] = useState(false);
  const [openCollapseVendaOpcoes, setOpenCollapseVendaOpcoes] = useState(false);
  const [openCollapseCompras, setOpenCollapseCompras] = useState(false);
  const [openCollapseEstoque, setOpenCollapseEstoque] = useState(false);
  const [openCollapseComissoes, setOpenCollapseComissoes] = useState(false);
  const [showPlanoRestricao, setShowPlanoRestricao] = useState(false);
  const [showUsuarioSemAcesso, SetShowUsuarioSemAcesso] = useState(false);

  const handleCloseModalMensagemRelatorioMascPlusPro = () => {
    setShowPlanoRestricao(false);
    navigate('/home');
  }

  const handleCloseUsuarioSemAcesso = () => {
    SetShowUsuarioSemAcesso(false);
  }

  const handleCollapseToggle = (value) => {
    const collapseStates = {
      'condicional': setOpenCollapseCondicional,
      'venda': setOpenCollapseVenda,
      'vendas': setOpenCollapseVendaOpcoes,
      'estoque': setOpenCollapseEstoque,
      'financeiro': setOpenCollapseFinanceiro,
      'compra': setOpenCollapseCompras,
      'comissoes': setOpenCollapseComissoes,
      'analisedados': setOpenCollapseAnaliseDados,
    };

    Object.keys(collapseStates).forEach(key => {
      const stateSetter = collapseStates[key];
      stateSetter(prevOpen => (key === value ? !prevOpen : false));
    });
  };

  const handleDrawerOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  function RelatorioMascPlusPro() {
    const _PlanoMasc = 1;
    if (plano !== _PlanoMasc) {
      return 1;
    }

    setShowPlanoRestricao(true);
    return 0;
  }

  async function ValidarPermissaoAcessoRelatorio(descricao) {
    const autorizado = await permissao.ValidarPermissaoAcessoRelatorio(descricao);
    if (autorizado) {
      return true;
    }

    await SetShowUsuarioSemAcesso(true);
    return false;
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <ModalMensagemRelatorioMascPlusPro show={showPlanoRestricao} onHide={handleCloseModalMensagemRelatorioMascPlusPro} />
        <ModalMensagemUsuarioSemAcesso show={showUsuarioSemAcesso} onHide={handleCloseUsuarioSemAcesso} />

        <div className={'rootDrawer'}>
          <CssBaseline />
          <AppBar
            style={{ backgroundColor: '#004A82' }}
            position="static"
            className={clsx('appBar', {

            })}
          >
            <Toolbar >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx('menuButton', open && 'hide')}
              >
                <MenuIcon />
              </IconButton>

              <img
                alt="Logo"
                src={logo}
                style={{ width: '130px', height: '29.57px', cursor: 'pointer', marginLeft: '20px' }}
                onClick={() => { navigate('/home') }}
              />

              <Typography variant="h6" noWrap style={{ marginLeft: '42%' }}>
                <div className='row'>
                  <div className='col'>
                    <InputLabel style={{
                      fontSize: '12px', alignItems: 'right', color: 'white', marginLeft: '170px', marginTop: '10px',
                      fontFamily: 'Arial, sans-serif'
                    }}>Grupo: {nomegrupo} </InputLabel>
                  </div>
                  <div className='col'>
                    <InputLabel style={{
                      fontSize: '12px', color: 'white', width: 'auto', marginLeft: '20px', marginTop: '10px',
                      fontFamily: 'Arial, sans-serif'
                    }}> Usuário : {usuario} </InputLabel>
                  </div>
                  <div className='col'>
                    <Logout style={{ width: '50px', height: '25px', marginLeft: '30px', color: 'white', cursor: 'pointer' }}
                      onClick={handleLogout} />
                  </div>

                </div>

              </Typography>
            </Toolbar>
          </AppBar>

          <ClickAwayListener onClickAway={handleDrawerClose}>
            <Drawer
              className={'drawer'}
              variant="persistent"
              anchor="left"

              open={open}
              style={{
                paper: {
                  backgroundColor: '#004A82'
                },
              }}
            >
              <div className={'drawerHeader'}>
                <IconButton onClick={handleDrawerClose}>
                  {'direction' === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }} /> : <ChevronLeftIcon style={{ color: 'white' }} />}
                </IconButton>
              </div>

              <div style={{ backgroundColor: '#004A82', height: '100%' }}>
                <ListItem className='list-icon' onClick={() => handleCollapseToggle('condicional')} >
                  <ListItemIcon>
                    <LocalMallOutlined style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Condicional" />
                  {openCollapseCondicional ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={openCollapseCondicional} timeout="auto" unmountOnExit className='colapse'>
                  <List component="div" disablePadding>
                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('49c721cdf04d3a6f9721cdf073562527')) {
                          navigate('/condicional');
                          setOpenCollapseCondicional(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>

                      </ListItemIcon>
                      <ListItemText primary="Condicional" />
                    </ListItem>

                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (RelatorioMascPlusPro() === 1) {
                          if (await ValidarPermissaoAcessoRelatorio('50967e74b1313709c384b61c4b32d566')) {
                            navigate('/analisecondicional');
                            setOpenCollapseCondicional(false);
                            handleDrawerClose()
                          }
                        }
                      }
                      }
                    >
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Análise de Condicional" />
                    </ListItem>

                  </List>
                </Collapse>

                <ListItem className='list-icon' onClick={() => handleCollapseToggle('venda')}>
                  <ListItemIcon>
                    <StorefrontOutlined style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Vendas" />
                  {openCollapseVenda ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={openCollapseVenda} timeout="auto" unmountOnExit className='colapse'>
                  <List component="div" disablePadding>

                    <ListItem className='list-icon-detail'
                      onClick={async () => { setOpenCollapseVendaOpcoes(!openCollapseVendaOpcoes) }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Vendas" />
                      {openCollapseVendaOpcoes ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </ListItem>

                    <Collapse in={openCollapseVendaOpcoes} timeout="auto" unmountOnExit className='colapse'>
                      <List component="div" disablePadding>
                        <ListItem className='list-icon-sub-detail'
                          onClick={async () => {
                            if (await ValidarPermissaoAcessoRelatorio('615e06c9b8c149c8ed9d156112c5d4a4')) {
                              navigate('/vendaperiodo');
                              setOpenCollapseVenda(false);
                              handleDrawerClose()
                            }
                          }}>
                          <ListItemIcon>

                          </ListItemIcon>
                          <ListItemText primary="Por Período" />
                        </ListItem>
                        <ListItem className='list-icon-sub-detail'
                          onClick={async () => {
                            if (await ValidarPermissaoAcessoRelatorio('43f29f33616124826d7ac38bcbd160a7')) {
                              navigate('/vendaproduto');
                              setOpenCollapseVenda(false);
                              handleDrawerClose()
                            }
                          }}>
                          <ListItemIcon>
                          </ListItemIcon>
                          <ListItemText primary="Por Produto" />
                        </ListItem>
                      </List>
                    </Collapse>

                    <ListItem className='list-icon-detail'
                      onClick={async () => setOpenCollapseComissoes(!openCollapseComissoes)}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Comissões" />
                      {openCollapseComissoes ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </ListItem>

                    <Collapse in={openCollapseComissoes} timeout="auto" unmountOnExit className='colapse'>
                      <List component="div" disablePadding>
                        <ListItem className='list-icon-sub-detail'
                          onClick={async () => {
                            if (await ValidarPermissaoAcessoRelatorio('f285ff6dd0200b6d2fa433f3ee20d226')) {
                              navigate('/comissao/vendedor');
                              setOpenCollapseVenda(false);
                              handleDrawerClose()
                            }
                          }}>
                          <ListItemIcon>

                          </ListItemIcon>
                          <ListItemText primary="Por Vendedor" />
                          <Grade style={{ color: 'yellow', height: '15px', marginBottom: '15px' }} />
                        </ListItem>

                        <ListItem className='list-icon-sub-detail'
                          onClick={async () => {
                            if (await ValidarPermissaoAcessoRelatorio('aa3670cde890704ff3f3a570752933bc')) {
                              navigate('/comissao/recebimento');
                              setOpenCollapseVenda(false);
                              handleDrawerClose()
                            }
                          }}>
                          <ListItemIcon>

                          </ListItemIcon>
                          <ListItemText primary="Por Recebimento" />
                          <Grade style={{ color: 'yellow', height: '15px', marginBottom: '15px' }} />
                        </ListItem>

                      </List>
                    </Collapse>

                  </List>
                </Collapse>

                <ListItem className='list-icon' onClick={() => handleCollapseToggle('financeiro')}>
                  <ListItemIcon>
                    <LocalAtmOutlined style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Financeiro" />
                  {openCollapseFinanceiro ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={openCollapseFinanceiro} timeout="auto" unmountOnExit className='colapse'>
                  <List component="div" disablePadding   >
                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('29547c214462c2b17c1305aee0900fa6')) {
                          navigate('/crediario');
                          setOpenCollapseFinanceiro(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>

                      </ListItemIcon>
                      <ListItemText primary="Crediário" />
                    </ListItem>

                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('f90699314233bcb8b8d0eeecdd28ca98')) {
                          navigate('/contasapagar');
                          setOpenCollapseFinanceiro(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Contas a Pagar" />
                    </ListItem>

                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('ae600b9f2fe038414e3b5f0fa1705551')) {
                          navigate('/entradasaidas');
                          setOpenCollapseFinanceiro(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>

                      </ListItemIcon>
                      <ListItemText primary="Entradas x Saídas" />
                    </ListItem>

                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('49627bfa0fef58023fecfc6f7f828546')) {
                          if (RelatorioMascPlusPro() === 1) {
                            navigate('/dre');
                            setOpenCollapseFinanceiro(false);
                            handleDrawerClose()
                          }
                        }
                      }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="D.R.E" />
                      <Grade style={{ color: 'yellow', height: '15px', marginBottom: '15px' }} />
                    </ListItem>

                  </List>
                </Collapse>

                <ListItem className='list-icon' onClick={() => handleCollapseToggle('estoque')}>
                  <ListItemIcon>
                    <LocalOfferOutlined style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Estoque" />
                  {openCollapseEstoque ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={openCollapseEstoque} timeout="auto" unmountOnExit className='colapse'>
                  <List component="div" disablePadding>
                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('1506f1e306f68021acc3d4343c745712')) {
                          navigate('/estoque');
                          setOpenCollapseEstoque(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>

                      </ListItemIcon>
                      <ListItemText primary="Saldo de Estoque" />
                    </ListItem>

                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('b7de6e30a193716d571b7836f96b19eb')) {
                          navigate('/estoque/contagem');
                          setOpenCollapseEstoque(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Contagem de Estoque" />
                    </ListItem>

                  </List>
                </Collapse>


                <ListItem className='list-icon' onClick={() => handleCollapseToggle('compra')}>
                  <ListItemIcon>
                    <ShoppingCartOutlined style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Compras" />
                  {openCollapseCompras ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={openCollapseCompras} timeout="auto" unmountOnExit className='colapse'>
                  <List component="div" disablePadding>
                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('50da04b6c20733ac293539832948a787')) {
                          navigate('/comprasfornecedor');
                          setOpenCollapseCompras(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Por Fornecedor" />
                    </ListItem>

                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('b4d6c647ce79f32092d60b6182ca46d8')) {
                          navigate('/comprasvendas');
                          setOpenCollapseCompras(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="Compras x Venda" />
                    </ListItem>
                  </List>
                </Collapse>

                {usuario === 'DEV' && (
                  <>
                <ListItem className='list-icon' onClick={() => handleCollapseToggle('analisedados')}>
                  <ListItemIcon>
                    <TipsAndUpdatesOutlined style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText primary="Análise de Dados" />
                  {openCollapseAnaliseDados ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={openCollapseAnaliseDados} timeout="auto" unmountOnExit className='colapse'>
                  <List component="div" disablePadding   >
                    <ListItem className='list-icon-detail'
                      onClick={async () => {
                        if (await ValidarPermissaoAcessoRelatorio('278b9108364683ec3c19264d0f2cf689')) {
                          navigate('/analisedados/clientes');
                          setOpenCollapseAnaliseDados(false);
                          handleDrawerClose()
                        }
                      }}>
                      <ListItemIcon>

                      </ListItemIcon>
                      <ListItemText primary="Listagem de Cliente" />
                    </ListItem>
                  </List>
                </Collapse>
                </>
                )}

              </div>
            </Drawer>
          </ClickAwayListener>
        </div>
      </div>
    </>
  );
}
