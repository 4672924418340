import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Button, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableContas = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [contas, setContas] = useState([]);  
  const [contasData, setContasData] = useState([]);
  const [selectedContas, setSelectedContas] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirContasSelecionadas } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedContas = sessionStorage.getItem('selectedContas');
    if (storedSelectedContas) {    
      setSelectedContas(JSON.parse(storedSelectedContas));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedContas.length > 0)
   {
      sessionStorage.setItem('selectedContas', JSON.stringify(selectedContas));
   }       
  }, [selectedContas, contas]);  

  const handleItemSelection = (id) => {
    const updatedIndexes = selectedContas.includes(id)
      ? selectedContas.filter((index) => index !== id)
      : [...selectedContas, id];
    setSelectedContas(updatedIndexes);   

    const selectedContasIds = updatedIndexes.map((index) => index.id);
    definirContasSelecionadas(selectedContasIds);
  };

  const handleRemoverItensSelecionadosClick = (id) => {
    const updatedContasSelecionados = selectedContas.includes(id)
      ? selectedContas.filter((index) => index !== id)
      : [...selectedContas, id];
    setSelectedContas(updatedContasSelecionados);   

    const selectedContasIds = updatedContasSelecionados.map((index) => index.id);
    definirContasSelecionadas(selectedContasIds);

    if (selectedContasIds.length === 0)
    {
      sessionStorage.removeItem('selectedContas');
    }
  };

  const handleRowClick = (contaId) => {
    setContas((prevContas) =>
      prevContas.filter((conta) => conta.id !== contaId)
    );
  };

  const handleRowSelectedClick = (conta) => {

    if (!contas.includes(conta.id))
    {
        const updatedItens = [...contas, conta];
        setContas(updatedItens);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedContas(contas);
       setContas([]);
       
       const selectedContasIds = contas.map((index) => index.id);
       definirContasSelecionadas(selectedContasIds);       
       sessionStorage.setItem('selectedContas', selectedContas);      
  }

  const handleRemoverTodos = async() => {         
      if (!contasData || contasData.length === 0)
      {
        getContas(); 
      }      
      setContas(contasData);
      setSelectedContas([]);    
      definirContasSelecionadas([]);         
      sessionStorage.removeItem('selectedContas');      
  }

  const getContas = async () => {   
    try {
      let url = 'conta/despesas';

      if (busca !== '') {
        url = `conta/descricao/despesas/${busca.toUpperCase()}`;
      }
      
      await SetTenant();
      const response = await api.get(url);
      setContas(response.data);   
      setContasData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getContas();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a conta de despesa"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {contas.map((item) => {
                  if (selectedContas.some((cli) => cli.id === item.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={item.id}
                        onClick={() => {
                          handleItemSelection(item);
                          handleRowClick(item.id);
                        }}
                      >
                        <td>{item.id}</td>
                        <td>{item.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedContas.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedContas.map((item) => (
                    <tr key={item.id}
                        onClick={() => {
                          handleRemoverItensSelecionadosClick(item);
                          handleRowSelectedClick(item)
                        }}
                    >
                      <td>{item.id}</td>
                      <td>{item.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableContas;

