import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonCores({ onClick, width, coresFiltro }) {
  return (    
    <Button style={{ 
        backgroundColor: '#007CB0',
        width: width, 
        height: '30px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',                                  
        borderRadius: '15px'
        }}  
        onClick={onClick}>
            + Cor
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {coresFiltro.length > 0 ? coresFiltro.length : ''}
            </span>
        </Button>         
  );
}

export default ButtonCores;
