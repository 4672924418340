import  React , { createContext, useState, useEffect } from 'react'; 
import {    
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
  } from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import PaginationItem from '@mui/material/PaginationItem';
import "react-datepicker/dist/react-datepicker.css";
import { Container, Pagination, Typography, Stack, Grid, Paper, Radio } from '@mui/material';
import { api } from '../../services/api';
import { Card } from 'react-bootstrap';
import { format, parse } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalClientes from '../../components/Modal/ModalClientes';
import ModalVendedores from '../../components/Modal/ModalVendedores';
import ModalImpressaoResumidoDetalhado from '../../components/Modal/ModalImpressaoResumidoDetalhado';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import ButtonClientes from '../../components/Buttons/ButtonClientes';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { ImpressaoVendaPDF } from '../../components/Impressao/ImpressaoVendaPeriodoPDF';
import './styles.css';
import { isEmpty } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import { startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Box } from '@mui/system';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonVendedores from '../../components/Buttons/ButtonVendedores';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function DetailPanelContent({ row: rowProp }) {   
    return (
      <Container sx={{ height: '100%' }}>
        <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
          <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
            <Stack direction="column" spacing={1} sx={{ height: '100%' }}>           
              <Grid container sx={{ flexGrow: 1 }}>
                <Grid item md={6}>                
                </Grid>
              </Grid>
              <Container sx={{ height: '100%' }}>           
                <CustomStyledDataGrid                       
                  density="compact"
                  rowHeight={40}
                  autoHeight                
                  columns={columnsDetalheVenda}                
                  rows={rowProp.vendaMovimentos}               
                  sx={{ flex: 1, height: '100%' }}                  
                  hideFooter
                />
              </Container>             
            </Stack>
          </Paper>
        </Stack>
      </Container>
    );
  }

  const columnsDetalheVenda= [     
    { field: "vendedorid", headerName: "Cód",  headerClassName: 'header-detail', width: 30 },
    { field: "vendedornome", headerName: "Vendedor",  headerClassName: 'header-detail', width: 80 },   
    { field: "produtoid", headerName: "Prod.",  headerClassName: 'header-detail', width: 60 },   
    { field: "produtodescricao", headerName: "Desc. Produto",  headerClassName: 'header-detail', width: 215 },   
    { field: "produtotamanho", headerName: "Tamanho",  headerClassName: 'header-detail', width: 70 },   
    { field: "produtocor", headerName: "Cor",  headerClassName: 'header-detail', width: 100 },   
    { field: "desconto", headerName: "Desconto",  headerClassName: 'header-detail', width: 80, align: 'right', 
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },   
    { field: "acrescimo", headerName: "Acréscimo",  headerClassName: 'header-detail', width: 90, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }, },   
    { field: "credito", headerName: "Crédito",  headerClassName: 'header-detail', width: 80, align: 'right', 
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },   
    { field: "qtde", headerName: "Qtde",  headerClassName: 'header-detail', align: 'right', width: 40 },
    { field: "valorunitario", headerName: "V. Unitário",  headerClassName: 'header-detail', width: 90, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      },
    },          
    { field: "valorliquido", headerName: "V. Líquido",  headerClassName: 'header-detail', width: 90, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      },
    },          
   ];    
  
export const VendaPeriodoContext = createContext();

  export const VendaPeriodoProvider = () => {   
    const sessionStorageUtils = new SessionStorageUtils();  
    const PAGE_SIZE = 50; 
    const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0,});         
    const [nomeGrupo, setNomeGrupo] = useState([]);
    const [showLojas, setShowLojas] = useState(false);   
    const [showClientes, setShowClientes] = useState(false);
    const [showVendedores, setShowVendedores] = useState(false);
    const [dadosVenda, setDadosVenda] = useState([]);      
    const [tipoVisaoSelect, setTipoVisaoSelect] = useState('dia');
    const [lojasFiltro, setLojasFiltro] = useState([]); 
    const [clientesFiltro, setClientesFiltro] = useState([]);
    const [vendedoresFiltro, setVendedoresFiltro] = useState([]);   
    const [showTipoImpRel, setShowTipoImpRel] = useState(false);
    const [tipoImpRel, setTipoImpRel] = useState('');
    const [loading, setLoading] = useState(true);              
    const [limparFiltros, SetLimparFiltros] = useState(false);           
    const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([startOfMonth(new Date()), new Date()]);    
    const navigate = useNavigate();
    const permissao = new Permissoes();       
 
    useEffect(() => {
        setTimeout(() => {     
            setLoading(true);   
            if (ValidarPermissoes() == false) { return; }     
            LimparFiltrosSelecionados();   
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []);  

    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('615e06c9b8c149c8ed9d156112c5d4a4');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  
    
    useEffect(() => {
        if (dadosVenda && dadosVenda.length > 0 && tipoImpRel !== '')
        {
            ImprimirRelatorio();          
        }      
    }, [tipoImpRel]);  

    useEffect(() => {        
        if (limparFiltros)
        {
            definirLojaInicial();            
            SetLimparFiltros(false);
        }        
    }, [limparFiltros]);

    const handleLimparFiltros = async () => {   
        resetarDataInicial();
        LimparFiltrosSelecionados();                                
        SetLimparFiltros(true);
    }

    const resetarDataInicial = () => {
        setDataIntervaloFiltro([
          startOfMonth(new Date()),
          new Date()
        ]);
      };

    function LimparFiltrosSelecionados() {
        sessionStorage.removeItem('selectedLojas');
        sessionStorage.removeItem('selectedClientes');
        sessionStorage.removeItem('selectedVendedores');
        setLojasFiltro([]);
        setClientesFiltro([]);
        setVendedoresFiltro([]);     
        setDadosVenda([]);                  
      }  

    const ImprimirRelatorio = async () => {
        const classeImpressao = new ImpressaoVendaPDF(dadosVenda, filtrosSelecionados);      
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));         
        setShowTipoImpRel(false);        
    }

    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
      setNomeGrupo(groupName);
    }
    
    const renderTable = () => {
        if (tipoVisaoSelect === 'venda') {
          return (
              <div className='table-align'>
              <table className="table">
                <thead>
                  <th scope="col" style={{width: '100px'}}></th>
                  <th scope="col" className="text-right" style={{width: '140px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '380px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '100px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '100px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '100px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '100px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '130px', fontSize: '13px'}}></th>
                </thead>
                <tbody>
                  <tr>
                    <th className="text-left" scope="row" style={{backgroundColor: '#DAEBF2'}}>Total</th>
                    <th className="text-left" scope="row" style={{backgroundColor: '#DAEBF2'}}>Qtde Vendas:</th>
                    <td className="text-left"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>
                      {!isEmpty(totalizador) ? totalizador.qtdevendas : 0}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {!isEmpty(totalizador) ? totalizador.qtdeitens : 0}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {(!isEmpty(totalizador) ? totalizador.desconto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {(!isEmpty(totalizador) ? totalizador.acrescimo : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'}}>
                      {(!isEmpty(totalizador) ? totalizador.credito : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'}}>
                      {(!isEmpty(totalizador) ? totalizador.valorliquido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>                
          )
        }
        else
        {
            return (
            <div className='table-align'>
              <table className="table">
                <thead>
                  <th scope="col" style={{width: '340px'}}></th>
                  <th scope="col" className="text-right" style={{width: '150px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '150px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '13px'}}></th>
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '13px'}}></th>
                </thead>
                <tbody>
                  <tr>
                    <th className="text-left" scope="row" style={{backgroundColor: '#DAEBF2'}}>Total</th>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {!isEmpty(totalizador) ? totalizador.qtdeitens : 0}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {(!isEmpty(totalizador) ? totalizador.qtdevendas : 0)}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {(!isEmpty(totalizador) ? (totalizador.valorliquido/totalizador.qtdevendas) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {(!isEmpty(totalizador) ? totalizador.desconto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                    <td className="text-right"
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'}}>
                      {(!isEmpty(totalizador) ? totalizador.valorliquido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>                  
            )
        }
     }

    function CustomPagination() {     
              const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
        const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
      
        return (
            <>
            <div>           
            <div className='table-align'>
              {renderTable()}
            </div>            
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            Total de registros: {totalRowCount}
                        </Typography>
                    </div>

                    <div style={{marginLeft: '50px'}}>
                        <Typography variant="subtitle2" color="textSecondary" >
                            Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                        </Typography>
                    </div>
                </div>
                    
                    <div style={{ alignSelf: 'flex-end'}}>
                        <Pagination
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            page={page + 1}
                            count={pageCount}                            
                            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                            onChange={(event, value) => apiRef.current.setPage(value - 1)}
                        />
                    </div>
                </div>            
            </div>
         
          </>
          );
        
      }
    
     
    const definirLojaInicial = async()  => 
    {        
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                             
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
        {                        
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant(); 
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;             
            await setLojasFiltro(firstId);               
        }          
    }

    const filtrosSelecionados = {
      tipoVisao : tipoVisaoSelect,
      lojasSelecionadas : lojasFiltro,
      datasFiltro : dataIntervaloFiltro,
      clientesFiltro : clientesFiltro,
      vendedoresFiltro : vendedoresFiltro,
      nomeGrupo : nomeGrupo,      
      tipoImpressao : tipoImpRel
    }  

    const visualizarImpressao = async  () => {        
      if (tipoVisaoSelect === 'venda')
      {
         await handleShowTipoImpressao();                                 
      }
      else
      {
          await ImprimirRelatorio();          
      }      
      await setTipoImpRel('');    
    }      

    const handleExecutar = async() => {                                                                     
        setLoading(true);
        await getVendaDetalhe();                         
        setLoading(false);
    }; 

   const totalizador = dadosVenda.reduce((total, obj) => {
    if (tipoVisaoSelect === 'venda')
    {
        total.qtdeitens += obj.vendas.reduce((total, vendas) => {return total + vendas.qtdeitens}, 0);       
        total.desconto += obj.vendas.reduce((total, vendas) => {return total + vendas.desconto}, 0);
        total.credito += obj.vendas.reduce((total, vendas) => {return total + vendas.credito}, 0);
        total.acrescimo += obj.vendas.reduce((total, vendas) => {return total + vendas.acrescimo}, 0);
        total.valorliquido += obj.vendas.reduce((total, vendas) => {return total + vendas.valorliquido}, 0); 
        total.qtdevendas++;
        return total;
    }
    else
    {
        total.qtdeitens += obj.qtdeitens;
        total.qtdevendas += obj.qtdevendas;
        total.ticketmedio += obj.ticketmedio;
        total.desconto += obj.desconto;        
        total.valorliquido += obj.valorliquido;             
        return total;
     }        
    }, { qtdeitens: 0, qtdevendas: 0, ticketmedio: 0, desconto: 0, credito: 0, acrescimo : 0, valorliquido: 0});

    const tipoVisaoFiltroToInt = () => {
        const enumValues = {
          'dia': 1,
          'mes' : 2,
          'venda' : 3,
        };
      
        return enumValues;
      };    

    const tipoVisaoToInt = tipoVisaoFiltroToInt();
    
    const getVendaDetalhe = async() => {                  
        try 
        {                                                
            let url = `venda/relatorio/analise`;

            switch (tipoVisaoToInt[tipoVisaoSelect])
            {
                case 1 : {
                    let dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
                    let dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`); 
                     url += `/dia/${dataIni}/${dataFin}/${lojasFiltro}`;
                }
                    break;
                case 2 : {
                    let mesIni = await new Date(dataIntervaloFiltro[0].getFullYear(), dataIntervaloFiltro[0].getMonth(), 1);
                    mesIni = await format(mesIni, "yyyy-MM-dd");  
                    let mesFim = await new Date(dataIntervaloFiltro[1].getFullYear(), dataIntervaloFiltro[1].getMonth()+1, 0);
                    mesFim = await format(mesFim, "yyyy-MM-dd");                 

                    console.log('data ini: ' + mesIni + ' data fim: ' + mesFim);
                    url += `/mes/${mesIni}/${mesFim}/${lojasFiltro}`;    
                }
                    break;
                case 3 : {
                    let dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
                    let dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`); 
                        url += `/venda/${dataIni}/${dataFin}/${lojasFiltro}`;
                }
                    break;
            }                       

            if (clientesFiltro.length > 0) {                
                url += `/${clientesFiltro}`;
            } else {
                url += "/ ";
            }
            
            if (vendedoresFiltro.length > 0) {
                url += `/${vendedoresFiltro}`;
            } else {
                url += "/ ";
            }                 

            setDadosVenda([]);            
            await SetTenant(); 
            const response = await api.get(url);                                                                           
            setDadosVenda(response.data);             
        }
        catch (e)
        {
            console.log('erro');
        }        
    }        
    
    const handleShowLojas = () => 
   {            
        setShowLojas(true);
   }   

   const handleShowVendedores = () => 
   {
        setShowVendedores(true);
   }
    
    const handleCloseLojas = () => 
   {        
        setShowLojas(false);                           
   }

   const handleCloseClientes = () => 
   {        
        setShowClientes(false);                           
   }

   const handleCloseVendedores = () => 
   {
        setShowVendedores(false);
   }
   
   const handleCloseTipoImpressao = () => 
   {            
        setShowTipoImpRel(false);
   }  

   const handleShowTipoImpressao = () => 
   {            
        setShowTipoImpRel(true);
   }  

   const handleShowClientes = () => 
   {            
        setShowClientes(true);
   }     

    const definirClientesSelecionados = (id) => 
    {
        setClientesFiltro(id);        
    }

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }  
    
    const definirVendedoresSelecionados = (id) =>
    {
        setVendedoresFiltro(id);
    }             

    const definirTipoImpressaoRelatorio= (bool) =>
    {
        setTipoImpRel(bool);
    }  
    
    const rowsVenda = dadosVenda.map((array) => {
        if (tipoVisaoSelect === 'venda') {
            const vendas = array.vendas[0];           
          
            const vendaMovimentos = array.vendaMovimentos.map((vendaMovimentos) => {         
              return {
                id: vendaMovimentos.id,               
                vendedorid : vendaMovimentos.vendedorid,    
                vendedornome: vendaMovimentos.vendedornome,                    
                produtoid: vendaMovimentos.produtoid,
                produtodescricao : vendaMovimentos.produtodescricao,
                produtotamanho : vendaMovimentos.produtotamanho,
                produtocor : vendaMovimentos.produtocor,
                qtde : vendaMovimentos.qtde,
                desconto : vendaMovimentos.desconto,
                acrescimo : vendaMovimentos.acrescimo,
                credito : vendaMovimentos.credito,
                valorliquido : vendaMovimentos.valorliquido,             
                valorunitario : vendaMovimentos.valorunitario             
                };                            
            }
      );
      
        vendas.vendaMovimentos = vendaMovimentos; 
      
        const  vendaInfo = {
          id: vendas.id,          
          lojaid : vendas.lojaid,
          venda : vendas.id,
          nota : vendas.numeronfce,
          data: new Date(vendas.data),
          vendedorid : vendas.vendedorid,
          vendedornome : vendas.vendedornome,
          qtdeitens : vendas.qtdeitens,
          desconto : vendas.desconto,
          acrescimo : vendas.acrescimo,
          credito : vendas.credito,
          valorliquido : vendas.valorliquido, 
          vendaMovimentos: vendaMovimentos
        };      
        return vendaInfo;
      }   
      });            

       const rows = dadosVenda.map((row) => {    
        if (tipoVisaoSelect === 'dia' || tipoVisaoSelect === 'mes')      
        {
            const vendaInfo = {
                id : row.lojaid + row.data + row.valorliquido,
                lojaid : row.lojaid,
                data: new Date(row.data),           
                qtdeitens: (row.qtdeitens),
                desconto: row.desconto,     
                qtdevendas: row.qtdevendas,
                ticketmedio: row.ticketmedio,                     
                valorliquido: row.valorliquido}   
            return vendaInfo;                       
        }            
        }                           
      );

    const columns = [              
        { field: "lojaid", headerName: "Cód Loja",  headerClassName: 'blue-header', width: 100 },
        { field: "data", 
            headerName: tipoVisaoSelect === 'dia' ? "Data" : "Mês",  
            type : 'date',
            valueFormatter: (params) => {
                if (tipoVisaoSelect === 'mes') {
                  return (format(new Date(params.value), 'MMMM/yyyy', { locale: dateFnsPtBR })
                          .charAt(0).toUpperCase() + format(new Date(params.value), 'MMMM/yyyy', { locale: dateFnsPtBR }).slice(1));                
                } else {
                  return format(new Date(params.value), 'dd/MM/yyyy', { locale: dateFnsPtBR });
                }
              },
            sortComparator: (v1, v2, cellParams1, cellParams2) => {                
                const date1 = typeof v1 === 'string' ? parse(v1, 'MMMM/yyyy', new Date()) : v1;
                const date2 = typeof v2 === 'string' ? parse(v2, 'MMMM/yyyy', new Date()) : v2;
          
                return date1.getTime() - date2.getTime();
              },   
            headerClassName: 'blue-header', width: 240 
        },
        { field: "qtdeitens", headerName: "Qtde Itens",  headerClassName: 'blue-header', headerAlign: 'right', width: 150, align: 'right'},                   
        { field: "qtdevendas", headerName: "Qtde Vendas",  headerClassName: 'blue-header', headerAlign: 'right', width: 150, align: 'right'},           
        { field: "ticketmedio", headerName:"Ticket Médio R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right', 
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
          }
        },        
        { field: "desconto", headerName:"Desconto R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right', 
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },        
        { field: "valorliquido", headerName:"Valor Líquido R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 170, align: 'right',
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
          },}        
    ];  

    const columnsVenda = [ 
        {
          headerClassName: 'blue-header',
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => (
            <CustomDetailPanelToggle id={params.id} value={params.value} />
          ),
        },                
        { field: "lojaid", headerName: "Loja",  headerClassName: 'blue-header', width: 35},
        { field: "data", 
            headerName: "Data",  
            type : 'date',           
            headerClassName: 'blue-header', width: 100 
        },
        { field: "venda", headerName: "Venda",  headerClassName: 'blue-header', headerAlign: 'right', width: 70, align: 'right'},           
        { field: "nota", headerName: "Nota",  headerClassName: 'blue-header', headerAlign: 'right', width: 90, align: 'right'},           
        { field: "vendedorid", headerName: "Vendedor",  headerClassName: 'blue-header', headerAlign: 'right', width: 90, align: 'right'},           
        { field: "vendedornome", headerName: "Vendedor Nome",  headerClassName: 'blue-header', headerAlign: 'left', width: 170, align: 'left'},           
        { field: "qtdeitens", headerName: "Qtde Itens",  headerClassName: 'blue-header', type : 'number',  headerAlign: 'right', width: 100, align: 'right'},           
        { field: "desconto", headerName:"Desconto R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }
        },        
        { field: "acrescimo", headerName:"Acréscimo R$",  headerClassName: 'blue-header', headerAlign: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },  width: 100, align: 'right'},        
        { field: "credito", headerName:"Crédito R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right', 
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
          }
        },        
        { field: "valorliquido", headerName:"Valor Líquido R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right',
            valueFormatter: (params) => {
              return parseFloat(params.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              });
          }
        }        
    ];  

    const contextvalues = {
        definirClientesSelecionados,
        definirLojasSelecionadas,
        definirVendedoresSelecionados,
        definirTipoImpressaoRelatorio
    }     

    const getDetailPanelContent = React.useCallback(
        ({ row}) => <DetailPanelContent row={row}/>,
        [],
      );
    
    if (loading) {
        return (
            <LoadingScreen/>
        );
      }

      const renderDatePickerMes = () => (
        <>
        <div className='row'>
            <div className='col'>
                <DatePicker
                label='Mês Inicial'
                views={['month', 'year']}
                value={dataIntervaloFiltro[0]}
                onChange={(newValue) =>
                    setDataIntervaloFiltro([newValue, dataIntervaloFiltro[1]])
                }
                />
            </div>
            <div className='col'>
                <DatePicker
                label='Mês Final'
                views={['month', 'year']}
                value={dataIntervaloFiltro[1]}
                onChange={(newValue) =>
                    setDataIntervaloFiltro([dataIntervaloFiltro[0], newValue])
                }
                />
            </div>
        </div>
        </>
      );         
      
      function CustomToolbar() {
        return (
          <CustomToolBar print={visualizarImpressao}/>         
        );
      }          

    return (
        <>       
        <VendaPeriodoContext.Provider value={contextvalues}>             
            <form className="form">                
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Vendas por Período</label>
            </div>                                                
              <Card.Body>                    
                <Container>                                                                                 
                  <Card>
                    <div className='align-left'>
                      <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>
                      <Card style={{width: 420}}>
                        <div className="row">                                             
                            <div className="col-md-4">                                
                                <Radio                                      
                                  checked={tipoVisaoSelect === 'dia'}
                                  onChange={() => {
                                      setTipoVisaoSelect('dia'); 
                                      setDadosVenda([]);                                          
                                  } }                  
                                  name="radio-button-demo"
                                  inputProps={{ 'aria-label': 'A' }}
                                  size="small"
                                  id="diaRadio" 
                                />   
                                <label class="form-check-label" for="diaRadio" style={{cursor: 'pointer'}}>
                                    Por Dia
                                </label>                                
                            </div>                                                           
                          <div className="col">                                                  
                            <Radio
                              checked={tipoVisaoSelect === 'mes'}
                              onChange={() => {
                                  setTipoVisaoSelect('mes'); 
                                  setDadosVenda([]);                                                            
                              }}                  
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                              size="small"
                              id="mesRadio" 
                            />   
                            <label class="form-check-label" for="mesRadio" style={{cursor: 'pointer'}}>
                                Por Mês
                            </label>                                
                          </div>         
                          <div className="col">                                                               
                            <Radio
                              checked={tipoVisaoSelect === 'venda'}
                              onChange={() => {
                                  setTipoVisaoSelect('venda'); 
                                  setDadosVenda([]);                                                                                                                  
                              }}                 
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                              size="small"
                              id="vendaRadio" 
                            />   
                            <label class="form-check-label" for="vendaRadio" style={{cursor: 'pointer'}}>
                                Por Venda
                            </label>                                
                          </div>                                                         
                        </div>  
                      </Card>                   
                    </div>
                  </Card>                                                            
                </Container>

                  <Container>
                      <Card>                                                       
                      <div className="row" style={{ marginLeft: '5px', marginTop: '30px', width: '440px' }}>
                          <div className="col-md">
                              <LocalizationProvider  
                                  dateAdapter={AdapterDateFns} 
                                  adapterLocale={ptBR}                                                               
                              >                                                           
                                {tipoVisaoSelect !== 'mes' ? (
                                <DateRangePicker
                                    localeText={{ start: 'Data Inicial', end: 'Data Final' }}
                                    value={dataIntervaloFiltro}
                                    onChange={(newValue) => setDataIntervaloFiltro(newValue)}
                                    renderInput={(startProps, endProps) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <input {...startProps.inputProps} />
                                        <span> to </span>
                                        <input {...endProps.inputProps} />
                                    </Box>
                                    )}
                                />
                                ) : (
                                renderDatePickerMes()
                                )}
                              </LocalizationProvider>                                
                              </div>                                                               
                          </div>
                                          
                          <div className="filtros">
                            <div className="align-left">
                              <div className="row align-items-start" style={{marginTop: '20px'}}>
                                  <div className="col-lg">
                                  <ButtonLojas
                                    onClick={handleShowLojas}
                                    width={'200px'}                                  
                                    lojasFiltro={lojasFiltro}
                                  />
                                  </div>                                
                                  <div className="col-lg">
                                  <ButtonClientes                                        
                                      onClick={handleShowClientes}
                                      width={'200px'}
                                      clientesFiltro={clientesFiltro}
                                  />              
                                  </div>                               
                                  <div className="col-lg">
                                  <ButtonVendedores
                                    onClick={handleShowVendedores}
                                    width={'200px'}
                                    vendedoresFiltro={vendedoresFiltro}
                                  />
                                  </div>                                                               
                              </div>                            
                            </div>                            
                      </div>        

                      <div class="row" style={{marginTop: '30px'}}>
                            <div className='col-2'>
                                <div className="actions">
                                <ButtonExecute 
                                    width={'200px'}
                                    onClick={handleExecutar}
                                />   
                                </div>                    
                            </div>
                            <div className='col-8' style={{marginLeft: '35px'}}>
                                <ButtonClear                                     
                                    onClick={handleLimparFiltros}
                                />   
                            </div>
                        </div>                   
                                      
                      </Card>
                  </Container>                 
              </Card.Body>                     
            </form>     
                
            <ModalLojas show={showLojas} value={VendaPeriodoContext} onHide={handleCloseLojas}/>                      
            <ModalClientes show={showClientes} value={VendaPeriodoContext} onHide={handleCloseClientes}/>
            <ModalVendedores show={showVendedores} value={VendaPeriodoContext} onHide={handleCloseVendedores}/>      
            <ModalImpressaoResumidoDetalhado show={showTipoImpRel} value={VendaPeriodoContext} onHide={handleCloseTipoImpressao}/>                   

             {dadosVenda && dadosVenda.length > 0 && ( 
              <Container>        

              <CustomStyledDataGrid
                  loading={loading}                                         
                  rows={tipoVisaoSelect === 'venda'
                    ? [...rowsVenda]
                    : [...rows]}                                                                                                        
                  columns={
                      tipoVisaoSelect === 'venda'
                      ? [...columnsVenda]
                      : [...columns]}                                                                                                                                      
                  getDetailPanelHeight={() => 'auto'} 
                  getDetailPanelContent={tipoVisaoSelect === 'venda' ? getDetailPanelContent : null}   
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={[PAGE_SIZE]}     
                  slots={{
                      toolbar: CustomToolbar,
                      pagination: CustomPagination                   
                  }}                                                     
              />                                                                                                    
              </Container>   
            )}                                                            
        </VendaPeriodoContext.Provider>
        </>
    )
  }

export default VendaPeriodoProvider;

