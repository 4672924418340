import React from 'react';
import { Container, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

function ModalAjudaRelatorioFinanceiro(props) {  
  const handleClose = () => props.onHide();

  return (    
    <Modal onHide={handleClose}
      {...props} 
      size='lg'      
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
    <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>  
      <Modal.Title id="contained-modal-title" >                               
        <Alert severity="info" style={{marginLeft: '250px'}}>          
            OBJETIVO DO RELATÓRIO
        </Alert>

       </Modal.Title>    
    </Modal.Header>
        <Modal.Body className="show-grid">
        <Container>
  <div className='row'>
    <label style={{ textAlign: 'center', fontSize: '20px', fontFamily: 'arial' }}>
      <strong>Apresentar o valor total da movimentação financeira</strong><br/><br/>      
      <strong>DEFINIÇÕES</strong><br/><br/>
    </label>
    <label style={{textAlign: 'left', fontSize: '20px'}}>    
      <strong>- Vendas à Vista: </strong> Compreende as vendas em Dinheiro e/ou Pix.<br />
      <strong>- Crediários Recebidos:</strong> Compreende as baixas de crediário em Dinheiro e/ou Pix.<br />
      <strong>- Cartões Recebidos:</strong> Compreende as baixas de cartões realizadas pelo menu do sistema (Financeiro &gt; Recebimentos &gt; Cartão).<br />
      <strong>- Cheques Recebidos:</strong> Compreende as baixas de cheques realizadas pelo menu do sistema (Financeiro &gt; Recebimentos &gt; Cheque).<br />
      <strong>- Receitas:</strong> Compreende as receitas lançadas diretamente no caixa da loja.<br />
      <strong>- Despesas:</strong> Compreende as despesas baixadas pelo contas a pagar bem como as lançadas diretamente no caixa da loja.<br />
      <strong>- Fornecedores:</strong> Compreende as baixas de contas a pagar de fornecedores.  
    </label>
  </div>
</Container>
        </Modal.Body>
      <Modal.Footer>      
        
      </Modal.Footer>
    </Modal>   

  );
}

export default ModalAjudaRelatorioFinanceiro;
