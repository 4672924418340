import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoBarrasAnaliseCondicional = ({ apiData, visao, analise }) => {

  const chartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
        barHeight: 30,
      }
    },
    colors: ['rgba(0, 200, 255)'],
    series: [
      {
        name: analise === 'valor' ? 'Valor enviado' : analise === 'peca' ? 'Qtde Peças Enviadas' : '',
        data: 
      
           apiData.slice(0, 15).map((item) => ({
            x: visao === 'loja' ? item.lojanome : visao === 'vendedor' ? item.vendedornome : visao === 'cliente' ? item.clientenome : visao === 'marca' ? item.marcanome : item.lojanome,
            y: analise === 'valor' ? item.valorenviado : item.qtdepecas,
            goals: [
              {
                name: analise === 'valor' ? 'Valor Convertido' : 'Qtde Peças Convertidas',
                value: analise === 'valor' ? item.valorconvertido : item.qtdepecasconvertidas,
                strokeWidth: 5,
                strokeHeight: 20,
                strokeLineCap: 'round',
                strokeColor: '#775DD0',
              },
            ],
          }))
      },
    ],
    xaxis: {
      categories: visao === 'loja'
        ? apiData.map(item => item.lojanome).slice(0, 15)
        : visao === 'cliente'
          ? apiData.map(item => item.clientenome).slice(0, 15)
          : visao === 'vendedor'
            ? apiData.map(item => item.vendedornome).slice(0, 15)
            : visao === 'marca'
              ? apiData.map(item => item.marcanome).slice(0, 15)
              : null,
      labels: {
        formatter: function (value) {
          if (analise === 'valor')
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

          return value;
        },
      },

    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      style: {
        fontSize: '12px',
        textAlign: 'left',
        width: '200px',
      },
      position: 'topRight',
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const originalValue = series[seriesIndex][dataPointIndex];
        const convertedValue = w.config.series[seriesIndex].data[dataPointIndex].goals[0].value;
        const color = w.config.colors[seriesIndex];

        if (analise === 'valor') {
          return '<div class="custom-tooltip">' +
            '<div style="display: flex; align-items: center; padding:4px">' +
            '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: ' + color + '; margin-right: 5px;"></div>' +
            '<span>' + 'Valor Enviado: <b>' + originalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }) + '</b> </span>' +
            '</div>' +
            '<div style="display: flex; align-items: center; padding:4px" >' +
            '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: #775DD0 ; margin-right: 5px;"></div>' +
            '<span>' + 'Valor Convertido: <b>' + convertedValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' }) + '</b> </span>' +
            '</div>' +
            '</div>';
        }

        return '<div class="custom-tooltip">' +
          '<div style="display: flex; align-items: center; padding:4px" >' +
          '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: ' + color + '; margin-right: 5px;"></div>' +
          '<span>' + 'Quantidade Enviada: <b>' + originalValue + '</b> </span>' +
          '</div>' +
          '<div style="display: flex; align-items: center; padding:4px" >' +
          '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: #775DD0 ; margin-right: 5px;"></div>' +
          '<span>' + 'Quantidade Convertida: <b>' + convertedValue + '</b> </span>' +
          '</div>' +
          '</div>';

      },
    },
    dataLabels: {
      enabled: true,
      distributed: true,
      textAnchor: 'start',
      formatter: function (val, opt) {
        const dataPoint = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex];
        const resultadoDivisao = ((dataPoint.goals[0].value) / dataPoint.y) * 100;

        return resultadoDivisao.toFixed(2) + '% Convertido';
      },
      style: {
        colors: ['#000000'],
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      show: true,
      showForSingleSeries: true,
      customLegendItems:
        analise === 'valor'
          ? ['Valor Enviado', 'Valor Convertido']
          : ['Quantidade Enviada', 'Quantidade Convertida'],
      markers: {
        fillColors: ['rgba(0, 200, 255)', '#775DD0']
      }
    },
  };

  return (
    <div>
      <ReactApexChart options={chartOptions} series={chartOptions.series} type="bar" height={100 + ((apiData.length >= 15 ? 15 : apiData.length) * 50)}
      />
    </div>
  );
};

export default GraficoBarrasAnaliseCondicional;