import React from 'react';
import { AES, enc } from 'crypto-js';

class EncryptionUtil extends React.Component {
  constructor(props) {
    super(props);
    this.key = enc.Hex.parse('0c0238750c4321170c0238750c432117');
    this.iv = enc.Hex.parse('0102030405060708090a0b0c0d0e0f10');
    this.secretkey = "diaussuidhaiushd%#$%#$&#%#$&$@#$$";
    this.state = {
      encryptedText: ''
    };    
  }

  encryptText = (text) => {
    const encryptedText = AES.encrypt(text, this.key, { iv: this.iv }).toString();
    return encryptedText;
  };

  decryptText = (encryptedText) => {
    const decryptedText = AES.decrypt(encryptedText, this.key, { iv: this.iv }).toString(enc.Utf8);
    return decryptedText;
  };
  
  encryptJSON(jsonObject) {
    const CryptoJS = require('crypto-js');
    const jsonString = JSON.stringify(jsonObject);
    const encrypted = CryptoJS.AES.encrypt(jsonString, this.secretkey).toString();
    return encrypted;
  }

  decryptJSON(encryptedString) {
    const CryptoJS = require('crypto-js');
    const bytes = CryptoJS.AES.decrypt(encryptedString, this.secretkey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }  
}

export default EncryptionUtil;