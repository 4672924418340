import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonTamanhos({ onClick, width, tamanhosFiltro }) {
  return (    
    <Button style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
        borderRadius: '15px'                                 
        }}  
        onClick={onClick}>
            + Tamanho
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {tamanhosFiltro.length > 0 ? tamanhosFiltro.length : ''}
            </span>
        </Button>         
  );
}

export default ButtonTamanhos;
