import moment from 'moment';
import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';

export class ImpressaoVendaProdutoPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;          

    this.totalVendas = dadosParaImpressao.reduce((total, obj) => {             
    if (this.filtros.tipoVisao  === 'cliente')
      {
          total.qtdetotal += obj.vendas.reduce((total, vendas) => {return total + vendas.qtdeprodutos;}, 0);                
          total.markup += obj.vendas.reduce((total, vendas) => {return total + vendas.markup}, 0);                
          total.markupliquido += obj.vendas.reduce((total, vendas) => {return total + vendas.markupliquido}, 0);                
          return total;           
      }            
   }, { qtdetotal: 0, markup : 0, markupliquido: 0});
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {  
    if (this.filtros.tipoVisao === 'produto')
    {
      const headerProduto = [        
        { text: 'Produto', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },
        { text: 'Tam.', bold: true, alignment: 'left', fontSize: 8, margin: [4, 4, 4, 4] },
        { text: 'Cor', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },
        { text: 'Qtde/Qtde %', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },        
        { text: 'Valor Liq. R$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'Custo \nR$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'Dif. Troca R$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'Markup %', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'Markup Real', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
        { text: 'Lucro \nR$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] }                  
      ];
      return headerProduto;       
    }

    const header = [      
      { text: 'Cliente', bold: true, alignment: 'left', fontSize: 8, margin:[4, 4, 4, 4]  },
      { text: '', fontSize: 8 },
      { text: 'Qtde Vendas', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },
      { text: 'Qtde/\nQtde %', bold: true, alignment: 'right', fontSize: 8, margin:[4, 4, 4, 4]  },      
      { text: 'V. Liquido \nR$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
      { text: 'Custo \nR$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
      { text: 'Dif. Troca \nR$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
      { text: 'Markup %', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
      { text: 'Markup Real %', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] },                  
      { text: 'Lucro \nR$', bold: true, alignment: 'right', fontSize: 8, margin: [4, 4, 4, 4] }                  
    ];
    return header;               
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#F2F2F2'; 
  }

  GetBody() {    
    if (this.filtros.tipoVisao === 'produto')
    {            
        const bodyVendaProduto = this.dadosParaImpressao.map((prod) => {    
          return  [            
            { text: (prod.produtoid + '- ' + prod.produtodescricao).substring(0, 22), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: prod.produtotamanho, alignment: 'left', fontSize: 8, },                          
            { text: prod.produtocor, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0] },
            { text: prod.qtdeprodutos + '/' + ((prod.qtdeprodutos / this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdeprodutos, 0)) * 100)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%', fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },          
            { text: prod.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.custo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.diferencatroca.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
            { text: (prod.markup / prod.qtdeprodutos).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
            { text: (prod.markupliquido / prod.qtdeprodutos).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]},
            { text: prod.lucro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 8 , alignment: 'right', margin:[0, 0, 4, 0]}
          ];
        }
      );     
      return bodyVendaProduto; 
    }

    const bodyVendaCliente = this.dadosParaImpressao.flatMap((vend) => {
      const vendaRows = vend.vendas.map((venda) => [
            { text: (venda.clienteid + '- '  + venda.clientenome).substring(0, 22), fontSize: 8, margin:[4, 0, 0, 0] },           
            { text: ''},   
            { text: venda.qtdevendas, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                                 
            { text: venda.qtdeprodutos + ' / ' + 
              ((venda.qtdeprodutos / this.totalVendas.qtdetotal) * 100)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%', fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },            
            { text: venda.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: venda.custo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: venda.diferencatroca.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: venda.markup.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: venda.markupliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
            { text: venda.lucro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },
        
      ]);

      if (this.filtros.tipoImpressao === 'detalhado')
      {
        const detalheRows = [
          {
            table: {
              widths: [20, 105, 40, 48, 50, 40, 23, 50, 50, 50], 
              headerRows: 1,              
              body: [    
                              
                [{ text: 'Loja', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },                                  
                 { text: 'Descrição Produto', fontSize: 8, alignment: 'left', fillColor: '#DAEBF2' },
                 { text: 'Tamanho', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2'},
                 { text: 'Cor', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2'},     
                 { text: 'Data', fontSize: 8, alignment: 'center', fillColor: '#DAEBF2'},     
                 { text: 'Venda', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },     
                 { text: 'Qtde', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },                        
                 { text: 'V. Uni. R$', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2'}, 
                 { text: 'V. Liq. R$', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' }, 
                 { text: 'Vendedor', fontSize: 8, alignment: 'right', fillColor: '#DAEBF2' },                 
                ],

                ...vend.vendaMovimentos.map((vendmov) => [
                  { text: vendmov.lojaid, fontSize: 8, margin:[4, 0, 0, 0] , border: []},                                                    
                  { text: (vendmov.produtoid + '- ' + vendmov.produtodescricao.substring(0, 16)), fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0], border: [] },
                  { text: vendmov.produtotamanho, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                  { text: vendmov.produtocor, fontSize: 8, alignment: 'right', margin:[4, 0, 0, 0] , border: []},
                  { text: this.formatarData(new Date(vendmov.datavenda)), fontSize: 8, alignment: 'right', margin:[4, 0, 0, 0], border: [] },                
                  { text: vendmov.vendaid, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: [] },
                  { text: vendmov.qtde, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: [] },
                  { text: vendmov.valorunitario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: [] },
                  { text: vendmov.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] ,  border: [] },
                  { text: vendmov.vendedornome, fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},
                ]),              
              ],              
            },
            fontSize: 8,
            colSpan: 10,
            margin: [0, 0, 0, 6], 
          },
        ];
    
        return [...vendaRows, detalheRows];
     }
      else
      {
         return [...vendaRows];
      }                
    });
    return bodyVendaCliente;
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: this.filtros.tipoVisao === 'produto' ? 10 : 10
      }
    ];

    const lineSpace = [
      {
        text:
        '\n\n\n',
        fontSize: 5,
        colSpan: this.filtros.tipoVisao === 'produto' ? 10 : 10
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody(), lineSpace];     
    content.push(this.GetTotalizadores());         
    return content;
  } 

  GetTotalizadores() {   
    if (this.filtros.tipoVisao === 'produto')
    {
      const TotalQtdes =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.qtdeprodutos, 0);   
      const TotalLiquido =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.valorliquido, 0);       
      const TotalCusto = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.custo, 0);
      const TotalDifTroca = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.diferencatroca, 0);         
      const TotalMarkup = (this.dadosParaImpressao.reduce((acc, row) => acc + row.markup, 0) / this.dadosParaImpressao.reduce((acc, row) => acc + row.qtdeprodutos, 0)) ;         
      const TotalMarkupLiquido = (this.dadosParaImpressao.reduce((acc, row) => acc + row.markupliquido, 0) / this.dadosParaImpressao.reduce((acc, row) => acc + row.qtdeprodutos, 0)) ;         
      const TotalLucro = this.dadosParaImpressao.reduce((acc, row) => acc + row.lucro, 0);         

      const totalRowVenda = [
        { text: 'Total', bold: true, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0] },        
        { text: ''},
        { text: ''},
        { text: TotalQtdes + '/100%', bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },      
        { text: 'R$' + TotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
        { text: 'R$' + TotalCusto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }), 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },      
        { text: 'R$' + TotalDifTroca.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }),
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },
        { text: TotalMarkup.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%', 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },      
        { text: TotalMarkupLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' , 
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  },      
        { text:  'R$' + TotalLucro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ,
          bold: true, alignment: 'right', fontSize: 7, margin:[0, 0, 4, 0]  }    
      ];    
      return totalRowVenda;    
    }

        const totalVendas = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].qtdevendas, 0);        
        const totalValorLiquido = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].valorliquido, 0);
        const totalCusto = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].custo, 0);
        const totalDifTroca =  this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].diferencatroca, 0);
        const TotalMarkup = (this.dadosParaImpressao.reduce((acc, prod) => acc +  (prod.vendas[0].markup * prod.vendas[0].qtdeprodutos), 0) / this.dadosParaImpressao.reduce((acc, vend) => acc + vend.vendas[0].qtdeprodutos, 0)) ;         
        const TotalMarkupLiquido = (this.dadosParaImpressao.reduce((acc, prod) => acc + (prod.vendas[0].markupliquido * prod.vendas[0].qtdeprodutos), 0) / this.dadosParaImpressao.reduce((acc, vend) => acc + vend.vendas[0].qtdeprodutos, 0)) ;   
        const totalLucro = this.dadosParaImpressao.reduce((acc, prod) => acc + prod.vendas[0].lucro, 0);                 
    
        const totalRowVenda = [
          { text: 'Total' , bold: true, alignment: 'left', fontSize: 9, margin:[4, 0, 0, 0]   },
          { text: '' },  
          { text: '' + totalVendas, bold: true, alignment: 'right', fontSize: 8, margin:[4, 0, 0, 0] },  
          { text: this.totalVendas.qtdetotal + '/100%', bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },                                                          
          { text: 'R$' + totalValorLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },      
          { text: 'R$' + totalCusto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },      
          { text: 'R$' + totalDifTroca.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: TotalMarkup.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%', 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: TotalMarkupLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%', 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  },
          { text: 'R$' + totalLucro.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }), 
            bold: true, alignment: 'right', fontSize: 8, margin:[0, 0, 4, 0]  }      
        ];    
        return totalRowVenda;      
  }
  
  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO DE VENDAS POR PRODUTO', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {          
          text: `\nVisão: Por ${this.primeiraLetraMaiuscula(this.filtros.tipoVisao)}` 
            + `  |  Período: ` + this.formatarData(this.filtros.datasFiltro[0]) + ' a ' + this.formatarData(this.filtros.datasFiltro[1])                
            + `  |  Lojas: ${this.filtros.lojasSelecionadas}`
            + `  |  Itens: ${(this.filtros.itensFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.itensFiltro))}`
            + `  |  Marcas: ${(this.filtros.marcasFiltro.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.marcasFiltro))}`
            + `  |  Referências: ${(this.filtros.referenciasFiltro.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.referenciasFiltro))}`
            + `  |  Departamentos: ${(this.filtros.departamentosFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.departamentosFiltro))}`
            + `  |  Coleções: ${(this.filtros.colecoesFiltro.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.colecoesFiltro))}`
            + `  |  Tamanhos: ${(this.filtros.tamanhosFiltro.length === 0 ? 'Todos' : this.OrdenarVetor(this.filtros.tamanhosFiltro))}`
            + `  |  Cores: ${(this.filtros.coresFiltro.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.coresFiltro))}`            
            + `  |  Categorias: ${(this.filtros.categoriasFiltro.length === 0 ? 'Todas' : this.OrdenarVetor(this.filtros.categoriasFiltro))}`                                                
            + `${this.filtros.tipoVisao === 'cliente' ?  `|  Vendedores: ${(this.filtros.vendedoresFiltro.length === 0 ? 'Todos ' : this.OrdenarVetor(this.filtros.vendedoresFiltro))}` : ''}` 
            + `${this.filtros.tipoVisao === 'cliente' ?  `|  Clientes: ${(this.filtros.clientesFiltro.length === 0 ? 'Todos ' : this.OrdenarVetor(this.filtros.clientesFiltro))}` : ''}`,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: this.filtros.tipoVisao === 'produto'
              ? [109, 27, 46, 45, 48, 48, 48, 35 , 40, 50]
              : [103, 0, 35, 54, 57, 57, 57, 35 , 40, 57],              
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}