import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Button, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { get } from 'lodash';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableReferencias = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [referencias, setReferencias] = useState([]);  
  const [referenciasData, setReferenciasData] = useState([]);
  const [selectedReferencias, setSelectedReferencias] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirReferenciasSelecionadas } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedReferencias = sessionStorage.getItem('selectedReferencias');
    if (storedSelectedReferencias) {    
      setSelectedReferencias(JSON.parse(storedSelectedReferencias));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedReferencias.length > 0)
   {
      sessionStorage.setItem('selectedReferencias', JSON.stringify(selectedReferencias));
   }       
  }, [selectedReferencias, referencias]);  

  const handleReferenciaSelection = (id) => {
    const updatedIndexes = selectedReferencias.includes(id)
      ? selectedReferencias.filter((index) => index !== id)
      : [...selectedReferencias, id];
    setSelectedReferencias(updatedIndexes);   

    const selectedReferenciasIds = updatedIndexes.map((index) => index.referencia);
    definirReferenciasSelecionadas(selectedReferenciasIds);
  };

  const handleRemoverReferenciasSelecionadasClick = (id) => {
    const updatedReferenciasSelecionadas = selectedReferencias.includes(id)
      ? selectedReferencias.filter((index) => index !== id)
      : [...selectedReferencias, id];
    setSelectedReferencias(updatedReferenciasSelecionadas);   

    const selectedReferenciasIds = updatedReferenciasSelecionadas.map((index) => index.referencia);
    definirReferenciasSelecionadas(selectedReferenciasIds);

    if (selectedReferenciasIds.length === 0)
    {
      sessionStorage.removeItem('selectedReferencias');
    }
  };

  const handleRowClick = (referenciaId) => {
    setReferencias((prevreferencias) =>
      prevreferencias.filter((referencia) => referencia.id !==(referenciaId)
    ));
  };

  const handleRowSelectedClick = (referencia) => {

    if (!referencias.includes(referencia.id))
    {
        const updatedReferencias = [...referencias, referencia];
        setReferencias(updatedReferencias);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedReferencias(referencias);
       setReferencias([]);
       
       const selectedReferenciasIds = referencias.map((index) => index.referencia);
       definirReferenciasSelecionadas(selectedReferenciasIds);       
       sessionStorage.setItem('selectedReferencias', selectedReferencias);      
  }

  const handleRemoverTodos = async() => {         
      if (!referenciasData || referenciasData.length === 0)
      {
        getReferencias(); 
      }      
      setReferencias(referenciasData);
      setSelectedReferencias([]);    
      definirReferenciasSelecionadas([]);         
      sessionStorage.removeItem('selectedReferencias');      
  }

  const getReferencias = async () => {   
    console.log('pesquisando');
    try {
      let url = 'grade/referencias';

      if (busca !== '') {
        url = `grade/referencias/nome/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setReferencias(response.data);   
      setReferenciasData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getReferencias();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da Referência"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>                 
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>                    
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Referência</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {referencias.map((grade) => {
                  if (selectedReferencias.some((mrc) => mrc.id === grade.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={grade.id}
                        onClick={() => {
                          handleReferenciaSelection(grade);
                          handleRowClick(grade.id);
                        }}
                      >                        
                        <td>{grade.referencia}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedReferencias.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>                    
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Referência</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedReferencias.map((grade) => (
                    <tr key={grade.id}
                        onClick={() => {
                          handleRemoverReferenciasSelecionadasClick(grade);
                          handleRowSelectedClick(grade)
                        }}
                    >                      
                      <td>{grade.referencia}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableReferencias;

