import React, { useContext } from 'react';
import { Container, Button , Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function ModalImpressaoResumidoDetalhado(props) {
  const { definirTipoImpressaoRelatorio } = useContext(props.value); 
  const handleClose = () => props.onHide();

  const handleTipoImpressaoRelatorioResumido = () => {
      definirTipoImpressaoRelatorio('resumido');
  }  

  const handleTipoImpressaoRelatorioDetalhado = () => {
      definirTipoImpressaoRelatorio('detalhado');
  } 

  return (    
    <Modal onHide={handleClose}
      {...props} 
      size="me"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
    <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Title id="contained-modal-title-sm">
        <label style={{ textAlign: 'center' }}>Selecione o tipo de impressão</label>
      </Modal.Title>
    </Modal.Header>
        <Modal.Body className="show-grid">
            <Container>
              <div className='row'>
                <div className='col'>
                  <Button variant="primary" onClick={handleTipoImpressaoRelatorioResumido}>Resumido</Button>
                </div>
                <div className='col'>
                  <Button variant="primary" onClick={handleTipoImpressaoRelatorioDetalhado}>Detalhado</Button>
                </div>
            </div>
            </Container>
        </Modal.Body>
      <Modal.Footer>      
        
      </Modal.Footer>
    </Modal>   

  );
}

export default ModalImpressaoResumidoDetalhado;
