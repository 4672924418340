import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableManequimInferior = ({ contexto }) => {
  const [manequimInferior, setManequimInferior] = useState([]);
  const [manequimInferiorData, setManequimInferiorData] = useState([]);
  const [selectedManequimInferior, setSelectedManequimInferior] = useState([]);
  const [busca, setBusca] = useState('');
  const { definirManequimInferiorSelecionado } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {
    const storedSelectedManequimInferior = sessionStorage.getItem('selectedManequimInferior');
    if (storedSelectedManequimInferior) {
      setSelectedManequimInferior(JSON.parse(storedSelectedManequimInferior));
    }
    pesquisaRef.current.focus();
  }, []);

  useEffect(() => {
    if (selectedManequimInferior.length > 0) {
      sessionStorage.setItem('selectedManequimInferior', JSON.stringify(selectedManequimInferior));
    }
  }, [selectedManequimInferior, manequimInferior]);

  const handleManequimInferiorSelection = (id) => {
    const updatedIndexes = selectedManequimInferior.includes(id)
      ? selectedManequimInferior.filter((index) => index !== id)
      : [...selectedManequimInferior, id];
    setSelectedManequimInferior(updatedIndexes);

    const selectedManequimInferiorIds = updatedIndexes.map((index) => index.descricao);
    definirManequimInferiorSelecionado(selectedManequimInferiorIds);
  };

  const handleRemoverManequimInferiorSelecionadosClick = (id) => {
    const updatedManequimInferiorSelecionados = selectedManequimInferior.includes(id)
      ? selectedManequimInferior.filter((index) => index !== id)
      : [...selectedManequimInferior, id];
    setSelectedManequimInferior(updatedManequimInferiorSelecionados);

    const selectedManequimInferiorIds = updatedManequimInferiorSelecionados.map((index) => index.descricao);
    definirManequimInferiorSelecionado(selectedManequimInferiorIds);

    if (selectedManequimInferiorIds.length === 0) {
      sessionStorage.removeItem('selectedManequimInferior');
    }
  };

  const handleRowClick = (manequimInferiorId) => {
    setManequimInferior((prevManequimInferior) =>
      prevManequimInferior.filter((manequimInferior) => manequimInferior.id !== manequimInferiorId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!manequimInferior.includes(item.id)) {
      const updatedManequimInferior = [...manequimInferior, item];
      setManequimInferior(updatedManequimInferior);
    }
  };

  const handleAdicionarTodos = () => {
    setSelectedManequimInferior(manequimInferior);
    setManequimInferior([]);

    const selectedManequimInferiorIds = manequimInferior.map((index) => index.descricao);
    definirManequimInferiorSelecionado(selectedManequimInferiorIds);
    sessionStorage.setItem('selectedManequimInferior', selectedManequimInferior);
  }

  const handleRemoverTodos = async () => {
    if (!manequimInferiorData || manequimInferiorData.length === 0) {
      getManequimInferior();
    }
    setManequimInferior(manequimInferiorData);
    setSelectedManequimInferior([]);
    definirManequimInferiorSelecionado([]);
    sessionStorage.removeItem('selectedManequimInferior');
  }

  const getManequimInferior = async () => {
    try {
      let url = `tamanhoproduto/listagem/false/${busca.toUpperCase()}`;  
      await SetTenant();
      const response = await api.get(url);
      const selectedManequimInferiorIds = selectedManequimInferior.map(tamanho => tamanho.id);    
      const filteredData = response.data.filter(tamanho => !selectedManequimInferiorIds.includes(tamanho.id)); 
      setManequimInferior(filteredData);
      setManequimInferiorData(filteredData);
    } catch (e) {
      console.log('erro');
    }
  };

  const handlePesquisar = () => {
    getManequimInferior();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }
    }
  }

  return (
    <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
      <Card style={{ border: '0px' }}>
        <div className="align-left">
          <div className="row">
            <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição do Manequim Inferior"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>
          </div>
        </div>
      </Card>

      <Container>
        <div className="col">
          <Card>
            <div className='col'>
              <ButtonAdicionarTodos onClick={handleAdicionarTodos} />
            </div>
          </Card>
          <Card>
            <table className="table table-striped table-hover">
              <thead className="table mt-3">
                <tr>                  
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white', textAlign: 'center' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {manequimInferior.map((manequimInferior) => {
                  if (selectedManequimInferior.some((mrc) => mrc.id === manequimInferior.id)) {
                    return null;
                  } else {
                    return (
                      <tr
                        key={manequimInferior.id}
                        onClick={() => {
                          handleManequimInferiorSelection(manequimInferior);
                          handleRowClick(manequimInferior.id);
                        }}
                      >                        
                        <td className='col text-center'>{manequimInferior.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Card>
        </div>

        <div className="col">
          <Card>
            <Card>
              <div className='row justify-content-end'>
                <div className='col text-left'>
                  <label className='labelSelecionar'>Selecionados: {selectedManequimInferior.length}</label>
                </div>
                <div className='col text-right'>
                  <ButtonRemoverTodos onClick={handleRemoverTodos} />
                </div>
              </div>
            </Card>
            <table className="table table-striped table-hover">
              <thead className="table-info mt-3">
                <tr>                  
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white', textAlign: 'center' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {selectedManequimInferior.map((manequimInferior) => (
                  <tr key={manequimInferior.id}
                    onClick={() => {
                      handleRemoverManequimInferiorSelecionadosClick(manequimInferior);
                      handleRowSelectedClick(manequimInferior)
                    }}
                  >                    
                    <td className='col text-center'>{manequimInferior.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default TableManequimInferior;

