import React, { createContext, useState, useEffect, useRef } from 'react';
import { Stack, Pagination, Paper, Typography, Box, Grid, Container, TextField, InputLabel, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import {    
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector
} from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import PaginationItem from '@mui/material/PaginationItem';
import '../ContasAPagar/styles.css';
import { api } from '../../services/api';
import { isEmpty } from 'lodash';
import { startOfMonth, endOfMonth } from 'date-fns';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { Card } from 'react-bootstrap';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import { ptBR } from 'date-fns/locale';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonLojasEmissao from '../../components/Buttons/ButtonLojasEmissao';
import ModalLojasEmissao from '../../components/Modal/ModalLojasEmissao';
import ButtonLojasPagamento from '../../components/Buttons/ButtonLojasPagamento';
import ModalLojasPagamento from '../../components/Modal/ModalLojasPagamento';
import ButtonFornecedores from '../../components/Buttons/ButtonFornecedores';
import ModalFornecedores from '../../components/Modal/ModalFornecedores';
import ButtonContas from '../../components/Buttons/ButtonContas';
import ModalContas from '../../components/Modal/ModalContas';
import ModalImpressaoResumidoDetalhado from '../../components/Modal/ModalImpressaoResumidoDetalhado';
import { ImpressaoContasAPagarPDF } from '../../components/Impressao/ImpressaoContasAPagarPDF';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function DetailPanelContent({ row: rowProp }) {     
  return (
    <Container sx={{ height: '100%' }}>
      <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
        <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
          <Stack direction="column" spacing={0} sx={{ height: '100%' }}>           
            <Grid container sx={{ flexGrow: 0 }}>
              <Grid item md={6}>                
              </Grid>
            </Grid>
            <Container sx={{ height: '100%' }}>           
              <CustomStyledDataGrid                                                                   
                density="compact"                
                columns={rowProp.pagarDetalhes
                    ? columnsDetailPendentes
                    : columnsDetailBaixadas}               
                rows={rowProp.pagarDetalhes 
                    ? rowProp.pagarDetalhes 
                    : rowProp.pagarMovimentoDetalhes}        
                sx={{ flex: 1, height: '100%' }}                  
                hideFooter
              />
            </Container>
           
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}

  const headerClassName = 'blue-header';

  const columnsPendentes = [   
    {
      headerClassName: 'blue-header',
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },          
    { field: "fornecedorid", headerName: "Cód",  headerClassName: 'blue-header', 
      renderCell: (params) => { return <strong>{params.value}</strong> } ,width: 50, headerHeight : 10 },
    { field: "fornecedor", headerName: "Fornecedor",  headerClassName: 'blue-header',  
      renderCell: (params) => { return <strong>{params.value}</strong> }, width: 330, headerHeight : 10},
    { field: "contaid", headerName: "Cód",  headerClassName: 'blue-header', 
      renderCell: (params) => { return <strong>{params.value}</strong> }, width: 50, headerHeight : 10 },
    { field: "conta", headerName: "Conta",  headerClassName: 'blue-header',  
      renderCell: (params) => { return <strong>{params.value}</strong> }, width: 330, headerHeight : 10},
    { field: "qtdeparcelas", headerName: "Qtde Parcelas",  headerClassName: 'blue-header', headerAlign: 'right',
      width: 170, align: 'right', headerHeight : 10},            
    { field: "valorpendente", headerName:"Valor R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      } , headerHeight : 10
    }   
  ];  

  const columnsDetailPendentes= [    
    { field: "lojaid", headerName: "Loja",  headerClassName: 'header-detail', width: 50 },
    { field: "documento", headerName: "Documento",  headerClassName: 'header-detail', width: 110 },
    { field: "emissao",  type: 'date', valueGetter: (params) => params.row.emissao,
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerName: "Emissão",  headerClassName: 'header-detail', 
      headerAlign: 'center', width: 110, align: 'right'},           
    { field: "vencimento", type: 'date', valueGetter: (params) => params.row.vencimento, 
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerName:"Vencimento",  headerClassName: 'header-detail', headerAlign: 'center', width: 110, 
      align: 'right'},           
    { field: "valorpendente", headerName:"Valor Parcela R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      } 
    },         
    { field: "observacao", headerName: "Observação",  headerClassName: 'header-detail', width: 270 },
  ];  

  const columnsBaixadas = [     
    {
      headerClassName: 'blue-header',
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    { field: "fornecedorid", headerName: "Cód",  headerClassName: 'blue-header', width: 45},
    { field: "fornecedor", headerName: "Fornecedor",  headerClassName: 'blue-header',  renderCell: (params) => { return <strong>{params.value}</strong>; },
      width: 145  },
    { field: "contaid", headerName: "Cód",  headerClassName: 'blue-header', width: 45},
    { field: "conta", headerName: "Conta",  headerClassName: 'blue-header',  renderCell: (params) => { return <strong>{params.value}</strong>; },
       width: 145  },
    { field: "qtdeparcelas", headerName: "Qtde Parcelas",  headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right'},    
    { field: "valorparcela", headerName:"Valor Parcela R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 120, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },             
    { field: "desconto", headerName:"Desc R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 90, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },            
    { field: "acrescimo", headerName:"Acréscimo R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 100, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },        
    { field: "multa", headerName:"Multa R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 90, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },        
    { field: "juro", headerName: "Juro R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 90, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },        
    { field: "valorpgto", headerName:"Valor Pago R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 120, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    }        
  ];  

  const columnsDetailBaixadas= [     
    { field: "lojaorigem", headerName: "Loja",  headerClassName: 'header-detail', width: 15 },
    { field: "lojabaixa", headerName: "Lj Baixa",  headerClassName: 'header-detail', headerAlign: 'left', width: 60 },
    { field: "documento", headerName: "Documento",  headerClassName: 'header-detail', width: 90 },
    { field: "emissao",  type: 'date', valueGetter: (params) => params.row.emissao,  headerName: "Emissão", 
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },   
      headerClassName: 'header-detail', headerAlign: 'center', width: 93, align: 'right'},           
    { field: "vencimento", type: 'date', valueGetter: (params) => params.row.vencimento,  headerName:"Vencimento",
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerClassName: 'header-detail', headerAlign: 'center', 
      width: 93, align: 'right'},        
    { field: "datapagto",  type: 'date', valueGetter: (params) => params.row.datapagto, headerName:"Pagamento",  
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerClassName: 'header-detail',  headerAlign: 'center', 
      width: 93, align: 'right'},     
    { field: "valorparcela", headerName:"Valor Parcela",  headerClassName: 'header-detail',  headerAlign: 'right', width: 110, align: 'right', 
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },          
    { field: "desconto", headerName:"Desc",  headerClassName: 'header-detail',  headerAlign: 'right', width: 70, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "acrescimo", headerName:"Acréscimo",  headerClassName: 'header-detail',  headerAlign: 'right', width: 80, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "multa", headerName:"Multa",  headerClassName: 'header-detail',  headerAlign: 'right', width: 70, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "juro", headerName:"Juro",  headerClassName: 'header-detail',  headerAlign: 'right', width: 70, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "valorpgto", headerName:"Valor Pago",  headerClassName: 'header-detail',  headerAlign: 'right', width: 100, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },    
    { field: "obsbaixa", headerName: "Obs Baixa",  headerClassName: 'header-detail', width: 80 },   
  ];  

export const ContasAPagarContext = createContext();

const ContasAPagarProvider = () => {
  const sessionStorageUtils = new SessionStorageUtils();    
  const PAGE_SIZE = 50;
  const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0});  
  const [nomeGrupo, setNomeGrupo] = useState([]);
  const [showLojasEmissao, setShowLojasEmissao] = useState(false);   
  const [showLojasPagamento, setShowLojasPagamento] = useState(false);     
  const [showFornecedores, setShowFornecedores] = useState(false);       
  const [showContas, setShowContas] = useState(false);
  const [dadosContasAPagar, SetDadosContasAPagar] = useState([]);    
  const [tipoVisaoSelect, setTipoVisaoSelect] = useState('todos');
  const [periodoDataFiltro, setTipoDataFiltro] = useState('emissao');
  const [statusContasAPagar, setStatusContasAPagar] = useState('pendentes');
  const [lojasEmissaoFiltro, setLojasEmissaoFiltro] = useState([]); 
  const [lojasPagamentoFiltro, setLojasPagamentoFiltro] = useState([]); 
  const [fornecedoresFiltro, setFornecedoresFiltro] = useState([]);
  const [contasFiltro, setContasFiltro] = useState([]);
  const [documentoFiltro, setDocumentoFiltro] = useState('');
  const [showTipoImpRel, setShowTipoImpRel] = useState(false);
  const [tipoImpRel, setTipoImpRel] = useState('');
  const [loading, setLoading] = useState(true);     
  const [limparFiltros, SetLimparFiltros] = useState(false);   
  const executeRef = useRef(null);    
  const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([
  startOfMonth(new Date()),
  endOfMonth(new Date())
  ]);
  const navigate = useNavigate();
  const permissao = new Permissoes(); 
    
    useEffect(() => {
        setTimeout(() => {                 
            setLoading(true);
            if (ValidarPermissoes() == false) { return; }        
            LimparFiltrosSelecionados();           
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []);    

    async function ValidarPermissoes(){                    
      const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('f90699314233bcb8b8d0eeecdd28ca98');        
      if (autorizado) { return true; }
      navigate('/home')              
      return false;      
    }  

    useEffect(() => {
      if (dadosContasAPagar && dadosContasAPagar.length > 0 && tipoImpRel !== '')
      {
        ImprimirRelatorio();          
      }      
    }, [tipoImpRel]);  

    useEffect(() => {        
      if (limparFiltros)
      {
          definirLojaInicial();            
          SetLimparFiltros(false);
      }        
    }, [limparFiltros]);
    
    const handleLimparFiltros = async () => {   
      resetarDataInicial();
      LimparFiltrosSelecionados()                
      SetLimparFiltros(true);
    }
    
    function LimparFiltrosSelecionados() {
      sessionStorage.removeItem('selectedLojasEmissao');
      sessionStorage.removeItem('selectedLojasPagamento');             
      sessionStorage.removeItem('selectedFornecedores');             
      sessionStorage.removeItem('selectedContas');        
      setLojasEmissaoFiltro([]);
      setLojasPagamentoFiltro([]);
      setFornecedoresFiltro([]);
      setContasFiltro([]);
      setStatusContasAPagar('pendentes');
      setTipoDataFiltro('emissao');
      setDocumentoFiltro('');
      SetDadosContasAPagar([]);
    }
    
    const resetarDataInicial = () => {
      setDataIntervaloFiltro([
        startOfMonth(new Date()),
        new Date()
      ]);
    };

    const ImprimirRelatorio = async () => {             
        const classeImpressao = new ImpressaoContasAPagarPDF(dadosContasAPagar, filtrosSelecionados);      
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));         
        setShowTipoImpRel(false);        
    }   
    
    const visualizarImpressao = async  () => {              
      await handleShowTipoImpressao();                                       
      await setTipoImpRel('');    
    }     

    const getDetailPanelContent = React.useCallback(
      ({ row}) => <DetailPanelContent row={row}/>,
      [],
    );

    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
      setNomeGrupo(groupName);
    }

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);

      const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
      const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
    
      return (
          <>
          <div>           
              <div className='table-align'>
                  {renderTable()}
              </div>                        
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                      <Typography variant="subtitle2" color="textSecondary">
                          Total de registros: {totalRowCount}
                      </Typography>
                  </div>

                  <div style={{marginLeft: '50px'}}>
                      <Typography variant="subtitle2" color="textSecondary" >
                          Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                      </Typography>
                  </div>
              </div>
                  
                  <div style={{ alignSelf: 'flex-end'}}>
                      <Pagination
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          page={page + 1}
                          count={pageCount}                                                    
                          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                          onChange={(event, value) => apiRef.current.setPage(value - 1)}
                      />
                  </div>
              </div>            
          </div>
       
        </>
        );        
    }

    const handleShowLojasEmissao = () => 
    {            
        setShowLojasEmissao(true);
    }      
    
    const handleShowLojasPagamento = () => 
    {            
        setShowLojasPagamento(true);
    }    
    
    const handleShowFornecedores = () => 
    {            
        setShowFornecedores(true);
    }   

    const handleShowContas = () => 
    {            
        setShowContas(true);
        }

    const handleCloseTipoImpressao = () => 
    {            
          setShowTipoImpRel(false);
    }  

    const handleShowTipoImpressao = () => 
    {            
          setShowTipoImpRel(true);
    }  
    
    const handleCloseLojasEmissao = () => 
    {        
        setShowLojasEmissao(false);                           
    }

    const handleCloseLojasPagamento = () => 
    {        
        setShowLojasPagamento(false);                           
    }

    const handleCloseFornecedores = () => 
    {        
        setShowFornecedores(false);                           
    }

    const handleCloseContas = () => 
    {        
        setShowContas(false);                           
    }

    const definirClientesSelecionados = (id) => 
    {
        setFornecedoresFiltro(id);        
    }

    const definirContasSelecionadas = (id) => 
    {
        setContasFiltro(id);        
    }

    const definirLojasEmissaoSelecionadas = (lojas) => 
    {
        setLojasEmissaoFiltro(lojas);        
    }            

    const definirLojasPagamentoSelecionadas = (lojas) => 
    {
        setLojasPagamentoFiltro(lojas);        
    }     

    const definirFornecedoresSelecionados = (fornecedores) => 
    {
        setFornecedoresFiltro(fornecedores);        
    }            

    const definirTipoImpressaoRelatorio= (bool) =>
    {
        setTipoImpRel(bool);
    }  

  const definirLojaInicial = async()  => 
  {
      const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                
      if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasEmissaoFiltro.length === 0)
      {          
          const userId = JSON.parse(desencryptJSONUser).id;   
          await SetTenant();                                          
          const response = await api.get(`loja/lojascadastradas/${userId}`);
          const firstId = response.data[0].idloja;             
          await setLojasEmissaoFiltro(firstId);               
      }          
  }

  const handleExecutar = async() => {                                                                     
      setLoading(true);
      await getContasAPagar();                                         
      setLoading(false);                  
  } 
     
  const tipoFiltroToInt = () => {
    const enumValues = {
      'todos': 1,
      'fornecedores' : 2,
      'contas' : 3      
    };
  
    return enumValues;
  };      

  const tipoToInt = tipoFiltroToInt();
  
  const periodoDataFiltroToInt = () => {
    const enumValues = {
      'emissao': 1,
      'vencimento' : 2,
      'pagamento' : 3      
    };
  
    return enumValues;
  };      

  const periodoDataToInt = periodoDataFiltroToInt();

  const statusContasAPagarFiltroToInt = () => {
    const enumValues = {
      'pendentes': 1,
      'baixados' : 2        
    };

    return enumValues;
  };      

  const statusContasPagarToInt = statusContasAPagarFiltroToInt();

  const filtrosSelecionados = {    
    tipoVisaoSelect : tipoVisaoSelect,
    tipoImpressao : tipoImpRel,
    lojasEmissaoSelecionadas : lojasEmissaoFiltro,
    lojasPagamentoSelecionadas : lojasPagamentoFiltro,
    dataIntervaloFiltro : dataIntervaloFiltro,
    fornecedoresFiltro : fornecedoresFiltro,
    contasFiltro : contasFiltro,
    statusContasAPagar : statusContasAPagar,
    periodoDataFiltro : periodoDataFiltro,
    documentoFiltro : documentoFiltro,
    nomeGrupo : nomeGrupo
  }    

  const getContasAPagar = async() => {                   
    try 
    {                           
        const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
        const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);        

        let url = "";
        switch (statusContasPagarToInt[statusContasAPagar])
        {
           case 1: url = `pagar/relatorio/pendentes/${tipoToInt[tipoVisaoSelect]}/${periodoDataToInt[periodoDataFiltro]}/${dataIni}/${dataFin}/${lojasEmissaoFiltro}/`;        
              break;
           case 2: url = `pagar/relatorio/baixados/${tipoToInt[tipoVisaoSelect]}/${periodoDataToInt[periodoDataFiltro]}/${dataIni}/${dataFin}/${lojasEmissaoFiltro}/`;           
              break;
        }         

        if (statusContasAPagar === 'baixados')
        {
            if (lojasPagamentoFiltro.length > 0)
            {
              url += `${lojasPagamentoFiltro}/`;
            }        
            else
            {
              url += ` /`;
            }
        }

        if (fornecedoresFiltro.length > 0)
        {
           url += `${fornecedoresFiltro}/`;
        }
        else
        {
           url += ` /`;
        }

        if (contasFiltro.length > 0)
        {
           url += `${contasFiltro}/`;
        }
        else
        {
           url += ` /`;
        }     
        
        if (!isEmpty(documentoFiltro))
        {
           url += `${documentoFiltro}/`;
        }   
        else
        {
           url += ` /`;
        }            

        SetDadosContasAPagar([]);     
        await SetTenant();             
        const response = await api.get(url);                                                                           
        SetDadosContasAPagar(response.data);           
    }
    catch (e)
    {
        console.log('erro');
    }        
  }          

  const contextvalues = {
    definirClientesSelecionados,
    definirLojasEmissaoSelecionadas,
    definirLojasPagamentoSelecionadas,
    definirFornecedoresSelecionados,
    definirContasSelecionadas,
    definirTipoImpressaoRelatorio
  }  

  function CustomToolbar() {
    return (
      <CustomToolBar print={visualizarImpressao}/>         
    );
  }        
  
  const rowsPendentes = dadosContasAPagar.map((array) => {
    if (array.pagarDetalhes && array.pagarDetalhes.data !== null) {   
      const pagar = array.pagarDetalhes[0];
      const qtdeparcelas = array.pagarDetalhes.length;
      const valorpendente = array.pagarDetalhes.reduce((total, pagar) => {
        return total + pagar.valorpendente;
      }, 0);

      const pagarDetalhes = array.pagarDetalhes.map((pagar) => {                
        return {          
          id : pagar.conta + pagar.fornecedor + pagar.documento,
          lojaid: pagar.lojaid,        
          conta : pagar.conta,   
          documento : pagar.documento,          
          emissao: new Date(pagar.emissao),          
          vencimento: new Date(pagar.vencimento),          
          valorpendente: pagar.valorpendente,
          observacao : pagar.observacao       
        };
      });
         
      pagar.pagarDetalhes = pagarDetalhes;

      const pagarInfo = {
        id: pagar.conta + pagar.fornecedor +  pagar.documento,
        fornecedorid : pagar.fornecedorid,
        fornecedor : pagar.fornecedor,
        contaid : pagar.contaid,
        conta : pagar.conta,   
        qtdeparcelas: qtdeparcelas,
        valorpendente : valorpendente,               
        pagarDetalhes: pagarDetalhes
      };
  
      return pagarInfo;
    }    
  });

  const rowsBaixados = dadosContasAPagar.map((array) => {
    if (array.pagarMovimentoDetalhes && array.pagarMovimentoDetalhes.data !== null) {   
      const pagar = array.pagarMovimentoDetalhes[0];
      const qtdeparcelas = array.pagarMovimentoDetalhes.length;
      const desconto = array.pagarMovimentoDetalhes.reduce((total, pagar) => {
        return total + pagar.desconto;
      }, 0);
      const acrescimo = array.pagarMovimentoDetalhes.reduce((total, pagar) => {
        return total + pagar.acrescimo;
      }, 0);
      const juro = array.pagarMovimentoDetalhes.reduce((total, pagar) => {
        return total + pagar.juro;
      }, 0);
      const multa = array.pagarMovimentoDetalhes.reduce((total, pagar) => {
        return total + pagar.multa;
      }, 0);
      const valorparcela = array.pagarMovimentoDetalhes.reduce((total, pagar) => {
        return total + pagar.valorparcela;
      }, 0);
      const valorpgto = array.pagarMovimentoDetalhes.reduce((total, pagar) => {
        return total + pagar.valorpgto;
      }, 0);

      const pagarMovimentoDetalhes = array.pagarMovimentoDetalhes.map((pagar) => {                        
        return {          
          id : pagar.id,
          lojaorigem: pagar.lojaorigem,        
          lojabaixa: pagar.lojabaixa,                  
          documento : pagar.documento,          
          emissao: new Date(pagar.emissao),          
          vencimento: new Date(pagar.vencimento),          
          datapagto: new Date(pagar.datapagto),          
          valorparcela: pagar.valorparcela,           
          desconto: pagar.desconto,           
          acrescimo: pagar.acrescimo,           
          multa: pagar.multa,           
          juro: pagar.juro,           
          valorpgto: pagar.valorpgto,     
          obsbaixa : pagar.obsbaixa      
        };
      });
         
      pagar.pagarMovimentoDetalhes = pagarMovimentoDetalhes;

      const pagarInfo = {
        id: pagar.conta + pagar.fornecedor +  pagar.documento,
        fornecedorid : pagar.fornecedorid,
        fornecedor : pagar.fornecedor,
        contaid : pagar.contaid,
        conta : pagar.conta,   
        qtdeparcelas: qtdeparcelas,
        desconto: desconto,
        acrescimo: acrescimo,
        juro: juro,
        multa: multa,
        valorparcela : valorparcela,               
        valorpgto : valorpgto,               
        pagarMovimentoDetalhes: pagarMovimentoDetalhes
      };
  
      return pagarInfo;
    }     
  });              
  
  const totalizador = dadosContasAPagar.reduce((total, obj) => {
    if (statusContasAPagar === 'pendentes')
    {
        total.qtdeparcelas += obj.pagarDetalhes.length;
        total.valorpendente += obj.pagarDetalhes.reduce((total, pagar) => {return total + pagar.valorpendente;}, 0);         
        return total;
    }
    else if (statusContasAPagar === 'baixados')
    {
        total.qtdeparcelas += obj.pagarMovimentoDetalhes.length;
        total.valorparcela += obj.pagarMovimentoDetalhes.reduce((total, pagarMovimentoDetalhes) => {return total + pagarMovimentoDetalhes.valorparcela;}, 0);      
        total.desconto += obj.pagarMovimentoDetalhes.reduce((total, pagarMovimentoDetalhes) => {return total + pagarMovimentoDetalhes.desconto;}, 0);
        total.acrescimo += obj.pagarMovimentoDetalhes.reduce((total, pagarMovimentoDetalhes) => {return total + pagarMovimentoDetalhes.acrescimo;}, 0);
        total.multa += obj.pagarMovimentoDetalhes.reduce((total, pagarMovimentoDetalhes) => {return total + pagarMovimentoDetalhes.multa;}, 0);    
        total.juro += obj.pagarMovimentoDetalhes.reduce((total, pagarMovimentoDetalhes) => {return total + pagarMovimentoDetalhes.juro;}, 0);                          
        total.valorpgto += obj.pagarMovimentoDetalhes.reduce((total, pagarMovimentoDetalhes) => {return total + pagarMovimentoDetalhes.valorpgto;}, 0);                          
        return total;
    }        
    }, { qtdeparcelas: 0, valorpendente: 0, valorparcela: 0, desconto: 0,  acrescimo: 0, multa : 0, juro: 0, valorpgto: 0});

  if (loading) {
    return (
       <LoadingScreen/>
    );
  }   

  const renderTable = () => {
    if (statusContasAPagar === 'pendentes') {
      return (
        <table class="table">              
              <thead>                        
                  <th scope="col" style={{ width: '820px', fontSize: '20px' }}></th>                                   
                  <th scope="col" className="text-left" style={{width: '170px', fontSize: '20px'}}></th>                  
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '20px'}}></th>                                      
              </thead>
              <tbody>
                  <tr >
                  <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold'}}>Total</th>
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {!isEmpty(totalizador) ? totalizador.qtdeparcelas : 0}
                  </td>
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.valorpendente : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>                                                    
                  </tr>
              </tbody>
              </table> 
      );
    } else {
      return (
        <table class="table">              
        <thead>                        
            <th scope="col" style={{ width : '440px', fontSize: '20px' }}></th>                                   
            <th scope="col" className="text-left" style={{width: '100px', fontSize: '20px'}}></th>
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '100px', fontSize: '20px'}}></th>  
            <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>  
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>                                     
        </thead>
        <tbody>
            <tr >
            <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>
                    {!isEmpty(totalizador) ? totalizador.qtdeparcelas : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorparcela : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.desconto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.acrescimo : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td> 
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.multa : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>    
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.juro : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>                                    
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorpgto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>                                    
            </tr>
        </tbody>
        </table> 
      );
    }
  };     
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;
  
      if (name === 'documento') {
        executeRef.current.focus();                   
      }
    }    
  }

  return (    
    <ContasAPagarContext.Provider value={contextvalues}>        
    <form className="form"> 
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Contas a Pagar</label>            
          </div>                                           
          <Card.Body>                    
              <Container>
                  <Card>
                  <div className='align-left'>
                      <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>

                      <Card style={{width: 482}}>

                      <div className="row">                     
                        <div className="col-md-4">
                          <div className="form-check">                            
                              <Radio
                                checked={tipoVisaoSelect === 'todos'}
                                onChange={() => {
                                  setTipoVisaoSelect('todos'); 
                                  SetDadosContasAPagar([]);                                                                   
                                } }
                                value={tipoVisaoSelect}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'A' }}
                                size="small"
                                id="todosRadio" 
                              />
                              <label class="form-check-label" for="todosRadio" style={{cursor: 'pointer'}}>
                                  Todos
                              </label>                          
                          </div>                      
                        </div>                      

                        <div className="col">                                  
                            <Radio
                              checked={tipoVisaoSelect === 'fornecedores'}
                              onChange={() => {
                                setTipoVisaoSelect('fornecedores'); 
                                SetDadosContasAPagar([]);                                                                   
                              } }
                              value={tipoVisaoSelect}
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'B' }}
                              size="small"
                              id="fornecedorRadio"
                            />
                              <label class="form-check-label" for="fornecedorRadio" style={{cursor: 'pointer'}}>
                                  Fornecedores
                              </label>                                                  
                        </div>                

                        <div className="col">    
                          <Radio
                            checked={tipoVisaoSelect === 'contas'}
                            onChange={() => {
                              setTipoVisaoSelect('contas'); 
                              SetDadosContasAPagar([]);                                                                   
                            } }
                            value={tipoVisaoSelect}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            size="small"
                            id="contasRadio"
                          />
                          <label class="form-check-label" for="contasRadio" style={{cursor: 'pointer'}}>
                              Contas
                          </label>
                        </div>

                      </div>                    
                    </Card>                   
                  </div>
                </Card>
              </Container>

              <Container>
                  <Card className='text-left'>                                                       
                      <div className='filtros-align-contas-pagar'>
                      <div className='row'>
                        <div className='col'>
                          <div className="row" style={{ width: '506px' }}>
                            <div className="col-md">
                            <LocalizationProvider  
                                dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                                               
                              >                                                           
                              <DateRangePicker                               
                                localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                value={dataIntervaloFiltro}      
                                onChange={(newValue) => setDataIntervaloFiltro(newValue)}                                 
                              />                     
                            </LocalizationProvider>    
                            </div>
                          </div>            
                        </div>

                        <div className='col'>
                        <label className="label" style={{fontWeight: 'bold'}}>Status:</label>          
                        <Card style={{width: 482}}>                      
                            <div className="row">
                              <div className="col-md-4">  
                                <div className="form-check">                                                                    
                                  <Radio
                                    checked={statusContasAPagar === 'pendentes'}
                                    onChange={() => {
                                      setStatusContasAPagar('pendentes'); 
                                      SetDadosContasAPagar([]);        
                                      setLojasPagamentoFiltro([]);
                                      sessionStorage.removeItem('selectedLojasPagamento');
                                      if (periodoDataFiltro === 'pagamento')
                                      {
                                          setTipoDataFiltro('emissao');                                                                       
                                      }
                                    }}                                    
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    size="small"
                                    id="pendenteRadio" 
                                  />                                     
                                  <label className="form-check-label" htmlFor="pendenteRadio" 
                                    style={{ cursor: 'pointer' }}>
                                    Pendentes
                                  </label>                                
                                </div>          
                              </div>          
                                              
                              <div className="col">                                                                                                                    
                                <Radio
                                    checked={statusContasAPagar === 'baixados'}
                                    onChange={() => {
                                      SetDadosContasAPagar([]);                                 
                                      setLojasPagamentoFiltro([]);
                                      sessionStorage.removeItem('selectedLojasPagamento');
                                      setStatusContasAPagar('baixados');  
                                    }}                             
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    size="small"
                                    id="baixadosRadio" 
                                  />         
                                <label className="form-check-label" htmlFor="baixadosRadio" style={{cursor: 'pointer'}}>
                                  Baixados
                                </label>                                                      
                              </div>     
                            </div>                               
                        </Card>  
                        </div>           
                      </div>                                      

                      <div className='row'>
                        <div className='col'>                      
                          <div className="align-left-filters">
                              <div className="row align-items-start">
                                  <div className="col">                       
                                    <ButtonLojasEmissao
                                        onClick={handleShowLojasEmissao}
                                        width={'220px'}                                  
                                        lojasEmissaoFiltro={lojasEmissaoFiltro}
                                    />                              
                                  </div>                                                             
                                  <div className="col">
                                    <ButtonLojasPagamento                                    
                                        onClick={handleShowLojasPagamento}
                                        width={'220px'}                                  
                                        lojasPagamentoFiltro={lojasPagamentoFiltro}
                                        disabled={!(statusContasAPagar === 'baixados')}
                                    />                                                                                   
                                  </div>                                                                                                                 
                              </div>                            
                          </div> 
                        </div>                            
                                  
                        <div className='col'>                        
                          <label className='text' style={{ fontWeight: 'bold', marginTop: '10px'  }}>Período:</label>                     
                              <Card style={{width: 483}}>
                              <div className="row">                     
                                <div className="col-md-4">
                                  <div className="form-check">                                   
                                    <Radio
                                      checked={periodoDataFiltro === 'emissao'}
                                      onChange={() => {
                                        setTipoDataFiltro('emissao'); 
                                      }}                         
                                      name="radio-button-demo"
                                      inputProps={{ 'aria-label': 'A' }}
                                      size="small"
                                      id="emissaoRadio" 
                                    />   
                                    <label className="form-check-label" htmlFor="emissaoRadio" style={{cursor: 'pointer'}}>
                                      Emissão
                                    </label>
                                  </div>
                                </div>                          
                                <div className="col">                                                                     
                                    <Radio
                                      checked={periodoDataFiltro === 'vencimento'}
                                      onChange={() => {
                                        SetDadosContasAPagar([]);                                                                                                 
                                        setTipoDataFiltro('vencimento');  
                                      }}                      
                                      name="radio-button-demo"
                                      inputProps={{ 'aria-label': 'A' }}
                                      size="small"
                                      id="vencimentoRadio" 
                                    />   
                                    <label className="form-check-label" htmlFor="vencimentoRadio" style={{cursor: 'pointer'}}>
                                      Vencimento
                                    </label>                                  
                                </div>            
                                <div className="col">                                                                    
                                    <Radio
                                      checked={periodoDataFiltro === 'pagamento'}                              
                                      onChange={() => {
                                         SetDadosContasAPagar([]);                                                                         
                                         setTipoDataFiltro('pagamento');                                          
                                       }}                    
                                      name="radio-button-demo"
                                      inputProps={{ 'aria-label': 'A' }}
                                      disabled={statusContasAPagar !== 'baixados'}
                                      size="small"
                                      id="pagamentoRadio" 
                                    />   
                                    <label className="form-check-label" htmlFor="pagamentoRadio" 
                                      style={{
                                        cursor: statusContasAPagar === 'baixados' ? 'pointer' : 'default',
                                        color: statusContasAPagar === 'baixados' ? 'black' : '#9e9e9e',
                                      }}>
                                      Pagamento
                                    </label>                                  
                                </div>                                  
                            </div>   
                            </Card>  
                          </div>    
                        </div>                                                                                                                   
                      </div>     

                      <div className='row'>                       
                        <div className="filtros">
                          <div className="align-left">
                            <div className="row align-items-start" style={{marginTop: '0px'}}>                        
                                <div className="col-lg">                       
                                  <ButtonFornecedores
                                      onClick={handleShowFornecedores}
                                      width={'220px'}    
                                      marginTop={'10px'}                              
                                      fornecedoresFiltro={fornecedoresFiltro}
                                  />                              
                                </div>    
                                  <div className="col-lg">                       
                                    <ButtonContas
                                        onClick={handleShowContas}
                                        width={'220px'}   
                                        marginTop={'10px'}                               
                                        contasFiltro={contasFiltro}
                                    />                              
                                  </div>   
                                <div className="col-lg">                       

                                <div className="col">               
                                  <TextField 
                                      type="text"
                                      id="outlined-basic" 
                                      name="documento" 
                                      label="Documento"                                       
                                      variant="outlined"     
                                      onKeyDown={handleKeyDown}                         
                                      style={{
                                        marginLeft: '62px',
                                        width: '170px',                                                                                                                                               
                                      }}                               
                                      onChange={(e) => {
                                        setDocumentoFiltro(e.target.value);                                       
                                      }}
                                      value={documentoFiltro} 
                                      InputProps={{
                                        style: {                                                                                   
                                          marginTop: '10px', 
                                          maxHeight: '35px',
                                        },
                                      }}                           
                                  />                                                                                     
                                </div>                    
                              </div>                    
                            </div>                    
                          </div>  
                        </div>                                
                      </div>
                 
                      <div class="row" style={{marginTop: '30px'}}>
                        <div className='col-2'>
                            <div className="actions">
                            <ButtonExecute 
                                refFilter={executeRef}
                                width={'220px'}
                                onClick={handleExecutar}
                            />   
                            </div>                    
                        </div>
                        <div className='col-8' style={{marginLeft: '75px'}}>
                            <ButtonClear                                 
                                onClick={handleLimparFiltros}
                            />   
                        </div>
                    </div>                              
                                  
                  </Card>
              </Container>                 
          </Card.Body>          
      </form>     
          
      <ModalLojasEmissao show={showLojasEmissao} value={ContasAPagarContext} onHide={handleCloseLojasEmissao}/>                      
      <ModalLojasPagamento show={showLojasPagamento} value={ContasAPagarContext} onHide={handleCloseLojasPagamento}/>                                 
      <ModalFornecedores show={showFornecedores} value={ContasAPagarContext} onHide={handleCloseFornecedores}/>                                 
      <ModalContas show={showContas} value={ContasAPagarContext} onHide={handleCloseContas}/>
      <ModalImpressaoResumidoDetalhado show={showTipoImpRel} value={ContasAPagarContext} onHide={handleCloseTipoImpressao}/>                   
            
      {dadosContasAPagar && dadosContasAPagar.length > 0 && (
        <Container>        
        <CustomStyledDataGrid                  
          columns={statusContasAPagar === 'baixados'
                ? [...columnsBaixadas]
                : statusContasAPagar === 'pendentes'
                ? columnsPendentes
                : []}                      
          rows={statusContasAPagar === 'baixados'
                ? [...rowsBaixados]
                : statusContasAPagar === 'pendentes'
                ? rowsPendentes
                : []}                      
          slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination                          
          }}       
          headerHeight={20}        
          getDetailPanelHeight={() => 'auto'} 
          getDetailPanelContent={getDetailPanelContent}                 
          headerClassName={headerClassName}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[PAGE_SIZE]}                                                                                 
        />                                
        </Container>
        )}      
      
    </ContasAPagarContext.Provider>         
  );
}

export default ContasAPagarProvider;

