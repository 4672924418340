import React, { createContext, useState, useEffect } from 'react';
import { Stack, Pagination, Paper, Typography, Box, Grid, Container, IconButton, Radio } from '@mui/material'
import {    
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  GridExpandMoreIcon
} from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import PaginationItem from '@mui/material/PaginationItem';
import '../Crediario/styles.css'
import { api } from '../../services/api';
import { isEmpty } from 'lodash';
import { startOfMonth, endOfMonth,  getYear } from 'date-fns';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalClientes from '../../components/Modal/ModalClientes';
import { ImpressaoCrediarioPDF } from '../../components/Impressao/ImpressaoCrediarioPDF';
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { Card, Button } from 'react-bootstrap';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import { ptBR } from 'date-fns/locale';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonClientes from '../../components/Buttons/ButtonClientes';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function DetailPanelContent({ row: rowProp }) {     
  return (
    <Container sx={{ height: '100%' }}>
      <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
        <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
          <Stack direction="column" spacing={1} sx={{ height: '100%' }}>           
            <Grid container sx={{ flexGrow: 1 }}>
              <Grid item md={6}>                
              </Grid>
            </Grid>
            <Container sx={{ height: '100%' }}>           
              <CustomStyledDataGrid 
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [
                        {field : rowProp.crediariosMovimento 
                          ? 'datapagto' 
                          : 'vencimento', 
                          sort: rowProp.crediariosMovimento 
                          ? 'desc' 
                          : 'asc'
                        }],
                  },
                }}                                                    
                autoHeight                
                columns={
                  rowProp.crediariosMovimento
                ? [...columnsDetailBaixadas]
                : [...columnsDetailPendentes]
                }                
                 rows={
                  rowProp.crediariosMovimento
                  ? rowProp.crediariosMovimento
                  : rowProp.crediarios
                }               
                sx={{ flex: 1, height: '100%' }}                  
                hideFooter
              />
            </Container>
           
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}

  const headerClassName = 'blue-header';

  const columnsPendentes = [         
    {
      headerClassName: 'blue-header',
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    { field: "id", headerName: "Cód",  headerClassName: 'blue-header', width: 80, headerHeight : 10 },
    { field: "nome", headerName: "Nome",  headerClassName: 'blue-header',  
      renderCell: (params) => { return <strong>{params.value}</strong>; }, width: 340, headerHeight : 10},
    { field: "qtdeparcelas", headerName: "Qtde Parcelas",  headerClassName: 'blue-header', headerAlign: 'right',
      width: 170, align: 'right', headerHeight : 10},         
    { field: "valorpendente", headerName:"Valor Parcela R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }, headerHeight : 10
    },    
    { field: "jurosmultacalculado", headerName:"Multa/Juros R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 170, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      } , headerHeight : 10
    },        
    { field: "valorliquido", headerName:"Valor Líquido R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 170, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }, headerHeight : 10
    }        
  ];  

  const columnsDetailPendentes= [     
    { field: "lojaid", headerName: "Loja",  headerClassName: 'header-detail', width: 90 },
    { field: "documento", headerName: "Documento",  headerClassName: 'header-detail', width: 130 },
    { field: "emissao",  type: 'date', valueGetter: (params) => params.row.emissao, 
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') }, 
      headerName: "Data Emissão",  headerClassName: 'header-detail', 
      headerAlign: 'right', width: 140, align: 'right'},           
    { field: "vencimento", type: 'date', valueGetter: (params) => params.row.vencimento, 
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerName:"Data Vencimento",  headerClassName: 'header-detail', headerAlign: 'right', width: 150, 
      align: 'right'},        
    { field: "ulitmovencimento",  type: 'date', valueGetter: (params) => getYear(params.row.ultimovencimento) <= 1969 ? '' : params.row.ultimovencimento,
      headerName:"Último Vencimento",  headerClassName: 'header-detail',  
      valueFormatter: (params) => {
        if (params.value) {
          const ultimovencimentoDate = new Date(params.value);
          return getYear(ultimovencimentoDate) >= 1969 ? ultimovencimentoDate.toLocaleDateString('pt-BR') : '';
        }
        return null;
      },
      headerAlign: 'right', width: 150, align: 'right',  
      },    
    { field: "valorpendente", headerName:"Valor Parcela R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      } 
    },         
    { field: "jurosmultacalculado", headerName:"Multa/Juros R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "valorliquido", headerName:"Valor Líquido R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 160, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      } 
  },       
  ];  

  const columnsBaixadas = [     
    {
      headerClassName: 'blue-header',
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    { field: "id", headerName: "Cód",  headerClassName: 'blue-header', width: 80},
    { field: "nome", headerName: "Nome",  headerClassName: 'blue-header',  renderCell: (params) => { return <strong>{params.value}</strong>; },
      width: 220  },
    { field: "qtdeparcelas", headerName: "Qtde Parcelas",  headerClassName: 'blue-header', headerAlign: 'right', width: 120, align: 'right'},    
    { field: "valorparcela", headerName:"Valor Parcela R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 150, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },             
    { field: "desconto", headerName:"Desc R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 120, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },        
    { field: "juromulta", headerName:"Multa/Juro R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 120, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },        
    { field: "descontojuro", headerName:"Desc Juro R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 120, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },        
    { field: "valorpgto", headerName:"Valor Líquido R$",  headerClassName: 'blue-header',  headerAlign: 'right', width: 170, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    }        
  ];  

  const columnsDetailBaixadas= [     
    { field: "lojaid", headerName: "Loja",  headerClassName: 'header-detail', width: 35 },
    { field: "documento", headerName: "Documento",  headerClassName: 'header-detail', width: 120 },
    { field: "emissao",  type: 'date', valueGetter: (params) => params.row.emissao,  headerName: "Data Emissão",  
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerClassName: 'header-detail', headerAlign: 'right', width: 110, align: 'right'},           
    { field: "vencimento", type: 'date', valueGetter: (params) => params.row.vencimento,  headerName:"Data Vencimento",      
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerClassName: 'header-detail', headerAlign: 'right', 
      width: 120, align: 'right'},        
    { field: "datapagto",  type: 'date', valueGetter: (params) => params.row.datapagto, headerName:"Data Baixa",  
      valueFormatter: (params) => { return params.value.toLocaleDateString('pt-BR') },
      headerClassName: 'header-detail',  headerAlign: 'right', 
      width: 110, align: 'right'},     
    { field: "valorparcela", headerName:"Valor Parcela R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', 
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      }
    },          
    { field: "desconto", headerName:"Desc R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 100, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "juromulta", headerName:"Multa/Juro R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 100, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "descontojuro", headerName:"Desc Juro R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 100, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
    { field: "valorpgto", headerName:"Valor Líquido R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 130, align: 'right', 
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      }
    },       
  ];  

export const CrediarioContext = createContext();

const CrediarioProvider = () => {
  const sessionStorageUtils = new SessionStorageUtils();  
  const PAGE_SIZE = 50;
  const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0});   
  const [nomeGrupo, setNomeGrupo] = useState([]);
  const [showLojas, setShowLojas] = useState(false);   
  const [showClientes, setShowClientes] = useState(false);
  const [dadosCrediario, SetDadosCrediario] = useState([]);    
  const [tipoVisaoSelect, setTipoVisaoSelect] = useState('resumido');
  const [periodoDataFiltro, setTipoDataFiltro] = useState('venda');
  const [statusCrediario, setStatusCrediario] = useState('pendentes');
  const [lojasFiltro, setLojasFiltro] = useState([]); 
  const [clientesFiltro, setClientesFiltro] = useState([]);
  const [loading, setLoading] = useState(true);      
  const [limparFiltros, SetLimparFiltros] = useState(false);               
  const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date())
  ]);
  const navigate = useNavigate();
  const permissao = new Permissoes(); 
    
  useEffect(() => {
        setTimeout(() => {                 
            setLoading(true);       
            if (ValidarPermissoes() == false) { return; } 
            LimparFiltrosSelecionados();   
            definirLojaInicial(); 
            DefinirNomeGrupo();
            setLoading(false);                      
          }, 500);                      
    }, []); 
    
    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('29547c214462c2b17c1305aee0900fa6');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  
    
    useEffect(() => {        
      if (limparFiltros)
      {
          definirLojaInicial();            
          SetLimparFiltros(false);
      }        
    }, [limparFiltros]);
    
    const handleLimparFiltros = async () => {   
      resetarDataInicial();
      LimparFiltrosSelecionados()                
      SetLimparFiltros(true);
    }

    function LimparFiltrosSelecionados() {
      sessionStorage.removeItem('selectedLojas');
      sessionStorage.removeItem('selectedClientes');
      setLojasFiltro([]);
      setClientesFiltro([]);
      setStatusCrediario('pendentes');
      setTipoDataFiltro('venda');
      SetDadosCrediario([]);
    }
    
    const resetarDataInicial = () => {
      setDataIntervaloFiltro([
        startOfMonth(new Date()),
        new Date()
      ]);
    };

    const getDetailPanelContent = React.useCallback(
      ({ row}) => <DetailPanelContent row={row}/>,
      [],
    );

    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
      setNomeGrupo(groupName);
    }

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);

      const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
      const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
    
      return (
          <>
          <div>           
              <div className='table-align'>
                  {renderTable()}
              </div>                        
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                      <Typography variant="subtitle2" color="textSecondary">
                          Total de registros: {totalRowCount}
                      </Typography>
                  </div>

                  <div style={{marginLeft: '50px'}}>
                      <Typography variant="subtitle2" color="textSecondary" >
                          Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                      </Typography>
                  </div>
              </div>
                  
                  <div style={{ alignSelf: 'flex-end'}}>
                      <Pagination
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          page={page + 1}
                          count={pageCount}                                                    
                          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                          onChange={(event, value) => apiRef.current.setPage(value - 1)}
                      />
                  </div>
              </div>            
          </div>
       
        </>
        );        
    }

    const handleShowLojas = () => 
    {            
        setShowLojas(true);
    }       
    
    const handleCloseLojas = () => 
    {        
        setShowLojas(false);                           
    }

    const handleCloseClientes = () => 
    {        
        setShowClientes(false);                           
    }    

    const handleShowClientes = () => 
    {            
        setShowClientes(true);
    }          

    const definirClientesSelecionados = (id) => 
    {
        setClientesFiltro(id);        
    }

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }            

  const definirLojaInicial = async()  => 
  {
      const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                
      if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
      {          
          const userId = JSON.parse(desencryptJSONUser).id;                       
          await SetTenant(); 
          const response = await api.get(`loja/lojascadastradas/${userId}`);
          const firstId = response.data[0].idloja;             
          await setLojasFiltro(firstId);               
      }          
  }

  const handleExecutar = async() => {                                                                     
      setLoading(true);
      await getCrediarios();                                         
      setLoading(false);                  
  } 
     
  
  const periodoDataFiltroToInt = () => {
    const enumValues = {
      'venda': 1,
      'vencimento' : 2,
      'pagamento' : 3      
    };
  
    return enumValues;
  };      

  const periodoDataToInt = periodoDataFiltroToInt();

  const statusCrediarioFiltroToInt = () => {
    const enumValues = {
      'pendentes': 1,
      'baixados' : 2        
    };

    return enumValues;
  };      

  const statusCrediarioToInt = statusCrediarioFiltroToInt();

  const filtrosSelecionados = {
    tipoVisao : tipoVisaoSelect,
    lojasSelecionadas : lojasFiltro,
    dataIntervaloFiltro : dataIntervaloFiltro,
    statusCrediario : statusCrediario,
    periodoDataFiltro : periodoDataFiltro,
    clientesFiltro : clientesFiltro,
    nomeGrupo : nomeGrupo
  }  

  const visualizarImpressao = async  () => {        
    const classeImpressao = new ImpressaoCrediarioPDF(dadosCrediario, filtrosSelecionados);
    const documento = await classeImpressao.PreparaDocumento();
    pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
  }  

  const getCrediarios = async() => {                   
    try 
    {                           
        const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
        const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);        

        let url = "";
        switch (statusCrediarioToInt[statusCrediario])
        {
           case 1: url = `crediario/relatorio/pendentes/${periodoDataToInt[periodoDataFiltro]}/${lojasFiltro}`;        
              break;
           case 2: url = `crediario/relatorio/baixados/${periodoDataToInt[periodoDataFiltro]}/${lojasFiltro}`;           
              break;
        }         

        if (clientesFiltro.length > 0) {                
            url += `/${clientesFiltro}`;
        } else {
            url += "/ ";
        }             

        url += `/${dataIni}/${dataFin}`                      

        SetDadosCrediario([]);       
        await SetTenant();         
        const response = await api.get(url);                                                                           
        SetDadosCrediario(response.data);           
    }
    catch (e)
    {
        console.log('erro');
    }        
  }          

  const contextvalues = {
    definirClientesSelecionados,
    definirLojasSelecionadas        
  }  

  function CustomToolbar() {
    return (
      <CustomToolBar print={visualizarImpressao}/>         
    );
  }        
  
  const rowsPendentes = dadosCrediario.map((array) => {
    if (array.crediarios && array.crediarios.data !== null) {    
      const cliente = array.clientes[0];   
      const qtdeparcelas = array.crediarios.length;
      const valorpendente = array.crediarios.reduce((total, crediario) => { return total + crediario.valorpendente}, 0);
      const jurosmultacalculado = array.crediarios.reduce((total, crediario) => {
        return total + crediario.jurosmultacalculado}, 0);
      const valorliquido = array.crediarios.reduce((total, crediario) => {
        return total + crediario.valorliquido}, 0);                      
    
      const crediarios = array.crediarios.map((crediario) => {                
        return {
          id: crediario.id,
          lojaid: crediario.lojaid,
          documento: crediario.documento,
          vendaid: crediario.vendaid,
          emissao: new Date(crediario.emissao),
          documento: crediario.documento,        
          vencimento: new Date(crediario.vencimento),
          ultimovencimento: new Date(crediario.ultimovencimento),   
          valorpendente: crediario.valorpendente, 
          jurosmultacalculado: crediario.jurosmultacalculado,
          valorliquido: crediario.valorliquido  
        };
      });
    
      cliente.crediarios = crediarios; 
    
      const clienteInfo = {
        id: cliente.id,
        nome: cliente.nome,
        qtdeparcelas: qtdeparcelas,
        valorpendente : valorpendente,
        jurosmultacalculado: jurosmultacalculado,
        valorliquido: valorliquido,
        crediarios: crediarios
      };
    
      return clienteInfo;
    }    
  });      

  const rowsBaixados = dadosCrediario.map((array) => {
    if (array.crediariosMovimento && array.crediariosMovimento.data !== null) {
        const cliente = array.clientes[0]; 
      
        const qtdeparcelas = array.crediariosMovimento.length;
        const valorparcela = array.crediariosMovimento.reduce((total, crediariosMovimento) => {
          return total + crediariosMovimento.valorparcela;}, 0)
        const desconto = array.crediariosMovimento.reduce((total, crediariosMovimento) => {
          return total + crediariosMovimento.desconto}, 0);
        const juromulta = array.crediariosMovimento.reduce((total, crediariosMovimento) => {
          return total + crediariosMovimento.juromulta}, 0);
        const descontojuro = array.crediariosMovimento.reduce((total, crediariosMovimento) => {
          return total + crediariosMovimento.descontojuro}, 0); 
        const valorpgto = array.crediariosMovimento.reduce((total, crediariosMovimento) => {
          return total + crediariosMovimento.valorpgto}, 0);                      
      
        const crediariosMovimento = array.crediariosMovimento.map((crediarioMovimento) => {         
          return {
            id: crediarioMovimento.id,
            lojaid: crediarioMovimento.lojaid,     
            documento : crediarioMovimento.documento,    
            emissao: new Date(crediarioMovimento.emissao),             
            vencimento: new Date(crediarioMovimento.vencimento),
            datapagto: new Date(crediarioMovimento.datapagto),
            valorparcela : crediarioMovimento.valorparcela,
            desconto : crediarioMovimento.desconto,
            juromulta : crediarioMovimento.juromulta,
            descontojuro : crediarioMovimento.descontojuro,
            valorpgto : crediarioMovimento.valorpgto, 
          };                            
  });
  
    cliente.crediariosMovimento = crediariosMovimento; 
  
    const clienteInfo = {
      id: cliente.id,
      nome: cliente.nome,
      qtdeparcelas: qtdeparcelas,
      valorparcela : valorparcela,
      desconto : desconto,
      juromulta: juromulta,
      descontojuro : descontojuro,
      valorpgto : valorpgto,   
      crediariosMovimento: crediariosMovimento
    };
  
    return clienteInfo;
  }
  });              
  
const totalizador = dadosCrediario.reduce((total, obj) => {
  if (statusCrediario === 'pendentes')
  {
      total.qtdeparcelas += obj.crediarios.length;
      total.valorpendente += obj.crediarios.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.valorpendente;}, 0); 
      total.jurosmultacalculado += obj.crediarios.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.jurosmultacalculado;}, 0);
      total.valorliquido += obj.crediarios.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.valorliquido;}, 0);                
      return total;
  }
  else if (statusCrediario === 'baixados')
  {
      total.qtdeparcelas += obj.crediariosMovimento.length;
      total.valorparcela += obj.crediariosMovimento.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.valorparcela;}, 0);      
      total.desconto += obj.crediariosMovimento.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.desconto;}, 0);
      total.juromulta += obj.crediariosMovimento.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.juromulta;}, 0);
      total.descontojuro += obj.crediariosMovimento.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.descontojuro;}, 0);    
      total.valorpgto += obj.crediariosMovimento.reduce((total, crediariosMovimento) => {return total + crediariosMovimento.valorpgto;}, 0);                          
      return total;
  }        
  }, { qtdeparcelas: 0, valorpendente: 0, jurosmultacalculado: 0, valorliquido : 0, valorparcela: 0, desconto: 0, juromulta: 0, descontojuro: 0, valorpgto: 0});

  if (loading) {
    return (
       <LoadingScreen/>
    );
  }   

  const renderTable = () => {
    if (statusCrediario === 'pendentes') {
      return (
        <table class="table">              
              <thead>                        
                  <th scope="col" style={{ width: '470px', fontSize: '20px' }}></th>                                   
                  <th scope="col" className="text-left" style={{width: '170px', fontSize: '20px'}}></th>
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '20px'}}></th>   
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '20px'}}></th>  
                  <th scope="col" className="text-right" style={{width: '170px', fontSize: '20px'}}></th>                                      
              </thead>
              <tbody>
                  <tr >
                  <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold'}}>Total</th>
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {!isEmpty(totalizador) ? totalizador.qtdeparcelas : 0}
                  </td>
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.valorpendente : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>                                   
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.jurosmultacalculado : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.valorliquido : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>                 
                  </tr>
              </tbody>
              </table> 
      );
    } else {
      return (
        <table class="table">              
        <thead>                        
            <th scope="col" style={{ width : '350px', fontSize: '20px' }}></th>                                   
            <th scope="col" className="text-left" style={{width: '120px', fontSize: '20px'}}></th>
            <th scope="col" className="text-right" style={{width: '150px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>  
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>  
            <th scope="col" className="text-right" style={{width: '170px', fontSize: '20px'}}></th>                                     
        </thead>
        <tbody>
            <tr >
            <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>
                    {!isEmpty(totalizador) ? totalizador.qtdeparcelas : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorparcela : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.desconto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.juromulta : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td> 
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.descontojuro : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>    
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorpgto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>                                    
            </tr>
        </tbody>
        </table> 
      );
    }
  };
  
    function extractClientesIdsFromClientesData(clientesData) {
      let allClientesIds = clientesData.flatMap((array) =>
        array.clientes && array.clientes.length ? array.clientes.map((cliente) => cliente.id) : []
      );
    
      return allClientesIds;
  }

  const expandedRowIds = tipoVisaoSelect === 'detalhado' ?  extractClientesIdsFromClientesData(dadosCrediario) : []; 
    
  return (    
    <CrediarioContext.Provider value={contextvalues}>        
    <form className="form"> 
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Crediário</label>
          </div>                                           
          <Card.Body>                    
              <Container>
                  <Card>
                  <div className='align-left'>
                      <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>
                      <Card style={{width: 482}}>
                      <div className="row">                     
                        <div className="col-md-4">
                          <div className="form-check">                          
                            <Radio
                              checked={tipoVisaoSelect === 'resumido'}
                              onChange={() => {
                                setTipoVisaoSelect('resumido'); 
                                SetDadosCrediario([]);                                                                   
                              } }                   
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                              size="small"
                              id="resumidoRadio" 
                            />   
                            <label class="form-check-label" for="resumidoRadio" style={{cursor: 'pointer'}}>
                                Resumido
                            </label>
                          </div>
                        </div>                          
                        <div className="col">
                          <div class="form-check">                            
                            <Radio
                                checked={tipoVisaoSelect === 'detalhado'}
                                onChange={() => {
                                  setTipoVisaoSelect('detalhado');  
                                  SetDadosCrediario([]);                                                                                                                                                               
                                }}                  
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'A' }}
                                size="small"
                                id="detalhadoRadio" 
                            />   
                            <label class="form-check-label" for="detalhadoRadio" style={{cursor: 'pointer'}}>
                                Detalhado
                            </label>
                          </div>    
                        </div>                                               
                      </div>  
                    </Card>                   
                  </div>
                </Card>
              </Container>

              <Container>
                  <Card className='text-left'>                                                       
                      <div className='filtros-align-crediario'>
                        <div className='row'>
                          <div className='col'>
                            <div className="row" style={{ width: '506px' }}>
                              <div className="col-md">
                              <LocalizationProvider  
                                  dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                                               
                                >                                                           
                                <DateRangePicker                               
                                  localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                  value={dataIntervaloFiltro}      
                                  onChange={(newValue) => setDataIntervaloFiltro(newValue)}                        
                                />                     
                              </LocalizationProvider>    
                              </div>
                            </div>            
                          </div>

                          <div className='col'>
                            <label className="label" style={{fontWeight: 'bold'}}>Status:</label>          
                            <Card style={{width: 482}}>                      
                                <div className="row">
                                  <div className="col-md-4">   
                                    <div className="form-check">                                    
                                      <Radio
                                        checked={statusCrediario === 'pendentes'}
                                        onChange={() => {
                                          setStatusCrediario('pendentes'); 
                                          SetDadosCrediario([]);                                  
                                          if (periodoDataFiltro === 'pagamento')
                                          {
                                              setTipoDataFiltro('venda');                                                                       
                                          }
                                        }}                 
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        size="small"
                                        id="pendenteRadio" 
                                      />   
                                      <label className="form-check-label" htmlFor="pendenteRadio" style={{cursor: 'pointer'}}>
                                        Pendentes
                                      </label>                              
                                    </div>                          
                                  </div>  

                                  <div className="col">                                                                      
                                      <Radio
                                        checked={statusCrediario === 'baixados'}
                                        onChange={() => {
                                          SetDadosCrediario([]);                                 
                                          setStatusCrediario('baixados');  
                                        }}                 
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        size="small"
                                        id="baixadosRadio" 
                                    />   
                                    <label className="form-check-label" htmlFor="baixadosRadio" style={{cursor: 'pointer'}}>
                                      Baixados
                                    </label>                                    
                                  </div>  
                              </div>                               
                            </Card>  
                          </div>           
                        </div>                                      

                      <div className='row'>
                        <div className='col'>                      
                          <div className="align-left-filters">
                            <div className="row align-items-start">
                                <div className="col">                       
                                  <ButtonLojas
                                    onClick={handleShowLojas}
                                    width={'220px'}                                  
                                    lojasFiltro={lojasFiltro}
                                  />                               
                                </div>                                                             
                                <div className="col">                                 
                                    <ButtonClientes
                                      onClick={handleShowClientes}
                                      width={'220px'}
                                      clientesFiltro={clientesFiltro}
                                    />
                                </div>                                                                                                                 
                            </div>                            
                          </div> 
                        </div>                                                              
                        <div className='col'>                        
                          <label className='text' style={{ fontWeight: 'bold', marginTop: '10px'  }}>Período:</label>                     
                            <Card style={{width: 483}}>
                              <div className="row">                     
                                <div className="col-md-4">
                                  <div className="form-check">                                  
                                     <Radio
                                        checked={periodoDataFiltro === 'venda'}
                                        onChange={() => {
                                          setTipoDataFiltro('venda'); 
                                        }}                
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                        size="small"
                                        id="vendaRadio" 
                                    />   
                                    <label className="form-check-label" htmlFor="vendaRadio" style={{cursor: 'pointer'}}>
                                      Venda
                                    </label>
                                  </div>
                                </div>                          
                                <div className="col">                                                                     
                                  <Radio
                                    checked={periodoDataFiltro === 'vencimento'}
                                    onChange={() => {
                                      SetDadosCrediario([]);                                 
                                      setTipoDataFiltro('vencimento');  
                                    }}                 
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                    size="small"
                                    id="vencimentoRadio" 
                                  />   
                                  <label className="form-check-label" htmlFor="vencimentoRadio" style={{cursor: 'pointer'}}>
                                    Vencimento
                                  </label>                                  
                                </div>            
                                <div className="col">                                                                     
                                    <Radio
                                      checked={periodoDataFiltro === 'pagamento'}                              
                                      onChange={() => {
                                        SetDadosCrediario([]);                                 
                                        setTipoDataFiltro('pagamento');  
                                      }}              
                                      name="radio-button-demo"
                                      inputProps={{ 'aria-label': 'A' }}
                                      disabled={statusCrediario !== 'baixados'}
                                      size="small"
                                      id="pagamentoRadio" 
                                    />   
                                    <label className="form-check-label" htmlFor="pagamentoRadio" 
                                      style={{
                                        cursor: statusCrediario === 'baixados' ? 'pointer' : 'default',
                                        color: statusCrediario === 'baixados' ? 'black' : '#9e9e9e'
                                        }}>
                                      Pagamento
                                    </label>
                                </div>                                                         
                              </div>   
                            </Card>  
                          </div>    
                        </div>   
                      </div>                                                                                                          

                      <div class="row" style={{marginTop: '10px'}}>
                          <div className='col-2'>
                              <div className="actions">
                              <ButtonExecute 
                                  width={'220px'}
                                  onClick={handleExecutar}
                              />   
                              </div>                    
                          </div>
                          <div className='col-8' style={{marginLeft: '55px'}}>
                              <ButtonClear                                   
                                  onClick={handleLimparFiltros}
                              />   
                          </div>
                      </div>     
                                  
                  </Card>
              </Container>                 
          </Card.Body>          
      </form>     
          
      <ModalLojas show={showLojas} value={CrediarioContext} onHide={handleCloseLojas}/>                      
      <ModalClientes show={showClientes} value={CrediarioContext} onHide={handleCloseClientes}/>      
      
      <Box
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: 'blue',
          },
        }}
      >
        {dadosCrediario && dadosCrediario.length > 0 && (
        <Container>        
        <CustomStyledDataGrid
          initialState={{ detailPanel: { 
              expandedRowIds : expandedRowIds               
            } }}
          columns={statusCrediario === 'baixados'
                ? [...columnsBaixadas]
                : statusCrediario === 'pendentes'
                ? columnsPendentes
                : []}                      
          rows={statusCrediario === 'baixados'
                ? [...rowsBaixados]
                : statusCrediario === 'pendentes'
                ? rowsPendentes
                : []}                      
          slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination                          
          }}       
          headerHeight={20}        
          getDetailPanelHeight={() => 'auto'} 
          getDetailPanelContent={getDetailPanelContent}       
          headerClassName={headerClassName}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}          
          pageSizeOptions={[PAGE_SIZE]}                
        />                                
        </Container>
        )}
      </Box>
      
    </CrediarioContext.Provider>         
  );
}

export default CrediarioProvider;

