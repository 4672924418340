import React from 'react';
import { Button } from 'react-bootstrap';
import { ExportOutlined, FileExcelOutlined, FileExcelTwoTone, PrinterOutlined, TableOutlined } from '@ant-design/icons';

function ButtonExportExcel({ onClick }) {
  return (
    <Button
      type="button"
      onClick={onClick}
      style={{
        backgroundColor: 'transparent',
        border: '0px',
        color: 'rgb(27, 119, 253)',
        fontSize: '15px',
        width: '170px',
        height: '30px',
        margin: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        cursor: 'pointer',
      }}
    >
      <FileExcelTwoTone style={{ marginRight: '4px', cursor: 'pointer', }} /> <label style={{ marginBottom: '0px', cursor: 'pointer'}} >EXPORTAR EXCEL</label> 
    </Button>
  );
}

export default ButtonExportExcel;