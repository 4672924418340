import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Button, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableClientes = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [clientes, setClientes] = useState([]);  
  const [clientesData, setClientesData] = useState([]);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirClientesSelecionados } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedClientes = sessionStorage.getItem('selectedClientes');
    if (storedSelectedClientes) {    
      setSelectedClientes(JSON.parse(storedSelectedClientes));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedClientes.length > 0)
   {
      sessionStorage.setItem('selectedClientes', JSON.stringify(selectedClientes));
   }       
  }, [selectedClientes, clientes]);  

  const handleClienteSelection = (id) => {
    const updatedIndexes = selectedClientes.includes(id)
      ? selectedClientes.filter((index) => index !== id)
      : [...selectedClientes, id];
    setSelectedClientes(updatedIndexes);   

    const selectedClienteIds = updatedIndexes.map((index) => index.id);
    definirClientesSelecionados(selectedClienteIds);
  };

  const handleRemoverClientesSelecionadosClick = (id) => {
    const updatedClientesSelecionados = selectedClientes.includes(id)
      ? selectedClientes.filter((index) => index !== id)
      : [...selectedClientes, id];
    setSelectedClientes(updatedClientesSelecionados);   

    const selectedClienteIds = updatedClientesSelecionados.map((index) => index.id);
    definirClientesSelecionados(selectedClienteIds);

    if (selectedClienteIds.length === 0)
    {
      sessionStorage.removeItem('selectedClientes');
    }
  };

  const handleRowClick = (itemId) => {
    setClientes((prevClientes) =>
      prevClientes.filter((item) => item.id !== itemId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!clientes.includes(item.id))
    {
        const updatedClientes = [...clientes, item];
        setClientes(updatedClientes);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedClientes(clientes);
       setClientes([]);
       
       const selectedClienteIds = clientes.map((index) => index.id);
       definirClientesSelecionados(selectedClienteIds);       
       sessionStorage.setItem('selectedClientes', selectedClientes);      
  }

  const handleRemoverTodos = async() => {         
      if (!clientesData || clientesData.length === 0)
      {
         getClientes(); 
      }      
      setClientes(clientesData);
      setSelectedClientes([]);    
      definirClientesSelecionados([]);         
      sessionStorage.removeItem('selectedClientes');      
  }

  const getClientes = async () => {   
    try {
      let url = 'cliente/relatorio';

      if (busca !== '') {
        url = `cliente/relatorio/nome/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setClientes(response.data);   
      setClientesData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getClientes();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite o cliente"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar}/>                
            </div>            
          </div>
        </div>
      </Card>

        <div>
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos
                     onClick={handleAdicionarTodos}
                  />
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody style={{cursor: 'pointer'}} >
                {clientes.map((item) => {
                  if (selectedClientes.some((cli) => cli.id === item.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={item.id}
                        onClick={() => {
                          handleClienteSelection(item);
                          handleRowClick(item.id);
                        }}
                      >
                        <td>{item.id}</td>
                        <td>{item.nome +  (item.fantasia != '' ? ' - ' + item.fantasia : '')}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedClientes.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos
                  onClick={handleRemoverTodos}
                />                  
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedClientes.map((item) => (
                    <tr key={item.id}
                        onClick={() => {
                          handleRemoverClientesSelecionadosClick(item);
                          handleRowSelectedClick(item)
                        }}
                    >
                      <td>{item.id}</td>
                      <td>{item.nome +  (item.fantasia != '' ? ' - ' + item.fantasia : '')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>
        </div>
      </div>    
  );
};

export default TableClientes;

