import moment from 'moment';
import { isEmpty } from 'lodash';

export class ImpressaoFinanceiroPDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {
    this.dadosParaImpressao = dadosParaImpressao;    
    this.filtros = filtrosSelecionados;
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {  
    const headerDiaMes = [
        { text: 'Entradas', bold: true, fontSize: 11, alignment : 'left',  fillColor: '#DAEBF2', margin: [4, 4, 4, 4] },
        { text: 'Valor R$', bold: true, fontSize: 11, alignment : 'right',  fillColor: '#DAEBF2', margin: [4, 4, 4, 4] }                             
      ];
    return headerDiaMes;       
  }  

  GetHeaderSaida() {  
    const headerDiaMes = [
        { text: 'Saídas', bold: true, fontSize: 11, alignment : 'left',  fillColor: '#DAEBF2', margin: [4, 4, 4, 4] },
        { text: 'Valor R$', bold: true, fontSize: 11, alignment : 'right',  fillColor: '#DAEBF2', margin: [4, 4, 4, 4] }                             
      ];
    return headerDiaMes;       
  }  

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#DAEBF2'; 
  }  

  GetBodyEntrada = () => {    
    const dadosReceita = {      
      "Vendas à Vista" : (this.dadosParaImpressao.vendasavista),
      "Crediários Recebidos": (this.dadosParaImpressao.listaCrediariosRecebidos.reduce((acc, conta) => acc + conta.valor, 0)),
      "Cartões Recebidos" : (this.dadosParaImpressao.cartoesrecebidos),
      "Cheques Recebidos" : (this.dadosParaImpressao.chequesrecebidos),
      "Receitas" : (this.dadosParaImpressao.listaContasReceitas.reduce((acc, conta) => acc + conta.valor, 0))                           
    };
    const body = [];
    const dataKeys = Object.keys(dadosReceita);

    for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i];
        const value = dadosReceita[dataKey];

        const row = [
            { text: dataKey, fontSize: 10, margin: [4, 4, 4, 4]},
            { text: value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 10, margin: [4, 4, 4, 4], alignment: 'right'}
        ];

        body.push(row);
    }
      return body;
  };

  GetBodySaida = () => {    
    const dadosSaidas = {      
      "Despesas" : (this.dadosParaImpressao.listaDespesas.reduce((acc, conta) => acc + conta.valor, 0)),
      "Fornecedores" : (this.dadosParaImpressao.listaContasAPagarFornecedores.reduce((acc, conta) => acc + conta.valor, 0))      
    };
    const body = [];
    const dataKeys = Object.keys(dadosSaidas);

    for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i];
        const value = dadosSaidas[dataKey];

        const row = [
            { text: dataKey, fontSize: 10, margin: [4, 4, 4, 4] },
            { text: value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 10, margin: [4, 4, 4, 4], alignment: 'right'}
        ];

        body.push(row);
    }
      return body;
  }; 

  GetTotalizador = (descricao, valortotal, customColor) => {    
    const dadosReceita = {                 
      [descricao] : valortotal,
    };
    const body = [];
    const dataKeys = Object.keys(dadosReceita);

    for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i];
        const value = dadosReceita[dataKey];

        const row = [
            { text: dataKey, fontSize: 10,  bold: true, fontSize: 11, fillColor: customColor ? '#DAEBF2' : '', margin: [4, 4, 4, 4] },
            { text: value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
              fontSize: 10, bold: true, fontSize: 11,  fillColor: customColor ? '#DAEBF2' : '', margin: [4, 4, 4, 4], alignment: 'right'}
        ];

        body.push(row);
    }
      return body;
  }; 

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan: 2
      }
    ];

    const lineSeparator = [
      {
        text:
        '\n\n\n',
        fontSize: 5,
        colSpan: 2
      }
    ];

    const informacao = [
      {
        text:
        'INFORMATIVO: Este relatório considera apenas as operações financeiras liquidadas. Para mais informações, utilize o ícone de interrogação na página do relatório.',
        fontSize: 10,
        colSpan: 2
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [
        ...content, 
        ...this.GetBodyEntrada(), 
        ...this.GetTotalizador("Valor Total Entradas R$", this.dadosParaImpressao.valortotalentradas, false),
        lineSeparator, 
        this.GetHeaderSaida(), 
        ...this.GetBodySaida(), 
        ...this.GetTotalizador("Valor Total Saídas R$", this.dadosParaImpressao.valortotaldespesas, false),
        lineSeparator,  
        lineSeparator,  
        lineSeparator,         
        ...this.GetTotalizador("Total Líquido R$",  (this.dadosParaImpressao.valortotalentradas - this.dadosParaImpressao.valortotaldespesas), true),
        informacao
      ];              
    return content;
  } 
 
  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]
        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'RELATÓRIO FINANCEIRO (ENTRADAS X SAÍDAS)', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {          
          text: `\nData: ${this.formatarData(this.filtros.datasFiltro[0])} a ${this.formatarData(this.filtros.datasFiltro[1])}`        
            + `  | Lojas: ${this.filtros.lojasSelecionadas}`,
          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: [ 300, 260 ],              
            body: corpoDocumento
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}