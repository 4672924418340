import { DoubleLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonRemoverTodos({ onClick }) {
  return (    
    <Button 
        type="button"
        style={{
        backgroundColor: 'transparent',
        border: '0px',
        color: '#007CB0',
        fontSize: '15px',
        width: '160px',
        height: '30px',
        margin: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        cursor: 'pointer'
        }
    }
    onClick={onClick}                    
    > <DoubleLeftOutlined style={{marginRight: '5px'}}  /> Remover Todos
    </Button>
  );
}

export default ButtonRemoverTodos;
