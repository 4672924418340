import moment from 'moment';

export class ImpressaoDrePDF {  
  constructor(dadosParaImpressao, filtrosSelecionados) {    
    this.dadosParaImpressao = dadosParaImpressao;
    this.filtros = filtrosSelecionados;             
  }

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();    
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  GetHeader() {       
      const headerDrePeriodo = [        
        { text: 'Descrição', bold: true, alignment: 'left', fontSize: 9, margin: [4, 4, 0, 0]},                                                          
        { text: 'Valor R$', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 0]},      
        { text: 'Percentual', bold: true, alignment: 'right', fontSize: 9, margin: [0, 4, 4, 4] },           
      ];
      return headerDrePeriodo;               
  }

  GetRowBackgroundColor(index) {
    return index % 2 === 0 ? '#FFFFFF' : '#FFFFFF'; // Alternating colors
  }

  GetBody() {            
      const bodyDRE = this.dadosParaImpressao.flatMap((array) => {                
        const contasRows = [
            { text: array.descricao, alignment: 'left', fontSize: 8, margin:[4, 0, 0, 0] },
            { text: ((array.valor !== 0) ? ("R$ " +array.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : ''), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                                                                                          
            { text: (array.percentual !== 0 ? array.percentual.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ "%" : '' )  , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] },                                                            
        ]                                              
          const detalheRows = [
            {
              table: {
                widths: [240, 150, 150], 
                headerRows: 1, 
                body: [                  
                  [{ text: '', border: []},                                    
                   { text: '',border: []},
                   { text: '',border: []},                 
                  ],                    
                    ...array.subRows.length > 0 ?  array.subRows.map((conta) => [                    
                      { text: conta.descricao, fontSize: 8, alignment: 'left', margin:[0, 0, 4, 0], border: [] },                   
                      { text: "R$ " + conta.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0], border: [] },                   
                      { text: conta.percentual.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%" , fontSize: 8, alignment: 'right', margin:[0, 0, 4, 0] , border: []},                                       
                    ]) : [],                
                ],
              },
              fontSize: 8,
              colSpan: 3,
              margin: [0, 0, 0, 6], 
            },
          ];      
          return [contasRows , detalheRows];                     
      });    
      return bodyDRE;           
  }

  CriaCorpoDocumento() {                  
    const lineHeader = [
      {
        text:
        '________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        fontSize: 5,
        colSpan:  3
      }
    ];

    let content = [this.GetHeader(), lineHeader];
    content = [...content, ...this.GetBody()];             
    return content;
  }     

  OrdenarVetor(vetor) {
    return vetor.sort((x,y) => {
     if (x > y) return 1;
     if (x < y) return - 1;
       return 0
     } 
   ).join(', ')
 }

  GerarDocumento(corpoDocumento) {    
    const estiloDocumento = {      
        corpoRelatorio: {
          marginTop : 10,
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0]        
      },

      campoFiltros: {        
        marginTop: 0,      
        fontSize: 10,        
        alignment: 'left'    
      },    

      negrito: {
        bold: true,
        alignment : 'center',
        fontSize  : 10
      }
    }
    
    const documento = {
      pageSize: 'A4',      
      pageMargins: [14, 53, 14, 48],
      header: function () {
       
        return {
          margin: [14, 12, 14, 5],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                { 
                  text: 'DEMONSTRATIVO DE RESULTADO (D.R.E)', 
                  style: 'corpoRelatorio' },    
              ]
            ]
          }
        };
      },      
      content: [   
        {
          text: `Grupo: ${this.filtros.nomeGrupo}`,
          style: 'negrito', 
        },
        {     
          text : 
            `Período: ${this.formatarData(this.filtros.datasFiltro[0])} a ${this.formatarData(this.filtros.datasFiltro[1])}` + 
            `   |   Lojas: ${this.filtros.lojasSelecionadas}`,

          style: 'campoFiltros',
        },
        {
          layout: 'noBorders',
          table: {
            marginTop : 10,
            headerRows: 1,            
            widths: [ 245, 150, 150 ] ,
            body: corpoDocumento.map((row, index) => row.map(cell => ({
              ...cell,
              fillColor: index % 2 === 0 ? '#DAEBF2' : '#FFFFFF' })))
          }
        }        
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text:
                  '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'left',
                  fontSize: 5
                }
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',                    
                    margin: [5, 0]
                  },
                  {
                    text: ` Data: ${moment().format('DD/MM/YYYY HH:mm')}                                                                                                                © Informezz`,
                    fontSize: 7,
                    alignment: 'left'
                  }
                ]
              ]
            ]
          }
        };
      },
      styles: estiloDocumento
    };        
    return documento;
  }  

  formatarData(data) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return data.toLocaleDateString('pt-BR', options);
  }
  
  primeiraLetraMaiuscula(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}