import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonClientes({ onClick, width, clientesFiltro }) {
  return (    
    <Button style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        border: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px'                                    
        }} 
        onClick={onClick}>
            + Clientes
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {clientesFiltro.length > 0 ? clientesFiltro.length : ''}
            </span>
        </Button>                                        
  );
}

export default ButtonClientes;


