import React from 'react';
import Container from 'react-bootstrap/Container';
import { Modal } from 'react-bootstrap';
import ButtonConfirmar from '../Buttons/ButtonConfimar';
import TableLojasEmissao from '../Table/TableLojasEmissao'
import 'bootstrap/dist/css/bootstrap.css';
import SelectAllTransferList from '../../pages/Teste/teste';

function ModalLojasEmissao(props) {
  const handleClose = () => props.onHide();

  return (
    <Modal onHide={handleClose}
      {...props}  
      size="lg"     
      aria-labelledby="contained-modal-title-vcenter"
      style={{ maxHeight: '700px' }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecione as Lojas de Emissão
        </Modal.Title>
      </Modal.Header>
        <Modal.Body className="show-grid">
            <Container>
            <div className='col'>                          
                <TableLojasEmissao contexto={props.value}/>              
                {/* <SelectAllTransferList contexto={props.value}/>               */}
            </div>    
            </Container>
        </Modal.Body>
      <Modal.Footer>      
        <ButtonConfirmar
          onClick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalLojasEmissao;
