import React, { useState, useContext, useEffect } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { SetTenant } from '../../services/api';

export const TableLojas = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [lojas, setLojas] = useState([]);  
  const [selectedLojas, setSelectedLojas] = useState([]);  
  const [loading, setLoading] = useState(true);
  const [lojasData, setLojasData] = useState([]);
  const { definirLojasSelecionadas } = useContext(contexto); 
  const [limparLoja, setLimparLoja] = useState(false);

  useEffect(() => {    
    if (loading)
    {
      getLojas(); 
      setLoading(false);
    }        
  }, [loading]);

  useEffect(() => {    
    const storedSelectedLojas = sessionStorage.getItem('selectedLojas');
    if (storedSelectedLojas) {
      setSelectedLojas(JSON.parse(storedSelectedLojas));
    }      
  }, []);

  useEffect(() => {        
   if (selectedLojas.length > 0)
   {
      sessionStorage.setItem('selectedLojas', JSON.stringify(selectedLojas));
   }    
   else if ((lojasData.length > 0) && (!limparLoja))
   {
      const selectedLoja = lojasData[0];
      handleLojaSelection(selectedLoja);       
      handleRowClick(selectedLoja.idloja);
      sessionStorage.setItem('selectedLojas', JSON.stringify(selectedLojas));     
   }                  
  }, [selectedLojas, lojas]);  

  const handleLojaSelection = async (idloja) => {
    const updatedIndexes = selectedLojas.includes(idloja)
      ? selectedLojas.filter((index) => index !== idloja)
      : [...selectedLojas, idloja];
    await setSelectedLojas(updatedIndexes);   

    const selectedLojaIds = updatedIndexes.map((index) => index.idloja);
    definirLojasSelecionadas(selectedLojaIds);
  };

  const handleRowClick = (idloja) => {
    setLojas((prevLojas) =>
      prevLojas.filter((item) => item.idloja !== idloja)
    );
  };

  const handleAdicionarTodos = () => {
       setSelectedLojas(lojasData);
       setLojas([]);
       
       const selectedLojaIds = lojasData.map((index) => index.idloja);
       definirLojasSelecionadas(selectedLojaIds);       
       sessionStorage.setItem('selectedLojas', selectedLojas);      
  }

  const handleRemoverTodos = () => {              
      setLojas(lojasData);            
      setSelectedLojas([]);    
      definirLojasSelecionadas([]);         
      sessionStorage.removeItem('selectedLojas');    
      setLimparLoja(true);
  } 

  const handleRemoveSelectedLoja = (idloja) => {

    const updatedSelectedLojas = selectedLojas.filter(loja => loja.idloja !== idloja);

    setSelectedLojas(updatedSelectedLojas);  
    setLojas((prevLojas) => {
      const lojaToAddBack = lojasData.find((loja) => loja.idloja === idloja);
        
      if (!prevLojas.some((loja) => loja.idloja === idloja)) {
        return [...prevLojas, lojaToAddBack];
      }
  
      return prevLojas;
    });

    const selectedLojaIds = updatedSelectedLojas.map((index) => index.idloja);
    definirLojasSelecionadas(selectedLojaIds);
    sessionStorage.setItem('selectedLojas', selectedLojaIds);   
    
    if (selectedLojaIds.length === 0)
    {
      sessionStorage.removeItem('selectedLojas');
    }
  };

  const getLojas = async () => {       
    try {            
      const userData = await sessionStorage.getItem('user');       
      
      if (userData)
      {        
        const desencryptJSON = new EncryptionUtil(); 
        const desencryptJSONLojas = desencryptJSON.decryptJSON(userData); 
        const user = JSON.parse(desencryptJSONLojas);        
        await SetTenant();
        const response = await api.get(`loja/lojascadastradas/${user.id}`);                                                     
        await setLojas(response.data);   
        await setLojasData(response.data);
      }      
    } catch (e) {
      console.log('erro');
    }
  };  

  return (
    <>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>       
        <Container>
          <div className="col">
            <Card>      
              <div className='col'>
                <ButtonAdicionarTodos 
                  onClick={handleAdicionarTodos}
                />                
              </div>                                             
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr >
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white'}}>Nome</th>
                  </tr>
                </thead>
                <tbody style={{cursor: 'pointer'}}>
                {lojas.map((item) => {
                  if (selectedLojas.some((loja) => loja.idloja === item.idloja)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={item.idloja}
                        onClick={() => {
                          handleLojaSelection(item);
                          handleRowClick(item.idloja);
                        }}
                      >
                        <td>{item.idloja}</td>
                        <td>{item.nome}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                  <div className='col text-left'> 
                    <label className='labelSelecionar' >Selecionados: {selectedLojas.length}</label>
                  </div>
                <div className='col text-right'>               
                <ButtonRemoverTodos 
                  onClick={handleRemoverTodos}
                />
                </div>
              </div>        
            </Card>
              <table className="table table-striped table-hover" >
                <thead className="table-dark mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Nome</th>
                  </tr>
                </thead>
                <tbody style={{cursor: 'pointer'}}>
                  {selectedLojas.map((item) => (
                    <tr key={item.idloja}      
                      onClick={() => {
                        handleRemoveSelectedLoja(item.idloja);                        
                    }                      
                    }                       
                    >
                      <td>{item.idloja}</td>
                      <td>{item.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TableLojas;
