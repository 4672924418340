import React from 'react';
import SessionStorageUtils from './SessionStorageUtils';

class Permissoes {
    async ValidarPermissaoAcessoRelatorio(descricao) {               
      const sessionStorageUtils = new SessionStorageUtils();
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      var permissoes = await JSON.parse(desencryptJSONObj).permissoes;
      const permissaoEncontrada = await permissoes.find(p => p.descricaopermissao == descricao && p.liberado == true);
      if (permissaoEncontrada)
      {        
        return true;
      }              
      return false;          
    }
  }

export default Permissoes;