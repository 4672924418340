import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonContas({ onClick, width, marginTop, contasFiltro }) {
  return (     
    <Button style={{ 
        backgroundColor: '#007CB0', 
        width: width, 
        height: '30px', 
        border: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
        marginLeft: '20px',   
        marginTop : marginTop,
        borderRadius: '15px'                                                              
        }} 
        onClick={onClick}>
            + Contas
            <span className="badge badge-light" 
                style={{
                    backgroundColor: 'white', 
                    color: 'black', 
                    marginLeft: '20px', 
                    marginTop: '3px'}}>
                    {contasFiltro.length > 0 ? contasFiltro.length : ''}
            </span>
        </Button>                                        
  );
}

export default ButtonContas;


