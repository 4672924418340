import * as XLSX from 'xlsx';

export class ComissaoRecebimentoXLS {
    constructor(dadosParaImpressao, totalizadoresImpressao) {
      this.dadosParaImpressao = dadosParaImpressao;
      this.totalizadoresImpressao = totalizadoresImpressao;
    }
  
    exportToXLS () {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(this.dataExportXLS());
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `Comissão_Recebimento.xlsx`);
    }
  
    dataExportXLS = () => {      
      const dataRows = this.dadosParaImpressao.map((vendedor) => {        
        const detalhesLinha = ({
          LOJA: vendedor.lojaid, 
          VENDEDOR: vendedor.vendedorid, 
          NOME: vendedor.vendedornome,           
          VALOR_RECEBIMENTO_R$: this.formatDecimal(vendedor.valorliquido),
          PERC_COMISSAO: this.formatDecimal(vendedor.percentualcomissao),
          VALOR_COMISSAO: this.formatDecimal(vendedor.valorcomissao), 
          PERC_REPRESENTATIVIDADE: this.formatDecimal(vendedor.percentualrepresentatividade),           
        });
         
        return detalhesLinha;
      });          
    
      const totalRow = {
        LOJA: 'Total:',
        VENDEDOR: '',
        NOME: '',        
        VALOR_RECEBIMENTO_R$: this.formatDecimal(this.calcTotal('valorliquido')),
        PERC_COMISSAO: '',
        VALOR_COMISSAO: this.formatDecimal(this.calcTotal('valorcomissao')),
        PERC_REPRESENTATIVIDADE: '',        
      };
          

      return [...dataRows,  totalRow] ;
    } 
              
    calcTotal = (property) => {
      return this.dadosParaImpressao.reduce((sum, dt) => sum + dt[property], 0);
    }

    formatDecimal = (value) => {      
      if (!isNaN(value)) {
        return parseFloat(value).toFixed(2);
      }
      return value; 
    };
}

