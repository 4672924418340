import React, { createContext, useState, useEffect } from 'react';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridRowCountSelector
} from '@mui/x-data-grid';
import PaginationItem from '@mui/material/PaginationItem';
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, ButtonGroup, Container, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Pagination, Radio, Select, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { api } from '../../../services/api';
import { Card } from 'react-bootstrap';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../../components/Modal/ModalLojas';
import CustomToolBar from '../../../components/Buttons/CustomToolBar';
import ButtonExecute from '../../../components/Buttons/ButtonExecute';
import LoadingScreen from '../../../components/Loading/TelaLoading';
import CustomStyledDataGrid from '../../../components/DataGrid/StyledDataGrid';
import '../../AnaliseDados/ListagemClientes/clientesStyles.css';
import { isEmpty } from 'lodash';
import SessionStorageUtils from '../../../Utils/SessionStorageUtils';
import ButtonClear from '../../../components/Buttons/ButtonClear';
import { SetTenant } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../../Utils/Permissoes';
import { getYear, startOfMonth } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import ButtonFiltroPadrao from '../../../components/Buttons/ButtonFiltroPadrao';
import ButtonFiltroPadraoLoja from '../../../components/Buttons/ButtonFiltroPadraoLoja';
import ModalProfissoes from '../../../components/Modal/ModalProfissoes';
import ModalClienteCategorias from '../../../components/Modal/ModalClienteCategorias';
import ModalManequimSuperior from '../../../components/Modal/ModalManequimSuperior';
import ModalManequimInferior from '../../../components/Modal/ModalManequimInferior';
import ModalManequimCalcado from '../../../components/Modal/ModalManequimCalcado';
import ModalTamanhos from '../../../components/Modal/ModalTamanhos';
import TypographyCustom from '../../../components/TypographyCustom';
import ModalItem from '../../../components/Modal/ModalItens';
import ModalMarcas from '../../../components/Modal/ModalMarcas';
import letraD from '../../../img/letraD.png';
import { CLIENTES_XLS } from '../../../components/Impressao/Excel/CLIENTES_XLS copy';
import { ImpressaoClientesPDF } from '../../../components/Impressao/ImpressaoClientesPDF';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ClientesContext = createContext();

export const ClientesProvider = () => {
    const sessionStorageUtils = new SessionStorageUtils();
    const PAGE_SIZE = 50;
    const [paginationModel, setPaginationModel] = useState({ pageSize: PAGE_SIZE, page: 0 });
    const [showLojas, setShowLojas] = useState(false);
    const [showClienteCategorias, setShowClienteCategorias] = useState(false);
    const [showManequimSuperior, setShowManequimSuperior] = useState(false);
    const [showManequimInferior, setShowManequimInferior] = useState(false);
    const [showManequimCalcado, setShowManequimCalcado] = useState(false);
    const [showTamanhos, setShowTamanhos] = useState(false);
    const [showProfissoes, setShowProfissoes] = useState(false);
    const [showItem, setShowItem] = useState(false);
    const [showMarcas, setShowMarcas] = useState(false);
    const [dadosClientes, setDadosClientes] = useState([]);
    const [lojasFiltro, setLojasFiltro] = useState([]);
    const [profissaoFiltro, setProfissaoFiltro] = useState([]);
    const [clienteCategoriasFiltro, setClienteCategoriasFiltro] = useState([]);
    const [manequimSuperiorFiltro, setManequimSuperiorFiltro] = useState([]);
    const [manequimInferiorFiltro, setManequimInferiorFiltro] = useState([]);
    const [manequimCalcadoFiltro, setManequimCalcadoFiltro] = useState([]);
    const [itensFiltro, setItensFiltro] = useState([]);
    const [marcasFiltro, setMarcasFiltro] = useState([]);
    const [tamanhosFiltro, setTamanhosFiltro] = useState([]);
    const [nomeGrupo, setNomeGrupo] = useState([]);
    const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([startOfMonth(new Date()), new Date()]);
    const [loading, setLoading] = useState(true);
    const [limparFiltros, SetLimparFiltros] = useState(false);
    const [tipoVisaoSelect, setTipoVisaoSelect] = useState('CADASTRO');
    const [tipoAnaliseSelect, setTipoAnaliseSelect] = useState('TODOS');
    const [tipoAnaliseVendaSelect, setTipoAnaliseVendaSelect] = useState('VENDASNOPERIODO');
    const [tipoCadastroSelect, setTipoCadastroSelect] = useState('CLIENTES');
    const [diaAniversarioFiltro, setDiaAniversarioFiltro] = useState(0);
    const [mesAniversarioFiltro, setMesAniversarioFiltro] = useState('TODOS');
    const [qtdeItensMinimoFiltro, setQtdeItensMinimoFiltro] = useState();
    const [qtdeItensMaximoFiltro, setQtdeItensMaximoFiltro] = useState();
    const [qtdeVendasMinimaFiltro, setQtdeVendasMinimaFiltro] = useState();
    const [qtdeVendasMaximaFiltro, setQtdeVendasMaximaFiltro] = useState();
    const [rankingFiltro, setRankingFiltro] = useState('VALOR');
    const [rankingFiltroValor, setRankingFiltroValor] = useState();
    const [valorMinimoFiltro, setValorMinimoFiltro] = useState();
    const [valorMaximoFiltro, setValorMaximoFiltro] = useState();
    const [tipoValorFiltro, setTipoValorFiltro] = useState('TOTAL');
    const [sexoFiltro, setSexoFiltro] = useState('TODOS');    
    const diasmes = Array.from({ length: 31 }, (_, i) => i);
    const [sexos] = useState([
        { id: 'T', nome: 'TODOS' },
        { id: 'M', nome: 'MASCULINO' },
        { id: 'F', nome: 'FEMININO' },
        { id: 'I', nome: 'INDEFINIDO' }]);
    const [meses] = useState([
        { id: 0, nome: 'TODOS' },
        { id: 1, nome: 'JANEIRO' },
        { id: 2, nome: 'FEVEREIRO' },
        { id: 3, nome: 'MARÇO' },
        { id: 4, nome: 'ABRIL' },
        { id: 5, nome: 'MAIO' },
        { id: 6, nome: 'JUNHO' },
        { id: 7, nome: 'JULHO' },
        { id: 8, nome: 'AGOSTO' },
        { id: 9, nome: 'SETEMBRO' },
        { id: 10, nome: 'OUTUBRO' },
        { id: 11, nome: 'NOVEMBRO' },
        { id: 12, nome: 'DEZEMBRO' },
    ]);
    const [exibirFotoCliente, setExibirFotoCliente] = useState({ exibir: false });

    const navigate = useNavigate();
    const permissao = new Permissoes();

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
            if (ValidarPermissoes() == false) { return; }
            LimparFiltrosSelecionados();
            definirLojaInicial();
            DefinirNomeGrupo();
            setLoading(false);
        }, 500);
    }, []);

    async function ValidarPermissoes() {
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('278b9108364683ec3c19264d0f2cf689');
        if (autorizado) { return true; }
        navigate('/home')
        return false;
    }

    useEffect(() => {
        if (limparFiltros) {
            definirLojaInicial();
            SetLimparFiltros(false);
        }
    }, [limparFiltros]);

    useEffect(() => {
        getClientes()
    }, [exibirFotoCliente]);

    const handleLimparFiltros = async () => {
        LimparFiltrosSelecionados()
        SetLimparFiltros(true);
    }

    function LimparFiltrosSelecionados() {
        sessionStorage.removeItem('selectedLojas');
        sessionStorage.removeItem('selectedProfissoes');
        sessionStorage.removeItem('selectedClienteCategorias');
        sessionStorage.removeItem('selectedManequimSuperior');
        sessionStorage.removeItem('selectedManequimInferior');
        sessionStorage.removeItem('selectedManequimCalcado');
        sessionStorage.removeItem('selectedItens');
        sessionStorage.removeItem('selectedMarcas');
        sessionStorage.removeItem('selectedTamanhos');
        setLojasFiltro([]);
        setProfissaoFiltro([]);
        setClienteCategoriasFiltro([]);
        setManequimSuperiorFiltro([]);
        setManequimInferiorFiltro([]);
        setManequimCalcadoFiltro([]);
        setItensFiltro([]);
        setMarcasFiltro([]);
        setTamanhosFiltro([]);
        setSexoFiltro('TODOS');        
        setRankingFiltro('VALOR');
        setRankingFiltroValor('');
        setTipoValorFiltro('TOTAL');
        setValorMinimoFiltro('');
        setValorMaximoFiltro('');
        setQtdeVendasMinimaFiltro('');
        setQtdeVendasMaximaFiltro('');
        setQtdeItensMinimoFiltro('');
        setQtdeItensMaximoFiltro('');
        setDadosClientes([]);
    }

    const DefinirNomeGrupo = async () => {
        const desencryptJSONObj = await sessionStorageUtils.GetUsuario();
        const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
        setNomeGrupo(groupName);
    }

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
        const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);

        return (
            <>
                <div>
                    <div className='table-align'>
                        {tipoVisaoSelect === 'VENDA' && renderTable()}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total de registros: {totalRowCount}
                                </Typography>
                            </div>

                            <div style={{ marginLeft: '50px' }}>
                                <Typography variant="subtitle2" color="textSecondary" >
                                    Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                                </Typography>
                            </div>
                        </div>

                        <div style={{ alignSelf: 'flex-end' }}>
                            <Pagination
                                color="primary"
                                variant="outlined"
                                shape="rounded"
                                page={page + 1}
                                count={pageCount}
                                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                            />
                        </div>
                    </div>
                </div>

            </>
        );
    }

    const definirLojaInicial = async () => {
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0) {
            const userId = JSON.parse(desencryptJSONUser).id;
            await SetTenant();
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;
            await setLojasFiltro(firstId);
        }
    }

    const filtrosSelecionados = {
        tipoVisao: tipoVisaoSelect,
        tipoAnaliseCadastro : tipoAnaliseSelect,
        tipoAnaliseVenda : tipoAnaliseVendaSelect,
        lojasSelecionadas: lojasFiltro,
        categoriasSelecionadas: clienteCategoriasFiltro,
        dataIntervaloFiltro : dataIntervaloFiltro,
        nomeGrupo: nomeGrupo,
        diaAniversarioFiltro : diaAniversarioFiltro,
        mesAniversarioFiltro : mesAniversarioFiltro,
        qtdeItensMinimoFiltro : qtdeItensMinimoFiltro,
        qtdeItensMaximoFiltro : qtdeItensMaximoFiltro, 
        qtdeVendasMinimaFiltro : qtdeVendasMinimaFiltro, 
        qtdeVendasMaximaFiltro : qtdeVendasMaximaFiltro, 
        rankingFiltro : rankingFiltro, 
        rankingFiltroValor : rankingFiltroValor, 
        valorMinimoFiltro : valorMinimoFiltro, 
        valorMaximoFiltro : valorMaximoFiltro, 
        tipoValorFiltro : tipoValorFiltro,
        sexoFiltro : sexoFiltro,         
        lojasFiltro: lojasFiltro,
        profissaoFiltro : profissaoFiltro, 
        clienteCategoriasFiltro : clienteCategoriasFiltro,
        manequimSuperiorFiltro : manequimSuperiorFiltro, 
        manequimInferiorFiltro : manequimInferiorFiltro, 
        manequimCalcadoFiltro: manequimCalcadoFiltro, 
        itensFiltro: itensFiltro, 
        marcasFiltro: marcasFiltro, 
        tamanhosFiltro: tamanhosFiltro,
        tipoCadastroSelect: tipoCadastroSelect,
    }

    const visualizarImpressao = async () => {
        const classeImpressao = new ImpressaoClientesPDF(dadosClientes, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    const handleExecutar = async () => {
        setLoading(true);
        await getClientes();
        setLoading(false);
    }

    function AddParams(value) {
        if (!isEmpty(value))
            return `${value}/`;
        return ` /`
    }

    const getClientes = async () => {
        try {
            const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth() + 1}-${dataIntervaloFiltro[0].getDate()}`);
            const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth() + 1}-${dataIntervaloFiltro[1].getDate()}`);

            let url;
            if (tipoVisaoSelect === 'CADASTRO') {
                url = `cliente/relatorio/analisedados/visaocadastro/${exibirFotoCliente.exibir}/${tipoAnaliseSelect}/${tipoCadastroSelect}/`;
                url += `${dataIni}/${dataFin}/${lojasFiltro}/${diaAniversarioFiltro}/${mesAniversarioFiltro}/${sexoFiltro}/`;

                url += AddParams(clienteCategoriasFiltro);
                url += AddParams(profissaoFiltro);
                url += AddParams(manequimSuperiorFiltro);
                url += AddParams(manequimInferiorFiltro);
                url += AddParams(manequimCalcadoFiltro);

                console.log(url)
            }
            else {
                url = `cliente/relatorio/analisedados/visaovenda/${exibirFotoCliente.exibir}/${tipoAnaliseVendaSelect}/`;
                url += `${dataIni}/${dataFin}/${lojasFiltro}/${diaAniversarioFiltro}/${mesAniversarioFiltro}/`;

                url += AddParams(qtdeItensMinimoFiltro);
                url += AddParams(qtdeItensMaximoFiltro);
                url += AddParams(rankingFiltro);
                url += AddParams(rankingFiltroValor);
                url += AddParams(tipoValorFiltro);
                url += AddParams(valorMinimoFiltro);
                url += AddParams(valorMaximoFiltro);
                url += AddParams(qtdeVendasMinimaFiltro);
                url += AddParams(qtdeVendasMaximaFiltro);
                url += AddParams(sexoFiltro);
                url += AddParams(profissaoFiltro);
                url += AddParams(clienteCategoriasFiltro);
                url += AddParams(itensFiltro);
                url += AddParams(marcasFiltro);
                url += AddParams(tamanhosFiltro);
            }

            await setDadosClientes([]);
            await SetTenant();
            const response = await api.get(url);
            await setDadosClientes(response.data);

            console.log(response.data)
        }
        catch (e) {
            console.log('erro');
        }
    }

    const handleShowLojas = () => {
        setShowLojas(true);
    }

    const handleShowClienteCategorias = () => {
        setShowClienteCategorias(true);
    }

    const handleShowManequimSuperior = () => {
        setShowManequimSuperior(true);
    }

    const handleShowManequimInferior = () => {
        setShowManequimInferior(true);
    }

    const handleShowManequimCalcado = () => {
        setShowManequimCalcado(true);
    }

    const handleShowProfissao = () => {
        setShowProfissoes(true);
    }

    const handleShowItem = () => {
        setShowItem(true);
    }

    const handleShowMarcas = () => {
        setShowMarcas(true);
    }

    const handleShowTamanhos = () => {
        setShowTamanhos(true);
    }

    const handleCloseLojas = () => {
        setShowLojas(false);
    }

    const handleCloseProfissoes = () => {
        setShowProfissoes(false);
    }

    const handleCloseClienteCategorias = () => {
        setShowClienteCategorias(false);
    }

    const handleCloseManequimSuperior = () => {
        setShowManequimSuperior(false);
    }

    const handleCloseManequimInferior = () => {
        setShowManequimInferior(false);
    }

    const handleCloseManequimCalcado = () => {
        setShowManequimCalcado(false);
    }

    const handleCloseTamanhos = () => {
        setShowTamanhos(false);
    }

    const handleCloseItem = () => {
        setShowItem(false);
    }

    const handleCloseMarcas = () => {
        setShowMarcas(false);
    }

    const handleChangeExibirFotoCliente = (event) => {
        setExibirFotoCliente({ ...exibirFotoCliente, [event.target.name]: event.target.checked });
    };

    const definirLojasSelecionadas = (lojas) => {
        setLojasFiltro(lojas);
    }

    const definirClienteCategoriasSelecionadas = (id) => {
        setClienteCategoriasFiltro(id);
    }

    const definirProfissoesSelecionadas = (id) => {
        setProfissaoFiltro(id);
    }

    const definirManequimSuperiorSelecionado = (id) => {
        setManequimSuperiorFiltro(id);
    }

    const definirManequimInferiorSelecionado = (id) => {
        setManequimInferiorFiltro(id);
    }

    const definirManequimCalcadoSelecionado = (id) => {
        setManequimCalcadoFiltro(id);
    }

    const definirItensSelecionados = (id) => {
        setItensFiltro(id);
    }

    const definirMarcasSelecionadas = (id) => {
        setMarcasFiltro(id);
    }

    const definirTamanhosSelecionados = (id) => {
        setTamanhosFiltro(id);
    }

    const rows = dadosClientes.map((row) => {
        if (tipoVisaoSelect === 'CADASTRO') {
            const clienteInfo = {
                id: row.id + row.nome + row.nomeparente,
                nome: row.nome,
                sexo: row.sexo,
                nascimento: row.nascimento ? row.nascimento : '',
                roupa: row.roupa,
                roupainferior: row.roupainferior,
                calcado: row.calcado,
                celular: row.celular,
                lojavendaid: row.lojavendaid,
                parentesco: row.parentesco,
                nomeparente: row.nomeparente,
                ultimavenda: row.ultimavenda,
                foto: row.foto,
                dependente : row.dependente
            }
            return clienteInfo;
        }

        const clienteVendaInfo = {
            id: row.id,
            nome: row.nome,
            sexo: row.sexo,
            nascimento: row.nascimento ? row.nascimento : '',
            celular: row.celular,
            lojavendaid: row.lojavendaid,
            ultimavenda: row.ultimavenda,
            foto: row.foto,
            qtdevendas: row.qtdevendas,
            qtdeitens: row.qtdeitens,
            valortotalvenda: row.valortotalvenda,
            valormedio: row.valormedio,
        }
        return clienteVendaInfo;
    });

    const columnsCadastro = [
        {
            field: "foto",
            hide: !exibirFotoCliente.exibir,
            headerName: "Foto",
            headerClassName: 'blue-header',
            headerAlign: 'center',
            width: 100,
            align: 'center',
            renderCell: (params) => !isEmpty(params.value) ? (
                <img
                    src={`data:image/jpeg;base64,${params.value}`}
                    alt="client"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ) : null
        },
        {
            headerName: '',
            field: 'dependente',
            headerClassName: 'blue-header',        
            renderCell: (params) => (
              params.row.dependente === true
                ?  <Tooltip title="Dependente" >
                      <img style={{ width: '16px', cursor: 'pointer' }} src={letraD} alt="provisao" />
                    </Tooltip>               
                : null
            ),
            width: 10,
            headerHeight: 10
          } , 
        { field: "lojavendaid", hide: tipoAnaliseSelect === 'TODOS', valueGetter: (params) => params.row.lojavendaid !== 0 ? params.row.lojavendaid : '', headerName: "Loja Venda", headerClassName: 'blue-header', headerAlign: 'center', width: 100, align: 'center' },
        {
            field: "ultimavenda", hide: tipoAnaliseSelect === 'TODOS', renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Última<br />Venda</div>), type: 'date', valueGetter: (params) => !isEmpty(params.row.ultimavenda) ? params.row.ultimavenda : '',
            valueFormatter: (params) => {
                if (params.value) {
                    const ultimavenda = new Date(params.value);
                    return getYear(ultimavenda) > 1969 ? ultimavenda.toLocaleDateString('pt-BR') : '';
                }
                return null
            }, headerClassName: 'blue-header', headerAlign: 'center', width: 120, align: 'center'
        },
        { field: "nome", headerName: tipoCadastroSelect === 'CLIENTES' ? "Cliente" : tipoCadastroSelect === 'DEPENDENTE' ? 'Dependente' : "Cliente/Dependente", headerClassName: 'blue-header', headerAlign: 'left', width: 260, align: 'left' },
        { field: "sexo", headerName: "Sexo", headerClassName: 'blue-header', headerAlign: 'right', width: 50, align: 'right' },
        {
            field: "nascimento", headerName: "Data Nascimento", headerClassName: 'blue-header', headerAlign: 'center',
            type: 'date', valueGetter: (params) => !isEmpty(params.row.nascimento) ? params.row.nascimento : '',
            valueFormatter: (params) => { return !isEmpty(params.value) ? new Date(params.value).toLocaleDateString('pt-BR') : '' }, width: 140, align: 'center'
        },
        {
            field: "roupa",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Manequim<br />Superior</div>), headerClassName: 'blue-header', width: 100, headerAlign: 'center', align: 'center'
        },
        {
            field: "roupainferior",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Manequim<br />Inferior</div>), headerClassName: 'blue-header', width: 100, headerAlign: 'center', align: 'center'
        },
        {
            field: "calcado",
            renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Manequim<br />Calçado</div>), headerClassName: 'blue-header', width: 100, headerAlign: 'center', align: 'center'
        },
        { field: "parentesco", hide: tipoCadastroSelect === 'CLIENTES', headerName: "Parentesco", headerClassName: 'blue-header', headerAlign: 'left', width: 120, align: 'left' },
        { field: "nomeparente", hide: tipoCadastroSelect === 'CLIENTES', headerName:  tipoCadastroSelect === 'DEPENDENTE' ? 'Clientes' : "Cliente", headerClassName: 'blue-header', headerAlign: 'left', width: 150, align: 'left' },
        { field: "celular", headerName: "Celular", headerClassName: 'blue-header', headerAlign: 'center', width: 130, align: 'center' },
    ];

    const columnsVisaoVenda = [        
        { field: "lojavendaid", valueGetter: (params) => params.row.lojavendaid !== 0 ? params.row.lojavendaid : '', headerName: "Loja Venda", headerClassName: 'blue-header', headerAlign: 'center', width: 100, align: 'center' },
        {
            field: "ultimavenda", hide: tipoAnaliseSelect === 'TODOS', renderHeader: (params) => (<div className='MuiDataGrid-columnHeaderTitle css-t89xny-MuiDataGrid-columnHeaderTitle texto-cabecalho'>
                Última<br />Venda</div>), type: 'date', valueGetter: (params) => !isEmpty(params.row.ultimavenda) ? params.row.ultimavenda : '',
            valueFormatter: (params) => {
                if (params.value) {
                    const ultimavenda = new Date(params.value);
                    return getYear(ultimavenda) > 1969 ? ultimavenda.toLocaleDateString('pt-BR') : '';
                }
                return null
            }, headerClassName: 'blue-header', headerAlign: 'center', width: 120, align: 'center'
        },
        { field: "nome", headerName: "Cliente", headerClassName: 'blue-header', headerAlign: 'left', width: 260, align: 'left' },
        { field: "sexo", headerName: "Sexo", headerClassName: 'blue-header', headerAlign: 'right', width: 50, align: 'right' },
        {
            field: "nascimento", headerName: "Data Nascimento", headerClassName: 'blue-header', headerAlign: 'center',
            type: 'date', valueGetter: (params) => !isEmpty(params.row.nascimento) ? params.row.nascimento : '',
            valueFormatter: (params) => { return !isEmpty(params.value) ? new Date(params.value).toLocaleDateString('pt-BR') : '' }, width: 140, align: 'center'
        },
        { field: "qtdevendas", type: "number", headerName: "Qtde Vendas", headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right' },
        { field: "qtdeitens", type: "number", headerName: "Qtde Itens", headerClassName: 'blue-header', headerAlign: 'right', width: 100, align: 'right' },
        { field: "valortotalvenda", headerName: "Valor Total Venda R$", headerClassName: 'blue-header', headerAlign: 'right', width: 200, align: 'right', 
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, 
         },
        { field: "valormedio", type: "number", headerName: "Valor Médio R$", headerClassName: 'blue-header', headerAlign: 'right', width: 130, align: 'right',
            valueFormatter: (params) => {
                return parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }, 
         },
        { field: "celular", headerName: "Celular", headerClassName: 'blue-header', headerAlign: 'center', width: 130, align: 'center' },
    ];

    const renderTable = () => {
        return (
            <table class="table">
                <thead>
                    <th scope="col" style={{ width: '670px' }}></th>
                    <th scope="col" className="text-right" style={{ width: '100px', fontSize: '13px' }}></th>
                    <th scope="col" className="text-right" style={{ width: '100px', fontSize: '13px' }}></th>
                    <th scope="col" className="text-right" style={{ width: '200px', fontSize: '13px' }}></th>
                    <th scope="col" className="text-right" style={{ width: '130px', fontSize: '13px' }}></th>
                    <th scope="col" className="text-right" style={{ width: '130px', fontSize: '13px' }}></th>
                </thead>
                <tbody>
                    <tr >
                        <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {!isEmpty(totalizador) ? totalizador.qtdevendas : 0}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {(!isEmpty(totalizador) ? totalizador.qtdeitens : 0)}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}
                        >
                            {(!isEmpty(totalizador) ? totalizador.valortotalvenda : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                            {!isEmpty(totalizador)
                                ? (dadosClientes.reduce((acc, row) => acc + row.valortotalvenda, 0) / dadosClientes.reduce((acc, row) => acc + row.qtdevendas, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', fontWeight: 'bold' })
                                : ''}
                        </td>
                        <td className="text-right"
                            style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    const totalizador = dadosClientes.reduce((total, obj) => {
        total.qtdevendas += obj.qtdevendas;
        total.qtdeitens += obj.qtdeitens;
        total.valortotalvenda += obj.valortotalvenda;
        return total;
    }, { qtdevendas: 0, qtdeitens: 0, valortotalvenda: 0 });

    const contextvalues = {
        definirLojasSelecionadas,
        definirProfissoesSelecionadas,
        definirClienteCategoriasSelecionadas,
        definirManequimSuperiorSelecionado,
        definirManequimInferiorSelecionado,
        definirManequimCalcadoSelecionado,
        definirItensSelecionados,
        definirMarcasSelecionadas,
        definirTamanhosSelecionados
    }

    const exportarExcel = async () => {
        const exportarExcel = new CLIENTES_XLS(dadosClientes, tipoVisaoSelect, tipoAnaliseSelect);
        exportarExcel.exportToXLS();
    }

    function CustomToolbar() {
        return (
            <>
                <div className='row'>
                    <div className='col'>
                        <CustomToolBar print={visualizarImpressao} exportExcel={exportarExcel} />
                    </div>
                </div>
            </>
        );
    }

    registerLocale('pt-BR', dateFnsPtBR);
    setDefaultLocale('pt-BR');

    if (loading) {
        return (
            <LoadingScreen />
        );
    }

    const handleChangeDiaAniversarioFiltro = async (event) => {
        setDiaAniversarioFiltro(event.target.value);
    };

    const handleChangeMesAniversarioFiltro = async (event) => {
        setMesAniversarioFiltro(event.target.value);
    };

    const handleChangeSexoFiltro = async (event) => {
        setSexoFiltro(event.target.value);
    }; 

    const handleChangeQtdeItensMinimoFiltro = async (event) => {
        setQtdeItensMinimoFiltro(event.target.value);
    };

    const handleChangeQtdeItensMaximoFiltro = async (event) => {
        setQtdeItensMaximoFiltro(event.target.value);
    };

    const handleChangeQtdeVendasMaximaFiltro = async (event) => {
        setQtdeVendasMaximaFiltro(event.target.value);
    };

    const handleChangeQtdeVendasMinimaFiltro = async (event) => {
        setQtdeVendasMinimaFiltro(event.target.value);
    };

    const handleChangeValorMinimoFiltro = async (event) => {
        setValorMinimoFiltro(event.target.value);
    };

    const handleChangeValorMaximoFiltro = async (event) => {
        setValorMaximoFiltro(event.target.value);
    };

    const handleChangeRankingFiltroValor = async (event) => {
        setRankingFiltroValor(event.target.value);
    };


    return (
        <>
            <ClientesContext.Provider value={contextvalues}>
                <form className="form">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Listagem de Clientes</label>
                    </div>

                    <Card style={{ marginLeft: 30, marginRight: 30 }}>
                        <div className='align-left'>
                            <div className='row' >
                                <div className='col-auto'>
                                    <label className='text' style={{ fontWeight: 'bold' }}>Visão</label>
                                    <Card style={{ width: '100%', paddingRight: 30 }}>
                                        <div className="row" >                                          
                                            <div className="col-auto">
                                                <Radio
                                                    checked={tipoVisaoSelect === 'CADASTRO'}
                                                    onChange={() => {
                                                        setTipoVisaoSelect('CADASTRO');
                                                        setDadosClientes([]);
                                                    }}
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    size="small"
                                                    id="cadastroRadio"
                                                />
                                                <label className="form-check-label" htmlFor="cadastroRadio" style={{ cursor: 'pointer' }}>
                                                    Cadastro
                                                </label>
                                            </div>

                                            <div className="col-auto">
                                                <div className="form-check">
                                                    <Radio
                                                        checked={tipoVisaoSelect === 'VENDA'}
                                                        onChange={() => {
                                                            setTipoVisaoSelect('VENDA');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="vendaRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="vendaRadio" style={{ cursor: 'pointer' }}>
                                                        Venda
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                {tipoVisaoSelect === 'CADASTRO' && (
                                    <div className='col-auto'>
                                        <label className='text' style={{ fontWeight: 'bold' }}>Análise</label>
                                        <Card style={{ width: '100%', paddingRight: 30 }}>
                                            <div className="row" >
                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={tipoAnaliseSelect === 'TODOS'}
                                                            onChange={() => {
                                                                setTipoAnaliseSelect('TODOS');
                                                                setDadosClientes([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="analiseTodosRadio"
                                                        />
                                                        <label className="form-check-label" htmlFor="analiseTodosRadio" style={{ cursor: 'pointer' }}>
                                                            Todos
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoAnaliseSelect === 'CLIENTESCOMVENDA'}
                                                        onChange={() => {
                                                            setTipoAnaliseSelect('CLIENTESCOMVENDA');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="clientescomvendaRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="clientescomvendaRadio" style={{ cursor: 'pointer' }}>
                                                        Clientes com Venda
                                                    </label>
                                                </div>

                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoAnaliseSelect === 'CLIENTESSEMVENDA'}
                                                        onChange={() => {
                                                            setTipoAnaliseSelect('CLIENTESSEMVENDA');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="clientessemvendaRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="clientessemvendaRadio" style={{ cursor: 'pointer' }}>
                                                        Clientes sem Venda
                                                    </label>
                                                </div>

                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoAnaliseSelect === 'ULTIMAVENDA'}
                                                        onChange={() => {
                                                            setTipoAnaliseSelect('ULTIMAVENDA');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="ultimavendaRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="ultimavendaRadio" style={{ cursor: 'pointer' }}>
                                                        Última Venda
                                                    </label>
                                                </div>

                                            </div>
                                        </Card>
                                    </div>
                                )}

                                {tipoVisaoSelect === 'VENDA' && (
                                    <div className='col-auto'>
                                        <label className='text' style={{ fontWeight: 'bold' }}>Análise</label>
                                        <Card style={{ width: '450px', paddingRight: 30 }}>
                                            <div className="row" >

                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoAnaliseVendaSelect === 'VENDASNOPERIODO'}
                                                        onChange={() => {
                                                            setTipoAnaliseVendaSelect('VENDASNOPERIODO');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="vendasnoperiodoRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="vendasnoperiodoRadio" style={{ cursor: 'pointer' }}>
                                                        Vendas no Período
                                                    </label>
                                                </div>


                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoAnaliseVendaSelect === 'ULTIMAVENDA'}
                                                        onChange={() => {
                                                            setTipoAnaliseVendaSelect('ULTIMAVENDA');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="ultimavendaRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="ultimavendaRadio" style={{ cursor: 'pointer' }}>
                                                        Última Venda
                                                    </label>
                                                </div>

                                            </div>
                                        </Card>
                                    </div>
                                )}

                                {tipoVisaoSelect === 'CADASTRO' && (
                                    <div className='col-auto'>
                                        <label className='text' style={{ fontWeight: 'bold' }}>Tipo Cadastro</label>
                                        <Card style={{ width: '100%', paddingRight: 30 }}>
                                            <div className="row" >
                                               
                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoCadastroSelect === 'CLIENTES'}
                                                        onChange={() => {
                                                            setTipoCadastroSelect('CLIENTES');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="clientesRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="clientesRadio" style={{ cursor: 'pointer' }}>
                                                        Clientes
                                                    </label>
                                                </div>

                                                <div className="col-auto">
                                                    <Radio
                                                        checked={tipoCadastroSelect === 'DEPENDENTE'}
                                                        onChange={() => {
                                                            setTipoCadastroSelect('DEPENDENTE');
                                                            setDadosClientes([]);
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        size="small"
                                                        id="dependentesRadio"
                                                    />
                                                    <label className="form-check-label" htmlFor="dependentesRadio" style={{ cursor: 'pointer' }}>
                                                        Dependente
                                                    </label>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <Radio
                                                            checked={tipoCadastroSelect === 'TODOS'}
                                                            onChange={() => {
                                                                setTipoCadastroSelect('TODOS');
                                                                setDadosClientes([]);
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            size="small"
                                                            id="todosRadio"
                                                        />
                                                        <label className="form-check-label" htmlFor="todosRadio" style={{ cursor: 'pointer' }}>
                                                            Todos
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </Card>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>

                    <Card style={{ marginLeft: 30, marginRight: 30 }}>
                        <div className="row" style={{ marginLeft: '5px', marginTop: '30px', width: '100%' }}>
                            <div className="col-auto">
                                <Card style={{ width: 360, padding: 25 }}>
                                    <TypographyCustom titulo={'Período'} />
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        adapterLocale={ptBR}
                                    >
                                        {
                                            <DateRangePicker
                                                localeText={{ start: 'Data Inicial', end: 'Data Final' }}
                                                value={dataIntervaloFiltro}
                                                disabled={tipoVisaoSelect === 'CADASTRO' && tipoAnaliseSelect === 'TODOS' ? true : false}
                                                onChange={(newValue) => setDataIntervaloFiltro(newValue)}
                                                renderInput={(startProps, endProps) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <input {...startProps.inputProps} />
                                                        <span> to </span>
                                                        <input {...endProps.inputProps} />
                                                    </Box>
                                                )}
                                            />
                                        }
                                    </LocalizationProvider>
                                </Card>
                            </div>

                            <div className='col-auto'>
                                <Card style={{ width: 350, padding: 25, height: 110 }}>
                                    <TypographyCustom titulo={tipoCadastroSelect === 'DEPENDENTE' ? 'Aniversário Dependente' : 'Aniversário Cliente'}/>
                                    <div className='row'>
                                        <div className='col'>
                                            <FormControl style={{ width: 120, marginTop: 10 }}>
                                                <InputLabel id="demo-simple-select-label">Dia</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={diaAniversarioFiltro}
                                                    label="Dia"
                                                    onChange={handleChangeDiaAniversarioFiltro}
                                                    style={{ height: 40 }}
                                                >
                                                    {diasmes.map((dia) => (
                                                        <MenuItem key={dia} value={dia}>
                                                            {dia === 0 ? 'TODOS' : dia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='col'>
                                            <FormControl style={{ width: 140, marginTop: 10 }}>
                                                <InputLabel id="demo-simple-select-label">Mês</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={mesAniversarioFiltro}
                                                    label="Mês"
                                                    onChange={handleChangeMesAniversarioFiltro}
                                                    style={{ height: 40 }}
                                                >
                                                    {meses.map((mes) => (
                                                        <MenuItem key={mes.id} value={mes.nome}>
                                                            {mes.nome}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>

                        {tipoVisaoSelect === 'VENDA' && (
                            <div className="row" style={{ marginLeft: '5px', marginTop: '10px', width: '100%' }}>
                                <div className='col-auto'>
                                    <Card style={{ width: 550, padding: 25, height: 100, marginTop: 25 }}>
                                        <TypographyCustom titulo={'Ranking'} />
                                        <div className='row' >

                                            <div className='col-auto'>
                                                <FormControl style={{ width: 140, height: 30 }}>
                                                    <TextField
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label={rankingFiltro === 'QTDEITENS' ? 'QTDE ITENS' : rankingFiltro === 'QTDEVENDAS' ? 'QTDE VENDAS' : rankingFiltro}
                                                        value={rankingFiltroValor}
                                                        onChange={handleChangeRankingFiltroValor}
                                                        type="number"
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </div>

                                            <div className='col-auto'>
                                                <ButtonGroup variant="contained" aria-label="Basic button group">
                                                    <Button
                                                        style={{
                                                            backgroundColor: rankingFiltro === 'VALOR' ? 'green' : 'white',
                                                            borderColor: 'white',
                                                            color: rankingFiltro === 'VALOR' ? 'white' : 'grey'
                                                        }}
                                                        onClick={() => setRankingFiltro('VALOR')}
                                                    >Valor
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            backgroundColor: rankingFiltro === 'QTDEITENS' ? 'green' : 'white',
                                                            borderColor: 'white',
                                                            color: rankingFiltro === 'QTDEITENS' ? 'white' : 'grey'
                                                        }}
                                                        onClick={() => setRankingFiltro('QTDEITENS')}
                                                    >Qtde Itens
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            backgroundColor: rankingFiltro === 'QTDEVENDAS' ? 'green' : 'white',
                                                            borderColor: 'white',
                                                            color: rankingFiltro === 'QTDEVENDAS' ? 'white' : 'grey'
                                                        }}
                                                        onClick={() => setRankingFiltro('QTDEVENDAS')}
                                                    >Qtde Vendas
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>

                                    </Card>
                                </div>

                                <div className='col-auto'>
                                    <Card style={{ width: 570, padding: 25, height: 100, marginTop: 25 }}>
                                        <TypographyCustom titulo={'Valor'} />
                                        <div className='row' >
                                            <div className='col-auto'>
                                                <FormControl style={{ width: 150, height: 30 }}>
                                                    <InputLabel htmlFor="outlined-adornment-minimum">Mínimo</InputLabel>
                                                    <OutlinedInput
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                        id="demo-simple-select"
                                                        label="Mínimo"
                                                        value={valorMinimoFiltro}
                                                        onChange={handleChangeValorMinimoFiltro}
                                                        type="number"
                                                    >
                                                    </OutlinedInput>
                                                </FormControl>
                                            </div>

                                            <div className='col-auto'>
                                                <FormControl style={{ width: 150, height: 30 }}>
                                                    <InputLabel htmlFor="outlined-adornment-minimum">Máximo</InputLabel>
                                                    <OutlinedInput
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                        id="demo-simple-select"
                                                        label="Máximo"
                                                        value={valorMaximoFiltro}
                                                        onChange={handleChangeValorMaximoFiltro}
                                                        type="number"
                                                    >
                                                    </OutlinedInput>
                                                </FormControl>
                                            </div>

                                            <div className='col-auto'>
                                                <ButtonGroup variant="contained" aria-label="Basic button group">
                                                    <Button
                                                        style={{
                                                            backgroundColor: tipoValorFiltro === 'TOTAL' ? 'green' : 'white',
                                                            borderColor: 'white',
                                                            color: tipoValorFiltro === 'TOTAL' ? 'white' : 'grey'
                                                        }}
                                                        onClick={() => setTipoValorFiltro('TOTAL')}
                                                    >Total
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            backgroundColor: tipoValorFiltro === 'MÉDIA' ? 'green' : 'white',
                                                            borderColor: 'white',
                                                            color: tipoValorFiltro === 'MÉDIA' ? 'white' : 'grey'
                                                        }}
                                                        onClick={() => setTipoValorFiltro('MÉDIA')}
                                                    >Média
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className='col-auto'>
                                    <Card style={{ width: 280, padding: 25, height: 100, marginTop: 25 }}>
                                        <TypographyCustom titulo={'Qtde de Vendas'} />
                                        <div className='row'>
                                            <div className='col'>
                                                <FormControl style={{ width: 80, marginTop: 10 }}>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <TextField
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={qtdeVendasMinimaFiltro}
                                                        label="Mínimo"
                                                        onChange={handleChangeQtdeVendasMinimaFiltro}
                                                        type="number"
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </div>

                                            <div className='col'>
                                                <FormControl style={{ width: 90, marginTop: 10 }}>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <TextField
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={qtdeVendasMaximaFiltro}
                                                        label="Máximo"
                                                        onChange={handleChangeQtdeVendasMaximaFiltro}
                                                        type="number"
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className='col-auto'>
                                    <Card style={{ width: 280, padding: 25, height: 100, marginTop: 25 }}>
                                        <TypographyCustom titulo={'Qtde de Itens'} />
                                        <div className='row'>
                                            <div className='col'>
                                                <FormControl style={{ width: 100, marginTop: 10 }}>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <TextField
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={qtdeItensMinimoFiltro}
                                                        label="Mínimo"
                                                        onChange={handleChangeQtdeItensMinimoFiltro}
                                                        type="number"
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </div>

                                            <div className='col'>
                                                <FormControl style={{ width: 100, marginTop: 10 }}>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <TextField
                                                        size="small"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={qtdeItensMaximoFiltro}
                                                        label="Máximo"
                                                        onChange={handleChangeQtdeItensMaximoFiltro}
                                                        type="number"
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                            </div>
                        )}

                        <div className="filtros">
                            <div className="align-left">
                                <div className="row align-items-start" style={{ marginTop: '20px' }}>
                                    <div className="col-auto">
                                        <ButtonFiltroPadraoLoja
                                            texto={tipoAnaliseSelect === 'TODOS' && tipoVisaoSelect === 'CADASTRO' ? "Loja Cadastro" : "Loja Venda"}                                             
                                            onClick={handleShowLojas}
                                            width={'230px'}
                                            itensFiltro={lojasFiltro}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <ButtonFiltroPadrao
                                            texto="Categoria"
                                            disabled={tipoCadastroSelect === 'DEPENDENTE'}
                                            onClick={handleShowClienteCategorias}
                                            width={'230px'}
                                            itensFiltro={clienteCategoriasFiltro}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <ButtonFiltroPadrao
                                            texto="Profissão"
                                            disabled={tipoCadastroSelect === 'DEPENDENTE'}
                                            onClick={handleShowProfissao}
                                            width={'230px'}
                                            itensFiltro={profissaoFiltro}
                                        />
                                    </div>
                                    <div className='col-auto'>
                                    {tipoVisaoSelect === 'CADASTRO' && (
                                        <div className='col-auto'>
                                            <FormControl style={{ width: 180 }}>
                                                <InputLabel id="demo-simple-select-label">Sexo {tipoCadastroSelect === 'DEPENDENTE' ? 'Dependente' : 'Cliente'}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"                                                    
                                                    value={sexoFiltro}
                                                    label="Sexo Dependente"
                                                    onChange={handleChangeSexoFiltro}
                                                    style={{ height: 40 }}
                                                >
                                                    {sexos.map((sexo) => (
                                                        <MenuItem key={sexo.id} value={sexo.nome}>
                                                            {sexo.nome}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )}    
                                    </div>                                                                  
                                </div>

                                {tipoVisaoSelect === 'CADASTRO' && (
                                    <div className="row align-items-start" style={{ marginTop: '20px' }}>
                                        <div className="col-auto">
                                            <ButtonFiltroPadrao
                                                texto="Manequim Sup"
                                                onClick={handleShowManequimSuperior}
                                                width={'230px'}
                                                itensFiltro={manequimSuperiorFiltro}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <ButtonFiltroPadrao
                                                texto="Manequim Inf"
                                                onClick={handleShowManequimInferior}
                                                width={'230px'}
                                                itensFiltro={manequimInferiorFiltro}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <ButtonFiltroPadrao
                                                texto="Manequim Calçado"
                                                onClick={handleShowManequimCalcado}
                                                width={'230px'}
                                                itensFiltro={manequimCalcadoFiltro}
                                            />
                                        </div>

                                        <div className='col-auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={exibirFotoCliente.exibir}
                                                        onChange={handleChangeExibirFotoCliente}
                                                        name="exibir" />}
                                                label={
                                                    <Typography
                                                        variant="body1"
                                                        style={
                                                            { fontWeight: 'bold' }}
                                                    >
                                                        Exibir Foto Cliente
                                                    </Typography>
                                                } />
                                        </div>
                                    </div>
                                )}

                                {tipoVisaoSelect === 'VENDA' && (
                                    <div className="row align-items-start" style={{ marginTop: '20px' }}>
                                        <div className="col-auto">
                                            <ButtonFiltroPadrao
                                                texto="Item"
                                                onClick={handleShowItem}
                                                width={'230px'}
                                                itensFiltro={itensFiltro}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <ButtonFiltroPadrao
                                                texto="Marca"
                                                onClick={handleShowMarcas}
                                                width={'230px'}
                                                itensFiltro={marcasFiltro}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <ButtonFiltroPadrao
                                                texto="Tamanho"
                                                onClick={handleShowTamanhos}
                                                width={'230px'}
                                                itensFiltro={tamanhosFiltro}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>

                        <div className="row align-items-start" style={{ marginTop: '30px' }}>
                            <div className='col-auto'>
                                <div className="actions">
                                    <ButtonExecute
                                        width={'230px'}
                                        onClick={handleExecutar}
                                    />
                                </div>
                            </div>
                            <div className='col-auto'>
                                <ButtonClear
                                    onClick={handleLimparFiltros}
                                />
                            </div>
                        </div>
                    </Card>
                </form>

                <ModalLojas show={showLojas} value={ClientesContext} onHide={handleCloseLojas} />
                <ModalProfissoes show={showProfissoes} value={ClientesContext} onHide={handleCloseProfissoes} />
                <ModalClienteCategorias show={showClienteCategorias} value={ClientesContext} onHide={handleCloseClienteCategorias} />
                <ModalManequimSuperior show={showManequimSuperior} value={ClientesContext} onHide={handleCloseManequimSuperior} />
                <ModalManequimInferior show={showManequimInferior} value={ClientesContext} onHide={handleCloseManequimInferior} />
                <ModalManequimCalcado show={showManequimCalcado} value={ClientesContext} onHide={handleCloseManequimCalcado} />
                <ModalTamanhos show={showTamanhos} value={ClientesContext} onHide={handleCloseTamanhos} />
                <ModalItem show={showItem} value={ClientesContext} onHide={handleCloseItem} />
                <ModalMarcas show={showMarcas} value={ClientesContext} onHide={handleCloseMarcas} />

                {!isEmpty(dadosClientes) && (
                    <Container maxWidth="xl" style={{ width: 'fit-content' }}>
                        <>
                            <CustomStyledDataGrid
                                customRowHeight={exibirFotoCliente.exibir ? 40 : 30}
                                slots={{
                                    toolbar: CustomToolbar,
                                    pagination: CustomPagination,
                                }}
                                loading={loading}
                                columns={tipoVisaoSelect === 'CADASTRO' ? columnsCadastro.filter((column) => !column.hide) : columnsVisaoVenda}
                                rows={rows}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={[PAGE_SIZE]}
                                sx={{
                                    '@media print': {
                                        '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' }
                                    },
                                    '& .MuiDataGrid-row': { borderTopColor: 'yellow', borderTopStyle: 'solid' }
                                }}
                                rowSpacingType="border"
                            />
                        </>
                    </Container>
                )}
            </ClientesContext.Provider>
        </>
    )
}


export default ClientesProvider;
