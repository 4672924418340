import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import { get } from 'lodash';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableDepartamentos = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [departamentos, setDepartamentos] = useState([]);  
  const [departamentosData, setDepartamentosData] = useState([]);
  const [selectedDepartamentos, setSelectedDepartamentos] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirDepartamentosSelecionados  } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedDepartamentos = sessionStorage.getItem('selectedDepartamentos');
    if (storedSelectedDepartamentos) {    
      setSelectedDepartamentos(JSON.parse(storedSelectedDepartamentos));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedDepartamentos.length > 0)
   {
      sessionStorage.setItem('selectedDepartamentos', JSON.stringify(selectedDepartamentos));
   }       
  }, [selectedDepartamentos, departamentos]);  

  const handleDepartamentoSelection = (id) => {
    const updatedIndexes = selectedDepartamentos.includes(id)
      ? selectedDepartamentos.filter((index) => index !== id)
      : [...selectedDepartamentos, id];
    setSelectedDepartamentos(updatedIndexes);   

    const selectedDepartamentosIds = updatedIndexes.map((index) => index.id);
    definirDepartamentosSelecionados(selectedDepartamentosIds);
  };

  const handleRemoverDepartamentoSelecionadosClick = (id) => {
    const updatedDepartamentosSelecionados = selectedDepartamentos.includes(id)
      ? selectedDepartamentos.filter((index) => index !== id)
      : [...selectedDepartamentos, id];
    setSelectedDepartamentos(updatedDepartamentosSelecionados);   

    const selectedDepartamentosIds = updatedDepartamentosSelecionados.map((index) => index.id);
    definirDepartamentosSelecionados(selectedDepartamentosIds);

    if (selectedDepartamentosIds.length === 0)
    {
      sessionStorage.removeItem('selectedDepartamentos');
    }
  };

  const handleRowClick = (departamentoId) => {
    setDepartamentos((prevDepartamentos) =>
      prevDepartamentos.filter((departamento) => departamento.id !== departamentoId)
    );
  };

  const handleRowSelectedClick = (departamento) => {

    if (!departamentos.includes(departamento.id))
    {
        const updatedDepartamentos = [...departamentos, departamento];
        setDepartamentos(updatedDepartamentos);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedDepartamentos(departamentos);
       setDepartamentos([]);
       
       const selectedDepartamentosIds = departamentos.map((index) => index.id);
       definirDepartamentosSelecionados(selectedDepartamentosIds);       
       sessionStorage.setItem('selectedDepartamentos', selectedDepartamentos);      
  }

  const handleRemoverTodos = async() => {         
      if (!departamentosData || departamentosData.length === 0)
      {
        getDepartamentos(); 
      }      
      setDepartamentos(departamentosData);
      setSelectedDepartamentos([]);    
      definirDepartamentosSelecionados([]);         
      sessionStorage.removeItem('selectedDepartamentos');      
  }

  const getDepartamentos = async () => {   
    console.log('pesquisando');
    try {
      let url = 'departamento';

      if (busca !== '') {
        url = `departamento/descricao/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setDepartamentos(response.data);   
      setDepartamentosData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getDepartamentos();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição do departamento"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />              
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>                 
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {departamentos.map((departamento) => {
                  if (selectedDepartamentos.some((mrc) => mrc.id === departamento.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={departamento.id}
                        onClick={() => {
                          handleDepartamentoSelection(departamento);
                          handleRowClick(departamento.id);
                        }}
                      >
                        <td>{departamento.id}</td>
                        <td>{departamento.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedDepartamentos.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody style={{cursor: 'pointer'}}>
                  {selectedDepartamentos.map((departamento) => (
                    <tr key={departamento.id}
                        onClick={() => {
                          handleRemoverDepartamentoSelecionadosClick(departamento);
                          handleRowSelectedClick(departamento)
                        }}
                    >
                      <td>{departamento.id}</td>
                      <td>{departamento.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableDepartamentos;

