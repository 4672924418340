import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import EncryptionUtil from '../../Utils/EncryptionUtil';
import { Container, Button, Card } from 'react-bootstrap';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableItem = ({contexto}) => {
  const sessionStorageUtils = new SessionStorageUtils(); 
  const [itens, setItens] = useState([]);  
  const [itensData, setItensData] = useState([]);
  const [selectedItens, setSelectedItens] = useState([]);
  const [busca, setBusca] = useState('');  
  const { definirItensSelecionados } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {    
    const storedSelectedItens = sessionStorage.getItem('selectedItens');
    if (storedSelectedItens) {    
      setSelectedItens(JSON.parse(storedSelectedItens));
    }    
    pesquisaRef.current.focus(); 
  }, []);

  useEffect(() => {
    
   if (selectedItens.length > 0)
   {
      sessionStorage.setItem('selectedItens', JSON.stringify(selectedItens));
   }       
  }, [selectedItens, itens]);  

  const handleItemSelection = (id) => {
    const updatedIndexes = selectedItens.includes(id)
      ? selectedItens.filter((index) => index !== id)
      : [...selectedItens, id];
    setSelectedItens(updatedIndexes);   

    const selectedItensIds = updatedIndexes.map((index) => index.id);
    definirItensSelecionados(selectedItensIds);
  };

  const handleRemoverItensSelecionadosClick = (id) => {
    const updatedItensSelecionados = selectedItens.includes(id)
      ? selectedItens.filter((index) => index !== id)
      : [...selectedItens, id];
    setSelectedItens(updatedItensSelecionados);   

    const selectedItensIds = updatedItensSelecionados.map((index) => index.id);
    definirItensSelecionados(selectedItensIds);

    if (selectedItensIds.length === 0)
    {
      sessionStorage.removeItem('selectedItens');
    }
  };

  const handleRowClick = (itemId) => {
    setItens((prevItens) =>
      prevItens.filter((item) => item.id !== itemId)
    );
  };

  const handleRowSelectedClick = (item) => {

    if (!itens.includes(item.id))
    {
        const updatedItens = [...itens, item];
        setItens(updatedItens);
    }    
  };

  const handleAdicionarTodos = () => {
       setSelectedItens(itens);
       setItens([]);
       
       const selectedItensIds = itens.map((index) => index.id);
       definirItensSelecionados(selectedItensIds);       
       sessionStorage.setItem('selectedItens', selectedItens);      
  }

  const handleRemoverTodos = async() => {         
      if (!itensData || itensData.length === 0)
      {
        getItens(); 
      }      
      setItens(itensData);
      setSelectedItens([]);    
      definirItensSelecionados([]);         
      sessionStorage.removeItem('selectedItens');      
  }

  const getItens = async () => {   
    try {
      let url = 'item';

      if (busca !== '') {
        url = `item/descricao/${busca.toUpperCase()}`;
      }

      await SetTenant();
      const response = await api.get(url);
      setItens(response.data);   
      setItensData(response.data);
    } catch (e) {
      console.log('erro');
    }
  };  

  const handlePesquisar = () => {                            
    getItens();                             
  }

  const handleKeyDown  = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }     
  }
}

  return (    
      <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
       <Card style={{border: '0px'}}>
        <div className="align-left">
          <div className="row">
          <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição do item"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>            
          </div>
        </div>
      </Card>
        
        <Container>
          <div className="col">
          <Card>             
              <div className='col'>
                  <ButtonAdicionarTodos onClick={handleAdicionarTodos}/>
              </div>                                              
            </Card>
            <Card>
              <table className="table table-striped table-hover">
                <thead className="table mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                {itens.map((item) => {
                  if (selectedItens.some((cli) => cli.id === item.id)) {                      
                      return null;
                  } else {                     
                    return (
                      <tr
                        key={item.id}
                        onClick={() => {
                          handleItemSelection(item);
                          handleRowClick(item.id);
                        }}
                      >
                        <td>{item.id}</td>
                        <td>{item.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              </table>
            </Card>           
          </div>

          <div className="col">
            <Card>
            <Card>     
            <div className='row justify-content-end'>
                <div className='col text-left'> 
                  <label className='labelSelecionar'>Selecionados: {selectedItens.length}</label>
                </div>
              <div className='col text-right'>               
                <ButtonRemoverTodos onClick={handleRemoverTodos}/>
              </div>
            </div>   
            </Card>
              <table className="table table-striped table-hover">
                <thead className="table-info mt-3">
                  <tr>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Cód</th>
                    <th scope="col" style={{ backgroundColor:'#007CB0', color: 'white' }}>Descrição</th>
                  </tr>
                </thead>
                <tbody  style={{cursor: 'pointer'}}>
                  {selectedItens.map((item) => (
                    <tr key={item.id}
                        onClick={() => {
                          handleRemoverItensSelecionadosClick(item);
                          handleRowSelectedClick(item)
                        }}
                    >
                      <td>{item.id}</td>
                      <td>{item.descricao}</td>
                    </tr>
                  ))}
                </tbody>
              </table>                        
            </Card>
          </div>
        </Container>        
      </div>    
  );
};

export default TableItem;

