import  React , { createContext, useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import "react-datepicker/dist/react-datepicker.css";
import { Container, Grid, Paper, Stack, Tooltip } from '@mui/material';
import { api } from '../../services/api';
import { Card } from 'react-bootstrap';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'bootstrap/dist/css/bootstrap.css';
import ModalLojas from '../../components/Modal/ModalLojas';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import '../../pages/DRE/DREStyles.css';
import { isEmpty } from 'lodash';
import { startOfMonth, endOfMonth, getYear } from 'date-fns';
import ButtonPrint from '../../components/Buttons/ButtonPrint';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
import letraP from '../../img/letraP.png';
import letraR from '../../img/letraR.png';
import { DRE_XLS } from '../../components/Impressao/Excel/DRE_XLS';
import ButtonExportExcel from '../../components/Buttons/ButtonExportExcel';
import { ImpressaoDrePDF } from '../../components/Impressao/ImpressaoDrePDF';
import ModalDetalheCondi from '../../components/Modal/ModalDetalheCondi';
import ModalDetalheRateio from '../../components/Modal/ModalDetalheRateio';
import ModalDetalheProvisao from '../../components/Modal/ModalDetalheProvisao';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const DREContext = createContext();
  export const DREProvider = () => {   
    const sessionStorageUtils = new SessionStorageUtils();      
    const [showDetalheRateio, setShowDetalheRateio] = useState(false);
    const [showDetalheProvisao, setShowDetalheProvisao] = useState(false);
    const [pagarIdDetalhe, setPagarIdDetalhe] = useState(0);    
    const [showLojas, setShowLojas] = useState(false);       
    const [lojasFiltro, setLojasFiltro] = useState([]);     
    const [limparFiltros, SetLimparFiltros] = useState(false);
    const [loading, setLoading] = useState(true);      
    const [nomeGrupo, setNomeGrupo] = useState([]);                   
    const [dataIntervaloFiltro, setDataIntervaloFiltro] = useState([
        startOfMonth(new Date()),
        endOfMonth(new Date())
      ]);     
    const [dadosDRE, SetDadosDRE] = useState([]);      
    const navigate = useNavigate();
    const permissao = new Permissoes(); 

    const getDetailPanelContent = React.useCallback(
      ({ row}) => <DetailPanelContent row={row}/>,
      [],
    );        
 
    useEffect(() => {
        setTimeout(() => {     
            setLoading(true);       
            if (ValidarPermissoes() === false) { return; } 
            sessionStorage.removeItem('selectedLojas');            
            definirLojaInicial();                                
            DefinirNomeGrupo();
            setLoading(false);            
          }, 500);                      
    }, []); 

    async function ValidarPermissoes(){                    
        const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('49627bfa0fef58023fecfc6f7f828546');        
        if (autorizado) { return true; }
        navigate('/home')              
        return false;      
    }  

    useEffect(() => {        
      if (limparFiltros)
      {
          definirLojaInicial();
          SetLimparFiltros(false);
      }        
    }, [limparFiltros]);
    
    const handleLimparFiltros = async () => {         
      LimparFiltrosSelecionados()                
      SetLimparFiltros(true);
    }
    
    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
      setNomeGrupo(groupName);
    }

    function LimparFiltrosSelecionados() {
      sessionStorage.removeItem('selectedLojas');
      setLojasFiltro([]);      
      SetDadosDRE([]);
    }
           
    const definirLojaInicial = async()  => 
    {        
        const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                             
        if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
        {                        
            const userId = JSON.parse(desencryptJSONUser).id;                       
            await SetTenant(); 
            const response = await api.get(`loja/lojascadastradas/${userId}`);
            const firstId = response.data[0].idloja;             
            await setLojasFiltro(firstId);               
        }          
    }

    const handleExecutar = async() => {                                                                     
        setLoading(true);
        
        await getFinanceiro();         
        setLoading(false);
    }    

    const handleCloseDetalheRateio = () => {
        setShowDetalheRateio(false);
    }
  
  const handleCloseDetalheProvisao = () => {
      setShowDetalheProvisao(false);
  }  
    
    const getFinanceiro = async() => {                  
        try 
        {             
            const dataIni = (`${dataIntervaloFiltro[0].getFullYear()}-${dataIntervaloFiltro[0].getMonth()+1}-${dataIntervaloFiltro[0].getDate()}`);
            const dataFin = (`${dataIntervaloFiltro[1].getFullYear()}-${dataIntervaloFiltro[1].getMonth()+1}-${dataIntervaloFiltro[1].getDate()}`);                                                  
            let url = `conta/resultado/dre/${lojasFiltro}/${dataIni}/${dataFin}`;                                

            SetDadosDRE([]);         
            await SetTenant();    
            const response = await api.get(url);                                                                           
            await SetDadosDRE(response.data);               
        }
        catch (e)
        {
            console.log('erro');
        }        
    }            

   const handleShowLojas = () => 
   {            
        setShowLojas(true);
   }     
    
    const handleCloseLojas = () => 
    {        
          setShowLojas(false);                           
    }    

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }         
    
    const filtrosSelecionados = {        
        lojasSelecionadas : lojasFiltro,
        datasFiltro : dataIntervaloFiltro,
        nomeGrupo : nomeGrupo
      }  

      function CustomToolbar() {
        return (
        <>
        <div className='row' >
            <div className='col' style={{marginLeft: '835px'}}>             
              <ButtonPrint onClick={visualizarImpressao} />     
            </div>                        
            <div className='col'>              
              <ButtonExportExcel onClick={exportarExcel} />     
            </div>
        </div>
        </>
        );
      }   

    const visualizarImpressao = async  () => {   
      if (!isEmpty(dadosDRE))
      {
        const classeImpressao = new ImpressaoDrePDF(dadosDRE, filtrosSelecionados);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
      }        
    }  

    function DetailPanelContent({ row: rowProp }) {        
      return (
        rowProp.subRows.length > 0 && (
         
        <Container sx={{ height: '100%' }}>
          <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
              <Stack direction="column" spacing={0} sx={{ height: '100%' }}>           
                <Grid container sx={{ flexGrow: 0 }}>
                  <Grid item md={6}>                
                  </Grid>
                </Grid>               
                <Container sx={{ height: '100%' }}>           

              <DataGridPro   
                    style={{ fontFamily:  [
                      '-apple-system',
                      'BlinkMacSystemFont',
                      '"Segoe UI"',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                      '"Apple Color Emoji"',
                      '"Segoe UI Emoji"',
                      '"Segoe UI Symbol"',
                    ].join(','),                         
                    }}                                      
                    columns={
                      rowProp.subRows ?
                      columnsDetail1
                      : []
                      }               
                    rows={
                      rowProp.subRows
                        ? rowProp.subRows 
                        : []
                    }                         
                    getCellClassName={getCellClassName}         
                    rowHeight={30}                              
                    getDetailPanelContent={({ row }) =>                       
                      row.subRows !== null &&(
                      <>
                      <Container sx={{ height: '100%' }}>
                      <Stack sx={{ py: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
                        <Paper sx={{ flex: 1, mx: 'auto', width: 'auto', p: 1}}>         
                          <Stack direction="column" spacing={0} sx={{ height: '100%' }}>           
                            <Grid container sx={{ flexGrow: 0 }}>
                              <Grid item md={6}>                
                              </Grid>
                            </Grid>               
                            <Container sx={{ height: '100%' }}>  
                            <DataGridPro   
                                style={{ fontFamily:  [
                                  '-apple-system',
                                  'BlinkMacSystemFont',
                                  '"Segoe UI"',
                                  'Roboto',
                                  '"Helvetica Neue"',
                                  'Arial',
                                  'sans-serif',
                                  '"Apple Color Emoji"',
                                  '"Segoe UI Emoji"',
                                  '"Segoe UI Symbol"',
                                ].join(','),                         
                                }}                   
                                columns={
                                  row.subRows  ?
                                  row.descricao === '(+) OUTRAS RECEITAS'
                                    ? columnsDetailCaixaMovimento
                                    : columnsDetail2
                                  : []
                                  }               
                                rows={
                                  row.subRows
                                    ? row.subRows
                                    : []
                                }                                             
                                rowHeight={30}                                         
                                getDetailPanelHeight={() => 'auto'}                                            
                                hideFooter                                      
                                getRowClassName={() => 'even'}                 
                                columnHeaderHeight={25}                                                                            
                                />  
                                 </Container>                                               
                          </Stack>
                        </Paper>
                      </Stack>
                    </Container>
                    </>
                    )

                    }    
                    getDetailPanelHeight={() => 'auto'}                                            
                    hideFooter      
                    hideHeader  
                    getRowClassName={() => 'even'}                 
                    columnHeaderHeight={0}                                                                
                    />                              
                               
                </Container>
               
              </Stack>
            </Paper>
          </Stack>
        </Container>
         
         )
      );
    }            
      
    const columnsDetail1= [ 
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => (
          params.row.subRows !== null && !isEmpty(params.row.subRows) ? (<CustomDetailPanelToggle id={params.id} value={params.value} />) : null
        ),     
      },             
      { field: "descricao", headerName: "Descrição", headerClassName: 'header-detail', width: 615, cellClassName: () => { return 'value-cell-red' } },
      { field: "valor", headerName:"Valor Líquido R$",  headerClassName: 'header-detail',  headerAlign: 'right', width: 270, align: 'right', 
        valueFormatter: (params) => {
          return "R$ " + (params.value > 0 ? parseFloat(params.value) : 0).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          }) ;
        }, cellClassName: () => { return 'value-cell-red' } 
      },
      { field: "percentual", headerName:"%",  headerClassName: 'header-detail',  headerAlign: 'right', width: 110, align: 'right', 
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          }) +  "%" ;
        } , cellClassName: () => { return 'value-cell-red-porcentagem-detail' }
      }
    ];  

    const columnsDetail2= [ 
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => (
          params.row.subRows !== null && !isEmpty(params.row.subRows) ? (<CustomDetailPanelToggle id={params.id} value={params.value} />) : null
        ),     
      },           
      { field: "lojaid", headerName: "Loja", headerClassName: 'header-detail', width: 50, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "emissao", headerName: "Emissão", 
        valueFormatter: (params) => { return new Date(params.value).toLocaleDateString('pt-BR') },             
        headerClassName: 'header-detail', width: 140, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "vencimento", headerName: "Vencimento", 
        valueFormatter: (params) => {
          if (params.value) {
            const ultimovencimentoDate = new Date(params.value);
            return getYear(ultimovencimentoDate) >= 1969 ? ultimovencimentoDate.toLocaleDateString('pt-BR') : '';
          }
          return null;
        },        
        headerClassName: 'header-detail', width: 140, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "documento", headerName: "Documento", headerClassName: 'header-detail', width: 100, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "parcela", headerName: "Parcela", headerClassName: 'header-detail', width: 100, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "historico", headerName: "Histórico/Obs", headerClassName: 'header-detail', width: 200, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "valor", headerName:"Valor Líquido R$",  
        valueFormatter: (params) => {
          return  "R$ " + parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
        }, headerClassName: 'header-detail',  headerAlign: 'right', width: 120, align: 'right', cellClassName: () => { return 'value-cell-detail2' } 
      },
      {
        headerName: '',
        field: 'rateio',
        headerClassName: 'header-detail',        
        renderCell: (params) => (
          params.row.rateio === true
            ?  <Tooltip title="Conta Rateada" onClick={() =>  handleDetalheRateio(params.row.pagarid)}>
                  <img style={{ width: '16px', cursor: 'pointer' }} src={letraR} alt="provisao" />
                </Tooltip>               
            : null
        ),
        width: 15,
        headerHeight: 10
      } , 
      {
        headerName: '',
        field: 'provisao',
        headerClassName: 'header-detail',        
        renderCell: (params) => (
          params.row.provisao === true
            ?  <Tooltip title="Conta Provisionada" onClick={() =>  handleDetalheProvisao(params.row.pagarid)}>
                  <img style={{ width: '16px', cursor: 'pointer' }} src={letraP} alt="provisao" />
                </Tooltip>              
            : null
        ),
        width: 15,
        headerHeight: 10
      }         
    ];       

    const handleDetalheRateio = async(pagarid) => {
      if (pagarid !== 0)
      {        
        await setPagarIdDetalhe(pagarid);
        await setShowDetalheRateio(true);  
      }       
    };      
    
    const handleDetalheProvisao = async(pagarid) => {
      if (pagarid !== 0)
      {        
        await setPagarIdDetalhe(pagarid);
        await setShowDetalheProvisao(true);  
      }       
    };     

    const columnsDetailCaixaMovimento= [ 
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => (
          params.row.subRows !== null && !isEmpty(params.row.subRows) ? (<CustomDetailPanelToggle id={params.id} value={params.value} />) : null
        ),     
      },         
      { field: "lojaid", headerName: "Loja", headerClassName: 'header-detail', width: 60, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "emissao", headerName: "Emissão", 
        valueFormatter: (params) => { return new Date(params.value).toLocaleDateString('pt-BR') },             
        headerClassName: 'header-detail', width: 140, cellClassName: () => { return 'value-cell-detail2' } },      
      { field: "contadescricao", headerName: "Conta", headerClassName: 'header-detail', width: 230, cellClassName: () => { return 'value-cell-detail2' } },      
      { field: "historico", headerName: "Histórico", headerClassName: 'header-detail', width: 180, cellClassName: () => { return 'value-cell-detail2' } },
      { field: "valor", headerName:"Valor R$",  
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
        }, headerClassName: 'header-detail',  headerAlign: 'right', width: 130, align: 'right', cellClassName: () => { return 'value-cell-detail2' } } 
    ];       

    const getRowClassName = (params) => {      
      if (params.row.descricao === "PONTO DE EQUILÍBRIO")
        params.row.percentual = "";

      if (params.row.descricao === "")
      {
        params.row.percentual = "";
        params.row.valor = "";
      }        
      
      return 'bold-row';
    };  

    const getCellClassName = (params) => {
      if (params.row.descricao === 'PONTO DE EQUILÍBRIO' || params.row.descricao === '(=) TOTAL RESULTADO BRUTO (MC)' 
        || params.row.descricao === '(=) TOTAL RESULTADO OPERACIONAL' || params.row.descricao === '(=) TOTAL RESULTADO LÍQUIDO')  {
        return 'black-row'; 
      }
      
      if (params.row.descricao === "(=) TOTAL DE RECEITAS BRUTA" || params.row.descricao === "(=) TOTAL DE RECEITAS LÍQUIDA"        
       || params.row.descricao === '(+) JUROS E MULTAS RECEBIDOS' 
       || params.row.descricao === '(+) OUTRAS RECEITAS'
       || params.row.descricao === '(+) CUSTO DA MERCADORIA DEVOLVIDA (TROCAS)')   {
        return 'blue-row'; 
      }

      if (params.row.descricao === '(=) TOTAL DE DESPESAS')   {
        return 'red-row'; 
      }
     
      return '';
    };        

    const contextvalues = {        
        definirLojasSelecionadas,        
        dataIntervaloFiltro,
        lojasFiltro  
    }  

    
    if (loading) {
        return (
            <LoadingScreen/>
        );
      }        
                
      const columnsPrincipal = [
        {
          headerClassName: 'entrada-header', 
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => (
            params.row.subRows !== null && !isEmpty(params.row.subRows) ? (<CustomDetailPanelToggle id={params.id} value={params.value} />) : null
          ),     
        },
        { field: "descricao", fontSize: '25px', headerName: "Descrição", headerClassName: 'entrada-header', headerAlign: 'left', width: 410, align: 'left', 
           cellClassName: () => { return 'description-cell' }
        },
        { field: "valor", headerName: "Valor R$", headerClassName: 'entrada-header', headerAlign: 'right', width: 530, align: 'right', 
            valueFormatter: (params) => {
                return params.value === "" ? "" : "R$ " + parseFloat(params.value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }) ;
            }, cellClassName: () => { return 'value-cell' } 
        },        
        { field: "percentual", headerName: "%", headerClassName: 'entrada-header', headerAlign: 'center', width: 160, align: 'center', 
          valueFormatter: (params) => {            
              return params.value === 0 || params.value === '' ? "" :  parseFloat(params.value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) + "%";
          }, cellClassName: () => { return 'value-cell-porcentagem' } 
        },     
      ];   

      function extractClientesIdsFromClientesData(array) {
        let allContIds = array.map((conta) => conta.id)              
        return allContIds;
    }
      const expandedRowIds = extractClientesIdsFromClientesData(dadosDRE); 

      const rows = dadosDRE.map((array) => ({
       
          id: array.id,
          descricao : array.descricao,
          valor : array.valor,
          percentual : array.percentual,
          subRows: array.subRows.map((subArray) => ({            
            id: subArray.id,
            descricao: subArray.descricao,
            valor : subArray.valor,
            percentual : subArray.percentual,
            subRows: subArray.subRows
          })),                   
        }
       ));

       const exportarExcel = async  () => {        
        const exportarExcel = new DRE_XLS(dadosDRE, null);
        exportarExcel.exportToXLS();
    }  
        
    return (
        <>               
        <DREContext.Provider value={contextvalues}> 

        <ModalDetalheRateio show={showDetalheRateio} pagarid={pagarIdDetalhe} onHide={handleCloseDetalheRateio}/>
        <ModalDetalheProvisao show={showDetalheProvisao} pagarid={pagarIdDetalhe} onHide={handleCloseDetalheProvisao}/>

            <form className="form">                
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Demonstrativo de Resultado &#40;D.R.E&#41;</label>
            </div>                                                
                <Card.Body>                                        
                    <Container>
                        <Card>                                                                                                                                                                                                            
                            <div className="filtros">
                            <div className="align-left">
                                <div className="row align-items-start" style={{marginTop: '20px', width:'460px' }}>
                                    <LocalizationProvider  
                                        dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                                               
                                    >                                                           
                                    <DateRangePicker                                          
                                        localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                        value={dataIntervaloFiltro}      
                                        onChange={(newValue) => setDataIntervaloFiltro(newValue)}                        
                                    />                     
                                    </LocalizationProvider>     
                                </div>
                                <div className="row align-items-start" style={{marginTop: '25px'}}>
                                    <div className="col-lg">
                                    <ButtonLojas
                                        width={'195px'}
                                        onClick={handleShowLojas}
                                        lojasFiltro={lojasFiltro}
                                    />
                                    </div>                                                                                                        
                                </div>                                                          
                            </div>
                        </div>      
                       
                        <div class="row" style={{marginTop: '30px'}}>
                            <div className='col-2'>
                                <div className="actions">
                                <ButtonExecute 
                                    width={'195px'}
                                    onClick={handleExecutar}
                                />   
                                </div>                    
                            </div>
                            <div className='col-7' style={{marginLeft: '15px'}}>
                                <ButtonClear                                     
                                    onClick={handleLimparFiltros}
                                />   
                            </div>                            
                        </div>                                                                                   
                        
                        </Card>
                    </Container>                 
                </Card.Body>                     
            </form>     
                
          <ModalLojas show={showLojas} value={DREContext} onHide={handleCloseLojas}/>              

          {!isEmpty(dadosDRE) && (
              <Container style={{marginBottom: '70px'}}>                                            
                <DataGridPro  
                    style={{ fontFamily:  [
                      '-apple-system',
                      'BlinkMacSystemFont',
                      '"Segoe UI"',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                      '"Apple Color Emoji"',
                      '"Segoe UI Emoji"',
                      '"Segoe UI Symbol"',
                    ].join(','),                         
                    }}                 
                    slots={{
                        toolbar: CustomToolbar                                          
                    }}        
                    getRowClassName={getRowClassName}  
                    getCellClassName={getCellClassName}                                         
                    columns={columnsPrincipal}                        
                    rows={rows}     
                    rowHeight={35}                                                           
                    hideFooter      
                    initialState={{ detailPanel: { 
                      expandedRowIds : expandedRowIds               
                    } }}                                 
                    columnHeaderHeight={30}   
                    disableColumnFilter                     
                    getDetailPanelHeight={() => 'auto'} 
                    getDetailPanelContent={!isEmpty(dadosDRE) ? getDetailPanelContent : null}                                                              
                    />                  
                 </Container>                 
                 )}                                            
        </DREContext.Provider>
        </>
    )
  }

export default DREProvider;

