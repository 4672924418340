import React, { useState, useContext, useEffect, useRef } from 'react';
import api from '../../services/api';
import { Container, Card } from 'react-bootstrap';
import ButtonAdicionarTodos from '../Buttons/ButtonAdicionarTodos';
import ButtonRemoverTodos from '../Buttons/ButtonRemoverTodos';
import ButtonSearch from '../Buttons/ButtonSearch';
import { SetTenant } from '../../services/api';

export const TableClienteCategorias = ({ contexto }) => {
  const [clienteCategorias, setClienteCategorias] = useState([]);
  const [clienteCategoriasData, setClienteCategoriasData] = useState([]);
  const [selectedClienteCategorias, setSelectedClienteCategorias] = useState([]);
  const [busca, setBusca] = useState('');
  const { definirClienteCategoriasSelecionadas } = useContext(contexto);
  const pesquisaRef = useRef(null);

  useEffect(() => {
    const storedSelectedClienteCategorias = sessionStorage.getItem('selectedClienteCategorias');
    if (storedSelectedClienteCategorias) {
      setSelectedClienteCategorias(JSON.parse(storedSelectedClienteCategorias));
    }
    pesquisaRef.current.focus();
  }, []);

  useEffect(() => {
    if (selectedClienteCategorias.length > 0) {
      sessionStorage.setItem('selectedClienteCategorias', JSON.stringify(selectedClienteCategorias));
    }
  }, [selectedClienteCategorias, clienteCategorias]);

  const handleClienteCategoriaSelection = (id) => {
    const updatedIndexes = selectedClienteCategorias.includes(id)
      ? selectedClienteCategorias.filter((index) => index !== id)
      : [...selectedClienteCategorias, id];
    setSelectedClienteCategorias(updatedIndexes);

    const selectedClienteCategoriasIds = updatedIndexes.map((index) => index.id);
    definirClienteCategoriasSelecionadas(selectedClienteCategoriasIds);
  };

  const handleRemoverClienteCategoriasSelecionadasClick = (id) => {
    const updatedClienteCategoriasSelecionadas = selectedClienteCategorias.includes(id)
      ? selectedClienteCategorias.filter((index) => index !== id)
      : [...selectedClienteCategorias, id];
    setSelectedClienteCategorias(updatedClienteCategoriasSelecionadas);

    const selectedClienteCategoriasIds = updatedClienteCategoriasSelecionadas.map((index) => index.id);
    definirClienteCategoriasSelecionadas(selectedClienteCategoriasIds);

    if (selectedClienteCategoriasIds.length === 0) {
      sessionStorage.removeItem('selectedClienteCategorias');
    }
  };

  const handleRowClick = (clienteCategoriaId) => {
    setClienteCategorias((prevCategorias) =>
      prevCategorias.filter((categoria) => categoria.id !== clienteCategoriaId)
    );
  };

  const handleRowSelectedClick = (categoria) => {

    if (!clienteCategorias.includes(categoria.id)) {
      const updatedClienteCategorias = [...clienteCategorias, categoria];
      setClienteCategorias(updatedClienteCategorias);
    }
  };

  const handleAdicionarTodos = () => {
    setSelectedClienteCategorias(clienteCategorias);
    setClienteCategorias([]);

    const selectedClienteCategoriasIds = clienteCategorias.map((index) => index.id);
    definirClienteCategoriasSelecionadas(selectedClienteCategoriasIds);
    sessionStorage.setItem('selectedClienteCategorias', selectedClienteCategorias);
  }

  const handleRemoverTodos = async () => {
    if (!clienteCategoriasData || clienteCategoriasData.length === 0) {
      getClienteCategorias();
    }
    setClienteCategorias(clienteCategoriasData);
    setSelectedClienteCategorias([]);
    definirClienteCategoriasSelecionadas([]);
    sessionStorage.removeItem('selectedClienteCategorias');
  }

  const getClienteCategorias = async () => {
    try {     
      let url = `clientecategoria/listagem/${busca.toUpperCase()}`;          

      await SetTenant();
      const response = await api.get(url);
      const selectedCategoriaClienteIds = selectedClienteCategorias.map(vendedor => vendedor.id);    
      const filteredData = response.data.filter(vendedor => !selectedCategoriaClienteIds.includes(vendedor.id));      
      setClienteCategorias(filteredData);
      setClienteCategoriasData(filteredData);
    } catch (e) {
      console.log('erro');
    }
  };

  const handlePesquisar = () => {
    getClienteCategorias();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;

      if (name === 'campopesquisa') {
        handlePesquisar();
      }
    }
  }

  return (
    <div style={{ maxHeight: '650px', overflowY: 'auto' }}>
      <Card style={{ border: '0px' }}>
        <div className="align-left">
          <div className="row">
            <div className="col">
              <input
                name="campopesquisa"
                type="text"
                className="form-control"
                id="loja"
                placeholder="Digite a descrição da categoria do cliente"
                value={busca}
                onKeyDown={handleKeyDown}
                ref={pesquisaRef}
                onChange={(ev) => setBusca(ev.target.value)}
              />
            </div>
            <div className="col-md-1">
              <ButtonSearch onClick={handlePesquisar} />
            </div>
          </div>
        </div>
      </Card>

      <Container>
        <div className="col">
          <Card>
            <div className='col'>
              <ButtonAdicionarTodos onClick={handleAdicionarTodos} />
            </div>
          </Card>
          <Card>
            <table className="table table-striped table-hover">
              <thead className="table mt-3">
                <tr>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Cód</th>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {clienteCategorias.map((clientecategoria) => {
                  if (selectedClienteCategorias.some((mrc) => mrc.id === clientecategoria.id)) {
                    return null;
                  } else {
                    return (
                      <tr
                        key={clientecategoria.id}
                        onClick={() => {
                          handleClienteCategoriaSelection(clientecategoria);
                          handleRowClick(clientecategoria.id);
                        }}
                      >
                        <td>{clientecategoria.id}</td>
                        <td>{clientecategoria.descricao}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </Card>
        </div>

        <div className="col">
          <Card>
            <Card>
              <div className='row justify-content-end'>
                <div className='col text-left'>
                  <label className='labelSelecionar'>Selecionados: {selectedClienteCategorias.length}</label>
                </div>
                <div className='col text-right'>
                  <ButtonRemoverTodos onClick={handleRemoverTodos} />
                </div>
              </div>
            </Card>
            <table className="table table-striped table-hover">
              <thead className="table-info mt-3">
                <tr>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Cód</th>
                  <th scope="col" style={{ backgroundColor: '#007CB0', color: 'white' }}>Descrição</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer' }}>
                {selectedClienteCategorias.map((clientecategoria) => (
                  <tr key={clientecategoria.id}
                    onClick={() => {
                      handleRemoverClienteCategoriasSelecionadasClick(clientecategoria);
                      handleRowSelectedClick(clientecategoria)
                    }}
                  >
                    <td>{clientecategoria.id}</td>
                    <td>{clientecategoria.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default TableClienteCategorias;

