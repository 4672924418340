import React, { createContext, useState, useEffect, useRef } from 'react';
import { Pagination, Typography, Box, Grid, Container, TextField, InputLabel, RadioGroup, Radio, FormControlLabel, Switch } from '@mui/material'
import {    
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector
} from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'; 
import PaginationItem from '@mui/material/PaginationItem';
import '../ComprasVendas/styles.css'
import { api } from '../../services/api';
import { isEmpty } from 'lodash';
import { startOfMonth, endOfMonth } from 'date-fns';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CustomToolBar from '../../components/Buttons/CustomToolBar';
import LoadingScreen from '../../components/Loading/TelaLoading';
import { Card } from 'react-bootstrap';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ButtonExecute from '../../components/Buttons/ButtonExecute';
import CustomStyledDataGrid from '../../components/DataGrid/StyledDataGrid';
import { ptBR } from 'date-fns/locale';
import SessionStorageUtils from '../../Utils/SessionStorageUtils';
import CustomDetailPanelToggle from '../../components/DataGrid/CustomDetailPanelToggle';
import ButtonLojas from '../../components/Buttons/ButtonLojas';
import ButtonItens from '../../components/Buttons/ButtonItens';
import ButtonMarcas from '../../components/Buttons/ButtonMarcas';
import ButtonCategorias from '../../components/Buttons/ButtonCategorias';
import ButtonDepartamentos from '../../components/Buttons/ButtonDepartamentos';
import ButtonColecoes from '../../components/Buttons/ButtonColecoes';
import ButtonCores from '../../components/Buttons/ButtonCores';
import ModalItem from '../../components/Modal/ModalItens';
import ModalLojas from '../../components/Modal/ModalLojas';
import ModalMarcas from '../../components/Modal/ModalMarcas';
import ModalCategorias from '../../components/Modal/ModalCategorias';
import ModalDepartamentos from '../../components/Modal/ModalDepartamentos';
import ModalColecoes from '../../components/Modal/ModalColecoes';
import ModalCores from '../../components/Modal/ModalCores';
import { ImpressaoComprasVendasPDF } from '../../components/Impressao/ImpressaoComprasVendasPDF';
import ButtonClear from '../../components/Buttons/ButtonClear';
import { SetTenant } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Permissoes from '../../Utils/Permissoes';
pdfMake.vfs = pdfFonts.pdfMake.vfs;  

export const ComprasVendasContext = createContext();

const ComprasVendasProvider = () => {
  const sessionStorageUtils = new SessionStorageUtils();  
  const PAGE_SIZE = 50;
  const [paginationModel, setPaginationModel] = useState({pageSize: PAGE_SIZE, page: 0});  
  const [nomeGrupo, setNomeGrupo] = useState([]);
  const [showLojas, setShowLojas] = useState(false);          
  const [showItens, setShowItens] = useState(false); 
  const [showCategorias, setShowCategorias] = useState(false);  
  const [showMarcas, setShowMarcas] = useState(false);  
  const [showDepartamentos, setShowDepartamentos] = useState(false);
  const [showColecoes, setShowColecoes] = useState(false);
  const [showCores, setShowCores] = useState(false);
  const [dadosComprasVendas, setDadosComprasVendas] = useState([]);    
  const [tipoVisaoSelect, setTipoVisaoSelect] = useState('item');  
  const [lojasFiltro, setLojasFiltro] = useState([]);   
  const [colecoesFiltro, setColecoesFiltro] = useState([]);    
  const [itensFiltro, setItensFiltro] = useState([]);
  const [marcasFiltro, setMarcasFiltro] = useState([]);   
  const [categoriasFiltro, setCategoriasFiltro] = useState([]);   
  const [departamentosFiltro, setDepartamentosFiltro] = useState([]);
  const [coresFiltro, setCoresFiltro] = useState([]);   
  const headerClassName = 'blue-header';      
  const [loading, setLoading] = useState(true);     
  const [limparFiltros, SetLimparFiltros] = useState(false);   
  const executeRef = useRef(null);    
  const [dataIntervaloVendaFiltro, setDataIntervaloVendaFiltro] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date())
  ]);
  const [dataIntervaloCompraFiltro, setDataIntervaloCompraFiltro] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date())
  ]);
  const [combinarPeriodo, setCombinarPeriodo] = useState({
    periodocompra: false    
  });
  const navigate = useNavigate();
  const permissao = new Permissoes(); 
    
  useEffect(() => {
      setTimeout(() => {                 
          setLoading(true); 
          if (ValidarPermissoes() == false) { return; }       
          LimparFiltrosSelecionados();               
          definirLojaInicial(); 
          DefinirNomeGrupo();
          setLoading(false);            
        }, 500);                      
  }, []);  
    
    async function ValidarPermissoes(){                    
      const autorizado = await permissao.ValidarPermissaoAcessoRelatorio('b4d6c647ce79f32092d60b6182ca46d8');        
      if (autorizado) { return true; }
      navigate('/home')              
      return false;      
  }  

    useEffect(() => {        
      if (limparFiltros)
      {
          definirLojaInicial();            
          SetLimparFiltros(false);
      }        
    }, [limparFiltros]);
    
    const handleLimparFiltros = async () => {   
      resetarDataInicial();
      LimparFiltrosSelecionados()                
      SetLimparFiltros(true);
    }
    
    function LimparFiltrosSelecionados() {
      sessionStorage.removeItem('selectedLojas');               
      sessionStorage.removeItem('selectedItens');               
      sessionStorage.removeItem('selectedMarcas');               
      sessionStorage.removeItem('selectedCategorias');               
      sessionStorage.removeItem('selectedDepartamentos');               
      sessionStorage.removeItem('selectedColecoes');               
      sessionStorage.removeItem('selectedCores');   
      setLojasFiltro([]);
      setItensFiltro([]);      
      setMarcasFiltro([]);
      setCategoriasFiltro([]);
      setDepartamentosFiltro([]);
      setColecoesFiltro([]);
      setCoresFiltro([]);
      setCombinarPeriodo({periodocompra: false});
      setDadosComprasVendas([]);
    }
    
    const resetarDataInicial = () => {
      setDataIntervaloCompraFiltro([
        startOfMonth(new Date()),
        new Date()
      ]);
      setDataIntervaloVendaFiltro([
        startOfMonth(new Date()),
        new Date()
      ]);
    };

    const visualizarImpressao = async  () => {        
      const classeImpressao = new ImpressaoComprasVendasPDF(dadosComprasVendas, filtrosSelecionados);
      const documento = await classeImpressao.PreparaDocumento();
      pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }      

    const handleChange = (event) => {
      setCombinarPeriodo({ ...combinarPeriodo, [event.target.name]: event.target.checked });
    };

    const DefinirNomeGrupo = async () => {
      const desencryptJSONObj = await sessionStorageUtils.GetUsuario();            
      const groupName = JSON.parse(desencryptJSONObj).nomegrupo;
      setNomeGrupo(groupName);
    }

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);

      const pageSize = useGridSelector(apiRef, gridPageSizeSelector); 
      const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
         
      return (
          <>
          <div>           
              <div className='table-align'>
                  {renderTable()}
              </div>                        
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                      <Typography variant="subtitle2" color="textSecondary">
                          Total de registros: {totalRowCount}
                      </Typography>
                  </div>

                  <div style={{marginLeft: '50px'}}>
                      <Typography variant="subtitle2" color="textSecondary" >
                          Mostrando registros {page * pageSize + 1} - {Math.min((page + 1) * pageSize, totalRowCount)} de {totalRowCount}
                      </Typography>
                  </div>
              </div>
                  
                  <div style={{ alignSelf: 'flex-end'}}>
                      <Pagination
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          page={page + 1}
                          count={pageCount}                                                    
                          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                          onChange={(event, value) => apiRef.current.setPage(value - 1)}
                      />
                  </div>
              </div>            
          </div>
       
        </>
        );        
    }

    const handleShowLojas = () => 
    {            
        setShowLojas(true);
    }          
        
    const handleShowColecoes = () => 
    {
          setShowColecoes(true);
    }

    const handleShowDepartamentos = () => 
    {
         setShowDepartamentos(true);
    }

    const handleShowItens = () => 
    {            
         setShowItens(true);
    }       

    const handleShowMarcas = () => 
    {
        setShowMarcas(true);
    }   
    
    const handleShowCategorias = () => 
    {
         setShowCategorias(true);
    }

    const handleShowCores = () => 
    {
          setShowCores(true);
    }   

    const handleCloseMarcas = () => 
    {
         setShowMarcas(false);
    }
    
    const handleCloseLojas = () => 
    {        
        setShowLojas(false);                           
    }

    const handleCloseItens = () => 
    {        
          setShowItens(false);                           
    }

    const handleCloseCategorias = () => 
    {
         setShowCategorias(false);
    }

    const handleCloseDepartamentos = () => 
    {
          setShowDepartamentos(false);
    }

    const handleCloseColecoes = () => 
    {
          setShowColecoes(false);
    }

    const handleCloseCores = () => 
    {
         setShowCores(false);
    }

    const definirItensSelecionados = (id) => 
    {
        setItensFiltro(id);        
    }    

    const definirLojasSelecionadas = (lojas) => 
    {
        setLojasFiltro(lojas);        
    }            
    
    const definirMarcasSelecionadas = (id) =>
    {
        setMarcasFiltro(id);
    }    

    const definirCategoriasSelecionadas = (id) =>
    {
        setCategoriasFiltro(id);
    }    

    const definirDepartamentosSelecionados = (id) =>
    {
        setDepartamentosFiltro(id);
    }    

    const definirColecoesSelecionadas = (id) =>
    {
        setColecoesFiltro(id);
    }    

    const definirCoresSelecionadas = (id) =>
    {
        setCoresFiltro(id);
    }   

  const definirLojaInicial = async()  => 
  {
      const desencryptJSONUser = await sessionStorageUtils.GetUsuario();                
      if ((!isEmpty(desencryptJSONUser)) && desencryptJSONUser.data !== null && lojasFiltro.length === 0)
      {          
          const userId = JSON.parse(desencryptJSONUser).id;         
          await SetTenant();                      
          const response = await api.get(`loja/lojascadastradas/${userId}`);
          const firstId = response.data[0].idloja;             
          await setLojasFiltro(firstId);               
      }          
  }

  const handleExecutar = async() => {           
      setLoading(true);
      await getComprasVendas();                                         
      setLoading(false);                  
  } 

  const columnsItemMarca = [   
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },          
    { field: "marcaitemdescricao", headerName: tipoVisaoSelect === 'item' ? "Item" : tipoVisaoSelect === 'marca' ? "Marca" : "Item" ,  headerClassName: 'blue-header', 
       width: 195, headerHeight : 10 , renderCell: (params) => { return <strong>{params.value}</strong> }},
    { field: "estoqueqtde", headerName: "Estoque",  headerClassName: 'blue-header',  
       width: 75, align:'right', headerAlign: 'right', headerHeight : 10,
       cellClassName: () => {return 'yellow-cell-soft'}
    },   
    { field: "estoquevalor", headerName:"Estoque R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 115, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
      });
      } , headerHeight : 10,
      cellClassName: () => {return 'yellow-cell-soft'}
    },
    { field: "compraqtde", headerName: "Compra",  headerClassName: 'blue-header',  
      width: 75, align:'right', headerAlign: 'right', headerHeight : 10, cellClassName: () => {return 'blue-cell-light'}},
    { field: "compravalor", headerName:"Compra R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 115, align: 'right', headerAlign: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
      } , headerHeight : 10 , cellClassName: () => {return 'blue-cell-light'}
    },   
    { field: "vendaqtde", headerName: "Venda",  headerClassName: 'blue-header', 
      width: 75 , align:'right', headerHeight : 10, headerAlign: 'right', cellClassName: () => {return 'green-cell-light'} }, 
   
    { field: "vendavalor", headerName:"Venda R$",  headerClassName: 'blue-header', headerAlign: 'right', width: 115, align: 'right',
        valueFormatter: (params) => {
          return parseFloat(params.value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
      } , headerHeight : 10, cellClassName: () => {return 'green-cell-light'} 
    },   
    { field: "markup", headerName:"Markup",  headerClassName: 'blue-header', headerAlign: 'right', width: 80, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) + '%' ;
      } , headerHeight : 10 
    },
    { field: "markupreal", headerName:"Markup Real",  headerClassName: 'blue-header', headerAlign: 'right', width: 95, align: 'right',
      valueFormatter: (params) => {
        return parseFloat(params.value).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) + '%';
    } , headerHeight : 10
    },
    { field: "condicionalqtde", headerName: "Qtde em Condi",  headerClassName: 'blue-header',  
      width: 120, align: 'right', headerHeight : 10
    },         
    { field: "trocaqtde", headerName: "Qtde Troca",  headerClassName: 'blue-header',  
      width: 90, align: 'right', headerHeight : 10
    },         
  ];         

  const tipoVisaoComprasVendasFiltroToInt = () => {
    const enumValues = {
      'item': 1,
      'marca' : 2,        
      'grade' : 3,        
    };

    return enumValues;
  };      

  const tipoVisaoToInt = tipoVisaoComprasVendasFiltroToInt();

  const filtrosSelecionados = {    
    tipoVisaoSelect,   
    dataIntervaloCompraFiltro,
    dataIntervaloVendaFiltro,    
    combinarPeriodo,
    lojasFiltro,
    itensFiltro,
    marcasFiltro,
    categoriasFiltro,
    departamentosFiltro,
    colecoesFiltro,
    coresFiltro,
    nomeGrupo : nomeGrupo
  }    

  const getComprasVendas = async() => {                   
    try 
    {                           
        const dataIniVenda = (`${dataIntervaloVendaFiltro[0].getFullYear()}-${dataIntervaloVendaFiltro[0].getMonth()+1}-${dataIntervaloVendaFiltro[0].getDate()}`);
        const dataFinVenda = (`${dataIntervaloVendaFiltro[1].getFullYear()}-${dataIntervaloVendaFiltro[1].getMonth()+1}-${dataIntervaloVendaFiltro[1].getDate()}`);        
        const dataIniCompra = (`${dataIntervaloCompraFiltro[0].getFullYear()}-${dataIntervaloCompraFiltro[0].getMonth()+1}-${dataIntervaloCompraFiltro[0].getDate()}`);
        const dataFinCompra = (`${dataIntervaloCompraFiltro[1].getFullYear()}-${dataIntervaloCompraFiltro[1].getMonth()+1}-${dataIntervaloCompraFiltro[1].getDate()}`);        
        
        let url = `compra/relatorio/comprasvendas/visao/${tipoVisaoSelect}/${lojasFiltro}/${dataIniVenda}/${dataFinVenda}/${combinarPeriodo.periodocompra}/${dataIniCompra}/${dataFinCompra}`; 

        if (itensFiltro.length > 0) {                
            url += `/${itensFiltro}`;
        } else {
            url += "/ ";
        }

        if (marcasFiltro.length > 0) {                
            url += `/${marcasFiltro}`;
        } else {
            url += "/ ";
        }         
        
        if (categoriasFiltro.length > 0) {                
            url += `/${categoriasFiltro}`;
        } else {
            url += "/ ";
        }      
        
        if (departamentosFiltro.length > 0) {                
            url += `/${departamentosFiltro}`;
        } else {
            url += "/ ";
        }       

        if (colecoesFiltro.length > 0) {                
            url += `/${colecoesFiltro}`;
        } else {
            url += "/ ";
        }    

        if (coresFiltro.length > 0) {                
            url += `/${coresFiltro}`;
        } else {
            url += "/ ";
        }    
               
        setDadosComprasVendas([]);               
        await SetTenant();   
        const response = await api.get(url);                                                                           
        setDadosComprasVendas(response.data);               
    }
    catch (e)
    {
        console.log('erro');
    }        
  }          

  const contextvalues = {    
    definirLojasSelecionadas,
    definirItensSelecionados,
    definirMarcasSelecionadas,
    definirCategoriasSelecionadas,
    definirDepartamentosSelecionados,
    definirColecoesSelecionadas,
    definirCoresSelecionadas
  }  

  function CustomToolbar() {
    return (
      <CustomToolBar print={visualizarImpressao}/>         
    );
  }           

  const rowsItemMarca = dadosComprasVendas.map((row) => {   
      if (tipoVisaoSelect === 'item' || tipoVisaoSelect === 'marca')      
      {
          const ItemInfo = {
              id : row.id ,
              marcaitemdescricao : row.marcaitemdescricao,
              compraqtde : row.compraqtde,
              vendaqtde : row.vendaqtde,
              estoqueqtde : row.estoqueqtde,
              compravalor : row.compravalor,
              vendavalor : row.vendavalor,       
              estoquevalor: row.estoquevalor,
              markup : row.markup,            
              markupreal : row.markupreal,            
              condicionalqtde : row.condicionalqtde,            
              trocaqtde : row.trocaqtde                       
          }                      
          return ItemInfo;          
      }                     
  });    
  
  const totalizador = dadosComprasVendas.reduce((total, obj) => {
    if (tipoVisaoSelect === 'item' || tipoVisaoSelect === 'marca')
    {        
        total.compraqtde += obj.compraqtde;
        total.compravalor += obj.compravalor;
        total.vendaqtde += obj.vendaqtde;
        total.vendavalor += obj.vendavalor;
        total.estoqueqtde += obj.estoqueqtde;
        total.estoquevalor += obj.estoquevalor;
        total.markup += obj.markup;
        total.markupreal += obj.markupreal;
        total.condicionalqtde += obj.condicionalqtde;
        total.trocaqtde += obj.trocaqtde;
        return total;
    }   
    }, { compraqtde: 0, compravalor: 0, vendaqtde: 0, vendavalor: 0,  estoqueqtde: 0, estoquevalor : 0, markup: 0, markupreal: 0, condicionalqtde: 0, trocaqtde: 0});

  if (loading) {
    return (
       <LoadingScreen/>
    );
  }   

  const renderTable = () => {
    if (tipoVisaoSelect === 'item' || tipoVisaoSelect === 'marca') {
      return (
        <table class="table">              
              <thead>                        
                  <th scope="col" style={{ width: '195px', fontSize: '20px' }}></th>                                   
                  <th scope="col" className="text-left" style={{width: '75px', fontSize: '20px'}}></th>                  
                  <th scope="col" className="text-right" style={{width: '115px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '75px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '115px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '75px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '115px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '80px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '95px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>                                      
                  <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>                                      
              </thead>
              <tbody>
                  <tr >
                  <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold'}}>Total</th>
                  <td className="text-right" 
                      style={{ backgroundColor: '#fff59d', fontWeight: 'bold', border: '1px solid #ccc' }}>
                      {!isEmpty(totalizador) ? totalizador.estoqueqtde : 0}
                  </td>
                  <td className="text-right" 
                      style={{ backgroundColor: '#fff59d', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.estoquevalor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#b3e5fc', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.compraqtde : 0)}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#b3e5fc', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.compravalor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#a5d6a7', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.vendaqtde : 0)}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#a5d6a7', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.vendavalor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? (dadosComprasVendas.reduce((acc, row) => acc + row.markup, 0) / dadosComprasVendas.length) : 0)
                        .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%' }
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                       {(!isEmpty(totalizador) ? (dadosComprasVendas.reduce((acc, row) => acc + row.markupreal, 0) / dadosComprasVendas.length) : 0)
                        .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2  }) + '%' }                      
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.condicionalqtde : 0)}
                  </td>    
                  <td className="text-right" 
                      style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc' }}>                            
                      {(!isEmpty(totalizador) ? totalizador.trocaqtde : 0)}
                  </td>                                                    
                  </tr>
              </tbody>
              </table> 
      );
    } else {
      return (
        <table class="table">              
        <thead>                        
            <th scope="col" style={{ width : '440px', fontSize: '20px' }}></th>                                   
            <th scope="col" className="text-left" style={{width: '100px', fontSize: '20px'}}></th>
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '100px', fontSize: '20px'}}></th>  
            <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>   
            <th scope="col" className="text-right" style={{width: '90px', fontSize: '20px'}}></th>  
            <th scope="col" className="text-right" style={{width: '120px', fontSize: '20px'}}></th>                                     
        </thead>
        <tbody>
            <tr >
            <th className="text-left" scope="row" style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold' }}>Total</th>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>
                    {!isEmpty(totalizador) ? totalizador.qtdeparcelas : 0}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorparcela : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.desconto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.acrescimo : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td> 
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.multa : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>    
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            ''
                    {(!isEmpty(totalizador) ? totalizador.juro : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>                                    
                <td className="text-right" 
                    style={{ backgroundColor: '#DAEBF2', fontWeight: 'bold', border: '1px solid #ccc'  }}>                            
                    {(!isEmpty(totalizador) ? totalizador.valorpgto : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',  fontWeight: 'bold'})}
                </td>                                    
            </tr>
        </tbody>
        </table> 
      );
    }
  };     
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { name } = e.target;
  
      if (name === 'documento') {
        executeRef.current.focus();                   
      }
    }    
  }

  return (    
    <ComprasVendasContext.Provider value={contextvalues}>        
    <form className="form"> 
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <label className='centered' style={{ fontSize: '30px', fontFamily: 'arial' }}>Relatório de Compras x Vendas</label>            
          </div>                                           
          <Card.Body>                    
              <Container>
                  <Card>
                  <div className='align-left'>
                      <div className='col'>
                      <label className='text' style={{ fontWeight: 'bold' }}>Visão:</label>
                      
                      <Card style={{width: 420}}>
                        <div className="row">                     
                          <div className="col-md-6">
                            <div className="form-check">                            
                                <Radio
                                  checked={tipoVisaoSelect === 'item'}
                                  onChange={() => {
                                    setTipoVisaoSelect('item'); 
                                    setDadosComprasVendas([]);                                                                   
                                  } }
                                  value={tipoVisaoSelect}
                                  name="radio-button-demo"
                                  inputProps={{ 'aria-label': 'A' }}
                                  size="small"
                                  id="itemRadio" 
                                />
                                <label class="form-check-label" for="itemRadio" style={{cursor: 'pointer'}}>
                                    Por Item
                                </label>                          
                            </div>                      
                          </div>                      

                          <div className="col">                                  
                              <Radio
                                checked={tipoVisaoSelect === 'marca'}
                                onChange={() => {
                                  setTipoVisaoSelect('marca'); 
                                  setDadosComprasVendas([]);                                                                   
                                } }
                                value={tipoVisaoSelect}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'B' }}
                                size="small"
                                id="marcaRadio"
                              />
                                <label class="form-check-label" for="marcaRadio" style={{cursor: 'pointer'}}>
                                    Por Marca
                                </label>                                                  
                          </div>                                        
                        </div>                    
                    </Card>   
                  </div>
                  </div>
                </Card>
              </Container>

              <Container>
              <Card>                                                                                                                                                                                                            
                      <div className="filtros">
                      <div className="align-left">
                      <div className='row'>
                        <div className='col'>
                          <div className="row" style={{ width: '506px' }}>
                            <div className="col">
                            <label className='text' style={{ fontWeight: 'bold', marginBottom: '20px' }}>Período de Venda:</label>   
                            <LocalizationProvider  
                                dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                                               
                              >                                                           
                              <DateRangePicker                               
                                localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                value={dataIntervaloVendaFiltro}      
                                onChange={(newValue) => setDataIntervaloVendaFiltro(newValue)}                                 
                              />                     
                            </LocalizationProvider>    
                            </div>
                          </div>            
                        </div>

                        <div className='col'>                        
                        <FormControlLabel 
                          control={<Switch checked={combinarPeriodo.periodocompra} onChange={handleChange} name="periodocompra" />}
                          label= {<Typography variant="body1" style={{ fontWeight: 'bold' }}>
                              Combinar Período de Compra:
                            </Typography>
                          }
                        />
                            <div className='row' style={{marginTop: '5px'}}>
                            <LocalizationProvider  
                                dateAdapter={AdapterDateFns} adapterLocale={ptBR}                                   
                              >                                                           
                              <DateRangePicker                               
                                localeText={{ start: 'Data Inicial', end: 'Data Final' }} 
                                value={dataIntervaloCompraFiltro}      
                                onChange={(newValue) => setDataIntervaloCompraFiltro(newValue)}       
                                disabled={!combinarPeriodo.periodocompra}                          
                              />                     
                            </LocalizationProvider>    
                            </div>
                            </div>
                                                      
                      </div>                  
                      </div>       

                      <div className="align-left">
                          <div className="row align-items-start" style={{marginTop: '20px'}}>
                              <div className="col-lg">
                                  <ButtonLojas
                                      onClick={handleShowLojas}
                                      width={'220px'}                                  
                                      lojasFiltro={lojasFiltro}
                                  />
                              </div>                                
                              <div className="col-lg">
                                  <ButtonItens
                                      onClick={handleShowItens}
                                      width={'220px'}
                                      itensFiltro={itensFiltro}
                                  />
                              </div>                               
                              <div className="col-lg">
                                  <ButtonMarcas 
                                      onClick={handleShowMarcas}
                                      width={'220px'}
                                      marcasFiltro={marcasFiltro}
                                  />                                         
                              </div>    
                              <div className="col-lg">
                                  <ButtonCategorias 
                                      onClick={handleShowCategorias}
                                      width={'220px'}
                                      categoriasFiltro={categoriasFiltro}
                                  />                                         
                              </div>                                                                                             
                          </div>                               
                      </div>

                      <div className="align-left">                
                          <div className="row align-items-start" style={{marginTop: '20px'}}>
                                  <div className="col">
                                    <ButtonDepartamentos 
                                        onClick={handleShowDepartamentos}
                                        width={'220px'}
                                        departamentosFiltro={departamentosFiltro}
                                    />                                         
                                  </div>    
                                  <div className="col-lg">
                                     <ButtonColecoes
                                        onClick={handleShowColecoes}
                                        width={'220px'}                                  
                                        colecoesFiltro={colecoesFiltro}
                                     />
                                  </div>                                     
                                  <div className="col-lg">
                                     <ButtonCores
                                        onClick={handleShowCores}
                                        width={'220px'}                                  
                                        coresFiltro={coresFiltro}                                                
                                     />
                                  </div>                                    
                              </div> 
                        </div>                                               
                      </div>        

                      <div class="row" style={{marginTop: '30px'}}>
                          <div className='col-2'>
                              <div className="actions">
                              <ButtonExecute 
                                  width={'220px'}
                                  onClick={handleExecutar}
                              />   
                              </div>                    
                          </div>
                          <div className='col-8' style={{marginLeft: '55px'}}>
                              <ButtonClear                                   
                                  onClick={handleLimparFiltros}
                              />   
                          </div>
                      </div>  
                  </Card>
              </Container>                 
          </Card.Body>          
      </form>     
          
      <ModalLojas show={showLojas} value={ComprasVendasContext} onHide={handleCloseLojas}/>                      
      <ModalItem show={showItens} value={ComprasVendasContext} onHide={handleCloseItens}/>                                     
      <ModalMarcas show={showMarcas} value={ComprasVendasContext} onHide={handleCloseMarcas}/>                                     
      <ModalCategorias show={showCategorias} value={ComprasVendasContext} onHide={handleCloseCategorias}/>
      <ModalDepartamentos show={showDepartamentos} value={ComprasVendasContext} onHide={handleCloseDepartamentos}/>
      <ModalColecoes show={showColecoes} value={ComprasVendasContext} onHide={handleCloseColecoes}/>
      <ModalCores show={showCores} value={ComprasVendasContext} onHide={handleCloseCores}/>
      
      <Box
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: 'blue',
          },
        }}
      >
        {dadosComprasVendas && dadosComprasVendas.length > 0 && (
        <Container>        
        <CustomStyledDataGrid                  
          columns={columnsItemMarca}                      
          rows={rowsItemMarca}                      
          slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination                          
          }}       
          headerHeight={20}                             
          headerClassName={headerClassName}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[PAGE_SIZE]}                                                                                 
        />                                
        </Container>
        )}
      </Box>
      
    </ComprasVendasContext.Provider>         
  );
}

export default ComprasVendasProvider;

